import React from "react";
import styles from "../../css/timeview/SearchInput.module.scss";
import { PropTypes } from "prop-types";

const SearchInput = ({
	name,
	id,
	searchVal,
	handleSearch,
	placeholder,
	searchRef = null,
	isDisabled = false,
	customStyles = {},
}) => {
	return (
		<div className={styles.SearchInput} style={customStyles}>
			<input
				type="text"
				name={name}
				id={id}
				ref={searchRef}
				value={searchVal}
				onChange={handleSearch}
				className={styles.SearchInput_input}
				placeholder={placeholder}
				disabled={isDisabled}
			/>
		</div>
	);
};

export default SearchInput;

SearchInput.defaultProps = {};

SearchInput.propTypes = {};
