import React, { useState } from "react";
import styles from "../../css/views/DailyTimeView.module.scss";
import { PropTypes } from "prop-types";
import {
	fetchTimeViewResources,
	fetchTimeViewTasks,
	getShiftIDs,
} from "../../helpers/utils_timeview";
import { getResidentIDs } from "../../helpers/utils_residents";
import { findAndReplaceStale } from "../../helpers/utils_tasks";
import { isEmptyArray } from "../../helpers/utils_types";
import {
	sortAlphaAscByKey,
	sortDateAscByKey,
} from "../../helpers/utils_processing";
// components
import Spinner from "../../components/shared/Spinner";
import TimeViewTaskList from "../../components/timeview/TimeViewTaskList";
import TimeViewTaskSummary from "../../components/timeview/TimeViewTaskSummary";
import TimeViewFilterController from "../../components/timeview/TimeViewFilterController";

const showTimeViewList = (isLoading, tasks) => {
	const showList = !isLoading && !isEmptyArray(tasks);

	return showList;
};

const initialFilters = {
	selectedFacility: {
		id: null,
		name: "",
		shifts: [],
		residents: [],
	},
	selectedResidents: [],
	selectedShifts: [],
	sortBy: "ASC",
};

const sortTypes = {
	Resident: "Resident", // resident name
	Task: "Task", // task name
	Time: "Time", // due date/time (eg. 'ScheduledDate')
	ADL: "ADL",
	TaskType: "TaskType", // ('scheduled' vs. 'unscheduled')
};

const DailyTimeView = ({ state, dispatch, dispatchAlert, history }) => {
	const { globals, user: currentUser } = state;
	const { currentFacility } = globals;
	const [filterSelections, setFilterSelections] = useState({
		// ...initialFilters
	});
	// daily tasks for all residents at a facility
	const [isLoadingView, setIsLoadingView] = useState(false);
	const [hasUpdated, setHasUpdated] = useState(false);
	const [dailyTasks, setDailyTasks] = useState([]);
	const [sortState, setSortState] = useState({
		isSorted: true,
		sortBy: "ADL",
		hideIndependent: false,
	});
	// scroll state
	const [scrollState, setScrollState] = useState({
		scrollPos: null,
		active: null,
		updated: false,
	});
	// new task ID
	const [newTaskIDs, setNewTaskIDs] = useState([]);

	const handleActive = ({ active, scrollPos, updated }) => {
		setScrollState({
			active,
			scrollPos,
			updated,
		});
	};

	// fetches resident tasks & photos, then syncs to state
	const applyFilterSelections = async (selectionState = {}) => {
		setIsLoadingView(true);
		const { selectedFacility, selectedShifts, selectedResidents } =
			selectionState;
		const { token } = currentUser;
		const residentIDs = getResidentIDs(selectedResidents);
		const shiftIDs = getShiftIDs(selectedShifts);

		// fetch tasks & photos & process/format data
		const { tasks, photos, photosMap } = await fetchTimeViewResources(token, {
			dayOfWeekDate: new Date().toISOString(),
			shifts: isEmptyArray(shiftIDs) ? [1] : shiftIDs,
			residentIDs: residentIDs,
		});

		// sync photos to global state & save filter selections locally
		if (!isEmptyArray(tasks?.ScheduledTasks)) {
			dispatch({
				type: "LOAD_TIMEVIEW",
				data: {
					selectedFacility,
					photosMap,
					photos,
				},
			});
			const mergedTasks = [
				...tasks?.ScheduledTasks,
				...tasks?.UnscheduledTasks,
			];
			const sorted = sortDateAscByKey("ADL", mergedTasks);
			// set state(s)
			setDailyTasks([...sorted]);
			setIsLoadingView(false);
			return setFilterSelections({ ...selectionState });
		} else {
			// set/reset state(s)
			setDailyTasks([]);
			setIsLoadingView(false);
			return setFilterSelections({ ...selectionState });
		}
	};

	const refetchTasks = async () => {
		setIsLoadingView(true);
		const { selectedShifts, selectedResidents } = filterSelections;
		const { token } = currentUser;
		const residentIDs = getResidentIDs(selectedResidents);
		const shiftIDs = getShiftIDs(selectedShifts);

		// fetch tasks & photos & process/format data
		const allTasks = await fetchTimeViewTasks(token, {
			dayOfWeekDate: new Date().toISOString(),
			shifts: isEmptyArray(shiftIDs) ? [1] : shiftIDs,
			residentIDs: residentIDs,
		});

		if (!isEmptyArray(allTasks)) {
			const sorted = sortAlphaAscByKey(sortState?.sortBy, allTasks);
			setDailyTasks([...sorted]);
			return setIsLoadingView(false);
		} else {
			setDailyTasks([]);
			return setIsLoadingView(false);
		}
	};

	// sync updates/changes to task list
	// replaces stale task w/ updated data record, then sets state
	const syncTaskChanges = (updatedTask = {}, sortState = {}) => {
		const currentTasks = [...dailyTasks];
		const newTasks = findAndReplaceStale(updatedTask, currentTasks);

		setDailyTasks(newTasks);
		setSortState({ ...sortState });
		setHasUpdated(true);
	};

	// sync one or more new tasks
	const syncNewTask = (newTasks = [], sortState = {}) => {
		// extract first task's task ID as task target (to determine if new task in list)
		const firstTask = newTasks?.[0];
		const newTaskID = firstTask?.AssessmentUnscheduleTaskId;
		// merge new tasks w/ existing tasks
		const currentTasks = [...dailyTasks];
		const mergedTasks = [...newTasks, ...currentTasks];
		// sync to state(s)
		setSortState(sortState);
		setDailyTasks(mergedTasks);
		setHasUpdated(true);
		setNewTaskIDs([newTaskID]);
		// ☝️ PREVIOUS 'syncNewTask()' CODE ☝️ //

		// 👇 NEW 'syncNewTask()' CODE 👇 //
		// refetchTasks();
	};

	const syncSorting = (sortState = {}) => {
		setSortState({ ...sortState });
		// RECENTLY ADDED
		setNewTaskIDs([]);
	};

	return (
		<div className={styles.DailyTimeView}>
			<header className={styles.DailyTimeView_header}>
				<h1 className={styles.DailyTimeView_header_title}>
					Tasks By Shift(s) View
				</h1>
				<p className={styles.DailyTimeView_header_desc}>
					Select a community, select one or more residents and one or more
					shifts via the "Resident Filters" button, then click "Apply & Load
					Tasks"
				</p>
			</header>
			<div className={styles.DailyTimeView_controller}>
				<TimeViewFilterController
					key={`TIME-SELECTOR-${currentUser?.facilities?.length}`}
					globalState={state}
					dispatch={dispatch}
					dispatchAlert={dispatchAlert}
					currentUser={currentUser}
					currentFacility={currentFacility}
					applySelections={applyFilterSelections}
				/>
			</div>

			<main className={styles.DailyTimeView_main}>
				{isLoadingView && (
					<div className={styles.DailyTimeView_main_loader}>
						<Spinner />
					</div>
				)}
				{showTimeViewList(isLoadingView, dailyTasks) && (
					<>
						<TimeViewTaskList
							key={`TV-TASK-LIST-${dailyTasks?.length}-${JSON.stringify(
								dailyTasks
							)}`}
							tasks={dailyTasks}
							currentUser={currentUser}
							currentFacility={currentFacility}
							dispatchAlert={dispatchAlert}
							dispatch={dispatch}
							globalState={state}
							syncTaskChanges={syncTaskChanges}
							syncNewTask={syncNewTask}
							filterSelections={filterSelections}
							hasUpdated={hasUpdated}
							scrollState={scrollState}
							handleActive={handleActive}
							initialSortState={sortState}
							syncSortState={syncSorting}
							newTaskIDs={newTaskIDs}
						/>
					</>
				)}
			</main>
		</div>
	);
};

export default DailyTimeView;

DailyTimeView.defaultProps = {};

DailyTimeView.propTypes = {};
