import React from "react";
import styles from "../../css/timeview/TimeViewControllerResidents.module.scss";
import sprite from "../../assets/icons/filters.svg";
import sprite2 from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import TimeViewFilter from "./TimeViewFilter";

const selected = "hsla(170, 100%, 39%, 1)";
const notSelected = "hsla(242, 89%, 64%, 1)";

const getIsSelected = (option, selections = [], disabledOptions = []) => {
	const selectedIDs = selections.map(({ id }) => id);
	const disabledIDs = disabledOptions.map(({ id }) => id);

	const isSelected = selectedIDs.includes(option.id);
	const isDisabled = disabledIDs.includes(option.id);
	return !isDisabled && isSelected;
};

const TimeViewControllerResidents = ({
	searchRef,
	searchVal,
	filterState = {},
	filterOptions = [],
	selections = [],
	disabledOptions = [],
	handleSearch,
	clearSearch,
	handleSelect,
	handleSelectAll,
	isAllSelected = false,
	saveSelections,
	clearSelections,
}) => {
	return (
		<div className={styles.TimeViewControllerResidents}>
			<div className={styles.TimeViewControllerResidents_top}>
				<svg className={styles.TimeViewControllerResidents_top_searchIcon}>
					<use xlinkHref={`${sprite2}#icon-magnifying-glass`}></use>
				</svg>
				<input
					type="text"
					name="search"
					id="search"
					value={searchVal}
					onChange={handleSearch}
					className={styles.TimeViewControllerResidents_top_search}
					placeholder="Search..."
					autoComplete="off"
					ref={searchRef}
				/>
				{!isEmptyVal(searchVal) && (
					<svg
						className={styles.TimeViewControllerResidents_top_clearIcon}
						onClick={clearSearch}
						onKeyDown={(e) => (e.key === "Enter" ? clearSearch(e) : null)}
						tabIndex={0}
					>
						<use xlinkHref={`${sprite2}#icon-clearclose`}></use>
					</svg>
				)}
			</div>
			<div className={styles.TimeViewControllerResidents_selectAll}>
				<button
					type="button"
					className={styles.TimeViewControllerResidents_selectAll_btn}
					onClick={handleSelectAll}
					style={isAllSelected ? { color: selected } : { color: notSelected }}
				>
					{isAllSelected ? "✓ All Selected" : "Select All"}
				</button>
			</div>
			<hr />
			<div className={styles.TimeViewControllerResidents_list}>
				<ul className={styles.TimeViewControllerResidents_list_options}>
					{!isEmptyArray(filterOptions) &&
						filterOptions.map((option, index) => (
							<TimeViewFilter
								option={option}
								handleSelect={() => handleSelect(option)}
								isSelected={getIsSelected(option, selections, disabledOptions)}
								isDisabled={disabledOptions.includes(option)}
								selections={selections}
								key={`${option}___${index}`}
							/>
						))}
				</ul>
			</div>
			<div className={styles.TimeViewControllerResidents_actions}>
				<button
					type="button"
					className={styles.TimeViewControllerResidents_actions_clearBtn}
					onClick={clearSelections}
				>
					Clear All
				</button>
				<button
					type="button"
					className={styles.TimeViewControllerResidents_actions_saveBtn}
					onClick={saveSelections}
				>
					Save
				</button>
			</div>
		</div>
	);
};

export default TimeViewControllerResidents;

TimeViewControllerResidents.defaultProps = {};

TimeViewControllerResidents.propTypes = {};
