import React, { useContext } from "react";
import styles from "../../css/views/DailyMultiViews.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
// components
import DailyViewSwitcher, {
	DailyViewPanel,
	DailyViewPanels,
	DailyViewSwitcherButton,
	DailyViewSwitcherButtons,
} from "../../components/daily/DailyViewSwitcher";
import DailyResidentView from "../daily/DailyResidentView";
import DailyTimeView from "../daily/DailyTimeView";
import Spinner from "../../components/shared/Spinner";

// NEW "DAILY VIEW" W/ SECTIONED TABS FOR SUB-VIEW(S) //

const DailyMultiViews = ({ history }) => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { AlertsHandler, dispatchAlert } = useAlerts({ ...initialSettings });
	const { app, globals, user } = globalState;
	const { isLoading } = app;
	const {
		loa,
		categories,
		currentResident,
		currentFacility, // added
		currentUnitType,
		residents, // added
		residentsByFacility, // added
		scheduledTasks,
		trackingTasks,
		unscheduledTasks,
		unscheduledTasksRaw,
	} = globals;
	const { facilities } = user;

	return (
		<>
			<div className={styles.DailyMultiViews}>
				<div className={styles.DailyMultiViews_load}>
					{isLoading && <Spinner />}
				</div>
				<section className={styles.DailyMultiViews_content}>
					<DailyViewSwitcher defaultIndex={1}>
						{/* BUTTONS */}
						<DailyViewSwitcherButtons>
							<DailyViewSwitcherButton>Cards</DailyViewSwitcherButton>
							<DailyViewSwitcherButton>Shifts</DailyViewSwitcherButton>
						</DailyViewSwitcherButtons>
						{/* PANELS */}
						<DailyViewPanels>
							<DailyViewPanel>
								<DailyResidentView
									key={`RESIDENT-CARDS-VIEW`}
									state={globalState}
									dispatch={dispatchToState}
									dispatchAlert={dispatchAlert}
									currentUser={user}
									currentFacility={currentFacility}
									history={history}
								/>
							</DailyViewPanel>
							<DailyViewPanel>
								<DailyTimeView
									key={`TIME-VIEW`}
									state={globalState}
									dispatch={dispatchToState}
									dispatchAlert={dispatchAlert}
									currentUser={user}
									currentFacility={currentFacility}
									history={history}
								/>
							</DailyViewPanel>
						</DailyViewPanels>
					</DailyViewSwitcher>
				</section>
			</div>

			{AlertsHandler}
		</>
	);
};

export default DailyMultiViews;

DailyMultiViews.defaultProps = {};

DailyMultiViews.propTypes = {};
