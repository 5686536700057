import {
	getResidentsByUserEmail,
	matchResidentByID,
	noResidentLoaded,
} from "./utils_residents";
import { getUserProfileByEmail, hasMultiFacility } from "./utils_user";
import {
	fetchDailyResidentData,
	getResidentLOA,
	isLOA,
} from "./utils_residentData";
import { sortResidentsBy } from "./utils_residents";
import { getUnscheduledTasks } from "./utils_unscheduled";
import { handleEmpties, isEmptyArray, isEmptyVal } from "./utils_types";
import {
	getFacilitiesByUserEmail,
	matchFacilityByID,
	noFacilityLoaded,
} from "./utils_facility";
import { groupBy } from "./utils_processing";
import { extractRawParams } from "./utils_params";
import { getUserSecurityInfo } from "./utils_security";
import {
	hasAlaAdminPerms,
	hasFacilityAdminPerms,
	hasMedTechPerms,
	hasRegionalAdminPerms,
	hasSuperUserPerms,
} from "./utils_userTypes";
import { hasReassess } from "./utils_reassess";

// ##TODOS:
// - Handles NON-ADMIN users that have multi-facility access ('getInitialResource()' & 'syncResourceToState()') ✓
// - Create logic to determine user types' statuses for NEW-INFRA users:
// 		- Admin (Regional)
// 		- Super-User
// 		- Med-Tech Restricted Access
// 		- Non-Admin
// 		- Facility Admin

const initialFacility = {
	communityName: null,
	facilityID: null,
	parentID: null,
	residents: [],
	shifts: [],
	address: {},
	exceptionTypes: [],
};

// called 1st (new updated replacement) - 4/1/2021 at 9:51 AM
// - Refactored version to be released in "alpha_v0.3.5"
const getInitialResource = async (auth) => {
	if (isEmptyVal(auth?.token) || isEmptyVal(auth?.username)) return;

	const { token, username } = auth;
	const [
		facilityList = [], // fallback to array if empty
		residentData = [], // fallback to array if empty
		securityData = {}, // fallback to object if empty
	] = await Promise.all([
		getFacilitiesByUserEmail(token, username),
		getResidentsByUserEmail(token, username), // remove this when implementing perf fixes
		getUserSecurityInfo(token, username), // replaced 'getUserProfileByXXXX()' as it supports 'ADVUSER' and 'NEW-INFRA' user types
	]);

	const {
		AdvUsers,
		AdvUserCommunities,
		UserProfile,
		UserLogins,
		UserApps,
		UserSecurityQuestions,
		UserSecurityAnswers,
	} = securityData;

	const AdvUser = AdvUsers?.[0] ?? {};
	const UserLogin = UserLogins?.[0] ?? {};
	const residents = residentData;

	const mergedProfile = {
		UserProfile: UserProfile,
		UserLogin: UserLogin,
		AdvUser: AdvUser,
		UserSecurityQuestions: UserSecurityQuestions,
		UserSecurityAnswers: UserSecurityAnswers,
		UserApps: UserApps,
		AdvUserCommunities: AdvUserCommunities,
	};

	const resByFacility = groupBy(residents, (x) => x?.FacilityId);

	return {
		facilityList: facilityList,
		residents: residents,
		residentsByFacility: resByFacility,
		userProfile: mergedProfile,
		auth: auth,
	};
};

// called 1st upon successful auth
// - updated 2/11/2021 6:50 AM: updated destructuring and object access
// fetches list of residents and user profile when authenticated
const getInitialResource_OLD = async (auth) => {
	if (isEmptyVal(auth?.token) || isEmptyVal(auth?.username)) return;

	const { token, username } = auth;
	const [
		facilityList = [], // fallback to array if empty
		residentData = [], // fallback to array if empty
		profileData = {}, // fallback to object if empty
	] = await Promise.all([
		getFacilitiesByUserEmail(token, username),
		getResidentsByUserEmail(token, username),
		getUserProfileByEmail(token, username), // possibly replace with 'getUserSecurityInfo()'???
		getUserSecurityInfo(token, username),
	]);

	const {
		AdvUser,
		UserLogin,
		// facility data: 1 or the other will be populated
		AdvUserCommunities,
		UserLoginFacilities,
	} = profileData;
	const residents = residentData.Data;

	const mergedProfile = {
		...AdvUser,
		...UserLogin,
	};

	return {
		facilityList: facilityList,
		residents: residents,
		userProfile: mergedProfile,
		auth: auth,
	};
};
// called 2nd upon successful auth
const syncResourceToState = (resource, state, dispatch) => {
	const {
		authData,
		userProfile,
		residents,
		residentsByFacility,
		facilityList,
	} = resource; // fetched from APIs

	// refactored 'getFacilityByUserType' on 4/1/2021 updated logic

	const getFacilityByUserType = (facilityList) => {
		if (facilityList?.length <= 1) {
			// if user only has access to 1 facility, auto-populate it as 'currentFacility'
			const facility = facilityList?.[0] ?? { ...initialFacility };
			const { FacilityId } = facility;
			const facilityResidents = groupBy(residents, (x) => x.FacilityId)[
				FacilityId
			];
			// const resByFacility = groupBy(residents, (x) => x.FacilityId)[FacilityId];
			return {
				communityName: facility?.CommunityName,
				facilityID: facility.FacilityId,
				parentFacilityID: facility?.ParentFacilityId ?? null,
				residents: !isEmptyArray(facilityResidents) ? facilityResidents : [],
				shifts: facility?.Shifts ?? [],
				address: {
					city: facility?.Address?.City,
					state: facility?.Address?.State,
					zip: facility?.Address?.Zip,
					street: facility?.Address?.Street,
				},
			};
		} else {
			// return 'currentFacility' initial state if users has multi-facility access, since nothings selected yet
			return { ...initialFacility };
		}
	};

	// NEW USER TYPE/PERMS LOGIC //
	// amended to rely on new user infra directly
	// Handles user types & various user-related fields:
	// - isSuperUser, isFacilityAdmin, isRegionalAdmin, isAdmin, isExecutiveAdmin...
	// - isPwdResetByXXXX...
	// - isSuspended, isLockedOut...
	const usersProfile = getUserProfileFields(
		authData,
		userProfile,
		facilityList
	);

	// OVERRIDE FOR CERTAIN CIRCUMSTANCES OF USER'S ACCESS
	// const isSuperUser =
	// 	facilityList?.length >= 200 && usersProfile?.AdvUser?.alaAdmin;

	return dispatch({
		type: "SUCCESS",
		data: {
			newState: {
				...state,
				user: {
					...state.user,
					...usersProfile,
					// REMOVED BELOW 'isSuperUser' as it's handled in 'usersProfile' field
					// isSuperUser: isSuperUser, // ??? TEMPORARY SOLUTION - REMOVE LATER
					// isAdmin: isSuperUser, // ??? TEMPORARY SOLUTION - REMOVE LATER
					facilities: handleEmpties(facilityList),
					hasMultiFacility: hasMultiFacility(facilityList),
				},
				globals: {
					...state.globals,
					residents: [...sortResidentsBy(residents, "Name")],
					residentsByFacility: residentsByFacility,
					currentFacility: getFacilityByUserType(facilityList),
				},
			},
		},
	});
};

const getUserProfileFields = (authData, profile, facilityList) => {
	const isAdvUser = !isEmptyVal(profile?.AdvUser?.guidUser);

	const { AdvUser, UserProfile, UserLogin } = profile;

	// THE 'UserLogin' field DOES NOT PROVIDE NAME INFO???
	// THE 'UserLogin' data DOES NOT PROVIDE THE USER-TYPE (eg 'ADMIN', 'SUPER-USER' etc)
	// 	- For user permissions; check for 'AdvUser' record & fallback to NewInfra for now
	// 	- This hacky method will be removed later when NewInfra support is better & we can deprecate support for 'AdvUser'

	// 'User Type' perms
	const isSuper = hasSuperUserPerms(AdvUser, UserProfile);
	const isFacAdmin = hasFacilityAdminPerms(AdvUser, UserProfile);
	const isRegional = hasRegionalAdminPerms(AdvUser, UserProfile, facilityList);
	const isAdmin = hasAlaAdminPerms(AdvUser, UserProfile);
	const isMedTech = hasMedTechPerms(AdvUser, UserProfile);
	const isReadOnly = hasReassess(UserProfile);

	if (isAdvUser) {
		return {
			firstName: AdvUser?.strFirstName,
			lastName: AdvUser?.strLastName,
			facilityID: AdvUser?.guidFacility,
			userID: AdvUser?.guidUser,
			profileID: UserProfile?.UserProfileID ?? null,
			title: AdvUser?.strTitle,
			username: authData?.username,
			password: authData?.password,
			token: authData?.token,
			// isSuperUser: AdvUser?.superUser || AdvUser?.alaAdmin,
			// isAdmin: AdvUser?.alaAdmin ?? AdvUser?.bitFacilityAdministrator,
			// new fields
			isSuperUser: isSuper,
			isAdmin: isFacAdmin || isRegional || isSuper || isAdmin,
			isFacilityAdmin: isFacAdmin,
			isMedTechRestricted: isMedTech,
			isReadOnly: isReadOnly,
			isLockedOut: UserLogin?.IsLockOut ?? false,
			lockoutDate: UserLogin?.LockOutDate ?? null,
			isSuspended: UserLogin?.IsSuspended ?? false,
			suspendDate: UserLogin?.SuspendedDate ?? null,
			isPwdResetByEmail: UserLogin?.IsPwdResetByEmail ?? false,
			isPwdResetByQuestions: UserLogin?.IsPwdResetByQuestions ?? true,
			isPwdResetByAdmin: UserLogin?.IsPwdResetByAdmin ?? true,
		};
	} else {
		return {
			firstName: UserProfile?.FirstName,
			lastName: UserProfile?.LastName,
			facilityID: UserProfile?.FacilityId,
			userID: UserLogin?.UserLoginID,
			profileID: UserProfile?.UserProfileID,
			title: null,
			username: authData?.username,
			password: authData?.password,
			token: authData?.token,
			// isSuperUser: UserProfile?.superUser ?? AdvUser?.superUser,
			// isAdmin: AdvUser?.alaAdmin || AdvUser?.bitFacilityAdministrator, // ???? How to Handle 'UserLogin' users 'isAdmin' type
			isSuperUser: isSuper,
			isAdmin: isFacAdmin || isRegional || isSuper || isAdmin,
			isFacilityAdmin: isFacAdmin,
			isMedTechRestricted: isMedTech,
			isReadOnly: isReadOnly,
			isLockedOut: UserLogin?.IsLockOut ?? false,
			lockoutDate: UserLogin?.LockOutDate ?? null,
			isSuspended: UserLogin?.IsSuspended ?? false,
			suspendDate: UserLogin?.SuspendedDate ?? null,
			isPwdResetByEmail: UserLogin?.IsPwdResetByEmail ?? false,
			isPwdResetByQuestions: UserLogin?.IsPwdResetByQuestions ?? true,
			isPwdResetByAdmin: UserLogin?.IsPwdResetByAdmin ?? true,
		};
	}
};

const mergeDailyResidentData = async (token, residentID, day = new Date()) => {
	const dailyData = await fetchDailyResidentData(token, residentID, day);
	const unscheduledTasks = await getUnscheduledTasks(token, residentID);
	const l_o_a = await getResidentLOA(token, residentID); // leave_of_absence

	const merged = {
		...dailyData,
		UnscheduledTasks: unscheduledTasks,
		LOA: [l_o_a],
		ResidentID: residentID,
	};
	return { ...merged };
};

const populateState = (data, state) => {
	const {
		ADL,
		ADLCareTask,
		ADLCareTaskHistory,
		ADLCareTaskFuture,
		ADLCareLevel,
		Resident,
		ResidentID, // comes from API - LEAVE ALONE!!!
		AssessmentTrackingTask,
		AssessmentTrackingTaskNote,
		AssessmentFacilityException,
		AssessmentTrackingReassess,
		Charts,
		Vitals,
		UnscheduledTasks,
		UnscheduledTasksRaw,
		ADLCategory,
		LOA,
		Medications,
	} = data;
	const [resident] = Resident;

	const newState = {
		...state,
		globals: {
			...state.globals,
			currentFacility: {
				...state.globals.currentFacility,
				exceptions: handleEmpties(AssessmentFacilityException),
			},
			currentResident: {
				...resident,
				ResidentID: ResidentID,
				isLOA: isLOA(LOA[0]),
				Meds: handleEmpties(Medications),
				reassessRecords: handleEmpties(AssessmentTrackingReassess),
			},
			adlDescriptions: handleEmpties(ADL),
			unscheduledTasks: handleEmpties(UnscheduledTasks),
			unscheduledTasksRaw: handleEmpties(UnscheduledTasksRaw),
			scheduledTasks: handleEmpties(ADLCareTask),
			scheduledTaskNotes: handleEmpties(AssessmentTrackingTaskNote),
			scheduledTasksHistory: handleEmpties(ADLCareTaskHistory),
			scheduledTasksFuture: handleEmpties(ADLCareTaskFuture),
			trackingTasks: handleEmpties(AssessmentTrackingTask),
			categories: handleEmpties(ADLCareLevel),
			activeCategories: handleEmpties(ADLCategory),
			charting: handleEmpties(Charts),
			vitals: handleEmpties(Vitals),
			loa: handleEmpties(LOA),
		},
	};

	return { ...newState };
};

// utils for loading from url params
const loadDefaultStateFromParams = (allFacilities = [], allResidents = []) => {
	const allParams = extractRawParams(window.location.href, [
		"residentID",
		"facilityID",
	]);
	const facilityRecord = matchFacilityByID(
		allParams?.facilityID,
		allFacilities
	);
	const residentRecord = matchResidentByID(
		+allParams?.residentID,
		allResidents
	);

	return { facility: facilityRecord, resident: residentRecord };
};

const noDataLoaded = (
	selectedRes,
	selectedFac,
	currentResident,
	currentFacility
) => {
	return (
		isEmptyVal(selectedRes) &&
		noResidentLoaded(currentResident) &&
		noFacilityLoaded(selectedFac, currentFacility)
	);
};

const hasFacilityAndResidents = (facilities = [], residents = []) => {
	return !isEmptyArray(facilities) && !isEmptyArray(residents);
};
// checks url for a single param
const hasTargetParams = (url, targetParam) => {
	const urlParams = extractRawParams(url, [targetParam]);
	return !isEmptyVal(urlParams[targetParam]);
};

export { populateState };

export {
	getInitialResource,
	getInitialResource_OLD,
	syncResourceToState,
	mergeDailyResidentData,
};
export {
	noDataLoaded,
	loadDefaultStateFromParams,
	hasFacilityAndResidents,
	hasTargetParams,
};
