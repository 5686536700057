import React, { useState } from "react";
import styles from "../../css/timeview/AdvancedAdlNotes.module.scss";
import { PropTypes } from "prop-types";
import {
	getAdlKeyByAdl,
	getAdlNotesByResidentID,
	processAdlNotes,
} from "../../helpers/utils_categories";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
// components
import SpinnerSM from "../shared/SpinnerSM";

const getTaskAdlNotes = (task, assessmentNotes) => {
	const { ADL } = task;
	const adlKey = getAdlKeyByAdl(ADL);
	const notes = assessmentNotes?.[adlKey];
	const fallback = `No "${adlKey}" notes found.`;

	return isEmptyVal(notes) ? fallback : notes;
};

const AdvancedAdlNotes = ({
	currentResident = {},
	currentUser = {},
	currentFacility = {},
	task = {},
}) => {
	const [assessmentNotes, setAssessmentNotes] = useState({});
	const [showAdlNotes, setShowAdlNotes] = useState(false);
	const [status, setStatus] = useState("NOT-LOADED");

	const handleToggle = () => {
		switch (status) {
			case "NOT-LOADED": {
				setStatus("LOADING");
				fetchAdlNotes();
				return setShowAdlNotes(!showAdlNotes);
			}
			case "LOADING": {
				setShowAdlNotes(!showAdlNotes);
				return setStatus("NOT-LOADED");
			}
			case "LOADED": {
				setAssessmentNotes({});
				setStatus("LOADED");
				return setShowAdlNotes(!showAdlNotes);
			}
			default:
				return new Error(`Invalid status: ${status}`);
		}
	};

	const fetchAdlNotes = async () => {
		const { token } = currentUser;
		const { ResidentId } = task;
		const record = await getAdlNotesByResidentID(token, ResidentId);

		if (!isEmptyObj(record)) {
			const allNotes = processAdlNotes(record);
			setStatus("LOADED");
			return setAssessmentNotes({ ...allNotes });
		} else {
			setStatus("NOT-LOADED");
			return setAssessmentNotes({});
		}
	};

	return (
		<div className={styles.AdvancedAdlNotes}>
			<button
				type="button"
				onClick={handleToggle}
				className={styles.AdvancedAdlNotes_toggle}
			>
				{showAdlNotes ? "Hide" : "Show"} ADL Notes
			</button>
			{status === "LOADING" && (
				<div className={styles.AdvancedAdlNotes_loader}>
					<SpinnerSM />
					<div className={styles.AdvancedAdlNotes_loader_text}>
						Loading ADL notes...
					</div>
				</div>
			)}
			{showAdlNotes && status === "LOADED" && (
				<div className={styles.AdvancedAdlNotes_main}>
					<div className={styles.AdvancedAdlNotes_main_label}>
						{getAdlKeyByAdl(task?.ADL ?? task?.ADLCategory)}
					</div>
					<div className={styles.AdvancedAdlNotes_main_notes}>
						{getTaskAdlNotes(task, assessmentNotes)}
					</div>
				</div>
			)}
		</div>
	);
};

export default AdvancedAdlNotes;

AdvancedAdlNotes.defaultProps = {};

AdvancedAdlNotes.propTypes = {};
