import React from "react";
import styles from "../../css/history/HistoricalUserAssignment.module.scss";
import { PropTypes } from "prop-types";
import CustomDropdown from "../shared/CustomDropdown";
import UserDropdown from "../user/UserDropdown";

// REQUIREMENTS:
// - Includes a dropdown that allows an ADMIN to assign a given action or actions to a specific user (eg. a caregiver)

const customCSS = {
	selector: {
		width: "45rem",
	},
};

const HistoricalUserAssignment = ({
	msg = "You can assign these changes to another user from your community for your records.",
	assignTo = {}, // assign to user
	currentUser = {},
	availableUsers = [],
	handleUserAssign,
}) => {
	return (
		<div className={styles.HistoricalUserAssignment}>
			<div className={styles.HistoricalUserAssignment_msg}>{msg}</div>
			<div className={styles.HistoricalUserAssignment_selector}>
				<UserDropdown
					name="assignTo"
					id="assignTo"
					label="Assign to a user"
					selection={assignTo}
					setSelection={handleUserAssign}
					options={availableUsers}
					customStyles={customCSS.selector}
				/>
				{/* <CustomDropdown
					name="assignTo"
					id="assignTo"
					label="Assign to a user"
					selection={assignToUserVal}
					setSelection={handleUserAssign}
					options={availableUsers}
					customStyles={customCSS.selector}
				/> */}
			</div>
		</div>
	);
};

export default HistoricalUserAssignment;

HistoricalUserAssignment.defaultProps = {};

HistoricalUserAssignment.propTypes = {};
