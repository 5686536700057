import React from "react";
import { Route, NavLink } from "react-router-dom";
import { isEmptyArray } from "../../helpers/utils_types";
import { themeColors } from "../../helpers/utils_styles";
// NavLink styles
import styles from "../../css/app/Sidetab.module.scss";
// VIEWS: 1ST-LEVEL
import DailyView from "../../views/dashboard/DailyView";
import CalendarView from "../../views/dashboard/CalendarView";
import SettingsView from "../../views/dashboard/SettingsView";
import ReportsView from "../../views/dashboard/ReportsView";
import AdlScheduleView from "../../views/dashboard/AdlScheduleView";
// HISTORICAL DOCUMENTING VIEW
import HistoricalTaskView from "../../views/dashboard/HistoricalTaskView";
// REPORT DASHBOARD VIEWS (2ND-LEVEL)
import PastDueReport from "../../views/reports/PastDueReport";
import ActualTimeReport from "../../views/reports/ActualTimeReport";
import ReassessReport from "../../views/reports/ReassessReport";
import CompletionReport from "../../views/reports/CompletionReport";
import TaskStatusReport from "../../views/reports/TaskStatusReport";
import ExceptionReport from "../../views/reports/ExceptionReport";
import TaskCreatedReport from "../../views/reports/TaskCreatedReport";
import ServicePlanReport from "../../views/reports/ServicePlanReport";
import AlertsDashboard from "../../views/app/AlertsDashboard";
import DailyMultiViews from "../../views/dashboard/DailyMultiViews";

////////////////////////////////////////////////////////////
///////////////////// NAV LINK CONFIG /////////////////////
////////////////////////////////////////////////////////////

/**
 * <NavLink/>'s in the <Sidetab/>
 * - Super-User: Daily, Calendar, Reports(dashboard), Adl Shift Schedule, Settings
 * - Admin: Daily, Calendar, Reports(dashboard), Adl Shift Schedule
 * - Non-Admin: Daily, Calendar
 */
const NAV_LINKS = [
	{
		name: `Daily Schedule`,
		desc: `Daily view w/ task schedule.`,
		to: `/daily`,
		restrict: false,
		isActive: true,
	},
	{
		name: `Calendar`,
		desc: `Weekly read-only overview of tasks.`,
		to: `/calendar`,
		restrict: false,
		isActive: true,
	},
	{
		name: `Reports Dashboard`,
		desc: `Quick access report card descriptions.`,
		to: `/reports`,
		restrict: false,
		isActive: true,
	},
	{
		name: `Resident Shift Schedule`,
		desc: `Adl shift schedule settings, w/ shift times.`,
		to: `/adlschedule`,
		restrict: true,
		isActive: false,
	},
	{
		name: `Historical Task View`,
		desc: `View, manage, edit & update historical tasks for your records.`,
		to: `/history`,
		restrict: true,
		isActive: true,
	},
];

// renders 'SUPER-USER' access links
const renderSuperUserLinks = (links) => {
	return links.map((link) => link);
};
// renders 'ADMIN' access links
const renderAdminLinks = (links) => {
	return links.filter((link) => link?.isActive);
};
// renders 'NON-ADMIN' access links
const renderNonAdminLinks = (links) => {
	return links.filter((link) => link?.isActive && !link?.restrict);
};
// renders <NavLink/> & <li/> element w/ route, match.url & history
const renderLinks = (links, history, match) => {
	return links.map((link, idx) => {
		return (
			<li
				className={styles.Sidetab_menu_top_list_item}
				key={`${idx}-${link.to}--${link.name}`}
			>
				<NavLink
					to={{
						pathname: `${match.url}${link.to}`,
						state: {
							from: history.location.pathname,
						},
					}}
					activeStyle={{ color: themeColors.main.blue }}
				>
					{link.name}
				</NavLink>
			</li>
		);
	});
};

const linksHandler = (currentUser, links = [], history, match) => {
	switch (true) {
		case currentUser?.isSuperUser: {
			const superLinks = renderLinks(
				[...renderSuperUserLinks(links)],
				history,
				match
			);
			return superLinks;
			// return renderLinks([...renderSuperUserLinks(links)], history, match);
		}
		case !currentUser?.isSuperUser && currentUser?.isAdmin: {
			const adminLinks = renderLinks(renderAdminLinks(links), history, match);
			return adminLinks;
			// return renderLinks(renderAdminLinks(links), history, match);
		}
		case !currentUser?.isAdmin: {
			return renderLinks(renderNonAdminLinks(links), history, match);
		}
		default:
			return renderLinks(renderNonAdminLinks(links), history, match);
	}
};

////////////////////////////////////////////////////////////
////////////////////// ROUTES CONFIG //////////////////////
////////////////////////////////////////////////////////////

/**
 * <Route/>'s
 * - Super-User: Daily, Calendar, Reports(dashboard), Adl Shift Schedule, Settings
 * - Admin: Daily, Calendar, Reports(dashboard), Adl Shift Schedule
 * - Non-Admin: Daily, Calendar
 * - Inactive: Settings, Actual Time Report, Alerts Dashboard
 */
const ROUTES_CONFIG = [
	{
		name: `Daily View`,
		exact: true,
		path: "/dashboard/daily",
		// component: DailyView,
		component: DailyMultiViews,
		props: {},
		restrict: false,
		isActive: true,
	},
	{
		name: `Calendar View`,
		exact: false,
		path: "/dashboard/calendar",
		component: CalendarView,
		props: {},
		restrict: false,
		isActive: true,
	},
	{
		name: `Reports Dashboard`,
		exact: false,
		path: "/dashboard/reports",
		component: ReportsView,
		props: {},
		restrict: false,
		isActive: true,
	},
	{
		name: `Past Due Report`,
		exact: false,
		path: "/dashboard/pastdue",
		component: PastDueReport,
		restrict: false,
		isActive: true,
	},
	{
		name: `Reassess Report`,
		exact: false,
		path: "/dashboard/reassess",
		component: ReassessReport,
		restrict: false,
		isActive: true,
	},
	{
		name: `Completion Report`,
		exact: false,
		path: "/dashboard/completion",
		component: CompletionReport,
		restrict: false,
		isActive: true,
	},
	{
		name: `Exception Report`,
		exact: false,
		path: "/dashboard/exception",
		component: ExceptionReport,
		restrict: false,
		isActive: true,
	},
	{
		name: `Task Created Report`,
		exact: false,
		path: "/dashboard/taskcreated",
		component: TaskCreatedReport,
		restrict: false,
		isActive: true,
	},
	{
		name: `Service Plan Report`,
		exact: false,
		path: "/dashboard/serviceplan",
		component: ServicePlanReport,
		restrict: false,
		isActive: true,
	},
	{
		name: `Task Status Report`,
		exact: false,
		path: "/dashboard/taskstatus",
		component: TaskStatusReport,
		restrict: false,
		isActive: true,
	},
	{
		name: `Adl Shift Schedule`,
		exact: false,
		path: "/dashboard/adlschedule",
		component: AdlScheduleView,
		restrict: true,
		isActive: false,
	},
	{
		name: `Actual Time Report`,
		exact: false,
		path: "/dashboard/actualtime",
		component: ActualTimeReport,
		restrict: false,
		isActive: false,
	},
	{
		name: `Settings Report`,
		exact: false,
		path: "/dashboard/settings",
		component: SettingsView,
		restrict: true,
		isActive: false,
	},
	{
		name: `Alerts Dashboard`,
		exact: false,
		path: "/dashboard/alerts",
		component: AlertsDashboard,
		restrict: true,
		isActive: false,
	},
	{
		name: `Historical Task View`,
		exact: false,
		path: "/dashboard/history",
		component: HistoricalTaskView,
		restrict: true,
		isActive: true,
	},
];
/**
 * NON-ADMIN ACCESS:
 * Render ONLY 'active' & non-restricted routes.
 */
const renderNonAdminRoutes = (routes) => {
	return routes.filter((route) => route?.isActive && !route?.restrict);
};
/**
 * ADMIN ACCESS:
 * Render ALL 'active' routes, including restricted.
 */
const renderAdminRoutes = (routes) => {
	return routes.filter((route) => route?.isActive);
};
/**
 * SUPER-USER ACCESS:
 * Renders ALL available routes, including restricted & 'inactive'.
 */
const renderSuperUserRoutes = (routes) => {
	return routes.map((route) => route);
};
/**
 * @description - Generic render fn() that iterates thru each route.
 * @param {Array} routes - A pre-determined configuration of routes based off of user access.
 * @param {Object} props - Any props to passed down to a route's component.
 */
const renderRoutes = (routes = [], props = {}) => {
	if (isEmptyArray(routes)) return;

	return routes.map((route, idx) => {
		if (route?.exact) {
			return (
				<Route
					key={route?.path}
					exact
					path={route?.path}
					component={route?.component}
					{...props}
				/>
			);
		} else {
			return (
				<Route
					key={route?.path}
					path={route?.path}
					component={route?.component}
					{...props}
				/>
			);
		}
	});
};
/**
 * @description - Render fn() for handling access to routes by user permissions & role.
 * @param {Array} routes - Accepts the custom route config array to iterate thru each route checking for permissions.
 * @param {Object} user - The current authenticated user object that covers the user's permissions & access.
 */
const routesHandler = (routes, user, props) => {
	switch (true) {
		case user?.isSuperUser: {
			// case user?.superUser: {
			return renderRoutes([...renderSuperUserRoutes(routes)], props);
		}
		// case !user?.superUser && user?.isAdmin: {
		case !user?.isSuperUser && user?.isAdmin: {
			return renderRoutes([...renderAdminRoutes(routes)], props);
		}
		case !user?.isAdmin || user?.isMedTechRestricted: {
			return renderRoutes([...renderNonAdminRoutes(routes)], props);
		}
		default:
			return [];
	}
};

export { NAV_LINKS, ROUTES_CONFIG };

export {
	renderNonAdminRoutes,
	renderAdminRoutes,
	renderSuperUserRoutes,
	renderRoutes,
	// NavLink's util
	renderAdminLinks,
	renderNonAdminLinks,
	renderSuperUserLinks,
	linksHandler,
	renderLinks,
};

export { routesHandler };
