import { format } from "date-fns";
import { user, security, generic, facility } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { userParams } from "./utils_params";
import { isEmptyArray, isEmptyVal } from "./utils_types";

/**
 * Fetches a user's Profile including: 'AdvUser', 'AdvUserCommunities', 'UserLogin', 'UserLoginFacilities'
 * @param {String} token - Security token
 * @param {String} email - User's userID/LoginID.
 */
const getUserProfile = async (token, userID) => {
	let url = currentEnv.base + user.getProfile;
	url += "?userId=" + userID;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();
		const profile = await JSON.parse(response.Data);
		return profile;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a user's Profile including: 'AdvUser', 'AdvUserCommunities', 'UserLogin', 'UserLoginFacilities'
 * @param {String} token - Security token
 * @param {String} email - User's email and/or username.
 */
const getUserProfileByEmail = async (token, email) => {
	let url = currentEnv.base + user.getProfileByEmail;
	url += "?" + new URLSearchParams({ userEmail: email });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: new Headers({
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			}),
		});
		const response = await request.json();
		const profile = response.Data;
		return profile;
	} catch (err) {
		return;
	}
};

/**
 * Fetches an array of 'UserLogin' records by username.
 * @param {String} token - Security token
 * @param {String} username - A user's username, specifically the 'LoginName'
 * @returns {Array} - Returns an array of all 'UserLogin' records for a given user.
 */
const getUserLoginByUsername = async (token, username) => {
	let url = currentEnv.base + security.userLogin.getLogin;
	url += "?" + new URLSearchParams({ LoginName: username });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data?.[0] ?? {};
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a user's 'UserLogin' record via 'UserLoginID'
 * @param {String} token - Auth token
 * @param {String} userID - User guid string
 * @returns {Object} - Returns 'UserLogin' record object
 */
const getUserLoginfromID = async (token, userID) => {
	let url = currentEnv.base + user.getLoginRecord;
	url += "?" + new URLSearchParams({ UserLoginID: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Fetches a number of users for a facility.
 * - The index & rows determines *how* many users to fetch.
 */
const getUsersByFacility = async (
	token,
	facilityId,
	index = 0,
	rows = 1000
) => {
	let url = currentEnv.base + facility.getUsers;
	url += "?" + new URLSearchParams({ guidFacility: facilityId });
	url += "&" + new URLSearchParams({ index, rows });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// fetches 'ADVUSER' record by userID
const getAdvUserByUserID = async (token, userID) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...userParams });
	url += "&" + new URLSearchParams({ guidUser: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		const user = processAdvUserForTV(response?.Data?.[0]);

		return user;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

const processAdvUserForTV = (advUser = {}) => {
	const {
		strFirstName: first = "",
		strLastName: last = "",
		guidUser: userID = "",
		strEmail: username = "",
		strTitle: title = "",
	} = advUser;

	const newUser = {
		firstName: first,
		lastName: last,
		userID: userID,
		username: username,
		title: title,
	};

	return newUser;
};

// processes a list of 'AdvUser' records into client format & purges deleted users from the list
const processAllAdvUsers = (users) => {
	const purged = users.filter((x) => !x.NoLongerAnEmployee);
	const newList = [...purged.map((x) => processAdvUserForTV(x))];

	return newList;
};

// PASSWORD
/**
 * Enables changing a user's password. All passwords are encoded to enable special characters for passwords.
 * @param {String} token - Security token
 * @param {Object} vals - An object of new user password vals:
 * - 'userID': target user to be modified
 * - 'oldPassword': target user's old/current password OR 'OTP'
 * - 'newPassword': target user's new password
 *
 * Updated 2/3/2021 at 10:07 AM
 * - Removed 'new URLSearchParams' from "oldPassword" & "newPassword"
 * - This is due to the additional encoding applied via these methdods, which messes w/ special chars.
 */
const changeUserPassword = async (token, vals = {}) => {
	let url = currentEnv.base + user.edit.changePassword;
	url += "?" + new URLSearchParams({ userId: vals?.userID });
	url += "&oldPassword=" + encodeURIComponent(vals?.oldPassword);
	url += "&newPassword=" + encodeURIComponent(vals?.newPassword);

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// user field related helpers

// checks if user has access to multiple facilities
const hasMultiFacility = (facilityList = []) => {
	if (isEmptyArray(facilityList) || facilityList.length <= 1) {
		return false;
	}
	return true;
};

// FORMATTING HELPERS
// formats user's name: LastName, FirstName
const formatUserNameLast = (task) => {
	const firstName = task?.EntryUserFirstName ?? task?.UserFirstName;
	const lastName = task?.EntryUserLastName ?? task?.UserLastName;

	if (firstName === "Backend" && lastName === "User") {
		return `System Generated`;
	}

	if (task?.TaskStatus === `AUTO-COMPLETED`) {
		return `${firstName} ${lastName}`;
	}

	return `${lastName}, ${firstName}`;
};
const formatUserNameLastSvcPlan = (record) => {
	const { UpdatedBy } = record;

	return formatUserNameLast(UpdatedBy);
};

const generateUserID = (username, date = new Date()) => {
	const timestamp = format(date, "M/D/YYYY h:mm A");
	const uid = `${username}_at_${timestamp}`;
	return uid;
};

/**
 * "UserBadge": this is the badge that appears in the lower left corner
 * of the <Sidebar/> with the currently logged in user's initials.
 * - NOT the badge used for "UserAccessRow"s
 */

const getUserInitials = (user) => {
	const firstName = user?.firstName ?? user?.strFirstName;
	const lastName = user?.lastName ?? user?.strLastName;

	if (isEmptyVal(firstName) || isEmptyVal(lastName)) {
		return `NA`;
	} else {
		const first = firstName.slice(0, 1);
		const last = lastName.slice(0, 1);
		return `${first}${last}`;
	}
};
const getFormattedUserNames = (user) => {
	const first = user?.firstName ?? user?.strFirstName ?? "";
	const last = user?.lastName ?? user?.strLastName ?? "";

	return `${first} ${last}`;
};

export {
	getAdvUserByUserID,
	getUserProfile,
	getUserProfileByEmail,
	getUserLoginByUsername,
	getUserLoginfromID,
	getUsersByFacility,
};
// password handlers
export { changeUserPassword };

// user-field utils
export { hasMultiFacility };

// formatting utils
export {
	formatUserNameLast,
	formatUserNameLastSvcPlan,
	getUserInitials,
	getFormattedUserNames,
	processAdvUserForTV,
	processAllAdvUsers,
};

// user tracking uid
export { generateUserID };
