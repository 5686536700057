import React from "react";
import styles from "../../css/timeview/TimeViewResidentBadge.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";

const TimeViewResidentBadge = ({ task = {}, residentInfo = {} }) => {
	const roomNum =
		residentInfo?.RoomNum ??
		residentInfo?.roomNum ??
		task?.RoomNum ??
		task?.roomNum;

	return (
		<div className={styles.TimeViewResidentBadge}>
			<div className={styles.TimeViewResidentBadge_roomNum}>
				{/* {residentInfo?.RoomNum} */}
				{isEmptyVal(roomNum) ? "N/A" : roomNum}
			</div>
		</div>
	);
};

export default TimeViewResidentBadge;

TimeViewResidentBadge.defaultProps = {};

TimeViewResidentBadge.propTypes = {};
