import React from "react";
import { hasProp } from "./utils_types";
import { isDevOrTest, isLocalhost } from "./utils_env";

// List of UserIDs that should have access to super-features
const featuresWhiteList = [
	`f61244bd-f3c3-4b2f-a073-2a58f850c046`, // trainingsb@aladvantage.com - Seth's #1 (TEST & PROD)
	`7801cc7e-4462-4442-b214-bcdff70b3f95`, // trainingsb2@aladvantage.com - Seth's #2 (TEST & PROD)
	`edc28929-2c5d-4fc6-8188-f786c1d598d4`, // dandemo@aladvantage.com - Seth's super-user account
	`2807d3cc-c791-4eae-810d-5749a2347a68`, // testingme334@gmail... (PROD)
	`e99c5c77-68b7-4ccf-aa5f-5dd5601aff16`, // sgore99@ala... (PROD)
	`86a67ebe-38e6-4b66-bc5a-8b14940ddf68`, // crule@ala... (PROD)
	// `30e9000c-0b38-4645-bde2-824bdbfed572`, // granitecollission@gmail.com
];

/**
 * Internal Feature's WhiteList:
 * - Restricts/enables feature access for INTERNAL-USE-ONLY to select users ONLY!
 */
const internalWhiteList = [
	`e99c5c77-68b7-4ccf-aa5f-5dd5601aff16`, // sgore99@aladvantage.com - main
	`2807d3cc-c791-4eae-810d-5749a2347a68`, // sgore99@ala/testingme334@gmail.com... (PROD)
	`86a67ebe-38e6-4b66-bc5a-8b14940ddf68`, // crule@ala... (PROD)
];

const featureFlags = {
	// task-related
	enableLogging: false,
	enableDataExport: false,
	enableSignature: false, // task update form
	enableShiftSelector: false, // task update form
	enableAdminNotes: true, // NEW "TASK NOTES" SECTION (ADMINS ONLY!!!)
	// enableDueDate: isLocalhost(), // NEW "SCHEDULED DUE DATE/TIME"
	enableDueDate: true, // NEW "SCHEDULED DUE DATE/TIME"
	enableQuickCreate: isLocalhost(), // only available in 'development' or 'test' env
	// enableQuickCreate: isLocalEnv ? true : false, // only available in 'development' or 'test' env
	enableDelete: isDevOrTest(),
	hideQuickCreate: true,
	enableSaveAll: false,
	// community-related
	enableCommunityInfo: false,
	enableCalendarNav: false,
	// alerts
	enableAlerts: false,
	// login-page
	showSignupLink: false,
	// time-view tab
	enableHideIndependentTasks: false,
};

const FLAGS = {
	tasks: {
		enableDelete: featureFlags?.enableDelete,
		enableAdminNotes: featureFlags?.enableAdminNotes,
	},

	dev: {
		enableLogging: featureFlags?.enableLogging,
		enableDataExport: featureFlags?.enableDataExport,
	},
};

/**
 * Checks if a given feature is enabled or not.
 * @param {String} feature - A feature 'name' to check
 * @param {Object} flags - A feature 'map' to check for support/access
 */
const checkFeatureSupport = (feature, flags = {}) => {
	const featureExists = hasProp(flags, feature);
	const hasSupport = flags[feature];
	return featureExists && hasSupport;
};

/**
 * Wrapper the renders 1 of 2 options based on an 'isEnabled' feature:
 * - 'enabledComponent': renders when 'isEnabled' is true.
 * - 'fallbackComponent': renders when 'isEnabled' is false.
 * @returns {Component|Null}
 */
const FeatureContainer = ({
	isEnabled = false,
	enabledComponent,
	fallbackComponent,
	...props
}) => {
	const Component = isEnabled ? enabledComponent : fallbackComponent;

	if (Component) {
		return <Component {...props} />;
	}
};

/**
 * Enables/disables a feature via whitelist override
 * @param {String} userID - User ID
 * @returns {Boolean} - Returns whether a feature can be enabled (even if turned off) via whitelist
 */
const enableFeatureViaWhiteList = (userID) => {
	const hasOverride = featuresWhiteList.includes(userID);

	return hasOverride;
};

/**
 * Enables/disables a feature via internal whitelist override
 * @param {String} userID - User ID
 * @returns {Boolean} - Returns whether a feature can be enabled (even if turned off) via whitelist
 */
const enableFeatureViaInternalList = (userID) => {
	const hasAccess = internalWhiteList.includes(userID);

	const normalList = internalWhiteList.map((x) => x?.toLowerCase());
	const hasOverride = normalList.includes(userID?.toLowerCase());
	// const hasOverride = internalWhiteList.includes(userID);

	return hasOverride || hasAccess;
};

export { featureFlags, checkFeatureSupport };
export { FLAGS };
export { FeatureContainer };

export {
	featuresWhiteList,
	internalWhiteList,
	enableFeatureViaInternalList,
	enableFeatureViaWhiteList,
};
