import { setHours, subDays } from "date-fns";
import { historical } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { getExceptionID } from "./utils_exceptions";
import { getTaskNotesByTaskID } from "./utils_notes";
import { sortAlphaAscByKey } from "./utils_processing";
import { getReassessByTrackingTask } from "./utils_reassess";
import { getResidentRecord } from "./utils_residentData";
import {
	getResidentPhotoByID,
	getResidentPhotosByFacilityID,
} from "./utils_residents";
import { getResolutionID } from "./utils_resolution";
import { getShiftID } from "./utils_shifts";
import { getStatusID } from "./utils_status";
import {
	getScheduledRecord,
	getTaskID,
	getTaskType,
	saveScheduledUpdates,
} from "./utils_tasks";
import { applyExceptionToTask, sortDailyTasksBy } from "./utils_timeview";
import { hasProp, isEmptyArray, isEmptyObj, isEmptyVal } from "./utils_types";
import {
	getUnscheduledRecord,
	saveUnscheduledUpdates,
} from "./utils_unscheduled";
import {
	getAdvUserByUserID,
	getUsersByFacility,
	processAdvUserForTV,
	processAllAdvUsers,
} from "./utils_user";

/**
 * Fetches a list of custom-structured historical task records (supports scheduled/unscheduled)
 * @param {String} token - Auth token
 * @param {Array} residentIDs - An array of resident IDs. (eg. POST body content)
 * @param {Object} params - An object of query params
 * @property {String} params.facilityId - Target facility ID
 * @property {String} params.dayOfWeekDate - Target historical date string (ISO format/eg. Date.toISOString())
 * @property {Boolean} params.useTimeZone - Optional boolean that determines whether to use local timezone or facility's timezone.
 * @returns {Object} - Returns an object of 'ScheduledTasks' & 'UnscheduledTasks'
 */
const getHistoricalTasks = async (token, residentIDs = [], params = {}) => {
	let url = currentEnv.base + historical.getTasks;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(residentIDs),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Determines what task request(s) to fire off, then saves those task(s)
 * @param {String} token - Auth token
 * @param {Object} pendingTasks - An object w/ scheduled & unscheduled tasks to be saved
 * @returns {Object} - Returns object for each type: API response & status (FULFILLED/REJECTED)
 * @property {Object} return.scheduled - Includes scheduled details (api resonse & status)
 * @property {Object} return.unscheduled - Includes unscheduled details (api resonse & status)
 */
const saveHistoricalTasks = async (token, pendingTasks = {}) => {
	const { scheduled, unscheduled } = pendingTasks;
	// request status keys
	const saved = "FULFILLED";
	const notSaved = "REJECTED";

	switch (true) {
		// SCHEDULED & UNSCHEDULED (both task types )
		case !isEmptyArray(scheduled) && !isEmptyArray(unscheduled): {
			const [savedScheduled, savedUnscheduled] = await Promise.all([
				saveScheduledUpdates(token, scheduled),
				saveUnscheduledUpdates(token, unscheduled),
			]);
			// request outcome indicator (success/fail)
			const statusS = !isEmptyVal(savedScheduled) ? saved : notSaved;
			const statusU = !isEmptyVal(savedUnscheduled) ? saved : notSaved;

			return {
				scheduled: { response: savedScheduled, status: statusS },
				unscheduled: { response: savedUnscheduled, status: statusU },
			};
		}
		// SCHEDULED ONLY
		case !isEmptyArray(scheduled) && isEmptyArray(unscheduled): {
			const savedScheduled = await saveScheduledUpdates(token, scheduled);
			// request outcome indicator (success/fail)
			const statusS = !isEmptyVal(savedScheduled) ? saved : notSaved;

			return {
				scheduled: { response: savedScheduled, status: statusS },
				unscheduled: { response: null, status: null },
			};
		}
		// UNSCHEDULED ONLY
		case isEmptyArray(scheduled) && !isEmptyArray(unscheduled): {
			const savedUnscheduled = await saveUnscheduledUpdates(token, unscheduled);
			// request outcome indicator (success/fail)
			const statusU = !isEmptyVal(savedUnscheduled) ? saved : notSaved;

			return {
				scheduled: { response: null, status: null },
				unscheduled: { response: savedUnscheduled, status: statusU },
			};
		}
		// no tasks provided
		default:
			return {
				scheduled: {
					response: new Error("No tasks provided (scheduled)"),
					status: null,
				},
				unscheduled: {
					response: new Error("No tasks provided (unscheduled)"),
					status: null,
				},
			};
	}
};

// saves a single task's changes via task details modal
const saveHistoricalTaskChanges = async (token, pendingTask = {}) => {
	const taskType = getTaskType(pendingTask);

	switch (taskType) {
		case "Scheduled": {
			const wasSaved = await saveScheduledUpdates(token, pendingTask);
			console.log("wasSaved(Scheduled):", wasSaved);
			return wasSaved;
		}
		case "Unscheduled": {
			const wasSaved = await saveUnscheduledUpdates(token, pendingTask);
			console.log("wasSaved(Unscheduled):", wasSaved);
			return wasSaved;
		}
		default:
			return null;
	}
};

// REQUEST RESPONSE HANDLER(S) //

// handler for 'saveHistoricalTasks' response(s)
const wasHistoryChangesSaved = (responses = {}) => {
	const { scheduled, unscheduled } = responses;
	const statusS =
		scheduled?.status === "FULFILLED" || scheduled?.status === null;
	const statusU =
		unscheduled?.status === "FULFILLED" || unscheduled?.status === null;
	// response
	const bothFulfilled = statusS && statusU;

	return bothFulfilled;
};

const getTotalTaskCount = (scheduled = [], unscheduled = []) => {
	const lengthS = scheduled?.length ?? 0;
	const lengthU = unscheduled?.length ?? 0;
	// total
	const total = lengthS + lengthU;

	return total;
};

// fetchs all changes for a given task record (this is some weird generic API that's gonna be insufficient)
const getHistoricalAuditInformation = async (token, params = {}) => {
	const {
		startDate = subDays(new Date(), 30).toISOString(),
		endDate = new Date().toISOString(),
		databaseName = "Advantage",
		tableName = "AssessmentTrackingTask",
		tableId = "AssessmentTrackingTaskId",
		primaryId = "AssessmentTrackingTaskId",
	} = params;

	let url = currentEnv.base + historical.getAuditInfo;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// fetches advanced options initial resources
const getHistoricalTaskDetails = async (
	token,
	task = {},
	facilityID = null
) => {
	const ResidentID = task?.ResidentId ?? task?.ResidentID ?? task?.residentID;
	const taskType = getTaskType(task);

	console.log(`Historical (task):`, task);

	switch (taskType) {
		case "Scheduled": {
			const { AssessmentTrackingTaskId: taskID } = task;

			// const [profile, taskDetails, taskNotes, reassessRecords, photoSrc] =
			const [profile, taskDetails, taskNotes, usersList] = await Promise.all([
				getResidentRecord(token, ResidentID),
				getScheduledRecord(token, taskID),
				getTaskNotesByTaskID(token, task),
				getUsersByFacility(token, facilityID),
				// getReassessByTrackingTask(token, taskID),
				// getResidentPhotoByID(token, ResidentID),
			]);
			const processedUsers = processAllAdvUsers(usersList);
			const names = getAvailableUsers([...processedUsers]);

			return {
				profile: profile?.[0],
				taskDetails: taskDetails?.[0],
				taskNotes: taskNotes,
				// reassessRecords: reassessRecords,
				usersList: { raw: processedUsers, names: names },
			};
		}
		case "Unscheduled": {
			const { AssessmentUnscheduleTaskId: taskID, CreatedBy: createdBy } = task;

			// const userID = isEmptyVal(task?.createdBy) ?

			const [profile, taskDetails, taskNotes, userDetails, usersList] =
				await Promise.all([
					getResidentRecord(token, ResidentID),
					getUnscheduledRecord(token, taskID),
					getTaskNotesByTaskID(token, task),
					getAdvUserByUserID(token, createdBy),
					getUsersByFacility(token, facilityID),
					// getResidentPhotoByID(token, ResidentID)
				]);

			const processedUsers = processAllAdvUsers(usersList);
			const names = getAvailableUsers([...processedUsers]);

			return {
				profile: profile?.[0],
				taskDetails: taskDetails?.[0],
				taskNotes: taskNotes,
				userDetails: userDetails,
				usersList: { raw: processedUsers, names: names },
			};
		}
		default:
			return new Error(`INVALID TASK TYPE: ${taskType}`);
	}
};

const getHistoricalViewResources = async (
	token,
	facilityID,
	taskOptions = {}
) => {
	const { residentIDs, params } = taskOptions;
	const [historicalTasks, residentPhotos] = await Promise.all([
		getHistoricalTasks(token, residentIDs, params),
		getResidentPhotosByFacilityID(token, facilityID),
	]);

	return {
		tasks: historicalTasks,
		photos: residentPhotos,
	};
};

const getAvailableUsers = (records = []) => {
	if (isEmptyArray(records)) return [];

	// const newList = records.map((x) => {
	// 	// const name = `${x.lastName}, ${x.firstName} - ${x.userID}`;
	// 	const name = `${x.lastName}, ${x.firstName}`;
	// 	return name;
	// });
	// const sorted = sortByAlphaAsc(newList);
	// return sorted;
	const sorted = sortAlphaAscByKey("lastName", records);

	return sorted;
};

// DATA UTILS

const getHistoryViewExceptions = (tasks = []) => {
	const exceptions = tasks.filter(
		(x) => !isEmptyVal(x.Exception) || !isEmptyVal(x?.AssessmentExceptionId)
	);

	return exceptions;
};

// handles selected date for API (adds hours padding to prevent UTC bugs)
const generateHistoricalDateFromSelection = (date) => {
	const paddedHours = 15;
	const raw = new Date(date);
	const withHours = setHours(raw, paddedHours).toISOString();

	return withHours;
};

const getHistoricalResidentName = (fallback = "", task = {}) => {
	if (isEmptyObj(task)) return fallback;
	const { FirstName, LastName } = task;
	return `${FirstName} ${LastName}`;
};
const getHistoricalTaskName = (task = {}) => {
	const name = task?.TaskDescription ?? "";

	return name;
};
const getHistoricalTaskNotes = (task = {}) => {
	const notes = task?.TaskNotes ?? "";

	return notes;
};
const getHistoricalTaskADL = (task = {}) => {
	const adl = task?.ADL ?? "";

	return adl;
};

// TASK UPDATER PREPARER UTILS //

// generate completed task records by task type
// prepares task records to be sent to server for save
const generateHistoryTasksForCompletion = (
	assignTo = {},
	selectedTasks = [],
	allTasks = []
) => {
	// iterate thru selected
	// generate a new server-side task record for each
	// make sure the applied values to transferred
	// divide the tasks into scheduled & unscheduled
	// return updated server-side task records for request

	const updated = selectedTasks.reduce(
		(updatedTasks, task) => {
			const { userID } = assignTo;
			const shiftID = getShiftID(task?.Shift);
			// task identifiers
			const taskType = getTaskType(task);
			const taskID = getTaskID(task);

			switch (taskType) {
				case "Scheduled": {
					const newTask = {
						AssessmentTrackingTaskId: taskID,
						AssessmentTrackingId: task?.AssessmentTrackingId,
						AssessmentTaskStatusId: 2,
						AssessmentResolutionId: 1,
						EntryDate: task?.ScheduledDate,
						IsCompleted: true,
						CompletedByUserId: userID,
						CompletedDate: task?.ScheduledDate,
						CompletedAssessmentShiftId: shiftID,
					};
					return {
						...updatedTasks,
						scheduled: [...updatedTasks?.scheduled, newTask],
					};
				}
				case "Unscheduled": {
					const newTask = {
						AssessmentUnscheduleTaskId: taskID,
						AssessmentTaskStatusId: 2,
						AssessmentResolutionId: 1,
						EntryDate: task?.ScheduledDate,
						IsCompleted: true,
						CompletedByUserId: userID,
						CompletedDate: task?.ScheduledDate,
						CompletedAssessmentShiftId: shiftID,
					};

					return {
						...updatedTasks,
						unscheduled: [...updatedTasks?.unscheduled, newTask],
					};
				}
				default:
					return { ...updatedTasks };
			}
		},
		{ scheduled: [], unscheduled: [] }
	);

	return updated;
};
// generates abbr. server-formatted tasks w/ exceptions removed
const generateHistoryTasksForException = (
	assignTo = {},
	selectedTasks = [],
	allTasks = []
) => {
	// iterate thru selected
	// generate a new server-side task record for each
	// make sure the applied values to transferred
	// divide the tasks into scheduled & unscheduled
	// return updated server-side task records for request

	const updated = selectedTasks.reduce(
		(updatedTasks, task) => {
			const { userID } = assignTo;
			// task identifiers
			const taskType = getTaskType(task);
			const taskID = getTaskID(task);

			switch (taskType) {
				case "Scheduled": {
					const newTask = {
						AssessmentTrackingTaskId: taskID,
						AssessmentTrackingId: task?.AssessmentTrackingId,
						AssessmentTaskStatusId: 6, // (PENDING STATUS)
						AssessmentResolutionId: null,
						EntryDate: task?.ScheduledDate,
						IsCompleted: true,
						ExceptionByUserId: null,
						ExceptionDate: null,
						AssessmentExceptionId: null,
						UpdatedByUserId: userID,
					};
					return {
						...updatedTasks,
						scheduled: [...updatedTasks?.scheduled, newTask],
					};
				}
				case "Unscheduled": {
					const newTask = {
						AssessmentUnscheduleTaskId: taskID,
						AssessmentTaskStatusId: 6, // PENDING STATUS
						AssessmentResolutionId: null,
						EntryDate: task?.ScheduledDate,
						ExceptionByUserId: null,
						ExceptionDate: null,
						AssessmentExceptionId: null,
						UpdatedByUserId: userID,
					};

					return {
						...updatedTasks,
						unscheduled: [...updatedTasks?.unscheduled, newTask],
					};
				}
				default:
					return { ...updatedTasks };
			}
		},
		{ scheduled: [], unscheduled: [] }
	);

	return updated;
};
const generateHistoryTasksForPastDue = (
	assignTo = {},
	selectedTasks = [],
	allTasks = []
) => {
	// iterate thru selected
	// generate a new server-side task record for each
	// make sure the applied values to transferred
	// divide the tasks into scheduled & unscheduled
	// return updated server-side task records for request

	const updated = selectedTasks.reduce(
		(updatedTasks, task) => {
			const { userID } = assignTo;
			// task identifiers
			const taskType = getTaskType(task);
			const taskID = getTaskID(task);

			switch (taskType) {
				case "Scheduled": {
					const newTask = {
						AssessmentTrackingTaskId: taskID,
						AssessmentTrackingId: task?.AssessmentTrackingId,
						AssessmentTaskStatusId: 6, // (PENDING STATUS)
						AssessmentResolutionId: null,
						EntryDate: task?.ScheduledDate,
						IsPastDue: false,
						PastDueDate: null,
					};
					return {
						...updatedTasks,
						scheduled: [...updatedTasks?.scheduled, newTask],
					};
				}
				case "Unscheduled": {
					const newTask = {
						AssessmentUnscheduleTaskId: taskID,
						AssessmentTaskStatusId: 6, // PENDING STATUS
						AssessmentResolutionId: null,
						EntryDate: task?.ScheduledDate,
						IsPastDue: false,
						PastDueDate: null,
					};

					return {
						...updatedTasks,
						unscheduled: [...updatedTasks?.unscheduled, newTask],
					};
				}
				default:
					return { ...updatedTasks };
			}
		},
		{ scheduled: [], unscheduled: [] }
	);

	return updated;
};
const generateHistoryTasksForChangeStatus = (
	assignTo = {},
	targetStatus = "COMPLETE",
	selectedTasks = [],
	allTasks = []
) => {
	// iterate thru selected
	// generate a new server-side task record for each
	// make sure the applied values to transferred
	// divide the tasks into scheduled & unscheduled
	// return updated server-side task records for request

	const updated = selectedTasks.reduce(
		(updatedTasks, task) => {
			const { userID } = assignTo;
			const statusID = getStatusID(targetStatus);
			const resolutionID = getResolutionID(targetStatus);
			// task identifiers
			const taskType = getTaskType(task);
			const taskID = getTaskID(task);

			switch (taskType) {
				case "Scheduled": {
					const newTask = {
						AssessmentTrackingTaskId: taskID,
						AssessmentTrackingId: task?.AssessmentTrackingId,
						AssessmentTaskStatusId: statusID, // (PENDING STATUS)
						AssessmentResolutionId: resolutionID,
						EntryDate: task?.ScheduledDate,
					};
					return {
						...updatedTasks,
						scheduled: [...updatedTasks?.scheduled, newTask],
					};
				}
				case "Unscheduled": {
					const newTask = {
						AssessmentUnscheduleTaskId: taskID,
						AssessmentTaskStatusId: statusID, // (PENDING STATUS)
						AssessmentResolutionId: resolutionID,
						EntryDate: task?.ScheduledDate,
					};

					return {
						...updatedTasks,
						unscheduled: [...updatedTasks?.unscheduled, newTask],
					};
				}
				default:
					return { ...updatedTasks };
			}
		},
		{ scheduled: [], unscheduled: [] }
	);

	return updated;
};

// SORTING UTILS

// sorts by ADL w/ resident name being secondary sort
const sortHistoricalTasks = (tasks = []) => {
	const byResident = sortDailyTasksBy("RESIDENT", tasks);
	const byADL = sortDailyTasksBy("ADL", byResident);

	return byADL;
};

// separates tasks list into types (scheduled & unscheduled)
const sortHistoryTasksByType = (pendingTasks = []) => {
	const byType = pendingTasks.reduce(
		(byType, task) => {
			const type = getTaskType(task);
			if (type === "Scheduled") {
				byType.scheduled = [...byType.scheduled, task];
				return byType;
			} else {
				byType.unscheduled = [...byType.unscheduled, task];
				return byType;
			}
		},
		{ scheduled: [], unscheduled: [] }
	);

	return byType;
};

// HISTORICAL ACTION UTILS FOR CLIENT TASKS //

// removes exceptions from any list of task records.
const removeExceptionsFromList = (taskList = []) => {
	const updatedList = taskList.map((task) => {
		if (
			!isEmptyVal(task?.Exception) ||
			!isEmptyVal(task?.AssessmentExceptionId)
		) {
			return {
				...task,
				Exception: null,
				ExceptionDate: null,
				AssessmentExceptionId: null,
				ExceptionByUserId: null,
			};
		} else {
			return task;
		}
	});

	return updatedList;
};

const removePastDueFromList = (taskList = []) => {
	const updatedList = taskList.map((task) => {
		if (task?.IsPastDue) {
			return {
				...task,
				IsPastDue: false,
				PastDueDate: null,
			};
		} else {
			return task;
		}
	});

	return updatedList;
};

// gets a list of selected task ids (merges all types together)
const getSelectedIDs = (selectedTasks = []) => {
	if (isEmptyArray(selectedTasks)) return [];
	const ids = selectedTasks.map((task) => getTaskID(task));

	return ids;
};

/**
 * Applies changes to all selected tasks in the 'allTasks' array
 * @param {Object} assignTo - User object for assigning to a user
 * @param {Array} selectedTasks - An array of selected client-tasks
 * @param {Array} allTasks - An array of all client-tasks
 * @returns {Array} - Returns an array of allTasks w/ updates applied
 */
const markHistoricalAsDoneBatch = (
	assignTo = {},
	selectedTasks = [],
	allTasks = []
) => {
	const selectedIDs = getSelectedIDs(selectedTasks);
	// apply to selected
	// apply to all tasks
	const updatedTasks = allTasks.map((task, idx) => {
		const id = getTaskID(task);
		const { userID } = assignTo;

		if (selectedIDs.includes(id)) {
			const shiftID = getShiftID(task?.Shift);

			return {
				...task,
				IsCompleted: true,
				CompletedDate: new Date(task?.ScheduledDate).toISOString(),
				CompletedByUserId: userID,
				CompletedAssessmentShiftId: shiftID,
				AssessmentTaskStatusId: 2,
			};
		} else {
			return task;
		}
	});

	return updatedTasks;
};

/**
 * Iterates thru tasks and applies exception removal to each selected task.
 * @param {Object} assignTo - User object to assign actions to
 * @param {Array} selectedTasks - Array of client tasks (selected)
 * @param {Array} allTasks - Array of client tasks (all)
 * @returns {Array} - Array of updated client tasks w/ appended fields
 */
const removeHistoricalExceptionsBatch = (
	assignTo = {},
	selectedTasks = [],
	allTasks = []
) => {
	const selectedIDs = getSelectedIDs(selectedTasks);

	const updatedTasks = allTasks.map((task, idx) => {
		const id = getTaskID(task);
		const { userID } = assignTo;

		// if task exists in 'selectedTasks' list:
		// - then remove exception & apply according fields value(s)
		if (selectedIDs.includes(id)) {
			return {
				...task,
				Exception: null,
				ExceptionDate: new Date(task?.ScheduledDate).toISOString(),
				ExceptionByUserId: userID,
				AssessmentExceptionId: null,
				AssessmentTaskStatusId: 2,
			};
		} else {
			return task;
		}
	});

	return updatedTasks;
};

const removeHistoricalPastDueBatch = (
	assignTo = {},
	selectedTasks = [],
	allTasks = []
) => {
	const selectedIDs = getSelectedIDs(selectedTasks);

	const updatedTasks = allTasks.map((task, idx) => {
		const id = getTaskID(task);
		const { userID } = assignTo;
		const statusID = getStatusID("NOT-COMPLETE");
		// const statusID = getStatusID("PENDING");

		// if task exists in 'selectedTasks' list:
		// - then remove exception & apply according fields value(s)
		if (selectedIDs.includes(id)) {
			return {
				...task,
				IsPastDue: false,
				PastDueDate: null,
				AssessmentTaskStatusId: statusID,
				UpdatedByUserId: userID,
			};
		} else {
			return task;
		}
	});

	return updatedTasks;
};

const changeHistoricalStatusBatch = (
	assignTo = {},
	targetStatus = "COMPLETE",
	selectedTasks = [],
	allTasks = []
) => {
	const selectedIDs = getSelectedIDs(selectedTasks);

	const updatedTasks = allTasks.map((task, idx) => {
		const id = getTaskID(task);
		const { userID } = assignTo;
		const shiftID = getShiftID(task?.Shift);
		const statusID = getStatusID(targetStatus);
		// if task exists in 'selectedTasks' list:
		// - then remove exception & apply according fields value(s)
		if (selectedIDs.includes(id)) {
			return {
				...task,
				IsCompleted: targetStatus === "COMPLETE",
				CompletedDate: new Date(task?.ScheduledDate).toISOString(),
				CompletedByUserId: targetStatus === "COMPLETE" ? userID : null,
				AssessmentTaskStatusId: statusID,
				CompletedAssessmentShiftId:
					targetStatus === "COMPLETE" ? shiftID : null,
			};
		} else {
			return task;
		}
	});

	return updatedTasks;
};

const applyExceptionToHistorical = (
	formState = {},
	task = {}, // should be task record, NOT client task
	facilityExceptions = [],
	assignTo = {} // may be currentUser or another user
) => {
	const { touched, values } = formState;
	const taskType = getTaskType(task);

	if (!hasProp(touched, "exceptionType")) return { ...task };

	switch (taskType) {
		case "Scheduled": {
			const baseTask = {
				...task,
				AssessmentTrackingTaskId: task?.AssessmentTrackingTaskId,
				AssessmentTrackingId: task?.AssessmentTrackingId,
			};

			// removes exception from task
			if (isEmptyVal(values?.exceptionType)) {
				// NOTE: REQUIRES 'EntryDate' ALWAYS!!!!
				const newTask = {
					...baseTask,
					ExceptionDate: null,
					AssessmentExceptionId: null,
					ExceptionByUserId: null,
				};
				return newTask;
				// applies non-removal changes to task
			} else {
				const exceptionID = getExceptionID(
					values?.exceptionType,
					facilityExceptions
				);
				const newTask = {
					...baseTask,
					ExceptionDate: baseTask?.EntryDate ?? baseTask?.ScheduledDate,
					AssessmentExceptionId: exceptionID,
					ExceptionByUserId: assignTo?.userID,
				};
				return newTask;
			}
		}
		case "Unscheduled": {
			const baseTask = {
				...task,
				AssessmentUnscheduleTaskId: task?.AssessmentUnscheduleTaskId,
			};

			// removes exception from task
			if (isEmptyVal(values?.exceptionType)) {
				const newTask = {
					...baseTask,
					ExceptionDate: null,
					AssessmentExceptionId: null,
					ExceptionByUserId: null,
				};
				return newTask;
				// applies non-removal changes to task
			} else {
				const exceptionID = getExceptionID(
					values?.exceptionType,
					facilityExceptions
				);
				const newTask = {
					...baseTask,
					AssessmentExceptionId: exceptionID,
					ExceptionDate: baseTask?.EntryDate ?? baseTask?.ScheduledDate,
					ExceptionByUserId: assignTo?.userID,
				};
				return newTask;
			}
		}

		default:
			break;
	}
};

// TASK DETAILS UTILS //

// checks for changes to Completion status & updates or returns accordingly
const applyCompleteToHistoryTask = (
	formState = {},
	task = {},
	assignTo = {}
) => {
	const { values: vals, touched } = formState;
	const { markComplete: wasTouched } = touched;
	// no changes applied - complete wasn't touched
	if (!wasTouched || !hasProp(touched, "markComplete")) return task;

	const { markComplete } = vals;
	const { userID } = assignTo;
	const ScheduledDate = task?.ScheduledDate ?? task?.EntryDate ?? new Date();
	const shiftID = getShiftID(task?.Shift);
	// task identifiers
	const taskType = getTaskType(task);
	const taskID = getTaskID(task);

	switch (taskType) {
		case "Scheduled": {
			const newTask = {
				...task,
				AssessmentTrackingTaskId: taskID,
				AssessmentTrackingId: task?.AssessmentTrackingId,
				AssessmentTaskStatusId: markComplete ? 2 : 4,
				AssessmentResolutionId: 1,
				EntryDate: ScheduledDate,
				IsCompleted: markComplete,
				CompletedByUserId: markComplete ? userID : null,
				CompletedDate: markComplete
					? new Date(ScheduledDate).toISOString()
					: null,
				CompletedAssessmentShiftId: markComplete ? shiftID : null,
			};
			return newTask;
		}
		case "Unscheduled": {
			const newTask = {
				...task,
				AssessmentUnscheduleTaskId: taskID,
				AssessmentTaskStatusId: markComplete ? 2 : 4,
				AssessmentResolutionId: 1,
				EntryDate: ScheduledDate,
				IsCompleted: markComplete,
				CompletedByUserId: markComplete ? userID : null,
				CompletedDate: markComplete
					? new Date(ScheduledDate).toISOString()
					: null,
				CompletedAssessmentShiftId: markComplete ? shiftID : null,
			};

			return newTask;
		}
		default:
			return null;
	}
};

// applies all values to task via TaskDetailsModal - server task
const applyAllChangesToHistoryTask = (
	formState = {},
	task = {}, // should be baseTask
	facilityExceptions = [],
	assignTo = {}
) => {
	// task values & types
	const taskType = getTaskType(task);
	const taskID = getTaskID(task);

	switch (taskType) {
		case "Scheduled": {
			const baseTask = {
				...task,
				AssessmentTrackingTaskId: taskID,
				AssessmentTrackingId: task?.AssessmentTrackingId,
				// EntryDate: task?.ScheduledDate,
			};
			const withException = applyExceptionToHistorical(
				formState,
				baseTask,
				facilityExceptions,
				assignTo
			);
			const withComplete = applyCompleteToHistoryTask(
				formState,
				withException,
				assignTo
			);

			console.group("Apply All to History(Scheduled)");
			console.log("withException:", withException);
			console.log("withComplete:", withComplete);
			console.groupEnd();

			return withComplete;
		}
		case "Unscheduled": {
			const baseTask = {
				...task,
				AssessmentUnscheduleTaskId: taskID,
				// EntryDate: task?.ScheduledDate,
			};
			const withException = applyExceptionToHistorical(
				formState,
				baseTask,
				facilityExceptions,
				assignTo
			);
			const withComplete = applyCompleteToHistoryTask(
				formState,
				withException,
				assignTo
			);

			console.group("Apply All to History(Unscheduled)");
			console.log("withException:", withException);
			console.log("withComplete:", withComplete);
			console.groupEnd();

			return withComplete;
		}
		default:
			break;
	}
};

// generates abbreviated task record to be updated for server ('taskRecord' === server-side record)
const generateHistoryBaseTask = (taskRecord = {}) => {
	const taskType = getTaskType(taskRecord);
	const taskID = getTaskID(taskRecord);

	console.log("taskRecord", taskRecord);

	switch (taskType) {
		case "Scheduled": {
			const baseRecord = {
				AssessmentTaskId: taskRecord?.AssessmentTaskId,
				AssessmentTrackingId: taskRecord?.AssessmentTrackingId,
				AssessmentTrackingTaskId: taskID,
				EntryDate: taskRecord?.EntryDate ?? taskRecord?.ScheduledDate,
			};
			return baseRecord;
		}
		case "Unscheduled": {
			const baseRecord = {
				ResidentId: taskRecord?.ResidentId,
				AssessmentTaskId: taskRecord?.AssessmentTaskId,
				AssessmentUnscheduleTaskId: taskID,
				EntryDate: taskRecord?.EntryDate ?? taskRecord?.ScheduledDate,
			};
			return baseRecord;
		}
		default:
			return taskRecord;
	}
};

// CLIENT UPDATER

const applyAllChangesToHistoryClient = (
	formState = {},
	clientTask = {},
	assignTo = {}
) => {
	const { values: vals, touched } = formState;
	const { markComplete, exceptionType = "", hasPastDue, taskNotes = "" } = vals;
	const originNotes = clientTask?.TaskNotes?.[0] ?? "";
	const newNotes = taskNotes;
	const allNotes =
		isEmptyVal(originNotes) && newNotes ? [] : [originNotes, newNotes];
	// applied task updates to client record
	const newTask = {
		...clientTask,
		IsCompleted: markComplete,
		Exception: exceptionType,
		IsPastDue: hasPastDue,
		TaskNotes: allNotes,
	};

	console.log("newTask(client):", newTask);
	return newTask;
};

export { getHistoricalTasks, getHistoricalAuditInformation };
// historical request utils
export {
	getHistoricalTaskDetails,
	getHistoricalViewResources,
	saveHistoricalTasks,
	saveHistoricalTaskChanges,
};

export {
	getHistoricalResidentName,
	getHistoricalTaskName,
	getHistoricalTaskNotes,
	getHistoricalTaskADL,
	getHistoryViewExceptions,
	// task generators utils
	generateHistoricalDateFromSelection,
	generateHistoryTasksForCompletion,
	generateHistoryTasksForException,
	generateHistoryTasksForPastDue,
	generateHistoryTasksForChangeStatus,
	// task details utils
	applyAllChangesToHistoryTask,
	applyAllChangesToHistoryClient,
	generateHistoryBaseTask,
};

export { sortHistoricalTasks, sortHistoryTasksByType };

// misc utils (client)
export {
	removeExceptionsFromList,
	removePastDueFromList,
	wasHistoryChangesSaved,
	getTotalTaskCount,
};

// client-task action handlers
export {
	markHistoricalAsDoneBatch,
	removeHistoricalExceptionsBatch,
	removeHistoricalPastDueBatch,
	changeHistoricalStatusBatch,
};
