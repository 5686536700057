import React, { useState } from "react";
import styles from "../../css/history/HistoryViewFilterController.module.scss";
import { PropTypes } from "prop-types";
import CustomDropdown from "../shared/CustomDropdown";
import { format, subDays } from "date-fns";
import DatePickerSM from "../shared/DatePickerSM";
import { isEmptyVal } from "../../helpers/utils_types";
import { formatAndSortClientFacilities } from "../../helpers/utils_facility";
import ButtonSM from "../shared/ButtonSM";

// MAIN SELECTOR FOR HISTORICAL VIEW //
// - Supports Date and Community

const customCSS = {
	loadBtn: {
		padding: "1.2rem 1.8rem",
		fontSize: "1.6rem",
		fontWeight: "600",
	},
};

const disableBtn = (selectedFacilitly, selectedDate) => {
	return isEmptyVal(selectedFacilitly) || isEmptyVal(selectedDate);
};

const HistoryViewFilterController = ({
	globalState = {},
	currentUser = {},
	currentFacility = {},
	dispatchAlert,
	applySelections,
	dispatchToState,
}) => {
	const { facilities } = currentUser;
	const { residentsByFacility } = globalState?.globals;
	const { communityName, residents, shifts, facilityID } = currentFacility;
	// local state
	const [selectedFacility, setSelectedFacility] = useState(() => {
		if (!isEmptyVal(facilityID)) {
			return communityName;
		} else {
			return "";
		}
	});
	const [selectedDate, setSelectedDate] = useState(
		format(subDays(new Date(), 1), "MM/DD/YYYY")
	);

	const handleFacility = (name, option) => {
		setSelectedFacility(option);
	};
	const handleDate = (name, option) => {
		setSelectedDate(option);
	};

	return (
		<div className={styles.HistoryViewFilterController}>
			<div className={styles.HistoryViewFilterController_facility}>
				<CustomDropdown
					name="facilitySelect"
					id="facilitySelect"
					label="Select a community"
					selection={selectedFacility}
					setSelection={handleFacility}
					options={formatAndSortClientFacilities(facilities)}
				/>
			</div>
			<div className={styles.HistoryViewFilterController_date}>
				<DatePickerSM
					name="dateSelect"
					id="dateSelect"
					label="Select a date"
					val={selectedDate}
					handleDate={handleDate}
					focusMode={true}
				/>
			</div>
			<div className={styles.HistoryViewFilterController_actions}>
				<ButtonSM
					isDisabled={disableBtn(selectedFacility, selectedDate)}
					handleClick={() =>
						applySelections({
							selectedFacility,
							selectedDate,
						})
					}
					customStyles={customCSS.loadBtn}
				>
					Load Historical Tasks
				</ButtonSM>
			</div>
		</div>
	);
};

export default HistoryViewFilterController;

HistoryViewFilterController.defaultProps = {};

HistoryViewFilterController.propTypes = {};
