import React from "react";
import styles from "../../css/timeview/TimeViewControllerFacilities.module.scss";
import sprite2 from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import TimeViewFilterFacility from "./TimeViewFilterFacility";

// flag for 'Clear'/'Clear All' button
const enableClearBtn = false;
const enableSaveBtn = false;

const getIsSelected = (option = {}, selection = {}, disabledIDs = []) => {
	const selectedID = selection?.id;

	const isSelected = selectedID === option.id;
	const isDisabled = disabledIDs.includes(option.id);
	return !isDisabled && isSelected;
};

const getSelectedMsg = (selectedFacility) => {
	if (isEmptyVal(selectedFacility?.id)) {
		return `Select a facility`;
	} else {
		return `Selected: ${selectedFacility?.name}`;
	}
};

const TimeViewControllerFacilities = ({
	searchVal,
	searchRef,
	selectedFacility = {},
	handleSearch,
	clearSearch,
	saveSelections,
	clearSelections,
	filterState = {},
	facilities = [],
	disabledFacilityIDs = [],
	handleFacility,
}) => {
	return (
		<div className={styles.TimeViewControllerFacilities}>
			<div className={styles.TimeViewControllerFacilities_top}>
				<svg className={styles.TimeViewControllerFacilities_top_searchIcon}>
					<use xlinkHref={`${sprite2}#icon-magnifying-glass`}></use>
				</svg>
				<input
					type="text"
					name="searchFacilities"
					id="searchFacilities"
					value={searchVal}
					onChange={handleSearch}
					className={styles.TimeViewControllerFacilities_top_search}
					placeholder="Search..."
					autoComplete="off"
					ref={searchRef}
				/>
				{!isEmptyVal(searchVal) && (
					<svg
						className={styles.TimeViewControllerFacilities_top_clearIcon}
						onClick={clearSearch}
						onKeyDown={(e) => (e.key === "Enter" ? clearSearch(e) : null)}
						tabIndex={0}
					>
						<use xlinkHref={`${sprite2}#icon-clearclose`}></use>
					</svg>
				)}
			</div>
			<div className={styles.TimeViewControllerFacilities_selectAll}>
				{getSelectedMsg(selectedFacility)}
			</div>
			<hr />
			<div className={styles.TimeViewControllerFacilities_list}>
				<ul className={styles.TimeViewControllerFacilities_list_options}>
					{!isEmptyArray(facilities) &&
						facilities.map((facility, index) => (
							<TimeViewFilterFacility
								key={`${facility.id}___${index}`}
								facility={facility}
								handleFacility={() => handleFacility(facility)}
								isDisabled={disabledFacilityIDs.includes(facility.id)}
								selectedFacility={selectedFacility}
								isSelected={getIsSelected(
									facility,
									selectedFacility,
									disabledFacilityIDs
								)}
							/>
						))}
				</ul>
			</div>
			<div className={styles.TimeViewControllerFacilities_actions}>
				{enableClearBtn && (
					<button
						type="button"
						className={styles.TimeViewControllerFacilities_actions_clearBtn}
						onClick={clearSelections}
					>
						Clear
					</button>
				)}
				{enableSaveBtn && (
					<button
						type="button"
						className={styles.TimeViewControllerFacilities_actions_saveBtn}
						onClick={saveSelections}
					>
						Save
					</button>
				)}
			</div>
		</div>
	);
};

export default TimeViewControllerFacilities;

TimeViewControllerFacilities.defaultProps = {};

TimeViewControllerFacilities.propTypes = {};
