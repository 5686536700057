import { currentEnv } from "./utils_env";
import { generic, facility, getFacilities } from "./utils_endpoints";
import { extractRawParams, facility as facilityParams } from "./utils_params";
import { isEmptyVal, isEmptyArray, isEmptyObj } from "./utils_types";
import { formatResidentOnly, matchResidentByID } from "./utils_residents";
import { convertShiftTimesToDate } from "./utils_shifts";

/**
 * @description - Fetches a list of facilities a user has access to based off the user's email.
 * @param {String} token - Base64 encoded auth token.
 * @param {String} userEmail - A string user email.
 * @param {Number} applicationId - A numeric appID.
 * @returns {Array} - Returns an array of custom facility records containing:
 * - "CommunityName": facility's community name as a string.
 * - "FacilityId": uid for facility
 * - "ParentFacilityId": uid for parent facility, if applicable.
 * - "Shifts": a list of 'AssessmentFacilityShift' records, IF AVAILABLE; IF NOT AVAILABLE, then returns default 'AssessmentShift' records.
 * - "Address":
 * 		- "Address.Street"
 * 		- "Address.State"
 * 		- "Address.City"
 * 		- "Address.Zip"
 *
 * - ✅ UPDATED API (10/18/2021 at 9:43 AM) to include: 'applicationId':
 * 		- NOW the API will check if each facility to be returned as access to the given application:
 * 				- IF NO ACCESS, then the facility is NOT returned
 * 				- IF YES ACCESS, then the facility IS returned, as normal
 */
const getFacilitiesByUserEmail = async (
	token,
	userEmail,
	applicationId = 2
) => {
	let url = currentEnv.base + getFacilities.byUserEmail;
	url += "?" + new URLSearchParams({ userEmail: userEmail });
	url += "&" + new URLSearchParams({ applicationId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * @description - Fetches the current user's facility info; location/address, director's name, capacity & other info.
 * @param {String} token - Base64 encoded auth token.
 * @param {String} facilityID - A specific uid for the current user's facility.
 * @returns {Object} - Returns an object representing an entry in the "FACILITY" table in ALA Services.
 */
const getFacilityInfo = async (token, facilityID) => {
	let url = currentEnv.base + generic.get;
	url += "?" + new URLSearchParams({ ...facilityParams });
	url += "&" + new URLSearchParams({ guidFacility: facilityID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		const { Data } = response; // array of objects
		const [entry] = Data; // object (ie 1st index)
		return entry;
	} catch (err) {
		return err.message;
	}
};
/**
 * @description - Fetches a facility's Assessment Category records w/ the category ID, name, IndicatorColor/Icon and meta data.
 * @param {String} token - A base64 encoded auth token.
 * @param {Number} index - Starting index in database to pull from.
 * @param {Number} rows - Number of rows in database to pull from.
 * @returns {Array} - Returns an array of "AssessmentCategory" records; NOT THE SAME AS "ADLCareLevel" records from the GetResident(Day|Week)*** APIs.
 */
const getFacilityCategories = async (token, index = 0, rows = 50) => {
	let url = currentEnv.base + facility.getCategories;
	url += "?" + new URLSearchParams({ index, rows });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};
/**
 * @description - Fetches a facility's Assessment Shift records w/ start/end times, shift ID, name and relevant meta. NOT SAME AS "ResidentAdlShift" records.
 * @param {String} token - A base64 encoded auth token.
 * @param {Number} index - Starting index in database to pull from.
 * @param {Number} rows - Number of rows in database to pull from.
 * @returns {Array} - Returns an array with every relevant "AssessmentShift" record for the facility.
 */
const getShifts = async (token, index = 0, rows = 10) => {
	let url = currentEnv.base + facility.getShifts;
	url += "?" + new URLSearchParams({ index, rows });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

//////////////////////////////////////////////////////////////////////
///////////// RESIDENT/FACILITY SELECTOR HELPERS & UTILS /////////////
//////////////////////////////////////////////////////////////////////

/**
 * @description - Finds a facility's shift records by matching facilityId's.
 * @param {String} facilityID - A selected facility's facility ID.
 * @param {Array} facilitiesByUser - An array of custom facility records to search.
 * @returns {Array} - Returns an array of 'AssessmentFacilityShift' records when given a facilityID.
 */
const getAdlShiftsByFacilityID = (facilityID, facilitiesByUser = []) => {
	if (isEmptyVal(facilityID)) return [];
	if (isEmptyArray(facilitiesByUser)) return [];
	return facilitiesByUser.reduce((shifts, current) => {
		if (facilityID === current.FacilityId) {
			shifts = [...current.Shifts];
			return shifts;
		}
		return shifts;
	}, []);
};

/**
 * @description - Helper that will sort facilities alphabetically by name and return a list of 'CommunityName' fields to use w/ the dropdown.
 * @param {Array} facilities - An array of facility records (ie 'AssessmentFacility' records).
 * @returns {Array of String} - Returns an array of strings containing ALL 'CommunityName' fields from facility records.
 */
const formatAndSortFacilities = (facilities = []) => {
	return facilities
		.sort((a, b) => {
			return a.CommunityName.localeCompare(b.CommunityName);
		})
		.map(({ CommunityName }) => CommunityName);
};
/**
 * @description - Helper that will sort facilities alphabetically by name and return a list of 'CommunityName' fields to use w/ the dropdown.
 * @param {Array} facilities - An array of facility records (ie 'AssessmentFacility' records).
 * @returns {Array of String} - Returns an array of strings containing ALL 'CommunityName' fields from facility records.
 */
const formatAndSortClientFacilities = (facilities = []) => {
	return facilities
		.sort((a, b) => {
			return a.CommunityName.localeCompare(b.CommunityName);
		})
		.map((fac) => {
			const name = fac?.CommunityName ?? fac?.communityName;
			return name;
		});
};

/**
 * @description - Finds the matching facility record based off a Community's 'CommunityName' field.
 * @param {String} selection - A user-selected string value representing a facility's 'CommunityName' field.
 * @param {Array} facilities - An array of facility records by current user (ie user has access to).
 * @returns {Object} - Returns an object; matching 'AssessmentFacility' record.
 */
const matchFacilityByName = (selection, facilities = []) => {
	return facilities.reduce((match, cur) => {
		const curName = cur?.CommunityName ?? cur?.communityName ?? cur?.name;
		if (curName === selection) {
			match = { ...cur };
			return match;
		}
		return match;
	}, {});
};
/**
 * @description - Finds matching facility record from a 'facilityID'.
 * @param {String} id - String-form facility ID.
 * @param {Array} facilities - An array of facilities from the 'currentUser' object.
 * @returns {Object} - Returns the matching facility record (ie 'FACILITY' record).
 */
const matchFacilityByID = (id, facilities = []) => {
	return facilities.reduce((match, cur) => {
		if (cur?.FacilityId === id) {
			match = { ...cur };
			return match;
		}
		return match;
	}, {});
};
const getFacilityID = (selection, facilities = []) => {
	const { FacilityId } = matchFacilityByName(selection, facilities);
	return FacilityId;
};

/**
 * @description - Determines a facility's 'Facility Day' (ie start of the AM shift to the end of the NOC shift)
 * @param {Array} shiftTimes - An array of 'AssessmentFacilityShift' records (ie shift times.)
 */
const getFacilityDay = (shiftTimes = []) => {
	const [am, , noc] = shiftTimes;
	let { startTime: localStart } = convertShiftTimesToDate(am, new Date());
	let { endTime: localEnd } = convertShiftTimesToDate(noc, new Date());

	return { startDate: localStart, endDate: localEnd };
};

//////////////////////////////////////////////////////////////////////
/////////////////////// ALASELECTOR UI HELPERS ///////////////////////
//////////////////////////////////////////////////////////////////////

// ALL UPDATED - 6/18/2020

const noFacilityLoaded = (selectedFacility, currenFacility = {}) => {
	return isEmptyVal(selectedFacility) && isEmptyVal(currenFacility.facilityID);
};

// extracts facilityID from url params & finds matching facility
const getDefaultFacilityFromParams = (urlString, allFacilities = []) => {
	const allParams = extractRawParams(urlString, ["facilityID"]);
	const facilityID = allParams?.facilityID;
	const facilityRecord = matchFacilityByID(facilityID, allFacilities);

	if (isEmptyObj(facilityRecord)) {
		return "";
	} else {
		return facilityRecord?.CommunityName;
	}
};
// extracts residentID from url params & finds matching resident
const getDefaultResidentFromParams = (urlString, facilityResidents = []) => {
	const allParams = extractRawParams(urlString, ["residentID"]);
	const residentID = allParams.residentID;
	const residentRecord = matchResidentByID(residentID, facilityResidents);

	if (isEmptyObj(residentRecord)) {
		return "";
	} else {
		return formatResidentOnly(residentRecord);
	}
};

// checks if user has selected a facility
const hasFacility = (selectedFacility) => {
	if (isEmptyVal(selectedFacility)) return false;
	return true;
};
const hasResident = (selectedResident) => {
	if (isEmptyVal(selectedResident)) return false;
	return true;
};
const getBtnText = (isAdmin = false, selectedFacility, selectedResident) => {
	if (!isAdmin && !hasResident(selectedResident)) {
		// not admin && no selection made
		return `Select Resident`;
	} else if (!isAdmin && hasResident(selectedResident)) {
		// not admin && has resident selection
		return `Change Resident`;
	} else if (!hasFacility(selectedFacility)) {
		// is admin && no facility selection
		return `Select Facility`;
	} else if (hasFacility(selectedFacility) && !hasResident(selectedResident)) {
		// is admin && has facility selection
		return `Change Facility`;
	} else {
		// is admin && has both resident/facility selections
		return `Change Facility/Resident`;
	}
};

// get the 'defaultResident' from the global store
const getCurrentResidentStr = (globalResident) => {
	if (isEmptyVal(globalResident?.ResidentID)) return "";
	return formatResidentOnly(globalResident);
};
// get the 'defaultFacility' from the global store
const getCurrentFacilityStr = (globalFacility) => {
	if (isEmptyVal(globalFacility?.facilityID)) return "";
	return globalFacility?.communityName;
};

export {
	getFacilityInfo,
	getFacilityCategories,
	getShifts,
	getFacilitiesByUserEmail,
};

// facility/resident selector utils
export {
	getFacilityID,
	matchFacilityByID,
	matchFacilityByName,
	formatAndSortFacilities,
	formatAndSortClientFacilities,
	getAdlShiftsByFacilityID,
};

// ALASelector UI utils
export {
	getBtnText,
	hasResident,
	hasFacility,
	getCurrentFacilityStr,
	getCurrentResidentStr,
	// query params & selector utils
	noFacilityLoaded,
	getDefaultFacilityFromParams,
	getDefaultResidentFromParams,
};
// determines facility schedule for a day
export { getFacilityDay };
