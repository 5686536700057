import React from "react";
import styles from "../../css/daily/DueDatePicker.module.scss";
import { PropTypes } from "prop-types";
import { getDateDescRelativeToNow } from "../../helpers/utils_dates";
// components
import DatePickerSM from "../shared/DatePickerSM";
import TimePicker from "../shared/TimePicker";
import { format } from "date-fns";
import { isEmptyVal } from "../../helpers/utils_types";

// returns: 'MM/DD/YYYY (Today/Tomorrow/Yesterday/X days ago/X days from now) at hh:mm A'
const CurrentDateTime = ({ currentDateTime = {} }) => {
	const {
		scheduledDueDate: date = format(new Date(), "MM/DD/YYYY"),
		scheduledDueTime: time = format(new Date(), "hh:mm A"),
	} = currentDateTime;

	return (
		<div className={styles.CurrentDateTime}>
			<span className={styles.CurrentDateTime_date}>
				{format(date, "MM/DD/YYYY")} <b>({getDateDescRelativeToNow(date)})</b>
			</span>
			<span className={styles.CurrentDateTime_separator}>at</span>
			<span className={styles.CurrentDateTime_time}>{time}</span>
		</div>
	);
};

const DueDatePicker = ({
	vals = {},
	currentUser = {},
	currentFacility = {},
	handleDueDate,
	handleDueTime,
}) => {
	return (
		<div className={styles.DueDatePicker}>
			<div className={styles.DueDatePicker_current}>
				{!isEmptyVal(vals?.scheduledDueTime) && (
					<CurrentDateTime currentDateTime={vals} />
				)}
			</div>

			<div className={styles.DueDatePicker_update}>
				<div className={styles.DueDatePicker_update_date}>
					<DatePickerSM
						name="scheduledDueDate"
						id="scheduledDueDate"
						label="Due Date"
						val={format(vals?.scheduledDueDate ?? new Date(), "MM/DD/YYYY")}
						handleDate={handleDueDate}
						focusMode={true}
						isLocked={true}
					/>
				</div>
				<div className={styles.DueDatePicker_update_time}>
					<TimePicker
						name="scheduledDueTime"
						id="scheduledDueTime"
						label="Due Time"
						val={vals?.scheduledDueTime ?? "03:00 PM"}
						handleTime={handleDueTime}
						enableSecs={false}
					/>
				</div>
			</div>
			{/*  */}
		</div>
	);
};

export default DueDatePicker;

DueDatePicker.defaultProps = {};

DueDatePicker.propTypes = {};
