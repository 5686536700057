import React from "react";
import styles from "../../css/timeview/TimeViewControllerShifts.module.scss";
import sprite from "../../assets/icons/checks.svg";
import { PropTypes } from "prop-types";
import CustomCheckbox from "../shared/CustomCheckbox";

const selected = "hsla(170, 100%, 39%, 1)";
const notSelected = "hsla(242, 89%, 64%, 1)";

const isShiftSelected = (shiftID, selectedShifts) => {
	const selectedIDs = selectedShifts.map((x) => x.id);

	return selectedIDs.includes(shiftID);
};

const ShiftSelector = ({
	label,
	shift = {},
	handleShifts,
	isSelected = false,
}) => {
	return (
		<button
			type="button"
			disabled={false}
			onClick={handleShifts}
			className={
				isSelected ? styles.ShiftSelector_isSelected : styles.ShiftSelector
			}
		>
			<span className={styles.ShiftSelector_label}>{label}</span>
			{isSelected && (
				<svg className={styles.ShiftSelector_icon}>
					<use xlinkHref={`${sprite}#icon-checkmark-outline`}></use>
				</svg>
			)}
		</button>
	);
};

const TimeViewControllerShifts = ({
	filterState = {},
	handleShifts,
	clearSelections,
	saveSelections,
	handleSelectAll,
	isAllSelected = false,
}) => {
	const { selectedShifts, selectedFacility } = filterState;
	const { shifts } = selectedFacility;
	const [am, pm, noc] = shifts;

	return (
		<div className={styles.TimeViewControllerShifts}>
			<div className={styles.TimeViewControllerShifts_selectAll}>
				<button
					type="button"
					className={styles.TimeViewControllerShifts_selectAll_btn}
					onClick={handleSelectAll}
					style={isAllSelected ? { color: selected } : { color: notSelected }}
				>
					{isAllSelected ? "✓ All Selected" : "Select All"}
				</button>
			</div>
			<div className={styles.TimeViewControllerShifts_inner}>
				<div className={styles.TimeViewControllerShifts_inner_item}>
					<ShiftSelector
						label="AM Shift"
						shift={am}
						isSelected={isShiftSelected(1, selectedShifts)}
						handleShifts={() => handleShifts(am)}
					/>
				</div>
				<div className={styles.TimeViewControllerShifts_inner_item}>
					<ShiftSelector
						label="PM Shift"
						shift={pm}
						isSelected={isShiftSelected(2, selectedShifts)}
						handleShifts={() => handleShifts(pm)}
					/>
				</div>
				<div className={styles.TimeViewControllerShifts_inner_item}>
					<ShiftSelector
						label="NOC Shift"
						shift={noc}
						isSelected={isShiftSelected(3, selectedShifts)}
						handleShifts={() => handleShifts(noc)}
					/>
				</div>
			</div>
			<div className={styles.TimeViewControllerShifts_actions}>
				<button
					type="button"
					className={styles.TimeViewControllerShifts_actions_clearBtn}
					onClick={clearSelections}
				>
					Clear All
				</button>
				<button
					type="button"
					className={styles.TimeViewControllerShifts_actions_saveBtn}
					onClick={saveSelections}
				>
					Save
				</button>
			</div>
		</div>
	);
};

export default TimeViewControllerShifts;

TimeViewControllerShifts.defaultProps = {};

TimeViewControllerShifts.propTypes = {};
