import React from "react";
import styles from "../../css/history/HistoricalTaskActions.module.scss";
import { PropTypes } from "prop-types";

// REQUIREMENTS:
// - BATCH ACTIONS:
//    - Mark all as Done
//    - Change all to <status>
//    - Remove all exceptions
// - SINGLE ACTIONS:
//    - Change status
//    - Add task note(s)
//    - Remove exception(s)
//    - Edit existing task note(s)

const showSingleActions = (allTasks = [], selectedTasks = []) => {
	const { length: total } = allTasks;
	const { length: selected } = selectedTasks;

	return selected === 1;
};
const showBatchActions = (allTasks = [], selectedTasks = []) => {
	const { length: total } = allTasks;
	const { length: selected } = selectedTasks;

	return selected > 1;
};

// SINGLE ACTION BUTTONS //
const MarkAsDoneButton = ({ actionHandler }) => {
	return (
		<button
			type="button"
			onClick={actionHandler}
			className={styles.MarkAsDoneButton}
		>
			Mark as done
		</button>
	);
};

const ChangeStatusButton = ({ actionHandler }) => {
	return (
		<button
			type="button"
			onClick={actionHandler}
			className={styles.ChangeStatusButton}
		>
			Change status
		</button>
	);
};
const RemoveExceptionButton = ({ actionHandler }) => {
	return (
		<button
			type="button"
			onClick={actionHandler}
			className={styles.RemoveExceptionButton}
		>
			Remove exception
		</button>
	);
};
const RemovePastDueFromSingleButton = ({ actionHandler }) => {
	return (
		<button
			type="button"
			onClick={actionHandler}
			className={styles.RemovePastDueFromSingleButton}
		>
			Remove past due
		</button>
	);
};

const SingleTaskActions = ({ singleTaskHandler }) => {
	return (
		<div className={styles.SingleTaskActions}>
			<MarkAsDoneButton
				actionHandler={() => singleTaskHandler("SINGLE-MARK-AS-DONE")}
			/>

			<RemoveExceptionButton
				actionHandler={() => singleTaskHandler("SINGLE-REMOVE-EXCEPTION")}
			/>
			<RemovePastDueFromSingleButton
				actionHandler={() => singleTaskHandler("SINGLE-REMOVE-PAST-DUE")}
			/>
		</div>
	);
};

// BATCH ACTION BUTTONS //

const MarkAllAsDoneButton = ({ actionHandler }) => {
	return (
		<button
			type="button"
			onClick={actionHandler}
			className={styles.MarkAllAsDoneButton}
		>
			Mark all as done
		</button>
	);
};
const SelectAllButton = () => {
	return <button className={styles.SelectAllButton}>Select all</button>;
};
const ChangeStatusOfSelectedButton = ({ actionHandler }) => {
	return (
		<button
			type="button"
			onClick={actionHandler}
			className={styles.ChangeStatusOfSelectedButton}
		>
			Change status of selected
		</button>
	);
};
const RemoveExceptionsFromSelectedButton = ({ actionHandler }) => {
	return (
		<button
			type="button"
			onClick={actionHandler}
			className={styles.RemoveExceptionsFromSelectedButton}
		>
			Remove exceptions from selected
		</button>
	);
};
const RemovePastDueFromSelectedButton = ({ actionHandler }) => {
	return (
		<button
			type="button"
			onClick={actionHandler}
			className={styles.RemovePastDueFromSelectedButton}
		>
			Remove past due from selected
		</button>
	);
};

const BatchTaskActions = ({ batchTaskHandler }) => {
	return (
		<div className={styles.BatchTaskActions}>
			<MarkAllAsDoneButton
				key="BATCH-MARK-ALL"
				actionHandler={() => batchTaskHandler("BATCH-MARK-ALL-AS-DONE")}
			/>
			<RemoveExceptionsFromSelectedButton
				key="BATCH-REMOVE-EXCEPTIONS"
				actionHandler={() => batchTaskHandler("BATCH-REMOVE-EXCEPTIONS")}
			/>
			<RemovePastDueFromSelectedButton
				key="BATCH-REMOVE-PAST-DUE"
				actionHandler={() => batchTaskHandler("BATCH-REMOVE-PAST-DUE")}
			/>
		</div>
	);
};

const HistoricalTaskActions = ({
	allTasks = [],
	selectedTasks = [],
	singleTaskHandler,
	batchTaskHandler,
}) => {
	return (
		<div className={styles.HistoricalTaskActions}>
			<div className={styles.HistoricalTaskActions_inner}>
				{/* SINGLE TASK ACTION(S) */}
				{showSingleActions(allTasks, selectedTasks) && (
					<SingleTaskActions singleTaskHandler={singleTaskHandler} />
				)}
				{/* BATCH TASK ACTION(S) */}
				{showBatchActions(allTasks, selectedTasks) && (
					<BatchTaskActions batchTaskHandler={batchTaskHandler} />
				)}
			</div>
		</div>
	);
};

export default HistoricalTaskActions;

HistoricalTaskActions.defaultProps = {};

HistoricalTaskActions.propTypes = {};
