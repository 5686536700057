import React, { useContext, useState } from "react";
import styles from "../../css/views/HistoricalTaskView.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { getFacilityStrFromDaily } from "./CalendarView";
import { addHours, addMinutes, setHours } from "date-fns";
import {
	generateResidentPhotosMapByID,
	getResidentIDs,
} from "../../helpers/utils_residents";
import { getShiftIDs } from "../../helpers/utils_timeview";
import {
	getFacilityID,
	matchFacilityByName,
} from "../../helpers/utils_facility";
import {
	getHistoricalTasks,
	getHistoricalViewResources,
	generateHistoricalDateFromSelection,
} from "../../helpers/utils_historical";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
// components
import HistoricalTaskActions from "../../components/history/HistoricalTaskActions";
import HistoricalTasksController from "../../components/history/HistoricalTasksController";
import HistoricalTaskSortController from "../../components/history/HistoricalTaskSortController";
import Spinner from "../../components/shared/Spinner";
import HistoryViewFilterController from "../../components/history/HistoryViewFilterController";

// REQUIREMENTS:
// - Facility Selector
// - Date Selector

const getCurrentFacility = (selectedFacility, facilities) => {
	if (isEmptyVal(selectedFacility)) return {};
	const record = matchFacilityByName(selectedFacility, facilities);
	return record;
};

const SelectionsPanel = () => {
	return (
		<div className={styles.SelectionsPanel}>
			{/* FACILITY SELECTOR */}
			{/* DATE PICKER */}
		</div>
	);
};

const HistoricalTaskView = () => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { globals, user: currentUser } = globalState;
	const { facilities } = currentUser;
	const { currentResident, currentFacility, residents, residentsByFacility } =
		globals;
	const { shifts: facilityShifts } = currentFacility;
	const { AlertsHandler, dispatchAlert } = useAlerts({ ...initialSettings });

	const [isLoading, setIsLoading] = useState(false);
	const [photosMap, setPhotosMap] = useState({});
	// prolly not needed???
	const [filterSelections, setFilterSelections] = useState({
		selectedFacility: getFacilityStrFromDaily(currentFacility),
		selectedResidents: [],
		selectedDate: "",
	});
	// task sort type: 'EXCEPTIONS', 'MISSED', 'ALL'
	const [sortType, setSortType] = useState("ALL");
	// historical tasks (scheduled/unscheduled)
	const [historicalTasks, setHistoricalTasks] = useState({
		scheduled: [],
		unscheduled: [],
	});

	// applies selections from user via child & syncs to state
	const applyFilterSelections = async (selectionState = {}) => {
		setIsLoading(true);
		const { token } = currentUser;
		const { selectedFacility, selectedDate, selectedResidents } =
			selectionState;
		const facility = matchFacilityByName(selectedFacility, facilities);
		const facilityID =
			facility?.facilityID ?? facility?.FacilityId ?? facility?.FacilityID;
		const residentIDs = [];
		const newDate = generateHistoricalDateFromSelection(selectedDate);
		// const date = new Date(selectedDate);
		// const newDate = setHours(date, 15).toISOString();

		// optional resident selections
		// const residentIDs = getResidentIDs(selectedResidents);

		// const historicalTasks = await getHistoricalTasks(token, residentIDs, {
		// 	facilityID: facilityID,
		// 	dayofWeekDate: new Date(selectedDate).toISOString(),
		// });

		const { tasks: historicalTasks, photos } = await getHistoricalViewResources(
			token,
			facilityID,
			{
				residentIDs: residentIDs,
				params: {
					facilityID: facilityID,
					// dayofWeekDate: new Date(selectedDate).toISOString(),
					dayofWeekDate: newDate,
				},
			}
		);

		if (!isEmptyArray(historicalTasks?.ScheduledTasks)) {
			const { ScheduledTasks: scheduled, UnscheduledTasks: unscheduled } =
				historicalTasks;
			const newPhotosMap = generateResidentPhotosMapByID(photos);
			setHistoricalTasks({
				scheduled: scheduled,
				unscheduled: unscheduled,
			});
			setFilterSelections({ ...selectionState });
			setIsLoading(false);
			setPhotosMap({ ...newPhotosMap });
			return dispatchAlert("SUCCESS", {
				heading: `Tasks are loaded`,
			});
		} else {
			setIsLoading(false);
			setPhotosMap({});
			return dispatchAlert("ERROR", {
				heading: `Something went wrong.`,
				subheading: `Please try again!`,
			});
		}
	};

	const syncSortType = (sortType) => {
		setSortType(sortType);
	};

	return (
		<>
			<article className={styles.HistoricalTaskView}>
				<header className={styles.HistoricalTaskView_header}>
					<h2 className={styles.HistoricalTaskView_header_title}>
						Historical Task Editor
					</h2>
					<p className={styles.HistoricalTaskView_header_desc}>
						Edit, update, status and manage historical task records. Prepare and
						make changes for state audits.
					</p>
				</header>
				<div className={styles.HistoricalTaskView_selectors}>
					<HistoryViewFilterController
						key={`HISTORY-VIEW-${currentUser?.facilities?.length}`}
						globalState={globalState}
						dispatch={dispatchToState}
						dispatchAlert={dispatchAlert}
						currentUser={currentUser}
						currentFacility={currentFacility}
						applySelections={applyFilterSelections}
						filterSelections={filterSelections}
					/>
				</div>
				<main className={styles.HistoricalTaskView_main}>
					{isLoading && (
						<div className={styles.HistoricalTaskView_main_loader}>
							<Spinner />
							<div className={styles.HistoricalTaskView_main_loader_msg}>
								Loading historical tasks...this may take a moment.
							</div>
						</div>
					)}

					{!isEmptyArray(historicalTasks?.scheduled) && !isLoading && (
						<>
							<div className={styles.HistoricalTaskView_main_msg}>
								<div className={styles.HistoricalTaskView_main_msg_text}>
									Click a task to view or edit details. Or select one or more
									tasks to bulk edit.
								</div>
								<div className={styles.HistoricalTaskView_main_msg_text}>
									Shift-select multiple sequential tasks at once for batch/bulk
									editing.
								</div>
							</div>
							<HistoricalTasksController
								key={`HISTORY-TASKS-${historicalTasks?.scheduled?.length}`}
								photosMap={photosMap}
								sortType={sortType}
								syncSortType={syncSortType}
								setSortType={setSortType}
								tasksByType={historicalTasks}
								currentUser={currentUser}
								dispatchAlert={dispatchAlert}
								currentFacility={getCurrentFacility(
									filterSelections?.selectedFacility,
									facilities
								)}
							/>
						</>
					)}
				</main>
			</article>

			{AlertsHandler}
		</>
	);
};

export default HistoricalTaskView;

HistoricalTaskView.defaultProps = {};

HistoricalTaskView.propTypes = {};
