import React, { useState, useEffect } from "react";
import styles from "../../css/timeview/ResidentTaskHandler.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { sortAndFormatAdls } from "../../helpers/utils_categories";
import { red, themeColors } from "../../helpers/utils_styles";
import { hasProp, isEmptyVal } from "../../helpers/utils_types";
import { formatResidentNameTimeView } from "../../helpers/utils_residents";
import { formatDate } from "../../helpers/utils_dates";
import { featureFlags } from "../../helpers/utils_features";
import { hasRecurringType } from "../../helpers/utils_repeatingTasks";
// components
import { ShiftIndicator } from "../daily/RepeatDesc";
import Divider from "../forms/Divider";
import FormSection from "../forms/FormSection";
import TextInput from "../shared/TextInput";
import CustomDropdown from "../shared/CustomDropdown";
import TimeViewResidentPhoto from "./TimeViewResidentPhoto";
import Textarea from "../shared/Textarea";
import DueDatePicker from "../daily/DueDatePicker";
import CustomDropdownSM from "../shared/CustomDropdownSM";
import RepeatTaskModal from "../daily/RepeatTaskModal";
import DateRangePickerSM from "../shared/DateRangePickerSM";
import CustomCheckbox from "../shared/CustomCheckbox";
import ButtonSM from "../shared/ButtonSM";

// REQUIREMENTS:
// - "Task Name"
// - "ADL" selector
// Perhaps show the below as 'More Options'
// - "Notes" or comment
// - "Scheduled Date" or due date selector
// - "Repeat Settings" UI
// - "Shift Schedule" UI
// - "Lock Task" checkbox

const customCSS = {
	createTaskBtn: {
		backgroundColor: themeColors.main.main,
		fontSize: "1.5rem",
		padding: "1rem 1.8rem",
		marginRight: "1rem",
	},
	cancelTaskBtn: {
		backgroundColor: "transparent",
		color: themeColors.main.blackBlue,
		border: "1px solid #ccc",
		fontSize: "1.5rem",
		padding: "1rem 1.8rem",
	},
	shiftBtn: {
		margin: "0 1rem",
	},
	divider: {
		margin: "2rem 0",
		opacity: ".4",
	},
};

// checks for shift selections when creating (scheduling) a task
const hasShiftSelections = (vals) => {
	const {
		isRecurringAM: hasAM,
		isRecurringPM: hasPM,
		isRecurringNOC: hasNOC,
	} = vals;
	const hasShifts = hasAM || hasPM || hasNOC;

	return hasShifts;
};
const hasDueTime = (formState = {}) => {
	const { touched, values } = formState;
	const dueTime = values?.scheduledDueTime;
	const hasChanges =
		hasProp(touched, "scheduledDueTime") && !isEmptyVal(dueTime);

	return hasChanges;
};

/**
 * Show task repeat settings if:
 * - User is admin type or above
 * - Non-admins can still create tasks and schedule them for a specific shift, but not add repeats
 */
const showRepeatFeature = (currentUser) => {
	const { isAdmin, isExecutiveAdmin, isFacilityAdmin, isSuperUser } =
		currentUser;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;

	return isAdminType;
};
/**
 * Show task lock settings if:
 * - User is admin type or above ONLY
 */
const showLockTaskFeature = (currentUser) => {
	const isAdmin = currentUser?.isAdmin ?? false;
	const isExecutiveAdmin = currentUser?.isExecutiveAdmin ?? false;
	const isFacilityAdmin = currentUser?.isFacilityAdmin ?? false;
	const isSuperUser = currentUser?.isSuperUser ?? false;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;

	return isAdminType;
};

const noSelections = (vals = {}) => {
	const { newTaskName, newTaskCategory } = vals;
	return isEmptyVal(newTaskCategory) || isEmptyVal(newTaskName);
};
const noAdlSelected = (vals = {}) => {
	const { newTaskCategory } = vals;
	return isEmptyVal(newTaskCategory);
};

const ResidentTaskHandler = ({
	vals = {},
	formState = {},
	selectedResident = {},
	currentUser = {},
	currentFacility = {},
	adlCategories = [],
	recurringTypes = [],
	handleChange,
	handleCheckbox,
	handleDateRange,
	handleDueDate,
	handleDueTime,
	handleRecurrences,
	handleCycleOptions,
	handleSettings,
	cancelRepeat,
	createResidentTask,
	cancelResidentTask,
	isSubmitting = false,
}) => {
	const [showRepeat, setShowRepeat] = useState(false);
	const [repeatDescription, setRepeatDescription] = useState("");

	const fetchNewTaskResources = async () => {
		const { token } = currentUser;
		const id = selectedResident?.residentID ?? selectedResident?.ResidentId;
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.ResidentTaskHandler}>
			<section className={styles.ResidentTaskHandler_resident}>
				<TimeViewResidentPhoto
					task={selectedResident}
					imgSize="LG"
					residentImage={{
						src: selectedResident?.photoSrc,
						alt: "Resident Photo (LG)",
					}}
					residentInfo={{
						...selectedResident,
						RoomNum: selectedResident?.roomNum,
					}}
				/>
				<div className={styles.ResidentTaskHandler_resident_title}>
					<div className={styles.ResidentTaskHandler_resident_title_name}>
						{formatResidentNameTimeView(selectedResident)}
					</div>
					<div className={styles.ResidentTaskHandler_resident_title_id}>
						ALA ID:{" "}
						{selectedResident?.residentID ?? selectedResident?.ResidentId}
					</div>
				</div>
			</section>

			{/*  */}
			<form className={styles.ResidentTaskHandler_form}>
				<fieldset
					className={styles.ResidentTaskHandler_form_formFields}
					disabled={isSubmitting}
				>
					<section className={styles.ResidentTaskHandler_form_formFields_top}>
						<h2
							className={styles.ResidentTaskHandler_form_formFields_top_title}
						>
							Create a New Task
						</h2>
						<div
							className={styles.ResidentTaskHandler_form_formFields_top_date}
						>
							<svg
								className={
									styles.ResidentTaskHandler_form_formFields_top_date_icon
								}
							>
								<use xlinkHref={`${sprite}#icon-event_note`}></use>
							</svg>
							<span>{formatDate(new Date())}</span>
						</div>
					</section>

					<Divider customStyles={customCSS.divider} />
					{/* TASK NAME - TEXT INPUT */}
					<FormSection
						title="Task Name"
						icon="signature"
						hoverText="Enter a name for the task."
					>
						<TextInput
							name="newTaskName"
							id="newTaskName"
							val={vals.newTaskName}
							handleChange={handleChange}
							placeholder="Helped resident with..."
						/>
					</FormSection>

					<Divider customStyles={customCSS.divider} />

					{/* TASK CATEGORY - CUSTOM DROPDOWN */}
					<FormSection
						title="Categorize"
						icon="building"
						hoverText="Select an ADL for the task."
					>
						<div className={styles.ResidentTaskHandler_adl}>
							<CustomDropdown
								name="newTaskCategory"
								id="newTaskCategory"
								placeholder="Select ADL..."
								selection={vals.newTaskCategory}
								setSelection={handleSettings}
								options={adlCategories}
							/>
						</div>
						{noAdlSelected(vals) && (
							<span className={styles.ResidentTaskHandler_required}>
								Please select an ADL
							</span>
						)}
					</FormSection>

					<Divider customStyles={customCSS.divider} />

					{/* TASK NOTES - TEXTAREA */}
					<FormSection
						title="Add a Note Or Comment"
						icon="note"
						hoverText="Add a description or note."
					>
						<Textarea
							name="newTaskNotes"
							id="newTaskNotes"
							placeholder="Enter a note or comment..."
							val={vals.newTaskNotes}
							handleChange={handleChange}
							enableCharCount={true}
							maxChar={250}
						/>
					</FormSection>
					<Divider customStyles={customCSS.divider} />

					{/* SCHEDULED DATE - DUE DATE PICKER */}

					{/* <>
						 	<FormSection
						 		title="Due Date/Time:"
						 		icon="clock"
						 		hoverText="Add a due date and time."
						 	>
						 		<DueDatePicker
						 			key={`RESIDENT-DUE-TIME`}
						 			vals={vals}
						 			currentUser={currentUser}
						 			currentFacility={currentFacility}
						 			handleDueDate={handleDueDate}
						 			handleDueTime={handleDueTime}
						 		/>
						 		
						 	</FormSection>
						 	<Divider customStyles={{ opacity: ".2" }} />
						 </> */}

					{/* SCHEDULED DATE - DUE DATE PICKER */}
					{featureFlags?.enableDueDate && (
						<FormSection
							title="Schedule Task Shift(s) OR Pick a Time"
							icon="clock"
							hoverText="Pick shift or time for task."
						>
							<div className={styles.ResidentTaskHandler_scheduler}>
								<p className={styles.ResidentTaskHandler_scheduler_msg}>
									Pick a specific time OR select one or more shifts to schedule
									a task for.
									<div>
										NOTE: selecting shifts will disable due time and vice versa.
									</div>
								</p>

								{/* DATE/TIME PICKER */}
								<fieldset
									className={styles.ResidentTaskHandler_scheduler_option}
									disabled={hasShiftSelections(vals) || vals?.isLocked}
									style={
										hasShiftSelections(vals)
											? customCSS.notAllowed
											: customCSS.allowed
									}
								>
									<DueDatePicker
										key={`GENERIC-DUE-TIME`}
										vals={vals}
										currentUser={currentUser}
										currentFacility={currentFacility}
										handleDueDate={handleDueDate}
										handleDueTime={handleDueTime}
									/>
								</fieldset>
								<div className={styles.ResidentTaskHandler_scheduler_divider}>
									OR
								</div>
								{/* SHIFT SELECTION */}
								<fieldset
									className={styles.ResidentTaskHandler_scheduler_shifts}
									disabled={hasDueTime(formState || vals?.isLocked)}
									style={
										hasDueTime(formState)
											? customCSS.notAllowed
											: customCSS.allowed
									}
								>
									<ShiftIndicator
										shift="AM"
										key={`AM__NewTask`}
										val={vals.isRecurringAM}
										toggleShift={handleRecurrences}
										isDisabled={vals.isLocked || hasDueTime(formState)}
										customStyles={customCSS.shiftBtn}
									/>
									<ShiftIndicator
										shift="PM"
										key={`PM__NewTask`}
										val={vals.isRecurringPM}
										toggleShift={handleRecurrences}
										isDisabled={vals.isLocked || hasDueTime(formState)}
										customStyles={customCSS.shiftBtn}
									/>
									<ShiftIndicator
										shift="NOC"
										key={`NOC__NewTask`}
										val={vals.isRecurringNOC}
										toggleShift={handleRecurrences}
										isDisabled={vals.isLocked || hasDueTime(formState)}
										customStyles={customCSS.shiftBtn}
									/>
								</fieldset>
							</div>
						</FormSection>
					)}
					<Divider customStyles={customCSS.divider} />

					{/* REPEAT/RECURRING TYPE */}
					{showRepeatFeature(currentUser) && (
						<FormSection
							title="Repeat Task"
							icon="recurring"
							hoverText="Select when the task repeats."
						>
							<div className={styles.ResidentTaskHandler_repeat}>
								<span className={styles.ResidentTaskHandler_repeat_label}>
									Repeat:{" "}
								</span>

								<CustomDropdownSM
									name="recurringType"
									id="recurringType"
									selection={vals?.recurringType ?? "Never"}
									setSelection={handleSettings}
									inputSize={12}
									options={recurringTypes}
								/>
								{/* UPDATE SETTINGS BUTTON */}
								{hasRecurringType(vals.recurringType) && (
									<button
										className={styles.ResidentTaskHandler_repeat_updateBtn}
										onClick={(e) => {
											e.preventDefault();
											setShowRepeat(true);
										}}
									>
										Update Settings (Pick days)
									</button>
								)}
								{/* REPEAT TASK MODAL */}
								{showRepeat && (
									<RepeatTaskModal
										key={`REPEAT-RESIDENT-TASK-NEW`}
										vals={vals}
										saveRepeat={() => setShowRepeat(false)}
										cancelRepeat={cancelRepeat}
										closeRepeatWindow={() => setShowRepeat(false)}
										getRepeatDescText={setRepeatDescription} // text description in form
										repeatType={vals?.recurringType ?? "Never"}
										handleSettings={handleSettings}
										handleCycleOptions={handleCycleOptions}
										handleDaySelections={handleCheckbox}
									/>
								)}
							</div>
							<div className={styles.ResidentTaskHandler_repeat_rangePicker}>
								<DateRangePickerSM
									key={`RESIDENT-TASK-DUE-DATES-${vals?.startDate}`}
									label="Select a start and end date"
									id="startDate"
									startName="startDate"
									endName="endDate"
									rangeVals={vals}
									handleSelection={handleDateRange}
									focusMode={true}
								/>
							</div>
							<div className={styles.ResidentTaskHandler_repeat_desc}>
								{!isEmptyVal(repeatDescription) && (
									<span>{repeatDescription}</span>
								)}
							</div>
						</FormSection>
					)}
					<Divider customStyles={customCSS.divider} />

					{/* SHIFT SELECTION */}
					{/* <FormSection
						title="Schedule task for one or more Shift(s)"
						icon="timer"
						hoverText="Selecting more than one shift will create multiple task occurrences."
					>
						<div className={styles.ResidentTaskHandler_shifts}>
							<ShiftIndicator
								shift="AM"
								key={`AM__NewTask`}
								val={vals.isRecurringAM}
								toggleShift={handleRecurrences}
								isDisabled={vals.isLocked}
								customStyles={customCSS.shiftBtn}
							/>
							<ShiftIndicator
								shift="PM"
								key={`PM__NewTask`}
								val={vals.isRecurringPM}
								toggleShift={handleRecurrences}
								isDisabled={vals.isLocked}
								customStyles={customCSS.shiftBtn}
							/>
							<ShiftIndicator
								shift="NOC"
								key={`NOC__NewTask`}
								val={vals.isRecurringNOC}
								toggleShift={handleRecurrences}
								isDisabled={vals.isLocked}
								customStyles={customCSS.shiftBtn}
							/>
						</div>
					</FormSection>
					<Divider customStyles={customCSS.divider} /> */}

					{/* LOCK TASK SECTION */}
					{showLockTaskFeature(currentUser) && (
						<FormSection
							title="Lock Task"
							icon="lock"
							iconColor={red[600]}
							titleColor={red[600]}
							hoverText="Locking a task disables non-admins from changing it."
						>
							<div className={styles.ResidentTaskHandler_lockTask}>
								<p className={styles.ResidentTaskHandler_lockTask_desc}>
									Locking a task will prevent unauthorized changes to a task
									from any "NON-ADMIN" user. Only users with "ADMIN"
									access/permissions are able to make changes to a "locked"
									task.
								</p>
							</div>
							<div className={styles.ResidentTaskHandler_lockTask_checkbox}>
								<CustomCheckbox
									name="isLocked"
									id="isLocked"
									label={vals.isLocked ? `Locked Task` : `Lock this task`}
									val={vals.isLocked}
									handleCheckbox={handleCheckbox}
								/>
							</div>
						</FormSection>
					)}

					<Divider customStyles={customCSS.divider} />

					{/* CREATE-TASK/CANCEL BUTTONS */}
					<section className={styles.ResidentTaskHandler_actions}>
						<ButtonSM
							isDisabled={noSelections(vals)}
							handleClick={createResidentTask}
							customStyles={customCSS.createTaskBtn}
						>
							<span>{isSubmitting ? "Saving..." : "Create Task"}</span>
						</ButtonSM>
						<ButtonSM
							handleClick={cancelResidentTask}
							customStyles={customCSS.cancelTaskBtn}
						>
							<span>Cancel</span>
						</ButtonSM>
					</section>
				</fieldset>
			</form>
		</div>
	);
};

export default ResidentTaskHandler;

ResidentTaskHandler.defaultProps = {};

ResidentTaskHandler.propTypes = {};
