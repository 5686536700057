import React from "react";
import styles from "../../css/timeview/TimeViewFilterFacility.module.scss";
import sprite from "../../assets/icons/filters.svg";
import { PropTypes } from "prop-types";

const TimeViewFilterFacility = ({
	facility = {},
	handleFacility,
	isSelected = false,
}) => {
	return (
		<li
			className={
				isSelected
					? styles.TimeViewFilterFacility_isSelected
					: styles.TimeViewFilterFacility
			}
			onClick={handleFacility}
		>
			<div className={styles.TimeViewFilterFacility_iconWrapper}>
				<svg className={styles.TimeViewFilterFacility_iconWrapper_icon}>
					<use xlinkHref={`${sprite}#icon-location_city`}></use>
				</svg>
			</div>
			<div className={styles.TimeViewFilterFacility_main}>
				<div className={styles.TimeViewFilterFacility_main_name}>
					{facility?.name}
				</div>
				<div className={styles.TimeViewFilterFacility_main_id}>
					{facility?.id}
				</div>
			</div>
			{isSelected && (
				<svg className={styles.TimeViewFilterFacility_icon}>
					<use xlinkHref={`${sprite}#icon-checkmark-outline`}></use>
				</svg>
			)}
		</li>
	);
};

export default TimeViewFilterFacility;

TimeViewFilterFacility.defaultProps = {};

TimeViewFilterFacility.propTypes = {};
