import React, { useState } from "react";
import styles from "../../css/daily/ADLNotes.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { formatWithFullDay } from "../../helpers/utils_dates";
import { themeColors } from "../../helpers/utils_styles";

import ButtonSM from "../shared/ButtonSM";
import Textarea from "../shared/Textarea";

// feature flag for editing ADL notes
const enableAddNotesFeature = false;

const custom = {
	backgroundColor: themeColors.main.green,
	borderRadius: "5rem",
	marginBottom: "2rem",
};
const saveBtn = {
	backgroundColor: themeColors.main.main,
	borderRadius: "5rem",
	padding: "1rem",
};
const cancelBtn = {
	backgroundColor: "transparent",
	color: "#333",
	borderRadius: "5rem",
	padding: "1rem",
	marginLeft: "1rem",
	border: "1px solid #ccc",
};
const txtBox = {
	height: "5rem",
};

const ADLNotes = ({
	adl,
	newNotes,
	readOnlyNotes,
	handleChange,
	saveNewNotes,
	cancelNewNotes,
}) => {
	const [isAddingNotes, setIsAddingNotes] = useState(false);

	const cancelNotesHandler = (e) => {
		setIsAddingNotes(false);
		cancelNewNotes(e);
	};

	return (
		<article className={styles.ADLNotes}>
			<section className={styles.ADLNotes_group_headings}>
				<h2 className={styles.ADLNotes_group_title}>
					{adl.AdlCategoryType} Details
				</h2>
				<h2 className={styles.ADLNotes_group_headings_date}>
					{formatWithFullDay(new Date())}
				</h2>
			</section>
			<hr style={{ margin: "2rem 0", opacity: ".4" }} />
			<section className={styles.ADLNotes_group}>
				<h2 className={styles.ADLNotes_group_subtitle}>ADL Notes</h2>
				<Textarea
					name="adlNotes"
					id="adlNotes"
					val={readOnlyNotes}
					handleChange={handleChange}
					maxChar={500}
					enableCharCount={true}
					readOnly={true}
				/>
				<div className={styles.ADLNotes_group_form}>
					{enableAddNotesFeature && (
						<ButtonSM
							isDisabled={isAddingNotes}
							handleClick={(e) => {
								setIsAddingNotes(!isAddingNotes);
								handleChange(e);
							}}
							customStyles={custom}
						>
							<span>Add {adl.AdlCategoryType} Notes</span>
						</ButtonSM>
					)}

					{isAddingNotes && (
						<>
							<Textarea
								label="Add Additional Notes"
								name="newNotes"
								id="newNotes"
								val={newNotes}
								handleChange={handleChange}
								maxChar={250}
								enableCharCount={true}
								customStyles={txtBox}
							/>
							<div className={styles.ADLNotes_group_form_buttons}>
								<ButtonSM handleClick={saveNewNotes} customStyles={saveBtn}>
									<svg className={styles.ADLNotes_group_form_icon}>
										<use xlinkHref={`${sprite}#icon-save11`}></use>
									</svg>
									<span>Save New Notes</span>
								</ButtonSM>
								<ButtonSM
									handleClick={cancelNotesHandler}
									customStyles={cancelBtn}
								>
									<svg
										className={styles.ADLNotes_group_form_icon}
										style={{ fill: themeColors.main.red }}
									>
										<use xlinkHref={`${sprite}#icon-clearclose`}></use>
									</svg>
									<span>Cancel Note</span>
								</ButtonSM>
							</div>
						</>
					)}
				</div>
			</section>
			<hr style={{ margin: "2rem 0", opacity: ".4" }} />
		</article>
	);
};

export default ADLNotes;

ADLNotes.defaultProps = {};

ADLNotes.propTypes = {
	adl: PropTypes.object.isRequired, // ADL Category object
	saveNewNotes: PropTypes.func.isRequired, // saves new notes
	cancelNewNotes: PropTypes.func.isRequired, // cancel button for new notes
	readOnlyNotes: PropTypes.string.isRequired, // existing notes val
	newNotes: PropTypes.string.isRequired, // new notes val
};
