import React, { useState, useEffect } from "react";
import styles from "../../css/history/SingleTaskActionsHandler.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	getUsersByFacility,
	processAdvUserForTV,
	processAllAdvUsers,
} from "../../helpers/utils_user";
import { green, purple, red, yellow } from "../../helpers/utils_styles";
import {
	sortAlphaAscByKey,
	sortByAlphaAsc,
} from "../../helpers/utils_processing";
// components
import ButtonSM from "../shared/ButtonSM";
import Spinner from "../shared/Spinner";
import HistoricalUserAssignment from "./HistoricalUserAssignment";

const customCSS = {
	doneBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		fontSize: "1.6rem",
		backgroundColor: green[500],
	},
	exceptionBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		fontSize: "1.6rem",
		backgroundColor: red[600],
	},
	pastdueBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		fontSize: "1.6rem",
		backgroundColor: purple[600],
	},
	statusBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		fontSize: "1.6rem",
		backgroundColor: yellow[600],
	},
};

const getAvailableUsers = (records = []) => {
	if (isEmptyArray(records)) return [];

	// const newList = records.map((x) => {
	// 	// const name = `${x.lastName}, ${x.firstName} - ${x.userID}`;
	// 	const name = `${x.lastName}, ${x.firstName}`;
	// 	return name;
	// });
	// const sorted = sortByAlphaAsc(newList);
	// return sorted;
	const sorted = sortAlphaAscByKey("lastName", records);

	return sorted;
};

const SingleCompleteHandler = ({ taskState = {}, handleSingleCompletion }) => {
	const { selected, tasks } = taskState;
	const { all: allTasks } = tasks;
	const { all: selectedTasks } = selected;

	return (
		<div className={styles.SingleCompleteHandler}>
			<div className={styles.SingleCompleteHandler_action}>
				<ButtonSM
					customStyles={customCSS.doneBtn}
					handleClick={handleSingleCompletion}
				>
					Mark as done
				</ButtonSM>
			</div>
			<div className={styles.SingleCompleteHandler_msg}>
				You're about to mark {selectedTasks?.length} tasks as completed.
			</div>
		</div>
	);
};

const SingleRemoveException = ({
	actionType,
	taskState = {},
	handleSingleException,
}) => {
	const { selected, tasks } = taskState;
	const { all: allTasks } = tasks;
	const { all: selectedTasks } = selected;
	return (
		<div className={styles.SingleRemoveException}>
			<div className={styles.SingleRemoveException_action}>
				<ButtonSM
					customStyles={customCSS.exceptionBtn}
					handleClick={handleSingleException}
				>
					Remove exception(s)
				</ButtonSM>
			</div>
			<div className={styles.SingleRemoveException_msg}>
				You're about to change the status of {selectedTasks?.length} tasks.
			</div>
		</div>
	);
};
const SingleRemovePastDue = ({
	actionType,
	taskState = {},
	handleSinglePastDue,
}) => {
	const { selected, tasks } = taskState;
	const { all: allTasks } = tasks;
	const { all: selectedTasks } = selected;
	return (
		<div className={styles.SingleRemovePastDue}>
			<div className={styles.SingleRemovePastDue_action}>
				<ButtonSM
					customStyles={customCSS.pastdueBtn}
					handleClick={handleSinglePastDue}
				>
					Remove past-due
				</ButtonSM>
			</div>
			<div className={styles.SingleRemovePastDue_msg}>
				You're about to change the status of {selectedTasks?.length} tasks.
			</div>
		</div>
	);
};

const SingleTaskActionsHandler = ({
	actionType,
	taskState = {},
	assignTo = {},
	currentUser = {},
	currentFacility = {},
	isSavingChanges = false,
	hasError = false,
	handleSingleException,
	handleSingleCompletion,
	handleSinglePastDue,
	handleUserAssignment,
	retryHandler,
}) => {
	const [usersList, setUsersList] = useState({
		records: [],
		options: [],
	});

	const fetchAssignableUsers = async () => {
		const { token } = currentUser;
		const { FacilityId: facilityID } = currentFacility;
		const users = await getUsersByFacility(token, facilityID);

		if (!isEmptyArray(users)) {
			const newList = processAllAdvUsers(users);
			const names = getAvailableUsers([...newList]);
			return setUsersList({
				records: names,
				names: names,
			});
		} else {
			return setUsersList({
				records: [],
				names: [],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchAssignableUsers();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (actionType === null || isEmptyVal(actionType)) {
		return null;
	}
	return (
		<div className={styles.SingleTaskActionsHandler}>
			{isSavingChanges && !hasError && (
				<div className={styles.SingleTaskActionsHandler}>
					<Spinner />
					<div className={styles.SingleTaskActionsHandler_loader}>
						Saving changes...please wait
					</div>
				</div>
			)}
			{isSavingChanges && hasError && (
				<div className={styles.SingleTaskActionsHandler}>
					<div className={styles.SingleTaskActionsHandler_error}>
						Something went wrong. Please try again.
					</div>
					<button
						onClick={retryHandler}
						className={styles.SingleTaskActionsHandler_error}
					>
						Retry
					</button>
				</div>
			)}

			{!isSavingChanges && (
				<div className={styles.SingleTaskActionsHandler}>
					<div className={styles.SingleTaskActionsHandler_msg}>
						You may assign this action to another user and the changes will be
						back-dated to the date and time of the task's original schedule (ie.
						due time).
					</div>
					<div className={styles.SingleTaskActionsHandler_assign}>
						{!isEmptyArray(usersList?.names) && (
							<HistoricalUserAssignment
								key={`USERS-ASSIGN-${usersList?.records.length}-${assignTo?.userID}`}
								assignTo={assignTo}
								availableUsers={usersList?.names ?? []}
								handleUserAssign={handleUserAssignment}
							/>
						)}
					</div>
					<div className={styles.SingleTaskActionsHandler_type}>
						{actionType === "SINGLE-MARK-AS-DONE" && (
							<SingleCompleteHandler
								taskState={taskState}
								handleSingleCompletion={handleSingleCompletion}
							/>
						)}
						{actionType === "SINGLE-REMOVE-EXCEPTION" && (
							<SingleRemoveException
								taskState={taskState}
								handleSingleException={handleSingleException}
							/>
						)}
						{actionType === "SINGLE-REMOVE-PAST-DUE" && (
							<SingleRemovePastDue
								taskState={taskState}
								handleSinglePastDue={handleSinglePastDue}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default SingleTaskActionsHandler;

SingleTaskActionsHandler.defaultProps = {};

SingleTaskActionsHandler.propTypes = {};
