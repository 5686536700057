import React, { useEffect, useState, useReducer, useRef } from "react";
import sprite from "../../assets/icons/filters.svg";
import styles from "../../css/timeview/TimeViewFilterController.module.scss";
import { PropTypes } from "prop-types";
import {
	processControllerFacilities,
	processControllerResidents,
	processControllerShifts,
} from "../../helpers/utils_timeview";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
// components
import TimeViewFilterDropdown from "./TimeViewFilterDropdown";
import TimeViewFilterDropdown2 from "./TimeViewFilterDropdown2";

// REQUIREMENTS:
// - Searchable list of ALL residents at a facility
//    - Each resident option is a selectable checkbox
// - Includes: "Select All" & "De-select All" options for facility residents

const hasNoSelections = (filterState) => {
	const {
		selectedFacility: facility,
		selectedResidents: residents,
		selectedShifts: shifts,
	} = filterState;

	const noShifts = isEmptyArray(shifts);
	const noFac = isEmptyVal(facility?.id) || isEmptyVal(facility?.name);
	const noRes = isEmptyArray(residents);

	const noSelections = noShifts || noFac || noRes;

	return noSelections;
};

const getSelectionMsg = (filterState) => {
	const { selectedResidents } = filterState;
	const { residents: allResidents } = filterState?.selectedFacility;
	const count = `${selectedResidents?.length} / ${allResidents?.length}`;
	// messages
	const showAllMsg = `Showing all residents`;
	const showSomeMsg = `Showing ${count} residents`;
	const notLoadedMsg = `No residents loaded.`;

	switch (true) {
		case isEmptyArray(allResidents): {
			return notLoadedMsg;
		}
		case selectedResidents?.length === allResidents?.length: {
			return showAllMsg;
		}
		case selectedResidents?.length !== allResidents?.length: {
			return showSomeMsg;
		}
		default:
			return ``;
	}
};

const advancedFacilitySearch = (val, options) => {
	val = val?.toLowerCase();

	return options.filter((option) => {
		if (
			option?.id?.toLowerCase().startsWith(val) ||
			option?.id?.toLowerCase().includes(val) ||
			option?.name?.toLowerCase().startsWith(val) ||
			option?.name?.toLowerCase().includes(val)
		) {
			return option;
		} else {
			return null;
		}
	});
};

const advancedSearch = (val, options) => {
	val = val?.toLowerCase();

	return options.filter((option) => {
		if (
			option?.name?.toLowerCase().startsWith(val) ||
			option?.name?.toLowerCase().includes(val) ||
			option?.id?.toString()?.toLowerCase().startsWith(val) ||
			option?.id?.toString()?.toLowerCase().includes(val)
		) {
			// matches
			return option;
		} else {
			return null;
		}
	});
};

const filterReducer = (state, action) => {
	switch (action.type) {
		case "SELECT_RESIDENTS": {
			const { newSelections } = action.data;

			return {
				...state,
				selectedResidents: newSelections,
			};
		}
		case "SELECT_ALL_RESIDENTS": {
			const { newSelections } = action.data;

			return {
				...state,
				selectedResidents: newSelections,
			};
		}
		case "SELECT_SHIFTS": {
			const { newShifts } = action.data;

			return {
				...state,
				selectedShifts: [...newShifts],
			};
		}
		case "SELECT_ALL_SHIFTS": {
			const { newSelections } = action.data;

			return {
				...state,
				selectedShifts: newSelections,
			};
		}
		case "SELECT_FACILITY": {
			const { newSelection, newResidents, newShifts, selectedFacilityID } =
				action.data;

			if (isEmptyVal(newSelection?.id) || isEmptyObj(newSelection)) {
				return {
					...state,
					selectedFacility: {
						id: "",
						name: "",
						shifts: [],
						residents: [],
					},
				};
			} else {
				const shifts = newSelection?.shifts ?? newShifts;

				return {
					...state,
					selectedFacility: {
						id: selectedFacilityID,
						name: newSelection?.name,
						shifts: processControllerShifts(shifts),
						residents: newResidents,
					},
				};
			}
		}
		case "LOAD": {
			return state;
		}
		case "SORT_BY": {
			return state;
		}
		case "SEARCH_RESIDENTS": {
			const { searchVal, rawOptions } = action.data;
			const { selectedFacility, selectedResidents } = state;
			const { residents } = selectedFacility;

			if (isEmptyVal(searchVal)) {
				return {
					...state,
					selectedResidents: selectedResidents,
					selectedFacility: {
						...selectedFacility,
						residents: rawOptions,
					},
				};
			} else {
				return {
					...state,
					selectedResidents: selectedResidents,
					selectedFacility: {
						...state?.selectedFacility,
						residents: [...advancedSearch(searchVal, residents)],
					},
				};
			}
		}
		// clears resident selections
		case "CLEAR_SELECTIONS": {
			return {
				...state,
				selectedResidents: [],
			};
		}
		case "SEARCH_FACILITIES": {
			const { searchVal, rawOptions } = action.data;

			if (isEmptyVal(searchVal)) {
				return {
					...state,
					facilities: [...rawOptions],
				};
			} else {
				return {
					...state,
					facilities: [...advancedFacilitySearch(searchVal, rawOptions)],
				};
			}
		}
		case "CLEAR_SHIFTS": {
			return {
				...state,
				selectedShifts: [],
			};
		}
		default:
			return state;
	}
};

const shiftAm = {
	idx: 0,
	id: 1,
	name: "AM",
	isRollOver: false,
	startTime: "9998-12-31T14:00:00Z",
	endTime: "9998-12-31T22:00:00Z",
};

const initialFilterState = {
	selectedFacility: {
		facilityID: "",
		name: "", // communityName
		residents: [], // all residents for facility
		shifts: [],
	},
	selectedResidents: [],
	selectedShifts: [shiftAm],
	sortBy: "ASC",
	facilities: [],
};

const TimeViewFilterController = ({
	globalState = {},
	currentUser = {},
	currentFacility = {},
	dispatchAlert,
	disableMultiSelect = false,
	applySelections,
	dispatchToState,
}) => {
	const { facilities } = currentUser;
	const { residentsByFacility } = globalState?.globals;
	const { communityName, residents, shifts, facilityID } = currentFacility;
	// local state
	const searchRef = useRef();
	const [showFilterOptions, setShowFilterOptions] = useState(false);
	const [searchVal, setSearchVal] = useState("");
	const [facilitySearch, setFacilitySearch] = useState("");
	const [clonedList, setClonedList] = useState(
		processControllerResidents(residents)
	);
	const [filterState, filterDispatch] = useReducer(filterReducer, {
		...initialFilterState,
		facilities: processControllerFacilities(facilities),
		selectedFacility: {
			facilityID: facilityID,
			name: communityName,
			residents: processControllerResidents(residents),
			shifts: processControllerShifts(shifts),
		},
	});
	const {
		selectedResidents: selections,
		selectedShifts,
		selectedFacility,
	} = filterState;
	const { residents: filterOptions } = filterState?.selectedFacility;

	const handleSelect = (option) => {
		// only handles single select
		if (disableMultiSelect)
			return filterDispatch({
				type: "SELECT_RESIDENTS",
				data: { newSelections: [option] },
			});

		if (selections.includes(option)) {
			const newSelections = [...selections.filter((x) => x.id !== option.id)];
			return filterDispatch({
				type: "SELECT_RESIDENTS",
				data: {
					newSelections,
				},
			});
		} else {
			const newSelections = [...selections, option];

			return filterDispatch({
				type: "SELECT_RESIDENTS",
				data: {
					newSelections,
				},
			});
		}
	};

	const handleSelectAll = () => {
		if (disableMultiSelect) return;

		if (selections.length === filterOptions.length) {
			return filterDispatch({
				type: "SELECT_ALL_RESIDENTS",
				data: {
					newSelections: [],
				},
			});
			// return setSelections([]);
		} else {
			return filterDispatch({
				type: "SELECT_ALL_RESIDENTS",
				data: {
					newSelections: [...filterOptions],
				},
			});
		}
	};

	const saveSelections = () => {
		setShowFilterOptions(false);
		// maybe add parent handler here...
	};

	const clearSelections = () => {
		filterDispatch({ type: "CLEAR_SELECTIONS" });
		// handleCustomSelect([]);
	};

	// search for residents
	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);
		// implement search options
		return filterDispatch({
			type: "SEARCH_RESIDENTS",
			data: {
				searchVal: value,
				rawOptions: clonedList,
			},
		});
	};
	const clearSearch = (e) => {
		setSearchVal("");
		filterDispatch({
			type: "SEARCH_RESIDENTS",
			data: {
				searchVal: "",
				rawOptions: [...clonedList],
			},
		});
		searchRef.current.focus();
	};

	// shift handlers
	const handleShifts = (shift) => {
		const { selectedShifts } = filterState;

		if (selectedShifts.includes(shift)) {
			const newShifts = [...selectedShifts.filter((x) => x.id !== shift.id)];
			return filterDispatch({
				type: "SELECT_SHIFTS",
				data: {
					newShifts,
				},
			});
		} else {
			const newShifts = [...selectedShifts, shift];
			return filterDispatch({
				type: "SELECT_SHIFTS",
				data: {
					newShifts,
				},
			});
		}
	};

	const handleSelectAllShifts = () => {
		if (selectedShifts.length === 3) {
			return filterDispatch({
				type: "SELECT_ALL_SHIFTS",
				data: {
					newSelections: [],
				},
			});
		} else {
			return filterDispatch({
				type: "SELECT_ALL_SHIFTS",
				data: {
					newSelections: [...selectedFacility?.shifts],
				},
			});
		}
	};

	const handleFacility = (selection) => {
		const { id, shifts } = selection;
		const facilityResidents = residentsByFacility?.[id];
		const cleaned = processControllerResidents(facilityResidents);

		setClonedList([...cleaned]);
		return filterDispatch({
			type: "SELECT_FACILITY",
			data: {
				selectedFacilityID: id,
				newSelection: selection,
				newResidents: [...cleaned],
				newShifts: [...shifts],
			},
		});
	};

	const searchFacilities = (e) => {
		const { value } = e.target;
		setFacilitySearch(value);
		filterDispatch({
			type: "SEARCH_FACILITIES",
			data: {
				searchVal: value,
				rawOptions: processControllerFacilities(facilities),
			},
		});
	};
	const clearSearchFacilities = (e) => {
		setFacilitySearch("");
		filterDispatch({
			type: "SEARCH_FACILITIES",
			data: {
				searchVal: "",
				rawOptions: processControllerFacilities(facilities),
			},
		});
	};

	const saveShiftSelections = (e) => {
		setShowFilterOptions(false);
	};
	// clears shift selections
	const clearShiftSelections = (e) => {
		filterDispatch({
			type: "CLEAR_SHIFTS",
		});
	};

	// useEffect(() => {
	// 	let isMounted = true;
	// 	if (!isMounted) {
	// 		return;
	// 	}

	// 	handleCustomSelect(filterState);

	// 	return () => {
	// 		isMounted = false;
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [filterState]);

	return (
		<div className={styles.TimeViewFilterController}>
			<div className={styles.TimeViewFilterController_controls}>
				<button
					type="button"
					disabled={isEmptyArray(facilities)}
					onClick={() => setShowFilterOptions(true)}
					className={styles.TimeViewFilterController_controls_button}
				>
					<svg className={styles.TimeViewFilterController_controls_button_icon}>
						<use xlinkHref={`${sprite}#icon-filter_alt`}></use>
					</svg>
					<span
						className={styles.TimeViewFilterController_controls_button_text}
					>
						Resident Filters
					</span>
				</button>
				<div className={styles.TimeViewFilterController_controls_selected}>
					{getSelectionMsg(filterState)}
				</div>
				{/* APPLY BUTTON */}
				<button
					type="button"
					onClick={() => applySelections(filterState)}
					disabled={hasNoSelections(filterState) || isEmptyArray(facilities)}
					className={styles.TimeViewFilterController_controls_loadButton}
				>
					Apply & Load Tasks
				</button>

				{/* RESIDENTS ONLY OPTION */}
				{/* {showFilterOptions && (
					<TimeViewFilterDropdown
						searchRef={searchRef}
						filterState={filterState}
						filterOptions={filterOptions}
						selections={selections}
						handleSelect={handleSelect}
						handleSelectAll={handleSelectAll}
						saveSelections={saveSelections}
						clearSelections={clearSelections}
						searchVal={searchVal}
						handleSearch={handleSearch}
						clearSearch={clearSearch}
						closeDropdown={() => setShowFilterOptions(false)}
					/>
				)} */}

				{/* MULTIPLE-TABS OPTION */}
				{showFilterOptions && (
					<TimeViewFilterDropdown2
						currentUser={currentUser}
						searchVal={searchVal}
						searchRef={searchRef}
						facilitySearch={facilitySearch}
						handleFacilitySearch={searchFacilities}
						clearFacilitySearch={clearSearchFacilities}
						filterState={filterState}
						filterOptions={filterOptions}
						selections={selections}
						saveSelections={saveSelections}
						clearSelections={clearSelections}
						saveShiftSelections={saveShiftSelections}
						clearShiftSelections={clearShiftSelections}
						handleSelect={handleSelect}
						handleSelectAll={handleSelectAll}
						handleSelectAllShifts={handleSelectAllShifts}
						handleShifts={handleShifts}
						handleFacility={handleFacility}
						handleSearch={handleSearch}
						clearSearch={clearSearch}
						searchFacilities={searchFacilities}
						closeDropdown={() => setShowFilterOptions(false)}
					/>
				)}
			</div>
		</div>
	);
};

export default TimeViewFilterController;

TimeViewFilterController.defaultProps = {};

TimeViewFilterController.propTypes = {};
