import React, { useCallback, useEffect, useState } from "react";
import styles from "../../css/history/HistoricalTaskDetails.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { getAdvancedResources } from "../../helpers/utils_timeview";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import { FACILITY_EXCEPTION_TYPES } from "../../helpers/utils_options";
import { format } from "date-fns";
import { getHistoricalTaskDetails } from "../../helpers/utils_historical";
import { green, pink, purple, red, yellow } from "../../helpers/utils_styles";
// components
import FormSection from "../forms/FormSection";
import CustomDropdown from "../shared/CustomDropdown";
import Divider from "../forms/Divider";
import ButtonSM from "../shared/ButtonSM";
import TaskNotesController from "../notes/TaskNotesController";
import Spinner from "../shared/Spinner";
import TimeViewResidentHeader from "../timeview/TimeViewResidentHeader";
import {
	getTaskDueTime,
	isCompleted,
	isNotComplete,
	isPastDueByTimeOrShift,
	isPRNTask,
} from "../../helpers/utils_tasks";
import Textarea from "../shared/Textarea";
import HistoricalUserAssignment from "./HistoricalUserAssignment";
import { sortAlphaAscByKey } from "../../helpers/utils_processing";

// REQUIREMENTS:
// - Supports feature(s):
//    - Assigning changes to a caregiver/user

const customCSS = {
	completeBtn: {
		padding: ".6rem 1.4rem",
		fontSize: "1.6rem",
		fontWeight: 600,
		backgroundColor: green[500],
		color: "#ffffff",
	},
	pastDueBtn: {
		marginTop: "2rem",
		padding: ".6rem 1.4rem",
		fontSize: "1.6rem",
		fontWeight: 600,
		backgroundColor: purple[600],
		color: "#ffffff",
	},
	exceptionBtn: {
		marginTop: "2rem",
		padding: ".6rem 1.4rem",
		fontSize: "1.6rem",
		fontWeight: 600,
		backgroundColor: red[600],
		color: "#ffffff",
	},
	cancel: {
		padding: "1rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: "1rem",
	},
	save: {
		padding: "1rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		backgroundColor: purple[600],
		color: "#ffffff",
	},
};

const getAvailableUsers = (records = []) => {
	if (isEmptyArray(records)) return [];

	// const newList = records.map((x) => {
	// 	// const name = `${x.lastName}, ${x.firstName} - ${x.userID}`;
	// 	const name = `${x.lastName}, ${x.firstName}`;
	// 	return name;
	// });
	// const sorted = sortByAlphaAsc(newList);
	// return sorted;
	const sorted = sortAlphaAscByKey("lastName", records);

	return sorted;
};

// returns the date/time of original task
const getOriginalScheduledDate = (task = {}, taskRecord = {}) => {
	// client task
	const { ScheduledDate: taskDate } = task;
	// server task
	const { EntryDate: entryDate } = taskRecord;

	// from client
	if (isEmptyVal(taskDate)) {
		const targetDate = format(entryDate, "MM/DD/YYYY h:mm A");
		return targetDate;
		// from task record
	} else {
		const targetDate = format(taskDate, "MM/DD/YYYY h:mm A");
		return targetDate;
	}
};

const getStatusCss = (task = {}, vals = {}, shiftTimes = []) => {
	const taskDueTime = getTaskDueTime(task, new Date(), shiftTimes);

	// due date/time anchored to today's date
	const dueDate = taskDueTime;

	switch (true) {
		case isPRNTask(task): {
			return {
				borderLeft: `8px solid ${pink[600]}`,
				marginLeft: "-2rem",
			};
		}
		// case false: {
		case isCompleted(task) || vals?.markComplete: {
			return {
				borderLeft: `8px solid ${green[600]}`,
				marginLeft: "-2rem",
			};
		}
		case vals?.hasPastDue:
		case isPastDueByTimeOrShift(task, dueDate, shiftTimes): {
			return {
				borderLeft: `8px solid ${purple[700]}`,
				marginLeft: "-2rem",
			};
		}
		case !vals?.hasPastDue:
		case isNotComplete(task, dueDate, shiftTimes): {
			return {
				borderLeft: `8px solid ${yellow[500]}`,
				marginLeft: "-2rem",
			};
		}

		default:
			return {
				borderLeft: `8px solid ${yellow[500]}`,
				marginLeft: "-2rem",
			};
	}
};

const getStatusColor = (task = {}, vals = {}) => {
	const withException =
		!isEmptyVal(vals?.exceptionType) || !isEmptyVal(task?.Exception);
	const isDone = task?.IsCompleted || vals?.markComplete;
	const isPast = vals?.hasPastDue && isEmptyVal(vals?.exceptionType);
	const notPast = !vals?.hasPastDue && !vals?.markComplete;

	switch (true) {
		// PRN TASK
		case isPRNTask(task): {
			return {
				borderLeft: `8px solid ${pink[600]}`,
				marginLeft: "-2rem",
			};
		}
		// COMPLETED
		case isDone: {
			return {
				borderLeft: `8px solid ${green[600]}`,
				marginLeft: "-2rem",
			};
		}
		// PAST-DUE
		case isPast: {
			return {
				borderLeft: `8px solid ${purple[700]}`,
				marginLeft: "-2rem",
			};
		}
		// NOT-COMPLETE
		case notPast: {
			return {
				borderLeft: `8px solid ${yellow[500]}`,
				marginLeft: "-2rem",
			};
		}

		default:
			// DEFAULTS TO 'PAST-DUE'
			return {
				borderLeft: `8px solid ${purple[700]}`,
				marginLeft: "-2rem",
			};
	}
};

const getPhotoSrc = (residentID, photosMap) => {
	const record = photosMap?.[residentID];

	return record?.photoSrc;
};

/**
 * Gets the original created date for the task record.
 * @param {Object} taskInfo - Task info record
 * @returns {Date} - Returns date string or empty string
 */
const getTaskOriginDate = (taskInfo) => {
	// date
	const created = taskInfo?.CreatedDate;

	if (!isEmptyVal(created)) {
		return format(created, "MM/DD/YYYY hh:mm A");
	} else {
		return "";
	}
};

/**
 * Determines whether to show 'Exceptions' UI
 * - Should be hidden for PRN tasks, since they're exempt from exceptions
 */
const showExceptionUI = (task) => {
	const adl = task?.ADL ?? task?.ADLCategory;

	return adl !== "PRN";
};

const showNewTaskNotes = (currentUser) => {
	const isEnabled = true;
	// const isEnabled = enableFeatureViaInternalList(currentUser?.userID);
	// const isLocal = isLocalhost();
	// const isDev = isDevOrTest();
	// const isNotProd = isLocal || isDev;

	return isEnabled;
};

const HistoricalTaskDetails = ({
	vals = {},
	task = {},
	assignTo = {},
	currentUser = {},
	currentFacility = {},
	photosMap = {},
	handleSettings,
	handleUserAssignment,
	handleCompletion,
	handleChange,
	facilityExceptions = [],
	shiftTimes = [],
	availableUsers = [],
	saveRef = null,
	wasNoteSaved = false,
	removeExceptionHandler,
	markCompleteHandler,
	removePastDueHandler,
	syncTaskNotes,
	syncUpdatedTaskNotes,
	saveTaskChanges,
	cancelTaskChanges,
	saveNewTaskNote,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [taskDetails, setTaskDetails] = useState({});
	// object of userObjs and names as strings
	const [usersList, setUsersList] = useState({});
	// task notes records
	const [allTaskNotes, setAllTaskNotes] = useState([]);
	const [reassessRecords, setReassessRecords] = useState([]);
	const [currentResident, setCurrentResident] = useState({
		firstName: task?.FirstName,
		lastName: task?.LastName,
		residentID: task?.ResidentID,
		roomNum: task?.RoomNum,
		photoSrc: getPhotoSrc(task?.ResidentId, photosMap),
	});
	// task values to edited/changed
	const [taskVals, setTaskVals] = useState({
		markComplete: task?.IsCompleted ?? false,
		exceptionType: task?.Exception ?? "",
		taskNotes: task?.TaskNotes?.[0] ?? "",
	});

	// audit log (INTERNAL ONLY!!!)
	const [taskChangeLog, setTaskChangeLog] = useState([]);

	const handleOriginalNote = (e) => {
		const { value } = e.target;
		setTaskVals({
			...taskVals,
			taskNotes: value,
		});
	};

	const saveChangesHandler = (e) => {
		saveTaskChanges({
			...task, // client-task
			...taskDetails, // server-record
		});
	};

	const triggerSaveNote = (e) => {
		saveRef.current.click(e);
	};

	const fetchInitialResources = useCallback(async () => {
		const { token } = currentUser;
		const { FacilityId: facilityID } = currentFacility;
		// ✓ - fetch resident profile/record
		// ✓ - fetch task details record
		// ✓ - fetch task notes record(s)
		setIsLoading(true);
		// const advancedResources = await getAdvancedResources(token, task);
		const advancedResources = await getHistoricalTaskDetails(
			token,
			task,
			facilityID
		);

		if (!isEmptyObj(advancedResources)) {
			const {
				taskNotes = [],
				taskDetails = {},
				profile = {},
				usersList = {},
				// reassessRecords = [],
				userDetails = {},
			} = advancedResources;
			// sync to local state(s)
			setCurrentResident({ ...currentResident, ...profile });
			setTaskDetails({
				...taskDetails,
				...userDetails,
				ADLCategory: task?.ADL,
			});
			setAllTaskNotes(taskNotes);
			// setReassessRecords(reassessRecords);
			setUsersList({ ...usersList });
			// applyTaskInfoToVals(taskDetails, formState, setFormState);
			return setIsLoading(false);
		} else {
			setCurrentResident({});
			setTaskDetails({});
			setAllTaskNotes([]);
			// setReassessRecords([]);
			setUsersList({});
			return setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fetch resources for modal
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchInitialResources();

		return () => {
			isMounted = false;
		};
	}, [fetchInitialResources]);

	// sync resident info from task
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		setCurrentResident({
			firstName: task?.FirstName,
			lastName: task?.LastName,
			residentID: task?.ResidentId,
			roomNum: task?.RoomNum,
			photoSrc: getPhotoSrc(task?.ResidentId, photosMap),
		});

		return () => {
			isMounted = false;
		};
	}, [photosMap, task]);

	return (
		<div
			className={styles.HistoricalTaskDetails}
			// style={getStatusCss(task, vals, shiftTimes)}
			style={getStatusColor(task, vals)}
		>
			{/* RESIDENT DETAILS */}
			<TimeViewResidentHeader
				task={{ ...task, ...taskDetails }}
				residentImage={{
					src: currentResident?.photoSrc,
					alt: "Resident Photo",
				}}
				currentResident={currentResident}
				currentUser={currentUser}
			>
				{/*  */}
				{/*  */}
			</TimeViewResidentHeader>
			{/* TASK DETAILS */}
			<section className={styles.HistoricalTaskDetails_top}>
				{isLoading && (
					<div className={styles.HistoricalTaskDetails_top_loader}>
						<Spinner />
						<div className={styles.HistoricalTaskDetails_top_loader_text}>
							Loading task details...
						</div>
					</div>
				)}

				{/* TASK ADL, TASK NAME AND TASK SHIFT */}
				{!isLoading && (
					<div className={styles.HistoricalTaskDetails_top_title}>
						<div className={styles.HistoricalTaskDetails_top_title_adl}>
							{taskDetails?.ADLCategory}:
						</div>
						<div className={styles.HistoricalTaskDetails_top_title_name}>
							<div className={styles.HistoricalTaskDetails_top_title_name_icon}>
								<svg
									className={
										vals?.markComplete
											? styles.HistoricalTaskDetails_top_title_name_icon_statusBadge_isCompleted
											: styles.HistoricalTaskDetails_top_title_name_icon_statusBadge
									}
								>
									<use xlinkHref={`${sprite}#icon-check_circle`}></use>
								</svg>
							</div>
							<div className={styles.HistoricalTaskDetails_top_title_name_task}>
								{task?.TaskDescription}
							</div>
						</div>
						<div className={styles.HistoricalTaskDetails_top_title_shift}>
							{task?.Shift}
						</div>
						<div className={styles.HistoricalTaskDetails_top_title_originDate}>
							Original Due Date: {getOriginalScheduledDate(task, taskDetails)}
						</div>
					</div>
				)}
			</section>
			<div className={styles.HistoricalTaskDetails_main}>
				{/* EXCEPTIONS HANDLER */}
				{showExceptionUI(task) && (
					<>
						<FormSection
							title="Task Exceptions:"
							icon="calendarBusy"
							hoverText="Reason why care was not provided."
						>
							<>
								<div className={styles.HistoricalTaskDetails_main_section}>
									<CustomDropdown
										name="exceptionType"
										id="exceptionType"
										selection={vals?.exceptionType}
										setSelection={handleSettings}
										placeholder="Select Exception"
										autoComplete="off"
										inputMode="none"
										options={facilityExceptions}
									/>
								</div>
								<div
									className={styles.HistoricalTaskDetails_main_sectionActions}
								>
									<ButtonSM
										isDisabled={isEmptyVal(vals?.exceptionType)}
										handleClick={removeExceptionHandler}
										customStyles={customCSS.exceptionBtn}
									>
										Remove Exception
									</ButtonSM>
								</div>
							</>
						</FormSection>
						<Divider customStyles={{ opacity: ".2" }} />
					</>
				)}

				{/* COMPLETION HANDLER */}
				<FormSection title="Mark Complete" icon="checkbox" hideInfoIcon={true}>
					<div className={styles.HistoricalTaskDetails_main_section}>
						<ButtonSM
							isDisabled={task?.IsCompleted}
							handleClick={markCompleteHandler}
							customStyles={customCSS.completeBtn}
						>
							{vals?.markComplete || task?.IsCompleted
								? "Undo Completed"
								: "Mark Complete"}
						</ButtonSM>
					</div>
				</FormSection>
				<Divider customStyles={{ opacity: ".2" }} />

				{/* PAST-DUE HANDLER */}
				{/* 
				- SHOW: No exception & no completion 
				- Hide: Has either an exception or is/was completed
				*/}
				{isEmptyVal(vals?.exceptionType) && isEmptyVal(vals?.markComplete) && (
					<>
						<FormSection
							title="Past Due Status"
							icon="alarm"
							hideInfoIcon={false}
							hoverText="Edit or update PAST-DUE status of a task."
						>
							<div className={styles.HistoricalTaskDetails_main_msg}>
								Note: A task MUST be completed in order for the Past-Due status
								to be removed permanently (ie. on reports).
							</div>
							<div className={styles.HistoricalTaskDetails_main_section}>
								<ButtonSM
									isDisabled={
										!vals?.markComplete && isEmptyVal(vals?.exceptionType)
									}
									handleClick={removePastDueHandler}
									customStyles={customCSS.pastDueBtn}
								>
									{vals?.hasPastDue ? "Remove Past-Due" : "Mark as Past-Due"}
								</ButtonSM>
							</div>
						</FormSection>
						<Divider customStyles={{ opacity: ".2" }} />
					</>
				)}

				{/* NEW TASK NOTES SECTION */}
				{showNewTaskNotes(currentUser) && (
					<>
						<FormSection
							title="Task Notes:"
							icon="note"
							hoverText="Any notes will be recorded on the task record."
						>
							<TaskNotesController
								key={`HISTORY-NOTES-${allTaskNotes?.length}`}
								task={taskDetails}
								currentUser={currentUser}
								allTaskNotes={allTaskNotes}
								syncTaskNotes={syncTaskNotes}
								syncTaskNotesHandler={syncUpdatedTaskNotes}
								saveRef={saveRef}
								// new field
								wasNoteSaved={wasNoteSaved}
							/>
						</FormSection>
						<Divider customStyles={{ opacity: ".2" }} />
					</>
				)}

				{/* USER ASSIGNMENT HANDLER ~ 'assignTo' */}
				<FormSection
					title="User Assignment"
					icon="warning"
					hideInfoIcon={false}
					hoverText="Assign changes to another user."
				>
					<HistoricalUserAssignment
						key={`HISTORY-ASSIGN-TO-${usersList?.names?.length}`}
						assignTo={assignTo}
						currentUser={currentUser}
						availableUsers={usersList?.names ?? []}
						handleUserAssign={handleUserAssignment}
					/>
				</FormSection>
			</div>

			<div className={styles.HistoricalTaskDetails_actions}>
				{/* ACTIONS */}
				<ButtonSM
					handleClick={cancelTaskChanges}
					customStyles={customCSS.cancel}
				>
					Cancel
				</ButtonSM>
				<ButtonSM
					// handleClick={saveChangesHandler}
					handleClick={(e) => {
						saveChangesHandler(e);
						triggerSaveNote(e);
					}}
					customStyles={customCSS.save}
				>
					Save All
				</ButtonSM>
			</div>
		</div>
	);
};

export default HistoricalTaskDetails;

HistoricalTaskDetails.defaultProps = {};

HistoricalTaskDetails.propTypes = {};
