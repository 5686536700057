import React from "react";
import styles from "../../css/timeview/TimeViewResidentPhoto.module.scss";
import { PropTypes } from "prop-types";
import ResidentPhoto from "../app/ResidentPhoto";
import TimeViewResidentBadge from "./TimeViewResidentBadge";

const TimeViewResidentPhoto = ({
	task = {},
	residentImage = {},
	residentInfo = {},
	imgSize = "SM-MD",
}) => {
	const { src, alt } = residentImage;

	return (
		<div className={styles.TimeViewResidentPhoto}>
			<ResidentPhoto imgSrc={src} imgAlt={alt} imgSize={imgSize} />
			<TimeViewResidentBadge task={task} residentInfo={residentInfo} />
		</div>
	);
};

export default TimeViewResidentPhoto;

TimeViewResidentPhoto.defaultProps = {};

TimeViewResidentPhoto.propTypes = {};
