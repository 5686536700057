import React, { useEffect, useRef } from "react";
import styles from "../../css/timeview/TimeViewFilterDropdown2.module.scss";
import sprite from "../../assets/icons/filters.svg";
import sprite2 from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import TimeViewFilter from "./TimeViewFilter";
import TimeViewControllerTabs, {
	TimeViewTabButton,
	TimeViewTabButtons,
	TimeViewTabPanel,
	TimeViewTabPanels,
} from "./TimeViewControllerTabs";
import TimeViewControllerResidents from "./TimeViewControllerResidents";
import TimeViewControllerShifts from "./TimeViewControllerShifts";
import TimeViewControllerFacilities from "./TimeViewControllerFacilities";
import { processControllerFacilities } from "../../helpers/utils_timeview";

const selected = "hsla(170, 100%, 39%, 1)";
const notSelected = "hsla(242, 89%, 64%, 1)";

const getIsSelected = (option, selections = [], disabledOptions = []) => {
	const selectedIDs = selections.map(({ id }) => id);
	const disabledIDs = disabledOptions.map(({ id }) => id);

	const isSelected = selectedIDs.includes(option.id);
	const isDisabled = disabledIDs.includes(option.id);
	return !isDisabled && isSelected;
};

const TimeViewFilterDropdown2 = ({
	searchVal,
	searchRef,
	facilitySearch,
	handleSelect,
	handleSelectAll,
	handleSelectAllShifts,
	handleFacility,
	handleShifts,
	handleSearch,
	clearSearch,
	handleFacilitySearch,
	clearSearchFacilities,
	saveSelections,
	clearSelections,
	clearShiftSelections,
	saveShiftSelections,
	closeDropdown,
	filterState = {},
	filterOptions = [],
	selections = [],
	disabledOptions = [],
	isAllSelected = false,
	currentUser = {},
	photosMap = {},
}) => {
	const menuRef = useRef();
	const { isOutside } = useOutsideClick(menuRef);

	const { facilities } = filterState;

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside) {
			return closeDropdown();
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside, closeDropdown]);
	return (
		<aside className={styles.TimeViewFilterDropdown} ref={menuRef}>
			<TimeViewControllerTabs>
				<TimeViewTabButtons>
					<TimeViewTabButton>Facilities</TimeViewTabButton>
					<TimeViewTabButton>Residents</TimeViewTabButton>
					<TimeViewTabButton>Shifts</TimeViewTabButton>
				</TimeViewTabButtons>

				<TimeViewTabPanels>
					<TimeViewTabPanel>
						{/* FACILITY(S) TAB */}
						<TimeViewControllerFacilities
							key={`TV-FACILITIES-TAB`}
							searchVal={facilitySearch}
							filterState={filterState}
							selectedFacility={filterState?.selectedFacility}
							handleSearch={handleFacilitySearch}
							clearSearch={clearSearchFacilities}
							handleFacility={handleFacility}
							facilities={filterState?.facilities}
						/>
					</TimeViewTabPanel>
					<TimeViewTabPanel>
						{/* RESIDENT(S) TAB */}
						<TimeViewControllerResidents
							key={`TV-RESIDENTS-TAB`}
							searchRef={searchRef}
							filterState={filterState}
							filterOptions={filterOptions}
							selections={selections}
							handleSelect={handleSelect}
							handleSelectAll={handleSelectAll}
							saveSelections={saveSelections}
							clearSelections={clearSelections}
							searchVal={searchVal}
							handleSearch={handleSearch}
							clearSearch={clearSearch}
							closeDropdown={closeDropdown}
							photosMap={photosMap}
						/>
					</TimeViewTabPanel>
					<TimeViewTabPanel>
						{/* SHIFT(S) TAB */}
						<TimeViewControllerShifts
							key={`TV-SHIFTS-TAB`}
							filterState={filterState}
							handleShifts={handleShifts}
							handleSelectAll={handleSelectAllShifts}
							clearSelections={clearShiftSelections}
							saveSelections={saveShiftSelections}
							isAllSelected={filterState?.selectedShifts?.length === 3}
						/>
					</TimeViewTabPanel>
				</TimeViewTabPanels>
			</TimeViewControllerTabs>
		</aside>
	);
};

export default TimeViewFilterDropdown2;

TimeViewFilterDropdown2.defaultProps = {};

TimeViewFilterDropdown2.propTypes = {};
