import React from "react";
import styles from "../../css/history/HistoricalTaskList.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { getTaskID } from "../../helpers/utils_tasks";
// components
import HistoricalTaskEntry from "./HistoricalTaskEntry";

// might need to separate tasks by type, create list of ids and check each separately
const checkIfSelected = (task, selectedTasks = []) => {
	const selectedIDs = [...selectedTasks.map((task) => getTaskID(task))];
	const existsInList = selectedIDs.includes(getTaskID(task));

	return existsInList;
};

const getMatchType = (sortType, selectedTasks = [], allTasks = []) => {
	switch (true) {
		case isEmptyVal(sortType): {
			return "matches";
		}
		case sortType === "ALL": {
			if (isEmptyArray(allTasks)) {
				return "matches";
			} else {
				return "";
			}
		}
		case sortType === "MISSED": {
			return "missed tasks";
		}
		case sortType === "exceptions": {
			return "exceptions tasks";
		}
		default:
			return "";
	}
};

const NoMatchesFound = ({ sortType, allTasks = [], selectedTasks = [] }) => {
	if (isEmptyArray(allTasks)) {
		return null;
	}
	return (
		<div className={styles.NoMatchesFound}>
			<svg className={styles.NoMatchesFound_icon}>
				<use xlinkHref={`${sprite}#icon-exclamation-outline`}></use>
			</svg>
			<div className={styles.NoMatchesFound_msg}>
				No {getMatchType(sortType, selectedTasks, allTasks)} found.
			</div>
		</div>
	);
};

const HistoricalTaskList = ({
	sortType,
	tasks = [],
	selectedTasks = [],
	exceptionTypes = [],
	photosMap = {},
	handleSelectTask,
	openTaskDetails,
	currentUser = {},
	currentFacility = {},
	syncTaskDetailsChanges,
	dispatchAlert,
}) => {
	return (
		<div className={styles.HistoricalTaskList}>
			<ul className={styles.HistoricalTaskList_inner}>
				{/* {isEmptyArray(tasks) && (
					<NoMatchesFound
						sortType={sortType}
						allTasks={tasks}
						selectedTasks={selectedTasks}
					/>
				)} */}
				{!isEmptyArray(tasks) &&
					tasks.map((task, idx) => (
						<HistoricalTaskEntry
							key={`HISTORY-TASK-${getTaskID(task)}--${idx}`}
							task={task}
							isSelected={checkIfSelected(task, selectedTasks)}
							handleSelectTask={(e) => handleSelectTask(e, task)}
							openTaskDetails={() => openTaskDetails(task)}
							exceptionTypes={exceptionTypes}
							photosMap={photosMap}
							currentUser={currentUser}
							currentFacility={currentFacility}
							dispatchAlert={dispatchAlert}
							syncTaskDetailsChanges={syncTaskDetailsChanges}
						/>
					))}
			</ul>
		</div>
	);
};

export default HistoricalTaskList;

HistoricalTaskList.defaultProps = {};

HistoricalTaskList.propTypes = {};
