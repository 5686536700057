import { getTaskNotesByTaskID } from "./utils_notes";
import { sortAlphaAscByKey, sortDateAscByKey } from "./utils_processing";
import { getResidentRecord } from "./utils_residentData";
import {
	checkForPastDueTimeView,
	getScheduledRecord,
	getTaskType,
	isException,
	isPastDueByTimeOrShift,
	isPRNTask,
	isScheduledTask,
	isUITask,
	saveScheduledUpdates,
} from "./utils_tasks";
import {
	createTaskPerShift,
	getUnscheduledRecord,
	saveUnscheduledUpdates,
} from "./utils_unscheduled";
import { currentEnv } from "./utils_env";
import { residentData } from "./utils_endpoints";
// mock photos
import rp_123258 from "../assets/images/PREVIEW/RP-123258.png";
import rp_137580 from "../assets/images/PREVIEW/RP-137580.png";
import {
	generateResidentPhotosMapByID,
	getResidentPhotosByList,
} from "./utils_residents";
import { hasProp, isEmptyArray, isEmptyVal } from "./utils_types";
import { getExceptionID } from "./utils_exceptions";
import { format, getTime } from "date-fns";
import { getResolutionID } from "./utils_resolution";
import { dueDateHandler } from "./utils_updates";
import {
	getReassessByTrackingTask,
	saveReassessMany,
	updateReassessModel,
} from "./utils_reassess";
import {
	compareDateToShiftTime,
	getCurrentShift,
	getCurrentShiftID,
	getShiftID,
	getShiftName,
	isBetween,
	matchClientShiftTimeByID,
	matchShiftTimeByID,
	matchShiftTimeFromRecords,
	processShiftTimes,
} from "./utils_shifts";
import { getCategoryNameFromID } from "./utils_categories";
import { getAdvUserByUserID } from "./utils_user";
import { applyDueTimeToDate } from "./utils_dates";

// TIME VIEW REQUEST UTILS //

/**
 * Fetches a facility's residents tasks for given day & shift(s)
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid
 * @param {Date} dayOfWeekDate - Date for target day
 * @param {Array} shifts - An array of 'AssessmentShiftId's
 * @returns {Object} - Returns an object of scheduled & unscheduled task arrays.
 */
const getMultiResidentsTasksByShifts = async (
	token,
	facilityID,
	dayOfWeekDate = new Date(),
	shifts = []
	// settings = {}
) => {
	let url = currentEnv.base + residentData.timeview.residentsTasksByShifts;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ dayOfWeekDate: dayOfWeekDate });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(shifts),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Fetches a list of residents tasks by date and shifts.
 * @param {String} token - Auth token
 * @param {Date} dayOfWeekDate - Target date
 * @param {Object} settings - An object of residentIDs and shiftIDs for the post body
 * @returns {Object} - Returns an object of scheduled & unscheduled tasks
 */
const getListOfResidentsTasksByShifts = async (
	token,
	dayOfWeekDate = new Date(),
	settings = {}
) => {
	let url =
		currentEnv.base + residentData.timeview.listOfResidentsTasksByShifts;
	url += "?" + new URLSearchParams({ dayOfWeekDate: dayOfWeekDate });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(settings),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 *
 * @param {String} token - Auth token
 * @param {Number} residentID - REsident id number
 * @param {Date} dayOfWeekDate - Date indicating which day to fetch tasks for
 * @param {Array} shifts - An array of shift ids as numbers
 * @returns {Array} - Returns an array of tasks for all residents
 */
const getDailyResidentTasksByShifts = async (
	token,
	residentID,
	dayOfWeekDate = new Date(),
	shifts = [1, 2, 3]
) => {
	let url = currentEnv.base + residentData.forTracker.tasksByShifts;
	url += "?" + new URLSearchParams({ residentId: residentID });
	url += "&" + new URLSearchParams({ dayOfWeekDate: dayOfWeekDate });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(shifts),
		});
		const response = await request.json();

		return response.Data;
	} catch (err) {
		return err.message;
	}
};

// fetches advanced options initial resources
const getAdvancedResources = async (token, task) => {
	const { ResidentID } = task;
	const taskType = getTaskType(task);

	console.log(`Get Advanced (task):`, task);

	switch (taskType) {
		case "Scheduled": {
			const { AssessmentTrackingTaskId: taskID } = task;

			const [profile, taskDetails, taskNotes, reassessRecords] =
				await Promise.all([
					getResidentRecord(token, ResidentID),
					getScheduledRecord(token, taskID),
					getTaskNotesByTaskID(token, task),
					getReassessByTrackingTask(token, taskID),
				]);
			return {
				profile: profile?.[0],
				taskDetails: taskDetails?.[0],
				taskNotes: taskNotes,
				reassessRecords: reassessRecords,
			};
		}
		case "Unscheduled": {
			const { AssessmentUnscheduleTaskId: taskID, CreatedBy: createdBy } = task;

			// const userID = isEmptyVal(task?.createdBy) ?

			const [profile, taskDetails, taskNotes, userDetails] = await Promise.all([
				getResidentRecord(token, ResidentID),
				getUnscheduledRecord(token, taskID),
				getTaskNotesByTaskID(token, task),
				getAdvUserByUserID(token, createdBy),
				// fetch task creator info for <OriginalTaskNote/>
			]);

			console.group(`Get Advanced Resources`);
			console.log(`UnscheduledRecord:`, taskDetails);
			console.log(`UserDetails:`, userDetails);
			console.groupEnd();

			return {
				profile: profile?.[0],
				taskDetails: taskDetails?.[0],
				taskNotes: taskNotes,
				userDetails: userDetails,
			};
		}
		default:
			return new Error(`INVALID TASK TYPE: ${taskType}`);
		// return {
		// 	profile: {},
		// 	taskDetails: {},
		// 	taskNotes: []
		// }
	}
};

// fetches tasks & photos for residents of a given facility
const fetchTimeViewResources = async (token, params = {}) => {
	const { dayOfWeekDate, shifts, residentIDs } = params;
	const settings = {
		ResidentIds: residentIDs,
		ShiftIds: shifts,
	};

	const [dailyTasks, residentPhotos] = await Promise.all([
		getListOfResidentsTasksByShifts(token, dayOfWeekDate, settings),
		getResidentPhotosByList(token, residentIDs),
	]);

	const photosMap = generateResidentPhotosMapByID(residentPhotos);

	return {
		tasks: dailyTasks,
		photos: residentPhotos,
		photosMap: photosMap,
	};
};

const fetchTimeViewTasks = async (token, params = {}) => {
	const { dayOfWeekDate, shifts, residentIDs } = params;
	const settings = {
		ResidentIds: residentIDs,
		ShiftIds: shifts,
	};

	const dailyTasks = await getListOfResidentsTasksByShifts(
		token,
		dayOfWeekDate,
		settings
	);

	if (!isEmptyArray(dailyTasks?.ScheduledTasks)) {
		const mergedTasks = [
			...dailyTasks?.ScheduledTasks,
			...dailyTasks?.UnscheduledTasks,
		];

		return mergedTasks;
	} else {
		return [];
	}
};

// save task updates handler
const saveTimeViewSectionChanges = async (
	token,
	formState = {},
	updatedTask = {},
	currentUser = {}
) => {
	const { userID } = currentUser;
	const { values, touched } = formState;
	const taskType = getTaskType(updatedTask)?.toUpperCase();

	switch (taskType) {
		case "SCHEDULED": {
			if (values?.reassess || touched?.reassess) {
				const reassessModel = updateReassessModel(
					values,
					userID,
					updatedTask,
					currentUser
				);
				const [reassessWasUpdated, taskWasUpdated] = await Promise.all([
					saveReassessMany(token, reassessModel),
					saveScheduledUpdates(token, updatedTask),
				]);

				return {
					wasReassessed: reassessWasUpdated,
					wasTaskUpdated: taskWasUpdated,
				};
			} else {
				const taskWasUpdated = await saveScheduledUpdates(token, updatedTask);
				return { wasReassessed: null, wasTaskUpdated: taskWasUpdated };
			}
		}
		case "UNSCHEDULED": {
			const taskWasUpdated = await saveUnscheduledUpdates(token, updatedTask);
			return { wasReassessed: null, wasTaskUpdated: taskWasUpdated };
		}

		default:
			break;
	}
};

const saveQuickCompleteUpdate = async (token, updatedTask) => {
	const taskType = getTaskType(updatedTask)?.toUpperCase();

	switch (taskType) {
		case "SCHEDULED": {
			const wasSaved = await saveScheduledUpdates(token, updatedTask);

			return wasSaved;
		}
		case "UNSCHEDULED": {
			const wasSaved = await saveUnscheduledUpdates(token, updatedTask);

			return wasSaved;
		}

		default:
			return new Error("INVALID TASK TYPE");
	}
};

// DATA PROCESSING UTILS
const processControllerResidents = (residents = []) => {
	const newList = residents.map((resident, idx) => {
		const {
			ResidentID: id,
			FirstName: first,
			LastName: last,
			FloorUnit: unitType,
			RoomNum: roomNum,
		} = resident;

		const name = `${last}, ${first}`;
		// const name = `${first} ${last}`;

		const newResident = {
			idx,
			id,
			name,
			unitType,
			roomNum,
		};

		return newResident;
	});

	const sorted = sortAlphaAscByKey("name", newList);
	return sorted;
};

const processControllerShifts = (shifts) => {
	const map = {
		1: "AM",
		2: "PM",
		3: "NOC",
	};

	const newShifts = shifts.map((shift, idx) => {
		const {
			AssessmentShiftId: id,
			IsRollOver: isRollOver,
			StartTime: startTime,
			EndTime: endTime,
		} = shift;

		const name = map[id];

		const newShift = {
			idx,
			id,
			name,
			isRollOver,
			startTime,
			endTime,
		};

		return newShift;
	});

	return newShifts;
};

const processControllerFacilities = (facilities = []) => {
	const newList = facilities.map((facility, idx) => {
		const {
			Shifts: shifts,
			FacilityId: id,
			CommunityName: name,
			ParentFacilityId: parentID,
		} = facility;

		const record = {
			idx,
			id,
			name,
			parentID,
			shifts,
		};

		return record;
	});

	const sorted = sortAlphaAscByKey("name", newList);

	return sorted;
};

//////////////////////////////////////////////////////////////////////////
///////////////////// "TIME-VIEW" TASK UPDATER UTILS /////////////////////
//////////////////////////////////////////////////////////////////////////

// applies an exception to a task
const applyExceptionToTask = (
	formState = {},
	task = {},
	facilityExceptions = [],
	currentUser = {}
) => {
	const { values: vals, touched } = formState;
	// if exception is pre-existing & has no new changes, then return early w/o updating exceptions for task
	if (!hasProp(touched, "exceptionType")) return { ...task };
	const taskType = getTaskType(task)?.toUpperCase();

	switch (taskType) {
		case "SCHEDULED": {
			const newTask = {
				...task,
				ExceptionDate: new Date().toISOString(),
				AssessmentExceptionId: getExceptionID(
					vals?.exceptionType,
					facilityExceptions
				),
				ExceptionByUserId: currentUser?.userID,
			};

			return { ...newTask };
		}
		case "UNSCHEDULED": {
			const newTask = {
				...task,
				ExceptionDate: new Date().toISOString(),
				AssessmentExceptionId: getExceptionID(
					vals?.exceptionType,
					facilityExceptions
				),
				ExceptionByUserId: currentUser?.userID,
			};

			return { ...newTask };
		}
		default:
			return task;
	}
};
// applies a change to due date to a task
const applyDueDateToTask = (formState = {}, task = {}, currentUser = {}) => {
	const updatedTask = dueDateHandler(formState, task);

	return updatedTask;
};
// applies a reassessment/service-plan suggestion to a task
const applyReassessToTask = (formState = {}, task = {}, currentUser = {}) => {
	const { values } = formState;
	const { reassess, reassessNotes } = values;
	if (!reassess || !isScheduledTask(task)) return task;

	// REMOVED - 8/8/2022 REASSESS NOTES are not added to 'Notes' field in task anymore
	const username = currentUser?.username;
	const notesWithUserCreds = `${reassessNotes}\n\n - ${username} \n\n${format(
		new Date(),
		"M/D/YYYY"
	)} at ${format(new Date(), "hh:mm:ss A")}`;
	// newly added to maintain reassess notes
	const notesField = !hasProp(task, "TaskNotes") ? "Notes" : "TaskNotes";
	const newNotes = `Suggest: ${notesWithUserCreds}`;

	return {
		...task,
		AssessmentResolutionId: getResolutionID("COMPLETED-REASSESSMENT-NEEDED"),
		AssessmentReasonId: 6,
		UpdatedByUserId: currentUser?.userID,
	};
};
// applies a completion toggle to a task
const applyCompletionToTask = (
	formState = {},
	task = {},
	shifts = [],
	currentUser = {}
) => {
	const { values, touched } = formState;
	const {
		completedDate,
		completedBy,
		CompletedAssessmentShiftId: completedShiftID,
	} = task;
	const shiftID = getCurrentShiftID(shifts);

	// ##TODOS:
	// - Add support for 'CompletedAssessmentShiftId'
	// 		- Should determine what shift the task was completed during
	const complete = 2;
	const notComplete = 4;

	return {
		...task,
		IsCompleted: values?.markComplete ?? false,
		CompletedAssessmentShiftId: values?.markComplete
			? shiftID
			: completedShiftID,
		AssessmentTaskStatusId: values.markComplete ? complete : notComplete,
		CompletedDate: values.markComplete
			? new Date().toISOString()
			: completedDate,
		CompletedByUserId: values?.markComplete ? currentUser?.userID : completedBy,
	};
};
// applies a quick-complete to task
const applyQuickCompleteToTask = (
	formState = {},
	task = {},
	shifts = [],
	currentUser = {}
) => {
	const { values } = formState;
	const { userID } = currentUser;
	const currentShiftID = getCurrentShiftID(shifts);
	const taskType = getTaskType(task)?.toUpperCase();

	const doneID = 2;
	const notDoneID = 4;

	switch (taskType) {
		case "SCHEDULED": {
			const {
				AssessmentTrackingId: trackingID,
				AssessmentTrackingTaskId: taskID,
				ScheduledDate: dueDate,
			} = task;

			// new custom task structure
			const updatedTask = {
				EntryDate: dueDate,
				AssessmentTrackingTaskId: taskID,
				AssessmentTrackingId: trackingID,
				AssessmentTaskStatusId: values?.markComplete ? doneID : notDoneID,
				// completion fields
				IsCompleted: values?.markComplete,
				CompletedDate: new Date().toISOString(),
				CompletedByUserId: userID,
				CompletedAssessmentShiftId: values?.markComplete
					? currentShiftID
					: null,
				// check past due fields???
			};
			return { ...updatedTask };
		}
		case "UNSCHEDULED": {
			const { AssessmentUnscheduleTaskId: taskID, ScheduledDate: dueDate } =
				task;

			const updatedTask = {
				EntryDate: dueDate,
				AssessmentUnscheduleTaskId: taskID,
				AssessmentTaskStatusId: values?.markComplete ? doneID : notDoneID,
				// completion fields
				IsCompleted: values?.markComplete,
				CompletedDate: new Date().toISOString(),
				CompletedByUserId: userID,
				CompletedAssessmentShiftId: values?.markComplete
					? currentShiftID
					: null,
				// check past due fields???
			};

			return { ...updatedTask };
		}
		default:
			return new Error("INVALID TASK TYPE");
	}
};

// applies all task changes for time view
// ##TODOS:
// - Consider starting with a stripped down 'baseTask':
// 		- ONLY adding fields if changes to those fields were made
const applyAllUpdatesToTask = (
	formState = {},
	taskInfo = {},
	facilityExceptions = [],
	shifts = [],
	currentUser = {}
) => {
	const taskType = getTaskType(taskInfo)?.toUpperCase();

	console.log("taskInfo", taskInfo);

	switch (taskType) {
		case "SCHEDULED": {
			const baseTask = {
				EntryDate: taskInfo?.EntryDate ?? new Date(),
				AssessmentTaskId: taskInfo?.AssessmentTaskId,
				AssessmentTrackingId: taskInfo?.AssessmentTrackingId,
				AssessmentTrackingTaskId: taskInfo?.AssessmentTrackingTaskId,
				AssessmentExceptionId: taskInfo?.AssessmentExceptionId ?? null,
				AssessmentTaskStatusId: taskInfo?.AssessmentTaskStatusId ?? 4,
				CompletedAssessmentShiftId:
					taskInfo?.CompletedAssessmentShiftId ?? null,
				// UserId: currentUser?.userID,
				// AssessmentResolutionId: taskInfo?.AssessmentResolutionId,
				IsCompleted: taskInfo?.IsCompleted ?? false,
				IsPastDue: taskInfo?.IsPastDue ?? false, // add implementation for this
				CompletedDate: taskInfo?.CompletedDate ?? null,
				ExceptionDate: taskInfo?.ExceptionDate ?? null,
				FollowUpDate: taskInfo?.FollowUpDate ?? null,
				PastDueDate: taskInfo?.PastDueDate ?? null,
				// user tracking
				CompletedByUserId: taskInfo?.CompletedByUserId ?? null,
				ExceptionByUserId: taskInfo?.ExceptionByUserId ?? null,
				UpdatedByUserId: taskInfo?.UpdatedByUserId ?? null,
			};
			// apply updates/changes
			const withException = applyExceptionToTask(
				formState,
				baseTask,
				facilityExceptions,
				currentUser
			);
			const withDueDate = applyDueDateToTask(
				formState,
				withException,
				currentUser
			);
			const withReassess = applyReassessToTask(
				formState,
				withDueDate,
				currentUser
			);
			const withStatus = applyCompletionToTask(
				formState,
				withReassess,
				shifts,
				currentUser
			);

			return {
				AssessmentTaskId: taskInfo?.AssessmentTaskId,
				EntryDate: new Date().toISOString(),
				...withStatus,
			};
		}
		case "UNSCHEDULED": {
			const baseTask = {
				EntryDate: taskInfo?.EntryDate ?? new Date(),
				UserId: currentUser?.userID,
				AssessmentTaskId: taskInfo?.AssessmentTaskId,
				AssessmentUnscheduleTaskId: taskInfo?.AssessmentUnscheduleTaskId,
				AssessmentExceptionId: taskInfo?.AssessmentExceptionId,
				AssessmentTaskStatusId: taskInfo?.AssessmentTaskStatusId,
				CompletedAssessmentShiftId: taskInfo?.CompletedAssessmentShiftId,
				AssessmentResolutionId: taskInfo?.AssessmentResolutionId,
				IsCompleted: taskInfo?.IsCompleted,
				IsPastDue: taskInfo?.IsPastDue, // add implementation for this
				CompletedDate: taskInfo?.CompletedDate,
				ExceptionDate: taskInfo?.ExceptionDate,
				ScheduledDate: taskInfo?.ScheduledDate,
				PastDueDate: taskInfo?.PastDueDate,
				// user tracking
				CompletedByUserId: taskInfo?.CompletedByUserId,
				ExceptionByUserId: taskInfo?.ExceptionByUserId,
				UpdatedByUserId: taskInfo?.UpdatedByUserId,
				// recurring settings
				RecurringChangedByUserId: taskInfo?.RecurringChangedByUserId,
				RecurringChangedDate: taskInfo?.RecurringChangedDate,
			};

			// apply updates/changes
			const withException = applyExceptionToTask(
				formState,
				baseTask,
				facilityExceptions,
				currentUser
			);
			const withDueDate = applyDueDateToTask(
				formState,
				withException,
				currentUser
			);
			const withReassess = applyReassessToTask(
				formState,
				withDueDate,
				currentUser
			);
			const withStatus = applyCompletionToTask(
				formState,
				withReassess,
				shifts,
				currentUser
			);

			return { ...withStatus };
		}
		default:
			return new Error("INVALID TASK TYPE");
	}
};
// apply changes to custom time-view task record //
const applyAllUpdatesToTimeViewTask = (formState, task) => {
	const { values } = formState;
	const pendingTask = { ...task };
	const updatedTask = {
		...pendingTask,
		Exception: values?.exceptionType ?? "",
		ScheduledDate: values?.scheduledDate ?? task?.ScheduledDate ?? "",
		IsCompleted: values?.markComplete ?? task?.IsCompleted,
	};

	return updatedTask;
};

// OTHER "TIME-VIEW" RELATED UTILS //

// MOCK TASK DATA

// creates 'time-view' task record for client
const generateNewTimeViewTask = (vals, task = {}) => {
	const { newTaskName, newTaskCategory, newTaskShift, newTaskNotes } = vals;
	const {
		FirstName,
		LastName,
		ResidentId,
		RoomNum,
		FloorUnit,
		ResidentType,
		TaskName,
		Description,
		EntryDate,
		AssessmentUnscheduleTaskId,
		AssessmentShiftId,
		ScheduledDate,
		CreatedBy,
	} = task;
	// PREVIOUS VERSION
	const shift = getShiftName(AssessmentShiftId);
	// const newShift = shift !== newTaskShift ? newTaskShift : shift;

	console.group("Generate TV task");
	console.log("vals?.newTaskShift:", vals?.newTaskShift);
	console.log("Task ShiftId:", task?.AssessmentShiftId);
	console.log("shift", shift);
	// console.log("newShift", newShift);
	console.groupEnd();

	const newTask = {
		ADL: newTaskCategory,
		AssessmentUnscheduleTaskId: AssessmentUnscheduleTaskId,
		FirstName: FirstName,
		LastName: LastName,
		ResidentId: ResidentId,
		RoomNum: RoomNum,
		FloorUnit: FloorUnit,
		ResidentType: ResidentType,
		TaskName: TaskName,
		TaskDescription: newTaskName,
		ScheduledDate: ScheduledDate,
		Shift: shift, // PREVIOUS VERSION
		// Shift: newShift,
		IsCompleted: false,
		Exception: null,
		TaskNotes: isEmptyVal(newTaskNotes) ? [] : [newTaskNotes],
		CreatedBy: CreatedBy,
		IsNewTask: true,
	};

	console.log("GenerateTask (newTask)", newTask);

	return newTask;
};

// needs to create ALL tasks per shift for timeview
/**
 * Generates & applies task values for timeview client-side task records.
 * @param {Object} vals - New task values
 * @param {Array} pendingQueue - An array of server-side generated tasks
 * @param {Object} taskInfo - User & resident-related data to be applied to task(s)
 * @returns {Array} - Returns an array of client-side timeview tasks
 */
const generateNewTimeViewTasks = (
	vals = {},
	pendingQueue = [],
	taskInfo = {}
) => {
	const newTimeViewTasks = pendingQueue.map((newTask, idx) => {
		const generatedTask = generateNewTimeViewTask(vals, {
			...newTask,
			...taskInfo,
			ScheduledDate: newTask?.ScheduledDate,
			// ...(idx === 0 && {IsNewTask: true})
		});
		return generatedTask;
	});

	return newTimeViewTasks;
};

/**
 * Creates new tasks w/ correct scheduled date
 * - This is a clone of 'createTaskPerShift()'
 * - It double checks the scheduled dates tho.
 * @param {Numbe} residentID - ResidentID
 * @param {String} userID - User guid string
 * @param {Array} shiftTimes - Array of shift objects
 * @param {Object} vals - Object of task vals
 * @returns {Array} - Returns array of updated tasks
 */
const createTimeViewTasksPerShift = (
	residentID,
	userID,
	shiftTimes = [],
	vals = {}
) => {
	const shiftTasks = createTaskPerShift(residentID, userID, vals);
	// 1. Iterate thru tasks
	// 2. Check shift times to scheduled date
	// 	2a. If scheduled date is null use shift, otherwise use scheduled date
	const withEndTimes = shiftTasks.map((task) => {
		// find matching shiftTime record from task Shift id
		const { ScheduledDate, AssessmentShiftId: shiftID } = task;
		const matchingShift = matchClientShiftTimeByID(shiftID, shiftTimes);

		// if scheduled date is null, then apply shift end time
		if (!ScheduledDate || isEmptyVal(ScheduledDate)) {
			const { shiftEndTime } = compareDateToShiftTime(null, matchingShift);
			const due = applyDueTimeToDate(shiftEndTime, new Date());

			const updatedTask = {
				...task,
				ScheduledDate: due.toISOString(),
			};
			return updatedTask;
		} else {
			return task;
		}
	});

	return withEndTimes;
};

// applies taskIDs from API response to task records
// also adds
const addTaskIDsToTasks = (taskIDs = [], tasks = []) => {
	const withIDs = tasks.map((task, idx) => {
		const id = taskIDs[idx];
		const newTask = {
			...task,
			AssessmentUnscheduleTaskId: id,
			// ↓ THIS IS ADDED TO USE THE 1ST TASK AS THE TASK TO SCROLL TO UPON CREATION ↓
			...(idx === 0 && { IsNewTask: true }),
		};

		console.group("addTaskIDsToTasks");
		console.log("new tasks(unchanged):", tasks);
		console.log("task(unchanged):", task);
		console.log("newTask:", newTask);
		console.groupEnd();

		return newTask;
	});

	console.log("withIDs", withIDs);
	return withIDs;
};

// DAILY SHIFT TASKS SORTING UTILS //

/**
 * Checks if two records are for the same resident.
 * @param {Object} a - A client-formatted daily shift task record.
 * @param {Object} b - A client-formatted daily shift task record.
 * @returns {Boolean} - Returns true|false
 */
const isSameResident = (a, b) => {
	// if no resident ID(s)
	if (!hasProp(a, "ResidentId") || !hasProp(b, "ResidentId")) {
		const firstA = a?.FirstName ?? a?.firstName;
		const lastA = a?.LastName ?? a?.lastName;
		// 'b' name(s)
		const firstB = b?.FirstName ?? b?.firstName;
		const lastB = b?.LastName ?? b?.lastName;

		const isSameFirst = firstA === firstB;
		const isSameLast = lastA === lastB;

		return isSameFirst && isSameLast;
	} else {
		// compare resident ID(s)
		const idA = a?.ResidentId ?? a?.ResidentID ?? a?.residentID;
		const idB = b?.ResidentId ?? b?.ResidentID ?? b?.residentID;

		return idA === idB;
	}
	// 'a' name(s)
};
/**
 * Checks if two records are for the same ADL.
 * @param {Object} a - A client-formatted daily shift task record.
 * @param {Object} b - A client-formatted daily shift task record.
 * @returns {Boolean} - Returns true|false
 */
const isSameADL = (a, b) => {
	const adlA = a?.ADL;
	const adlB = b?.ADL;

	return adlA === adlB;
};
/**
 * Checks if two records are for the same Shift.
 * @param {Object} a - A client-formatted daily shift task record.
 * @param {Object} b - A client-formatted daily shift task record.
 * @returns {Boolean} - Returns true|false
 */
const isSameShift = (a, b) => {
	const shiftA = a?.Shift;
	const shiftB = b?.Shift;

	return shiftA === shiftB;
};
/**
 * Checks if two records are for the same Shift Time & same Shift.
 * @param {Object} a - A client-formatted daily shift task record.
 * @param {Object} b - A client-formatted daily shift task record.
 * @returns {Boolean} - Returns true|false
 */
const isSameShiftTime = (a, b) => {
	// 'a' field(s)
	const shiftA = a?.Shift;
	const timeA = a?.ScheduledDate;
	// 'b' field(s)
	const shiftB = b?.Shift;
	const timeB = b?.ScheduledDate;

	const sameShift = isSameShift(a, b);
	const sameTime = timeA === timeB;

	return sameShift && sameTime;
};

const sortTVTasksByDueTime = (key, dailyTasks = []) => {
	if (isEmptyArray(dailyTasks)) return [];

	return [...dailyTasks].sort((a, b) => {
		const timeA = getTime(a?.[key]);
		const timeB = getTime(b?.[key]);
		return timeA - timeB;
	});
};

const sortTVByShiftAsc = (dailyTasks = []) => {
	const shiftMap = {
		AM: 1,
		PM: 2,
		NOC: 3,
	};

	const sorted = [...dailyTasks].sort((a, b) => {
		const aID = shiftMap[a?.Shift];
		const bID = shiftMap[b?.Shift];
		return aID - bID;
		// return a[key] - b[key];
	});

	return sorted;
};

/**
 * Sorts/orders timeview tasks by a specific criteria.
 * @param {String} sortBy - String sorting key/identifier
 * @param {Array} dailyTasks - Array of custom task objects (timeview-format)
 * @returns {Array} - Returns sorted array of custom task objects
 */
const sortDailyTasksBy = (sortBy, dailyTasks = []) => {
	const sortKey = sortBy?.toUpperCase();

	switch (sortKey) {
		case "ADL": {
			const sorted = sortAlphaAscByKey("ADL", dailyTasks);
			return sorted;
		}
		case "RESIDENT": {
			const sorted = sortAlphaAscByKey("LastName", dailyTasks);
			return sorted;
		}
		case "TASK": {
			const sorted = sortAlphaAscByKey("TaskDescription", dailyTasks);
			return sorted;
		}
		case "TIME": {
			// const sorted = sortDateAscByKey("ScheduledDate", dailyTasks);
			// 1st sort by shift
			// 2nd sort by time
			const byShift = sortTVByShiftAsc(dailyTasks);

			const sorted = sortTVTasksByDueTime("ScheduledDate", byShift);
			return sorted;
		}
		// RESET SORTING CASE
		case "":
		case "RESET":
		case "NONE": {
			return dailyTasks;
		}
		default:
			return dailyTasks;
	}
};
// ##TODOS:
// - WORK IN PROGRESS!!!
const multiSortDailyTasksBy = (sortBy, dailyTasks = []) => {
	const sortKey = sortBy?.toUpperCase();

	switch (sortKey) {
		case "ADL": {
			const sorted = sortAlphaAscByKey("ADL", dailyTasks);
			return sorted;
		}
		case "RESIDENT": {
			// const sorted = sortAlphaAscByKey("LastName", dailyTasks);
			// return sorted;
			const sorted = dailyTasks.sort((a, b) => {
				switch (true) {
					// 'DIFFERENT RESIDENT' => SORT ALPHA
					case !isSameResident(a, b): {
						return a?.[sortBy]?.localeCompare(b?.[sortBy]);
					}
					// 'SAME RESIDENT & SHIFT' => SHIFT TIME SORT
					case isSameResident(a, b) && isSameShift(a, b): {
						return new Date(a?.[sortBy]) - new Date(b?.[sortBy]);
					}
					case isSameResident(a, b): {
						return;
					}

					default:
						return;
				}
			});
			return sorted;
		}
		case "TASK": {
			const sorted = sortAlphaAscByKey("TaskDescription", dailyTasks);
			return sorted;
		}
		// RESET SORTING CASE
		case "":
		case "RESET":
		case "NONE": {
			return dailyTasks;
		}
		default:
			return dailyTasks;
	}
};

const generateTimeToday = (hours, mins) => {
	const base = new Date();
	const newDate = new Date(
		base.getFullYear(),
		base.getMonth(),
		base.getDate(),
		hours,
		mins
	);

	return newDate;
};

// VARIOUS RELATED UTILS //

const getShiftIDs = (shifts = []) => {
	const shiftIDs = shifts.map((x) => {
		const id = x?.id ?? x?.shiftID ?? x?.AssessmentShiftId;
		return id;
	});

	return shiftIDs;
};

const getTimeViewCreatedDate = (task) => {
	const createdDate = task?.EntryDate ?? task?.ScheduledDate;
	return format(createdDate, "MM/DD/YYYY");
};

const getResidentFromNameString = (nameString, residents = []) => {
	const [ln, fn] = nameString.split(", ");
	const match = residents.filter((x) => x.name === nameString);
	const resident = match?.[0] ?? {};

	return resident;
};

// TIME-VIEW TASK SUMMARY UTILS //

const isExceptionTV = (task) => {
	return !isEmptyVal(task?.Exception);
};

const isCompletedTV = (task) => {
	return task?.IsCompleted;
};
const isNotCompleteTV = (task, dueDate, shiftTimes) => {
	const notComplete = !task?.IsCompleted;
	const notPastDue = !isPastDueTV(task, dueDate, shiftTimes);

	return notComplete && notPastDue;
};
const isPastDueTV = (task, dueDate, shiftTimes) => {
	// 1. check by 'ScheduledDate'
	// 2. check by 'Shift' due date

	const isPast = isPastDueByTimeOrShift(task, dueDate, shiftTimes);

	return isPast;
};

/**
 * Default 'counts' object for 'getCountsByStatus'.
 */
const initialCounts = {
	total: 0,
	pastDue: 0,
	exceptions: 0,
	notComplete: 0,
	complete: 0,
	prn: 0,
};

/**
 * Sorts & groups 'timeview' tasks by status (eg. 'exceptions', 'notComplete' etc.)
 * @param {Array} allTasks - An array of 'timeview' tasks
 * @param {Array} shiftTimes - AN array of shift times' records
 * @returns {Object} - Returns object w/ tasks sorted by status
 * - UPDATE TO PREVENT 'Exceptions' BEING MARKED AS "PAST-DUE"??????
 */
const getTimeViewCountsByStatus = (allTasks = [], shiftTimes = []) => {
	if (isEmptyArray(allTasks) || isEmptyArray(shiftTimes))
		return { ...initialCounts };

	return allTasks.reduce(
		(counts, task) => {
			if (isPRNTask(task)) {
				counts.prn += 1;
				return { ...counts, total: allTasks.length };
			} else if (isExceptionTV(task) && !isCompletedTV(task)) {
				counts.exceptions += 1;
				return { ...counts, total: allTasks.length };
				// UPDATED AS OF 8/5/2022 AT 9:57 AM
				// } else if (isPastDue(task, new Date(), shiftTimes)) {
			} else if (
				// isLocalhost()
				// ? isPastDueByTimeOrShift(task, new Date(), shiftTimes)
				// :
				isPastDueTV(task, new Date(), shiftTimes)
			) {
				counts.pastDue += 1;
				return { ...counts, total: allTasks.length };
			} else if (
				// isNotComplete(task, task?.TaskDate ?? new Date(), shiftTimes)
				isNotCompleteTV(task, new Date(), shiftTimes)
			) {
				counts.notComplete += 1;
				return { ...counts, total: allTasks.length };
			} else {
				counts.complete += 1;
				return { ...counts, total: allTasks.length };
			}
		},
		{ ...initialCounts }
	);
};

const determineTaskShift = (shifts = [], values = {}) => {
	const { dueDate } = values;
	// maybe incorrect
	// const newShift = values?.newTaskShift;

	const matchingShift = shifts.reduce((matchingRecord, entry) => {
		const { startTime, endTime } = processShiftTimes(entry, new Date());
		// check if 'dueDate' falls between shift start/end times
		if (isBetween(dueDate, startTime, endTime)) {
			matchingRecord = { ...entry };
			return matchingRecord;
		} else {
			return matchingRecord;
		}
	}, {});
	const targetID = matchingShift?.AssessmentShiftId ?? matchingShift?.id;
	const shiftName = getShiftName(targetID);

	return shiftName;

	// const record = matchShiftTimeFromRecords()
};

export { getTimeViewCountsByStatus };

// request utils
export {
	getAdvancedResources,
	getDailyResidentTasksByShifts,
	getMultiResidentsTasksByShifts,
	getListOfResidentsTasksByShifts,
};

// timeview request utils
export {
	fetchTimeViewTasks,
	fetchTimeViewResources,
	saveTimeViewSectionChanges,
	saveQuickCompleteUpdate,
};

export {
	getShiftIDs,
	getResidentFromNameString,
	getTimeViewCreatedDate,
	generateNewTimeViewTask,
	generateNewTimeViewTasks,
	// other create task utils
	addTaskIDsToTasks,
	createTimeViewTasksPerShift,
};

// sorting utils

export {
	isSameADL,
	isSameShift,
	isSameShiftTime,
	isSameResident,
	sortDailyTasksBy,
};

export {
	processControllerResidents,
	processControllerShifts,
	processControllerFacilities,
};

// "TIME-VIEW" TASK UPDATER UTILS
export {
	applyExceptionToTask,
	applyDueDateToTask,
	applyReassessToTask,
	applyCompletionToTask,
	applyQuickCompleteToTask,
	// wrapper around all updaters
	applyAllUpdatesToTask,
	// client-side task updater
	applyAllUpdatesToTimeViewTask,
};

export { determineTaskShift };
