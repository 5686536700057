import React from "react";
import styles from "../../css/user/UserDropdownOption.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";

const checkIcon = `checkmark-outline`;

const getUserInitials = (user) => {
	const firstName = user?.firstName ?? user?.strFirstName;
	const lastName = user?.lastName ?? user?.strLastName;

	if (isEmptyVal(firstName) || isEmptyVal(lastName)) {
		return `NA`;
	} else {
		const first = firstName.slice(0, 1);
		const last = lastName.slice(0, 1);
		return `${first}${last}`;
	}
};
const getUserName = (user) => {
	const firstName = user?.firstName ?? user?.strFirstName;
	const lastName = user?.lastName ?? user?.strLastName;

	if (isEmptyVal(firstName) && isEmptyVal(lastName)) {
		return `Unknown`;
	} else {
		return `${lastName}, ${firstName}`;
	}
};

const getUserID = (user) => {
	const id = user?.userID ?? user?.UserId ?? user?.UserLoginID;

	return id;
};

const getOptionStyles = (isSelected = false, isDisabled = false) => {
	if (isDisabled) {
		return styles.UserDropdownOption_isDisabled;
	} else if (isSelected) {
		return styles.UserDropdownOption_isSelected;
	} else {
		return styles.UserDropdownOption;
	}
};

const UserDropdownOption = ({
	option = {},
	hasFocus = false,
	isDisabled = false,
	isSelected = false,
	handleFocus,
	handleSelection,
	handleSelectionByKey,
}) => {
	return (
		<li
			tabIndex={0}
			className={getOptionStyles(isSelected, isDisabled)}
			onClick={isDisabled ? null : () => handleSelection(option)}
			onKeyDown={handleSelectionByKey}
			onFocus={handleFocus}
			title={isDisabled ? "Disabled" : ""}
		>
			<div className={styles.UserDropdownOption_badge}>
				<div className={styles.UserDropdownOption_badge_wrapper}>
					<div className={styles.UserDropdownOption_badge_wrapper_initials}>
						{getUserInitials(option)}
					</div>
				</div>
			</div>
			<div className={styles.UserDropdownOption_details}>
				<div className={styles.UserDropdownOption_details_label}>
					{getUserName(option)}
				</div>
				<div className={styles.UserDropdownOption_details_id}>
					{getUserID(option)}
				</div>
			</div>

			{isSelected && (
				<svg className={styles.UserDropdownOption_checked}>
					<use xlinkHref={`${sprite}#icon-${checkIcon}`}></use>
				</svg>
			)}
		</li>
	);
};

export default UserDropdownOption;

UserDropdownOption.defaultProps = {};

UserDropdownOption.propTypes = {};
