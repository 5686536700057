import React, { useEffect, useRef } from "react";
import styles from "../../css/history/HistoricalModal.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";

const HistoricalModal = ({ title, closeModal, children }) => {
	const HistoricalModalRef = useRef();
	const { isOutside } = useOutsideClick(HistoricalModalRef);
	const userEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll(); // runs on mount ONLY

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || userEscaped) {
			return closeModal();
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside, closeModal, userEscaped]);

	return (
		<aside className={styles.HistoricalModal} ref={HistoricalModalRef}>
			<header className={styles.HistoricalModal_header}>
				<h2 className={styles.HistoricalModal_header_title}>{title}</h2>
				<svg
					className={styles.HistoricalModal_header_closeIcon}
					onClick={closeModal}
				>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</header>
			<section className={styles.HistoricalModal_main}>{children}</section>
		</aside>
	);
};

export default HistoricalModal;

HistoricalModal.defaultProps = {};

HistoricalModal.propTypes = {
	title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	closeModal: PropTypes.func,
	children: PropTypes.element,
};
