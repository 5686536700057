import React, { useState, useContext } from "react";
import { PropTypes } from "prop-types";
import { format } from "date-fns";
import { useForm } from "../../utils/useForm";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import styles from "../../css/adlschedule/ShiftHeading.module.scss";
import sprite from "../../assets/icons/checks.svg";
// COMPONENTS
import EditShiftModal from "./EditShiftModal";
import ModalLG from "../shared/ModalLG";
import {
	getShiftName,
	getShiftSelections,
	updateShiftTimesModel,
	saveShiftTimes,
	prepareAndUpdateShiftTimesModel,
} from "../../helpers/utils_shifts";
import { formatShiftTimes, utcToLocal } from "../../helpers/utils_dates";
import { isEmptyVal } from "../../helpers/utils_types";
import { sortNumAscByKey } from "../../helpers/utils_processing";

// TODOS:
// - Add validation when editing shiftTimes
// 		- Make sure the "date" is incremented for NOC endtimes that roll over
// 		- Make sure no 24hr GAPs occur
// 		- Make sure no overlapping shift times occur
// - Check if 'NOC' shift is being edited: ✓
// 		- IF true, AND 'endTime' if *before* the 'startTime', then increment the date used ✓

const checkForShiftGapsAndOverlaps = (allShiftData = {}) => {
	const {
		shiftTimes: currentShiftTimes = {},
		vals = {},
		shift = {},
		allShifts = [],
	} = allShiftData;

	// sort & destrucure shift records: AM, PM, NOC
	const [shiftAM, shiftPM, shiftNOC] = sortNumAscByKey(
		"AssessmentShiftId",
		allShifts
	);
};

const ShiftHeading = ({ shift, allShifts = [] }) => {
	const { state } = useContext(GlobalStateContext);
	const { user } = state;
	const { currentFacility } = state.globals;
	const { AlertsHandler, dispatchAlert } = useAlerts(initialSettings);

	const [shiftTimes, setShiftTimes] = useState({
		startTime: format(utcToLocal(shift.StartTime), "h:mm A"),
		endTime: format(utcToLocal(shift.EndTime), "h:mm A"),
	});
	const [showShiftModal, setShowShiftModal] = useState(false);
	const [showEditIcons, setShowEditIcons] = useState(false);
	const { formState, setFormState, handleReset } = useForm({
		AMShiftStart: "",
		AMShiftEnd: "",
		PMShiftStart: "",
		PMShiftEnd: "",
		NOCShiftStart: "",
		NOCShiftEnd: "",
	});
	const { values } = formState;

	const handleShiftTimes = (name, time) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				[name]: time,
			},
		});
	};

	// checks for both shift time selections
	// dispatches alert if either are empty and returns false
	const hasSelections = (start, end) => {
		if (isEmptyVal(start)) {
			dispatchAlert("warn", {
				heading: "Select a shift start time.",
			});
			return false;
		}
		if (isEmptyVal(end)) {
			dispatchAlert("warn", {
				heading: "Select a shift end time.",
			});
			return false;
		}
		return true;
	};

	const saveShiftTimeChanges = async (e) => {
		const { startTime, endTime } = getShiftSelections(values, shift);
		if (!hasSelections(startTime, endTime)) return;
		const { token, facilityID } = user;

		// check for any gaps or overlaps between all shifts
		const results = checkForShiftGapsAndOverlaps({
			shift,
			allShifts,
			shiftTimes,
			vals: values,
		});

		// convert, prepare & update shift times into date & apply to 'ShiftTimes' model
		const updatedModel = prepareAndUpdateShiftTimesModel(
			values,
			shift,
			currentFacility?.facilityID
		);

		console.group("Save Shift Times");
		console.log("Validation:", results);
		console.log("updatedModel:", updatedModel);
		console.groupEnd();

		// fire off request
		// const wasSaved = false;
		const wasSaved = await saveShiftTimes(
			token,
			facilityID, // or can use shift.FacilityId
			updatedModel
		);

		if (wasSaved) {
			// 'shiftName' is only used for the alert
			const shiftName = getShiftName(shift.AssessmentShiftId);
			setShiftTimes({
				startTime,
				endTime,
			});
			setShowShiftModal(false);
			return dispatchAlert("success", {
				heading: "Update Successful!",
				subheading: `${shiftName} shift times have been updated.`,
			});
		} else {
			setShiftTimes({
				startTime,
				endTime,
			});
			return dispatchAlert("error", {
				heading: "Error!",
				subheading: `An error occured while saving. Please try again.`,
			});
		}

		// save changes to server
		// return saveNewShiftTimes(updatedModel);
	};

	const cancelChanges = (e) => {
		handleReset(e);
		setShowShiftModal(false);
	};

	return (
		<>
			<section
				className={styles.ShiftHeading}
				onMouseEnter={() => setShowEditIcons(true)}
				onMouseLeave={() => setShowEditIcons(false)}
			>
				<div className={styles.ShiftHeading_shiftName}>
					<span>{getShiftName(shift.AssessmentShiftId)}</span>
				</div>
				<div className={styles.ShiftHeading_shiftRange}>
					<span>
						{shiftTimes?.startTime} - {shiftTimes?.endTime}
					</span>

					{showEditIcons && (
						<>
							<div
								className={styles.ShiftHeading_shiftRange_edit}
								onClick={() => setShowShiftModal(true)}
							>
								<svg className={styles.ShiftHeading_shiftRange_edit_editIcon}>
									<use xlinkHref={`${sprite}#icon-edit-pencil`}></use>
								</svg>
							</div>
						</>
					)}
				</div>
			</section>

			{showShiftModal && (
				<ModalLG
					title={`Edit Shift Times: ${getShiftName(
						shift.AssessmentShiftId
					)} Shift`}
					closeModal={() => setShowShiftModal(false)}
				>
					<EditShiftModal
						allShifts={allShifts}
						shiftName={getShiftName(shift.AssessmentShiftId)}
						shiftTimeVals={formState.values}
						shift={shift}
						currentShiftTimes={shiftTimes}
						handleShiftTimes={handleShiftTimes}
						saveChanges={saveShiftTimeChanges}
						cancelChanges={cancelChanges}
					/>
				</ModalLG>
			)}

			{/* ALERTS HANDLER */}
			{AlertsHandler}
		</>
	);
};

export default ShiftHeading;

ShiftHeading.defaultProps = {};

// uses 'AssessmentFacilityShift' record as 'shift'
ShiftHeading.propTypes = {
	shift: PropTypes.shape({
		AssessmentShiftId: PropTypes.number.isRequired,
		AssessmentFacilityShiftId: PropTypes.number.isRequired,
		StartTime: PropTypes.oneOfType([
			PropTypes.instanceOf(Date),
			PropTypes.string,
		]).isRequired,
		EndTime: PropTypes.oneOfType([
			PropTypes.instanceOf(Date),
			PropTypes.string,
			PropTypes.object,
		]).isRequired,
	}),
};
