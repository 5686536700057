import React, { useState, useRef, useLayoutEffect } from "react";
import styles from "../../css/timeview/TimeViewTaskListEntry.module.scss";
import sprite from "../../assets/icons/settings.svg";
import sprite2 from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useWindowSize } from "../../utils/useWindowSize";
import { addEllipsis } from "../../helpers/utils_processing";
import { format, getTime, isWithinRange } from "date-fns";
import { green, pink, purple, yellow } from "../../helpers/utils_styles";
import { useForm } from "../../utils/useForm";
import {
	getTaskDueTime,
	getTaskID,
	isCompleted,
	isLocked,
	isNotComplete,
	isPastDueByTimeOrShift,
	isPRNTask,
	isScheduledTask,
} from "../../helpers/utils_tasks";
import { deleteUnscheduledByID } from "../../helpers/utils_unscheduled";
import {
	getDefaultDay,
	getDefaultShift,
	getRecurringStartAndEnd,
	getRecurringTypeFromID,
	isRecurring,
} from "../../helpers/utils_repeatingTasks";
import { checkCategoryNaming } from "../../helpers/utils_categories";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	initAndUpdateNewTaskNote,
	saveTaskNotes,
} from "../../helpers/utils_notes";
import {
	applyAllUpdatesToTask,
	applyAllUpdatesToTimeViewTask,
	applyQuickCompleteToTask,
	saveTimeViewSectionChanges,
	saveQuickCompleteUpdate,
	determineTaskShift,
} from "../../helpers/utils_timeview";
import {
	BASE_EXCEPTION_TYPES as baseExceptions,
	FACILITY_EXCEPTION_TYPES as defaultExceptions,
} from "../../helpers/utils_options";
import { hasException } from "../../helpers/utils_exceptions";
import { getShiftName } from "../../helpers/utils_shifts";
// components
import MobileModal from "../shared/MobileModal";
import ResidentPhotoController from "./ResidentPhotoController";
import TimeViewAdvancedOptions from "./TimeViewAdvancedOptions";

// REQUIREMENTS:
// Task Info:
//    - Task Name/Desc
//    - ADL Category
//    - ADL Category Icon
//    - Due Date/Time
// Task's Resident Info:
//    - Resident Photo
//    - Resident Name
//    - Resident's Room Number

const getStatusCss = (
	task = {},
	vals = {},
	wasUpdated = false,
	isNewTask = false,
	shiftTimes = []
) => {
	const taskDueTime = getTaskDueTime(task, new Date(), shiftTimes);
	// task status classification(s)
	const isPRN = isPRNTask(task);
	const withException = hasException(task) || !isEmptyVal(vals?.exceptionType);
	// due date/time anchored to today's date
	const dueDate = taskDueTime;

	switch (true) {
		case isPRNTask(task): {
			return {
				borderLeft: `5px solid ${pink[600]}`,
				opacity: "1",
			};
		}
		case isCompleted(task) || vals?.markComplete: {
			return {
				borderLeft: `5px solid ${green[600]}`,
				opacity: ".5",
			};
		}
		// UPDATED FOR TICKET #174 - Exceptions should NOT be marked past-due
		case isPastDueByTimeOrShift(task, dueDate, shiftTimes) &&
			!isPRN &&
			!withException: {
			return {
				borderLeft: `5px solid ${purple[700]}`,
				opacity: "1",
			};
		}

		// UPDATED FOR TICKET #174 - Exceptions should NOT be marked past-due
		case withException && (!isCompleted(task) || vals?.markComplete):
		case isNotComplete(task, dueDate, shiftTimes): {
			return {
				borderLeft: `5px solid ${yellow[500]}`,
				opacity: "1",
			};
		}

		default:
			return {
				borderLeft: `5px solid ${yellow[500]}`,
				opacity: "1",
			};
	}
};

const getShiftNameValue = (isNewTask, task, shifts = []) => {
	if (isNewTask) {
		const shiftName = determineShiftFromDate(task, shifts);
		return shiftName;
	} else {
		return task?.Shift;
	}
};

const determineShiftFromDate = (task, shifts = []) => {
	const matchingShift = determineTaskShift(shifts, {
		...task,
		dueDate: task?.ScheduledDate,
	});
	const shiftName = getShiftName(matchingShift?.AssessmentShiftId);

	return shiftName;
};

const determineShift = (task = {}, shifts = []) => {
	const { Shift, ScheduledDate } = task;
	const [am, pm, noc] = shifts;
	const amStart = getTime(am?.startTime);
	const amEnd = getTime(am?.endTime);
	const pmStart = getTime(pm?.startTime);
	const pmEnd = getTime(pm?.endTime);
	const nocStart = getTime(noc?.startTime);
	const nocEnd = getTime(noc?.endTime);

	if (isEmptyVal(Shift)) {
		const isAM = isWithinRange(ScheduledDate, amStart, amEnd);
		const isPM = isWithinRange(ScheduledDate, pmStart, pmEnd);
		const isNOC = isWithinRange(ScheduledDate, nocStart, nocEnd);
		const label = (isAM && "AM") || (isPM && "PM") || (isNOC && "NOC");

		return label;
	} else {
		return Shift;
	}
};

/**
 * Formats resident names (first & last). Supports:
 * - 'First Last' => 'FL' (ie. First Last)
 * - 'Last, First' => 'LF' (ie. Last. First)
 * - 'F. Last' => 'FIL' (ie. First initial Last)
 * - 'F.L.' => 'FILI' (ie. First initial Last initial)
 * - 'Last, F.' => 'LFI' (ie. Last, First initial)
 * @param {String} format - String formatting key. Determines how to format resident names
 * @param {Object} task - Task record object (client format)
 * @returns {String} - Returns formatted resident names as string
 */
const formatTaskResidentNames = (format = "FL", task = {}) => {
	const { FirstName: first, LastName: last } = task;

	switch (format) {
		// 'First Last'
		case "FL": {
			const name = `${first} ${last}`;

			return name;
		}
		// 'Last, First'
		case "LF": {
			const name = `${last}, ${first}`;

			return name;
		}
		// 'F. Last'
		case "FIL": {
			const firstInitial = first.slice(0, 1);
			const name = `${firstInitial}. ${last}`;

			return name;
		}
		// 'F.L.'
		case "FILI": {
			const firstInitial = first.slice(0, 1);
			const lastInitial = last.slice(0, 1);
			const name = `${firstInitial}.${lastInitial}.`;

			return name;
		}
		// 'Last, F.'
		case "LFI": {
			const firstInitial = first.slice(0, 1);
			const name = `${last}, ${firstInitial}`;

			return name;
		}

		default:
			throw new Error(`Invalid format key:`, format);
	}
};

const getTaskADLName = (task) => {
	const rawAdl = task?.ADL ?? task?.ADLCategory;
	const adl = checkCategoryNaming(rawAdl);

	// previous code - 12/16/2022 - removed the below to use default ADL name
	// return isEmptyVal(adl) ? "Other" : adl;
	return rawAdl;
};

/**
 * Extracts base64 resident photo string from 'photosMap'
 * @param {Object} task - Custom-shaped and abbreviated task data
 * @param {Object} photosMap - A dictionary of resident photos by resident ID
 * @returns {String} - Returns base64 resident photo string
 */
const getPhotoSrcFromData = (task = {}, photosMap = {}) => {
	const { ResidentId: id } = task;
	const photoRecord = photosMap?.[id];
	const photoSrc = photoRecord?.photoSrc ?? null;

	return photoSrc;
};

const QuickCreateTaskButton = () => {
	return (
		<button className={styles.QuickCreateT}>
			<svg className={styles.QuickCreateTaskButton_icon}>
				<use xlinkHref={`${sprite}#icon-add`}></use>
			</svg>
			0
		</button>
	);
};

const CreatedTaskIndicator = ({ task }) => {
	if (isScheduledTask(task)) {
		return null;
	}
	return (
		<>
			{/* 'CREATED TASK' INDICATOR ICON */}
			<div
				className={styles.CreatedTaskIndicator}
				title={`This task was user-created for today.`}
				// title={`Created Task on ${getTimeViewCreatedDate(task)}`}
			>
				<svg className={styles.CreatedTaskIndicator_icon}>
					<use xlinkHref={`${sprite2}#icon-add2`}></use>
				</svg>
			</div>
		</>
	);
};

const CreatedTaskLabel = ({ task, isNewTask = false }) => {
	if (isScheduledTask(task) || !isNewTask) {
		return null;
	}
	return (
		<div className={styles.CreatedTaskLabel}>
			<div className={styles.CreatedTaskLabel_label}>NEW!</div>
		</div>
	);
};

const AdvancedButton = ({ handleAdvanced, isDisabled = false }) => {
	return (
		<button
			type="button"
			disabled={isDisabled}
			onClick={handleAdvanced}
			className={styles.AdvancedButton}
		>
			Advanced
		</button>
	);
};

const ExceptionIcon = () => {
	return (
		<svg className={styles.ExceptionIcon}>
			<use xlinkHref={`${sprite2}#icon-error_outline`}></use>
		</svg>
	);
};

const ExceptionDesc = ({ desc }) => {
	return (
		<div className={styles.ExceptionDesc} title={`EXCEPTION: ${desc}`}>
			{addEllipsis(desc, 20)}
		</div>
	);
};

const ExceptionIndicator = ({ task = {}, facilityExceptions = [] }) => {
	return (
		<div className={styles.ExceptionIndicator}>
			<ExceptionDesc
				// desc={matchExceptionDescFromTask(task, facilityExceptions)}
				desc={task?.Exception}
			/>
			<ExceptionIcon />
		</div>
	);
};

const QuickCompleteCheckbox = ({
	label,
	name,
	id,
	val,
	isDisabled = false,
	handleCheckbox,
}) => {
	return (
		<div className={styles.QuickCompleteCheckbox}>
			<input
				type="checkbox"
				name={name}
				id={id}
				checked={val}
				onChange={handleCheckbox}
				disabled={isDisabled}
				className={styles.QuickCompleteCheckbox_checkbox}
			/>
			<label htmlFor={id} className={styles.QuickCompleteCheckbox_label}>
				{label}
			</label>
		</div>
	);
};

const QuickCompleteHandler = ({
	task,
	name,
	id,
	isCompleted = false,
	handleQuickComplete,
}) => {
	return (
		<div className={styles.QuickCompleteHandler}>
			<QuickCompleteCheckbox
				label="Complete"
				id={id}
				name={name}
				val={isCompleted}
				isDisabled={false}
				handleCheckbox={handleQuickComplete}
			/>
		</div>
	);
};

const ResidentName = ({ task = {} }) => {
	// ↓ PREVIOUS CODE ↓
	// const { FirstName: first, LastName: last } = task;
	// const name = `${first} ${last}`;
	// NEW CODE
	const name = formatTaskResidentNames("FL", task);

	return (
		<div className={styles.ResidentName} title={name}>
			{addEllipsis(name, 25)}
		</div>
	);
};
const TaskADL = ({ task }) => {
	return <div className={styles.TaskADL}>{getTaskADLName(task)}</div>;
};
const TaskName = ({ task = {} }) => {
	const { width: w } = useWindowSize();
	const desktop = 50; // 50
	const mobile = 30;

	return (
		<div className={styles.TaskName} title={task?.TaskDescription}>
			{addEllipsis(task?.TaskDescription, w < 700 ? mobile : desktop)}
		</div>
	);
};
const TaskTime = ({ task = {}, isPastDue = false }) => {
	if (isPRNTask(task)) {
		return null;
	}
	return (
		<div
			className={styles.TaskTime}
			title={`Due ${format(task?.ScheduledDate, "M/D/YYYY h:mm A")}`}
		>
			<div
				className={
					isPastDue ? styles.TaskTime_bubble_isPastDue : styles.TaskTime_bubble
				}
			>
				<span className={styles.TaskTime_bubble_time}>
					{format(task?.ScheduledDate, "h:mm A")}
				</span>
			</div>
		</div>
	);
};
const TaskShift = ({
	task = {},
	vals = {},
	shifts = [],
	isNewTask = false,
}) => {
	const { Shift: shift } = task;

	if (isPRNTask(task)) {
		return null;
	}
	return (
		<div className={styles.TaskShift}>
			<span className={styles.TaskShift_label}>{shift}</span>
		</div>
	);
};

const TaskInfo = ({
	task = {},
	vals = {},
	handleChange,
	handleCheckbox,
	handleQuickComplete,
	isPastDue = false,
	isNewTask = false,
	facilityExceptions = [],
	shifts = [],
}) => {
	return (
		<div className={styles.TaskInfo}>
			{/* TASK NAME, ADL, CHECKBOX */}
			<div className={styles.TaskInfo_name}>
				<div className={styles.TaskInfo_name_details}>
					<TaskName task={task} />
					<TaskADL task={task} />
				</div>
				<div className={styles.TaskInfo_name_quickComplete}>
					<QuickCompleteHandler
						key={`--${getTaskID(task)}--`}
						id={`--${getTaskID(task)}--`}
						task={task}
						name="markComplete"
						isCompleted={vals?.markComplete}
						handleQuickComplete={handleQuickComplete}
					/>
					<CreatedTaskIndicator task={task} />
					<CreatedTaskLabel task={task} isNewTask={isNewTask} />
				</div>
			</div>

			{/* TASK DUE TIME */}
			<div className={styles.TaskInfo_time}>
				<TaskTime task={task} isPastDue={isPastDue} />
			</div>

			{/* EXCEPTION */}
			{(!isEmptyVal(task?.AssessmentExceptionId) ||
				!isEmptyVal(task?.Exception)) && (
				<ExceptionIndicator
					task={task}
					facilityExceptions={facilityExceptions}
				/>
			)}
		</div>
	);
};

const getInitialTaskState = (task = {}) => {
	const initialState = {
		reassess: false,
		reassessNotes: "",
		shift: task?.Shift, // when shift was completed (ie statusing)
		taskNotes: task?.TaskNotes?.[0] ?? "",
		syncNotesVal: "", // only used to track notes in children
		signature: "",
		markComplete: isCompleted(task),
		isLocked: isLocked(task),
		exceptionType: task?.Exception ?? "",
		recurringType: getRecurringTypeFromID(task?.AssessmentRecurringId),
		isRecurring: isRecurring(task),
		recurringCycle: "1",
		recurringCycleOption: "",
		isRecurringAM: getDefaultShift("AM", task),
		isRecurringPM: getDefaultShift("PM", task),
		isRecurringNOC: getDefaultShift("NOC", task),
		isRecurringSun: getDefaultDay("Sun", task),
		isRecurringMon: getDefaultDay("Mon", task),
		isRecurringTue: getDefaultDay("Tue", task),
		isRecurringWed: getDefaultDay("Wed", task),
		isRecurringThu: getDefaultDay("Thu", task),
		isRecurringFri: getDefaultDay("Fri", task),
		isRecurringSat: getDefaultDay("Sat", task),
		startDate: getRecurringStartAndEnd(task).startDate,
		endDate: getRecurringStartAndEnd(task).endDate,
		// NEW TASK NOTES FIELDS
		trackingID: task?.AssessmentTrackingId ?? 0,
		taskID:
			task?.AssessmentTrackingTaskId ?? task?.AssessmentUnscheduleTaskId ?? 0,
		scheduledDueDate: task?.ScheduledDate ?? new Date(),
		scheduledDueTime: format(task?.ScheduledDate, "hh:mm A") ?? "3:00 PM",
	};

	return initialState;
};

const TimeViewTaskListEntry = ({
	task = {},
	currentUser = {},
	currentFacility = {},
	facilityExceptions = [...baseExceptions],
	dispatchAlert,
	isPastDue = false,
	isNewTask = false,
	syncTaskChanges,
	openResidentInfo, // popup handler
	openNewResidentTaskModal,
	handleActive,
	scrollState = {},
	sortByState = {},
	taskListRef,
}) => {
	const { photosMap } = currentFacility;
	const saveRef = useRef();
	const taskRef = useRef();
	// ##TODOS:
	// - Update 'values' to support recurring settings for 'Unscheduled' task types
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			reassess: false,
			reassessNotes: "",
			shift: task?.Shift, // when shift was completed (ie statusing)
			taskNotes: task?.TaskNotes?.[0] ?? "",
			syncNotesVal: "", // only used to track notes in children
			signature: "",
			markComplete: isCompleted(task),
			isLocked: isLocked(task),
			exceptionType: task?.Exception ?? "",
			recurringType: getRecurringTypeFromID(task?.AssessmentRecurringId),
			isRecurring: isRecurring(task),
			recurringCycle: "1",
			recurringCycleOption: "",
			isRecurringAM: getDefaultShift("AM", task),
			isRecurringPM: getDefaultShift("PM", task),
			isRecurringNOC: getDefaultShift("NOC", task),
			isRecurringSun: getDefaultDay("Sun", task),
			isRecurringMon: getDefaultDay("Mon", task),
			isRecurringTue: getDefaultDay("Tue", task),
			isRecurringWed: getDefaultDay("Wed", task),
			isRecurringThu: getDefaultDay("Thu", task),
			isRecurringFri: getDefaultDay("Fri", task),
			isRecurringSat: getDefaultDay("Sat", task),
			startDate: getRecurringStartAndEnd(task).startDate,
			endDate: getRecurringStartAndEnd(task).endDate,
			// NEW TASK NOTES FIELDS
			// assessmentTaskID: task?.AssessmentTaskId ?? null,
			trackingID: task?.AssessmentTrackingId ?? 0,
			taskID:
				task?.AssessmentTrackingTaskId ?? task?.AssessmentUnscheduleTaskId ?? 0,
			assessmentID: task?.AssessmentTaskId ?? 0,
			scheduledDueDate: task?.ScheduledDate ?? new Date(),
			scheduledDueTime: format(task?.ScheduledDate, "hh:mm A") ?? "03:00 PM",
		});
	const { values, touched } = formState;
	const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [wasNoteSaved, setWasNoteSaved] = useState(false);
	const [wasUpdated, setWasUpdated] = useState(false);

	const initNewTaskModal = (details) => {
		setShowAdvancedOptions(false);
		openNewResidentTaskModal(details);
		console.log("details", details);
	};

	const initAdvanced = (e) => {
		e.preventDefault();

		setShowAdvancedOptions(true);
		console.log(`Advanced was clicked!`);
	};
	const initMenu = () => {
		setShowMenu(true);
		console.log(`Menu was clicked!`);
	};

	// just monitors task notes changes
	const syncTaskNotes = (e) => {
		const { value } = e.target;

		setFormState({
			...formState,
			values: {
				...values,
				syncNotesVal: value,
			},
		});
	};

	const handleQuickComplete = async () => {
		const { shifts } = currentFacility;
		const current = values?.markComplete;

		setFormState({
			...formState,
			values: {
				...values,
				markComplete: !current,
			},
			touched: {
				...touched,
				markComplete: true,
			},
		});

		// a manual state toggle is required since state has NOT been updated yet!!!
		const newFormState = {
			...formState,
			values: {
				...values,
				markComplete: !current,
			},
		};

		// apply changes to task
		const updatedTask = applyQuickCompleteToTask(
			newFormState,
			task,
			shifts,
			currentUser
		);

		handleActive({
			scrollPos: taskListRef?.current?.scrollTop,
			active: getTaskID(task),
			updated: true,
		});
		console.log("updatedTask", updatedTask);
		return saveQuickComplete(updatedTask);
	};

	const handleDueDate = (name, date) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: date,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};
	const handleDueTime = (name, time) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: time,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	const handleAdvancedCompletion = () => {
		const { markComplete } = values;
		return setFormState({
			...formState,
			values: {
				...values,
				markComplete: !markComplete,
			},
			touched: {
				...touched,
				markComplete: !markComplete,
			},
		});
	};
	// handle custom dropddowns & date pickers
	const handleTaskSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				[name]: val,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};
	// handle radios in create task form
	const handleShift = (shift) => {
		setFormState({
			...formState,
			values: {
				...values,
				shift: shift,
			},
		});
	};
	const handleCycleOptions = (val) => {
		return setFormState({
			...formState,
			values: {
				...values,
				recurringCycleOption: val,
			},
		});
	};
	const handleDateRange = ({ startDate, endDate }) => {
		return setFormState({
			...formState,
			values: {
				...values,
				startDate,
				endDate,
			},
		});
	};

	// only saves a single section; does close modal
	// save 'Save Section Changes' handler
	// ##TODOS:
	// - Consider removing: "ModifiedDate" & "ModifiedBy" fields
	const saveSectionChanges = async (taskInfo) => {
		const { token } = currentUser;
		const { shifts } = currentFacility;

		const updatedTask = applyAllUpdatesToTask(
			formState,
			taskInfo,
			defaultExceptions,
			shifts,
			currentUser
		);

		// fires off relevant task & reassess request(s)
		const { wasReassessed, wasTaskUpdated } = await saveTimeViewSectionChanges(
			token,
			formState,
			updatedTask,
			currentUser
		);

		if (wasTaskUpdated) {
			const updatedClientTask = applyAllUpdatesToTimeViewTask(formState, task);
			setWasUpdated(true);
			setShowAdvancedOptions(false);
			dispatchAlert("SUCCESS", {
				heading: `Task was updated!`,
			});
			setFormState({
				...formState,
				values: {
					...values,
					...getInitialTaskState({ ...task, ...updatedTask }),
				},
				touched: {},
			});
			setWasUpdated(true);
			handleActive({
				scrollPos: taskListRef.current.scrollTop,
				active: getTaskID(task),
				updated: true,
			});
			return syncTaskChanges(updatedClientTask, sortByState);
		} else {
			setWasUpdated(false);
			return dispatchAlert("ERROR", {
				heading: `There was an error!`,
				subheading: `Task was NOT updated.`,
			});
		}
	};
	// cancels FormActions' action
	const cancelSectionChanges = (e) => {
		const resetState = getInitialTaskState(task);
		setFormState({
			values: {
				...resetState,
			},
			touched: {},
		});
		// setShowAdvancedOptions(false);
	};

	const saveAllChanges = async (taskInfo) => {
		const { token } = currentUser;
		const { shifts } = currentFacility;
		console.log(`TASK INFO (saveAllChanges):`, taskInfo);
		// add support for shifts
		const updatedTask = applyAllUpdatesToTask(
			formState,
			taskInfo,
			defaultExceptions,
			shifts,
			currentUser
		);

		// fires off relevant task & reassess request(s)
		const { wasReassessed, wasTaskUpdated } = await saveTimeViewSectionChanges(
			token,
			formState,
			updatedTask,
			currentUser
		);

		if (wasTaskUpdated) {
			const updatedClientTask = applyAllUpdatesToTimeViewTask(formState, task);
			setShowAdvancedOptions(false);
			setWasUpdated(true);
			// dispatchAlert("SUCCESS", {
			// 	heading: `Task was updated!`,
			// });
			setFormState({
				...formState,
				values: {
					...values,
					...getInitialTaskState({ ...task, ...updatedTask }),
				},
				touched: {},
			});
			setWasUpdated(true);
			handleActive({
				// scrollPos: scrollState?.scrollPos,
				scrollPos: taskListRef?.current?.scrollTop,
				active: getTaskID(updatedTask),
				updated: true,
			});
			syncTaskChanges(updatedClientTask, sortByState);
			return dispatchAlert("SUCCESS", {
				heading: `Task was updated!`,
			});
		} else {
			setWasUpdated(false);
			return dispatchAlert("ERROR", {
				heading: `There was an error!`,
				subheading: `Task was NOT updated.`,
			});
		}
	};

	const cancelAllChanges = (e) => {
		const resetState = getInitialTaskState(task);
		setFormState({
			values: {
				...resetState,
			},
			touched: {},
		});
		setShowAdvancedOptions(false);
	};

	const saveNewTaskNote = async () => {
		const { token } = currentUser;
		// creates client-side & server-formatted note models
		const { client, server } = initAndUpdateNewTaskNote(task, {
			...values,
			entryDate: new Date().toISOString(),
			createdDate: new Date().toISOString(),
			modifiedDate: new Date().toISOString(),
		});

		// const wasSaved = true;
		const wasSaved = await saveTaskNotes(token, server);

		if (wasSaved) {
			// const newNotesList = [...allNotes, client];
			// setAllNotes([...newNotesList]);
			// prolly need to sync new task note to <TaskUpdateForm/> above, or re-fetch task notes
			// handleReset(e);
			return setWasNoteSaved(true);
		} else {
			return setWasNoteSaved(false);
		}
	};

	const saveQuickComplete = async (updatedTask) => {
		const { token } = currentUser;

		// fire off request to mark or toggle complete/not-complete status
		// const wasTaskSaved = await saveScheduledUpdates(token, updatedTask);
		const wasTaskSaved = await saveQuickCompleteUpdate(token, updatedTask);

		if (wasTaskSaved) {
			const newTask = {
				...task,
				IsCompleted: updatedTask?.IsCompleted ?? false,
			};
			setWasUpdated(true);
			syncTaskChanges(newTask, sortByState);

			// handleActive({
			// 	scrollPos: taskListRef?.current?.scrollTop,
			// 	active: getTaskID(updatedTask),
			// 	updated: true,
			// });
			return dispatchAlert("SUCCESS", {
				heading: `Task was updated!`,
			});
		} else {
			setWasUpdated(false);
			return dispatchAlert("ERROR", {
				heading: `Update failed!`,
			});
		}
	};

	// deletes unscheduled tasks ONLY
	const deleteTask = async (task) => {
		const { token } = currentUser;
		const id = task?.AssessmentUnscheduleTaskId ?? null;
		const wasSuccessful = await deleteUnscheduledByID(token, id);
		setShowAdvancedOptions(false);

		if (wasSuccessful) {
			// reloadResident();
			return dispatchAlert("SUCCESS", {
				heading: `Success!`,
				subheading: `Task was deleted!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Oops!`,
				subheading: `'deleteTask()' failed!`,
			});
		}
	};

	// scroll NEW TASK into view
	useLayoutEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// ##TODOS:
		// - Change 'task?.IsNewTask' to be a prop value 'isNewTask'
		// - Store the 1st new task ID in state in <DailyTimeView/>
		// - Then check each task's ID when rendering the list to determine if it's a 'NEW TASK'
		// if (task?.IsNewTask) {
		if (isNewTask) {
			// const pos = getNewTaskScrollPos(taskRef, taskListRef);
			// taskListRef.current.scrollTo(0, pos);
			taskRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div
				className={
					wasUpdated
						? `${styles.TimeViewTaskListEntry} ${styles.wasUpdated}`
						: styles.TimeViewTaskListEntry
				}
				style={getStatusCss(
					task,
					values,
					wasUpdated,
					isNewTask,
					currentFacility?.shifts
				)}
				ref={taskRef}
			>
				<div className={styles.TimeViewTaskListEntry_photo}>
					<ResidentPhotoController
						task={task}
						handleClick={() => openResidentInfo(task)}
						residentDetails={{
							id: task?.ResidentId,
							src: getPhotoSrcFromData(task, photosMap),
							alt: "Resident Photo",
							roomNum: task?.RoomNum,
						}}
					/>
					<ResidentName task={task} />
					<TaskShift task={task} vals={values} isNewTask={isNewTask} />
				</div>
				<div className={styles.TimeViewTaskListEntry_details}>
					<TaskInfo
						task={task}
						vals={values}
						isPastDue={isPastDue}
						isNewTask={isNewTask}
						facilityExceptions={currentFacility?.exceptions}
						handleQuickComplete={handleQuickComplete}
						shifts={currentFacility?.shifts ?? []}
					/>
				</div>
				<div className={styles.TimeViewTaskListEntry_actions}>
					<AdvancedButton handleAdvanced={initAdvanced} />
				</div>
			</div>

			{showAdvancedOptions && (
				<MobileModal
					title={`Advanced Options`}
					closeModal={() => setShowAdvancedOptions(false)}
				>
					<TimeViewAdvancedOptions
						key={`TIME-VIEW-ADVANCED-${values?.taskNotes}`}
						task={task}
						saveRef={saveRef}
						vals={values}
						formState={formState}
						handleChange={handleChange}
						handleCheckbox={handleCheckbox}
						handleDateRange={handleDateRange}
						handleDueDate={handleDueDate}
						handleDueTime={handleDueTime}
						handleShift={handleShift}
						handleSettings={handleTaskSettings}
						handleCycleOptions={handleCycleOptions}
						currentUser={currentUser}
						currentFacility={currentFacility}
						setFormState={setFormState}
						handleAdvancedCompletion={handleAdvancedCompletion}
						openNewResidentTaskModal={initNewTaskModal}
						// action handlers
						saveSectionChanges={saveSectionChanges}
						saveAllChanges={saveAllChanges}
						saveNewTaskNote={saveNewTaskNote}
						cancelChanges={cancelSectionChanges}
						cancelAllChanges={cancelAllChanges}
						deleteTask={deleteTask}
						syncTaskNotes={syncTaskNotes}
						photoSrc={getPhotoSrcFromData(task, photosMap)}
					/>
				</MobileModal>
			)}
		</>
	);
};

export default TimeViewTaskListEntry;

TimeViewTaskListEntry.defaultProps = {};

TimeViewTaskListEntry.propTypes = {};
