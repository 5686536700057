import React, { useState, useEffect } from "react";
import styles from "../../css/timeview/TimeViewTaskSummary.module.scss";
import { PropTypes } from "prop-types";
import { getTimeViewCountsByStatus } from "../../helpers/utils_timeview";
import { isEmptyArray } from "../../helpers/utils_types";

const handleCount = (dailyTasks = [], countsObj, type) => {
	if (isEmptyArray(dailyTasks)) return 0;
	return countsObj[type];
};

const TimeViewPRNCount = ({ prnCount }) => {
	return (
		<div className={styles.TimeViewPRNCount}>
			<h4 className={styles.TimeViewPRNCount_heading}>PRN</h4>
			<h4 className={styles.TimeViewPRNCount_val} value={prnCount}>
				{prnCount}
			</h4>
		</div>
	);
};

/**
 * 'Task Counts Summary':
 * - Includes all tasks sorted & counted by status
 */
const defaultTaskCounts = {
	total: 0,
	pastDue: 0,
	exceptions: 0,
	notComplete: 0,
	complete: 0,
};

const TimeViewTaskSummary = ({
	dailyTasks = [],
	shiftTimes = [],
	hasUpdated = false,
}) => {
	const [taskCounts, setTaskCounts] = useState({
		...getTimeViewCountsByStatus(dailyTasks, shiftTimes),
	});

	// "watches" for changes and updates status counts
	useEffect(() => {
		if (hasUpdated) {
			return setTaskCounts({
				...getTimeViewCountsByStatus(dailyTasks, shiftTimes),
			});
		}
	}, [dailyTasks, hasUpdated, shiftTimes]);

	return (
		<article className={styles.TimeViewTaskSummaryWrapper}>
			<div className={styles.TimeViewTaskSummary}>
				{/* TOTAL TASKS */}
				<div className={styles.TimeViewTaskSummary_tile}>
					<h4 className={styles.TimeViewTaskSummary_tile_heading}>
						<b>Tasks</b>
					</h4>
					<h4
						className={styles.TimeViewTaskSummary_tile_val}
						value={taskCounts.total}
					>
						{handleCount(dailyTasks, taskCounts, "total")}
					</h4>
				</div>
				{/* COMPLETED */}
				<div className={styles.TimeViewTaskSummary_tile}>
					<h4 className={styles.TimeViewTaskSummary_tile_heading_green}>
						Completed
					</h4>
					<h4
						className={styles.TimeViewTaskSummary_tile_val_green}
						value={taskCounts.complete}
					>
						{handleCount(dailyTasks, taskCounts, "complete")}
					</h4>
				</div>
				{/* NOT COMPLETE */}
				<div className={styles.TimeViewTaskSummary_tile}>
					<h4 className={styles.TimeViewTaskSummary_tile_heading_yellow}>
						Not-Complete
					</h4>
					<h4
						className={styles.TimeViewTaskSummary_tile_val_altYellow}
						value={taskCounts.notComplete}
					>
						{handleCount(dailyTasks, taskCounts, "notComplete")}
					</h4>
				</div>
				{/* PAST DUE */}
				<div className={styles.TimeViewTaskSummary_tile}>
					<h4 className={styles.TimeViewTaskSummary_tile_heading_purple}>
						Past Due
					</h4>
					<h4
						className={styles.TimeViewTaskSummary_tile_val_purple}
						value={taskCounts.pastDue}
					>
						{handleCount(dailyTasks, taskCounts, "pastDue")}
					</h4>
				</div>
				{/* EXCEPTIONS */}
				<div className={styles.TimeViewTaskSummary_tile}>
					<h4 className={styles.TimeViewTaskSummary_tile_heading_red}>
						Exceptions
					</h4>
					<h4
						className={styles.TimeViewTaskSummary_tile_val_red}
						value={taskCounts.exceptions}
					>
						{handleCount(dailyTasks, taskCounts, "exceptions")}
					</h4>
				</div>
			</div>
			<div className={styles.TimeViewTaskSummaryWrapper_prn}>
				<TimeViewPRNCount prnCount={taskCounts?.prn} />
			</div>
		</article>
	);
};

export default TimeViewTaskSummary;

TimeViewTaskSummary.defaultProps = {};

TimeViewTaskSummary.propTypes = {};
