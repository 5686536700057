import React, { useRef, useState, useEffect } from "react";
import styles from "../../css/timeview/TimeViewTaskList.module.scss";
import sprite from "../../assets/icons/settings.svg";
import sprite2 from "../../assets/icons/filters.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { sortDateAscByKey } from "../../helpers/utils_processing";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	formatResidentNameTimeView,
	formatResidentsNameTimeView,
	matchResidentByID,
} from "../../helpers/utils_residents";
import {
	addTaskIDsToTasks,
	createTimeViewTasksPerShift,
	determineTaskShift,
	generateNewTimeViewTask,
	generateNewTimeViewTasks,
	getResidentFromNameString,
	sortDailyTasksBy,
} from "../../helpers/utils_timeview";
import {
	RECURRING_TYPES as recurringTypes,
	ADLS as adlCategories,
} from "../../helpers/utils_options";
import { format } from "date-fns";
import {
	getTaskID,
	isPastDueByTime,
	isPastDueByTimeOrShift,
} from "../../helpers/utils_tasks";
import {
	createTaskPerShift,
	saveUnscheduledUpdates,
} from "../../helpers/utils_unscheduled";
import { applyDueTimeToDate } from "../../helpers/utils_dates";

// components
import TimeViewTaskListEntry from "./TimeViewTaskListEntry";
import SearchInput from "./SearchInput";
import MobileModal from "../shared/MobileModal";
import TimeViewResidentDetails from "./TimeViewResidentDetails";
import ResidentTaskHandler from "./ResidentTaskHandler";
import ScrollToTop from "../app/ScrollToTop";
import CreateTaskHandler from "./CreateTaskHandler";
import TimeViewSortController from "./TimeViewSortController";
import TimeViewTaskSummary from "./TimeViewTaskSummary";

/**
 * Extracts base64 resident photo string from 'photosMap'
 * @param {Object} task - Custom-shaped and abbreviated task data
 * @param {Object} photosMap - A dictionary of resident photos by resident ID
 * @returns {String} - Returns base64 resident photo string
 */
const getPhotoSrcFromData = (task = {}, photosMap = {}) => {
	const { ResidentId: id } = task;
	const photoRecord = photosMap?.[id];
	const photoSrc = photoRecord?.photoSrc ?? null;

	return photoSrc;
};

const getSelectedResident = (task) => {
	const {
		ResidentId: residentID,
		FirstName: firstName,
		LastName: lastName,
		RoomNum: roomNum,
		ResidentType: residentStatus,
		FloorUnit: floorUnit,
	} = task;

	const resident = {
		residentID,
		firstName,
		lastName,
		roomNum,
		residentStatus,
		floorUnit,
	};

	return { ...resident };
};

const isNewTask = (task, newTaskIDs) => {
	const { AssessmentUnscheduleTaskId: taskID } = task;
	const isNew = newTaskIDs.includes(taskID);

	return isNew;
};

const hasTaskListUpdated = (propTasks, stateTasks) => {
	const propTasksJSON = JSON.stringify(propTasks);
	const stateTasksJSON = JSON.stringify(stateTasks);
	const hasChanges = propTasksJSON !== stateTasksJSON;
	console.log(`Has Changes?`, hasChanges);
	return hasChanges;
};

const isSameTaskList = (tasksFromParent, childTasks) => {
	const sortedParent = sortDateAscByKey("ScheduledDate", tasksFromParent);
	const parent = JSON.stringify(sortedParent);
	const child = JSON.stringify(childTasks);

	return parent === child;
};

const noTasks = (tasksFromProps, tasksFromChild) => {
	const emptyTasks =
		isEmptyArray(tasksFromProps) && isEmptyArray(tasksFromChild);

	return emptyTasks;
};

const noMatchingTasks = (tasksFromProps, tasksFromChild) => {
	const noMatches =
		!isEmptyArray(tasksFromProps) && isEmptyArray(tasksFromChild);

	return noMatches;
};

const getNoTasksMsg = (propsTasks, childTasks) => {
	const allEmpty = noTasks(propsTasks, childTasks);
	const noMatches = noMatchingTasks(propsTasks, childTasks);

	if (allEmpty) return `No tasks found.`;
	if (noMatches) return `No matching tasks found.`;
	return `No tasks.`;
};

const shouldPersistScroll = (taskListRef, scrollState = {}) => {
	return (
		!isEmptyVal(taskListRef.current) &&
		!isEmptyVal(scrollState.active) &&
		!isEmptyVal(scrollState.scrollPos)
	);
};

const advancedSearch = (val, options = []) => {
	val = val?.toLowerCase();

	return options.filter((option) => {
		// checks: 'Resident', 'ADL' and 'Task' fields

		if (
			option?.["FirstName"]?.toLowerCase().includes(val) ||
			option?.["FirstName"]?.toLowerCase().startsWith(val) ||
			option?.["LastName"]?.toLowerCase().includes(val) ||
			option?.["LastName"]?.toLowerCase().startsWith(val) ||
			option?.["ADL"]?.toLowerCase().includes(val) ||
			option?.["ADL"]?.toLowerCase().startsWith(val) ||
			option?.["TaskDescription"]?.toLowerCase().startsWith(val) ||
			option?.["TaskDescription"]?.toLowerCase().includes(val)
		) {
			return option;
		} else {
			return null;
		}
	});
};

const TaskInstructions = ({ children }) => {
	return (
		<div className={styles.TaskInstructions}>
			<div>Click the "Advanced" button to view task details</div>
			<div>
				Click a resident's photo to view that resident and/or create a task.
			</div>
			{children}
		</div>
	);
};

const TaskListOptionsPanel = ({
	facilityName = "",
	taskList = [],
	allTasks = [],
	scrollToTop,
	initSort,
	initNewTask,
}) => {
	return (
		<div className={styles.TaskListOptionsPanel}>
			<div className={styles.TaskListOptionsPanel_topRow}>
				<div className={styles.TaskListOptionsPanel_topRow_facility}>
					{facilityName}
				</div>
			</div>
			<div className={styles.TaskListOptionsPanel_bottomRow}>
				<div className={styles.TaskListOptionsPanel_left}>
					{/* <div className={styles.TaskListOptionsPanel_left_count}>
						<b>{taskList?.length}</b> of <b>{allTasks?.length}</b> tasks
					</div> */}
					<button
						type="button"
						onClick={initNewTask}
						className={styles.TaskListOptionsPanel_left_newTask}
					>
						Create Task
					</button>
				</div>
				<div className={styles.TaskListOptionsPanel_right}>
					<button
						type="button"
						onClick={initSort}
						className={styles.TaskListOptionsPanel_right_sort}
					>
						<svg className={styles.TaskListOptionsPanel_right_sort_icon}>
							<use xlinkHref={`${sprite2}#icon-sort`}></use>
						</svg>
						<span>Sort</span>
					</button>
					<button
						type="button"
						onClick={scrollToTop}
						className={styles.TaskListOptionsPanel_right_top}
					>
						↑ Top
					</button>
				</div>
			</div>
		</div>
	);
};

const NoTasksFound = ({ msg }) => {
	return (
		<div className={styles.NoTasksFound}>
			<div className={styles.NoTasksFound_msg}>{msg}</div>
		</div>
	);
};

const initialNewTask = {
	isLocked: false,
	newTaskName: "", // name
	newTaskCategory: "", // ADL
	newTaskNotes: "",
	newTaskShift: "", // scheduled shift
	scheduledDueDate: new Date(),
	// scheduledDueTime: "03:00 PM",
	scheduledDueTime: "",
	// repeat settings
	isRecurring: false,
	recurringType: "Never", // daily, weekly, etc.
	recurringCycle: "1", // every "2" weeks, every 2 months etc.
	recurringCycleOption: "",
	startDate: format(new Date(), "MM/DD/YYYY"),
	endDate: "",
	isRecurringSun: false,
	isRecurringMon: false,
	isRecurringTue: false,
	isRecurringWed: false,
	isRecurringThu: false,
	isRecurringFri: false,
	isRecurringSat: false,
	isRecurringAM: false,
	isRecurringPM: false,
	isRecurringNOC: false,
	selectedResident: "",
};

const TimeViewTaskList = ({
	tasks = [],
	currentUser = {},
	currentFacility = {},
	globalState = {},
	filterSelections = {},
	dispatchToState,
	dispatchAlert,
	syncTaskChanges,
	syncNewTask,
	handleActive,
	hasUpdated = false,
	scrollState = {},
	initialSortState = {},
	newTaskIDs = [],
	syncSortState,
}) => {
	const { app } = globalState;
	const { residents: allResidents } = globalState?.globals;
	const { photosMap, residents } = currentFacility;
	const searchRef = useRef();
	const taskListRef = useRef();
	// 'NEW' task fields
	const { formState, setFormState, handleChange, handleCheckbox } = useForm({
		...initialNewTask,
	});
	const { values, touched } = formState;
	// new task submission state
	const [isSavingNewTask, setIsSavingNewTask] = useState(false);
	// task list search state(s)
	const [searchVal, setSearchVal] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	// sorting sync state
	const [showSortingOptions, setShowSortingOptions] = useState(false);
	const [sortByState, setSortByState] = useState({
		...initialSortState,
		// isSorted: false,
		// sortBy: "ADL",
		// hideIndependent: false,
	});
	const { sortBy, isSorted, hideIndependent } = sortByState;
	// list of tasks sorted by scheduled date
	// ↓ previous code ↓
	const [taskList, setTaskList] = useState([
		...sortDailyTasksBy(sortBy, tasks),
	]);

	// show resident info modal & resident selection state(s)
	const [showResidentInfoModal, setShowResidentInfoModal] = useState(false);
	const [selectedResident, setSelectedResident] = useState({});
	// new task modal (resident-specific)
	const [showCreateResidentTaskModal, setShowCreateResidentTaskModal] =
		useState(false);
	// new task modal (generic/any resident)
	const [showNewTaskModal, setShowNewTaskModal] = useState(false);
	const [wasNewTaskCreated, setWasNewTaskCreated] = useState(false);

	// opens 'sorting options' modal (eg. <TimeViewSortController/>)
	const initSort = () => {
		setShowSortingOptions(true);
	};

	const initNewTask = () => {
		setShowNewTaskModal(true);
	};
	const closeNewTaskModal = () => {
		setShowNewTaskModal(false);
	};

	// opens resident details/info modal
	const openResidentInfo = (selection = {}) => {
		// 'selection' is a task, extract resident info from it
		const resident = getSelectedResident(selection);
		const photoSrc = getPhotoSrcFromData(selection, photosMap);

		setShowResidentInfoModal(true);
		setSelectedResident({ ...resident, photoSrc: photoSrc });
	};
	// closes resident details/info modal
	const closeResidentInfo = () => {
		setShowResidentInfoModal(false);
	};

	// open resident-specific NEW task modal
	const openCreateResidentTaskModal = (residentInfo = {}) => {
		console.log(`Create Task Modal(residentInfo):`, residentInfo);

		setSelectedResident({
			...selectedResident,
			...residentInfo,
		});
		setShowResidentInfoModal(false);
		setShowCreateResidentTaskModal(true);
	};
	// close resident-specific NEW task modal
	const closeCreateResidentTaskModal = () => {
		setFormState({ ...initialNewTask });
		setShowCreateResidentTaskModal(false);
	};

	const handleResidentSelection = (name, val) => {
		if (isEmptyVal(val)) {
			// clear state, if selection ('val') is empty
			setSelectedResident({});
			return setFormState({
				...formState,
				values: {
					...values,
					selectedResident: val,
				},
				touched: {
					...touched,
					selectedResident: false,
				},
			});
		} else {
			// get resident record from name string
			const resident = getResidentFromNameString(val, residents);
			const record = matchResidentByID(resident?.id, allResidents);
			console.log("resident", resident);

			setSelectedResident({
				...resident,
				firstName: record?.FirstName,
				lastName: record?.LastName,
				age: record?.Age,
				dateOfBirth: record?.DateOfBirth,
				residentID: resident?.id,
				floorUnit: record?.unitType ?? record?.floorUnit ?? resident?.unitType,
				residentStatus: "Active",
			});
			return setFormState({
				...formState,
				values: {
					...values,
					selectedResident: val,
				},
				touched: {
					...touched,
					selectedResident: true,
				},
			});
		}
	};

	const handleDueDate = (name, dueDate) => {
		// perhaps extract time selections???
		setFormState({
			...formState,
			values: {
				...values,
				[name]: dueDate,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	const handleDateRange = ({ startDate, endDate }) => {
		setFormState({
			...formState,
			values: {
				...values,
				startDate,
				endDate,
			},
		});
	};

	const handleSettings = (name, val) => {
		// toggle 'isRecurring' to true, when changing recurring settings
		if (name === "recurringType") {
			const isRecurring = val !== "Never";
			return setFormState({
				...formState,
				values: {
					...values,
					[name]: val,
					isRecurring: isRecurring,
				},
			});
		} else {
			return setFormState({
				...formState,
				values: {
					...values,
					[name]: val,
				},
			});
		}
	};

	// handles shift selections
	const handleRecurrences = (name) => {
		const currentVal = values[name];

		setFormState({
			...formState,
			values: {
				...values,
				[name]: !currentVal,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	const handleTaskSearch = (e) => {
		const { value } = e.target;

		if (isEmptyVal(value)) {
			setSearchVal("");
			setIsSearching(false);
			return setTaskList([...sortDailyTasksBy(sortByState?.sortBy, tasks)]);
		} else {
			setSearchVal(value);

			setIsSearching(true);
			return setTaskList([...advancedSearch(value, tasks)]);
		}
	};

	const clearSearch = (e) => {
		setSearchVal("");
		// setTaskList([...sortDateAscByKey("ScheduledDate", tasks)]);

		// ↓ ADDED TO FIX SORT STATE NOT PERSISTING AFTER CLEARING SEARCH ↓
		const sortedList = sortDailyTasksBy(sortByState?.sortBy, tasks);
		setTaskList([...sortedList]);
		searchRef.current.focus();
	};

	// passes new 'client' task to parent & adds to list
	// then resets local state for fresh props

	// handles creating multiple tasks at once (eg. AM, PM, NOC)
	const addNewTasksToList = (newTasks = []) => {
		const { scheduledDueTime: time } = values;
		const dueDate = applyDueTimeToDate(time, new Date());
		// const taskInfo = {
		// 	FirstName: selectedResident?.firstName ?? selectedResident?.FirstName,
		// 	LastName: selectedResident?.lastName ?? selectedResident?.LastName,
		// 	RoomNum: selectedResident?.roomNum ?? selectedResident?.RoomNum,
		// 	ResidentId: selectedResident?.residentID ?? selectedResident?.ResidentId,
		// 	FloorUnit: selectedResident?.floorUnit ?? selectedResident?.FloorUnit,
		// 	ResidentType:
		// 		selectedResident?.residentStatus ?? selectedResident?.ResidentType,
		// 	ScheduledDate: dueDate,
		// 	CreatedBy: currentUser?.userID,
		// 	IsNewTask: true,
		// };
		const taskInfo = {
			FirstName: selectedResident?.FirstName ?? selectedResident?.firstName,
			LastName: selectedResident?.LastName ?? selectedResident?.lastName,
			RoomNum: selectedResident?.roomNum ?? selectedResident?.RoomNum,
			ResidentId: selectedResident?.residentID ?? selectedResident?.ResidentId,
			FloorUnit: selectedResident?.floorUnit ?? selectedResident?.FloorUnit,
			ResidentType:
				selectedResident?.residentStatus ?? selectedResident?.ResidentType,
			ScheduledDate: dueDate,
			CreatedBy: currentUser?.userID,
			IsNewTask: true,
		};
		// generate client-side tasks
		const clientTasks = generateNewTimeViewTasks(values, newTasks, taskInfo);
		// append to start of list???
		// task is added to start of list via being passed down from parent

		setWasNewTaskCreated(true);
		setFormState({ ...initialNewTask });
		syncNewTask(clientTasks, sortByState);
	};

	// create new resident-specific task(s) - tied to <ResidentTaskHandler/>
	const createResidentTask = async (e) => {
		const { token, userID } = currentUser;
		const residentID =
			selectedResident?.residentID ??
			selectedResident?.ResidentId ??
			selectedResident?.id;
		const { scheduledDueTime: time } = values;
		// apply 'time' to scheduledDate in local tz
		const dueDate = applyDueTimeToDate(time, new Date());
		// shift name based off scheduled date
		const shiftName = determineTaskShift(currentFacility?.shifts ?? [], {
			...values,
			dueDate: dueDate,
		});

		// create multi-task(s), if recurring is selected
		const pendingQueue = createTaskPerShift(residentID, userID, {
			...values,
			dueDate: dueDate,
			shifts: currentFacility?.shifts,
			// newly added
			newTaskShift: shiftName,
		});

		// const arrayOfIDs = [];
		const arrayOfIDs = await saveUnscheduledUpdates(token, pendingQueue);

		// test value
		// const success = true;

		setIsSavingNewTask(true);

		if (!isEmptyArray(arrayOfIDs) && arrayOfIDs !== null) {
			// apply taskIDs to 'pendingQueue' from API response
			const taskWithIDs = addTaskIDsToTasks(arrayOfIDs, pendingQueue);
			setIsSavingNewTask(false);
			setShowCreateResidentTaskModal(false);

			console.log("taskWithIDs", taskWithIDs);

			dispatchAlert("SUCCESS", {
				heading: "Success!",
				subheading: `${arrayOfIDs?.length} tasks were created`,
			});
			setWasNewTaskCreated(true);
			setFormState({ ...initialNewTask });
			return addNewTasksToList(taskWithIDs);
		} else {
			// trigger alert & clear form state
			setIsSavingNewTask(false);
			return dispatchAlert("ERROR", {
				heading: "Error!",
				subheading: `Could NOT create task(s).`,
			});
			// ENABLE BELOW FOR TESTING
			// dispatchAlert("ERROR", {
			// 	heading: "Error!",
			// 	subheading: `Could NOT create task(s).`,
			// });
			// return addNewTasksToList(pendingQueue);
		}
	};

	const cancelResidentTask = (e) => {
		setShowCreateResidentTaskModal(false);
		setFormState({ ...initialNewTask });
		// setSelectedResident({})
	};

	// from fixed 'Create Task' button - tied to <CreateTaskHandler/>
	const createGenericTask = async (e) => {
		const { token, userID } = currentUser;
		const residentID =
			selectedResident?.residentID ??
			selectedResident?.ResidentId ??
			selectedResident?.id;
		const { scheduledDueTime: time } = values;
		// apply 'time' to scheduledDate in local tz
		const dueDate = applyDueTimeToDate(time, new Date());
		// shift name based off scheduled date
		const shiftName = determineTaskShift(currentFacility?.shifts ?? [], {
			...values,
			dueDate: dueDate,
		});

		// create multi-task(s), if recurring/multi-shift(s) is selected
		// const pendingQueue = createTimeViewTasksPerShift(
		// 	residentID,
		// 	userID,
		// 	shifts,
		// 	{
		// 		...values,
		// 		dueDate: dueDate,
		// 	}
		// );
		const pendingQueue = createTaskPerShift(residentID, userID, {
			...values,
			dueDate: dueDate,
			shifts: currentFacility?.shifts,
			// NEWLY ADDED
			newTaskShift: shiftName,
		});
		console.log(`Pending Queue(Resident Task):`, pendingQueue);
		const arrayOfIDs = await saveUnscheduledUpdates(token, pendingQueue);

		// test value
		// const success = true;

		setIsSavingNewTask(true);

		if (!isEmptyArray(arrayOfIDs) && arrayOfIDs !== null) {
			// apply taskIDs to 'pendingQueue' from API response
			const taskWithIDs = addTaskIDsToTasks(arrayOfIDs, pendingQueue);
			setIsSavingNewTask(false);
			setShowNewTaskModal(false);

			dispatchAlert("SUCCESS", {
				heading: "Success!",
				subheading: `${arrayOfIDs?.length} tasks were created`,
			});
			setWasNewTaskCreated(true);
			setFormState({ ...initialNewTask });
			return addNewTasksToList(taskWithIDs);
		} else {
			// trigger alert & clear form state
			setIsSavingNewTask(false);
			return dispatchAlert("ERROR", {
				heading: "Error!",
				subheading: `Could NOT create task(s).`,
			});
		}
	};

	const cancelGenericTask = (e) => {
		setShowNewTaskModal(false);
		setFormState({ ...initialNewTask });
		setSelectedResident({});
	};

	// scrolls to top of 'taskList' container
	const scrollToTop = (e) => {
		e.preventDefault();
		// const { top, left } = scrollOpts.position;
		const el = taskListRef?.current;
		el.scrollTo({
			// top: top,
			// left: left,
			top: 0,
			left: 0,
			behavior: "smooth",
		});
	};

	const syncAndApplySorting = (sortBy = {}) => {
		console.log(`Sort By:`, sortBy);
		// sync selections to state
		setSortByState({
			isSorted: true,
			sortBy: sortBy?.sortBy,
			hideIndependent: sortBy?.hideIndependent,
		});
		// apply sorting to 'taskList'
		syncSortState({
			isSorted: isEmptyVal(sortBy) ? false : true,
			sortBy: sortBy?.sortBy,
			hideIndependent: sortBy?.hideIndependent,
		});
		applySortingSelecions(sortBy);
		setShowSortingOptions(false);
	};

	// applies sorting selection(s) to task list
	const applySortingSelecions = (sortState = {}) => {
		// resets to 'ADL' sorting (A-Z) (previously, disregard)
		// now defaults to current sort state, if no selection/change is made
		if (isEmptyVal(sortState?.sortBy)) {
			const clonedList = [...tasks];
			// const sortedList = sortDailyTasksBy("ADL", clonedList);
			const sortedList = sortDailyTasksBy(sortState?.sortBy, clonedList);
			return setTaskList(sortedList);
		} else {
			const clonedList = [...taskList];
			const sortedList = sortDailyTasksBy(sortState?.sortBy, clonedList);
			return setTaskList(sortedList);
		}
	};

	// persists scroll position after 'advanced' update
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (shouldPersistScroll(taskListRef, scrollState) && !wasNewTaskCreated) {
			taskListRef.current.scrollTop = scrollState.scrollPos;
		}

		return () => {
			isMounted = false;
		};
	}, [scrollState, wasNewTaskCreated]);

	if (isEmptyArray(taskList) && isEmptyArray(tasks)) {
		return <NoTasksFound msg="No tasks matching that criteria." />;
	}
	return (
		<>
			<div className={styles.TimeViewTaskList}>
				<div className={styles.TimeViewTaskList_taskCount}>
					Viewing <b>{taskList?.length}</b> of <b>{tasks?.length}</b> tasks
				</div>
				<div className={styles.TimeViewTaskList_settings}>
					{/* CONTROLS: SHOW MORE/LESS */}
					<div className={styles.TimeViewTaskList_settings_search}>
						<SearchInput
							searchRef={searchRef}
							name="taskSearch"
							id="taskSearch"
							searchVal={searchVal}
							handleSearch={handleTaskSearch}
							placeholder="Search by resident, ADL or task..."
						/>
						<button
							type="button"
							onClick={clearSearch}
							className={styles.TimeViewTaskList_settings_search_btn}
						>
							<svg className={styles.TimeViewTaskList_settings_search_btn_icon}>
								<use xlinkHref={`${sprite}#icon-clearclose`}></use>
							</svg>
						</button>
					</div>
				</div>
				{/* USER-RELATED INSTRUCTIONS */}
				<TaskInstructions />
				{/* TASK COUNT STATUS SUMMARY(S) */}
				<TimeViewTaskSummary
					key={`SUMMARY-COUNTS-${JSON.stringify(taskList)}`}
					dailyTasks={taskList}
					shiftTimes={currentFacility?.shifts ?? []}
					hasUpdated={hasUpdated}
				/>

				{/* TOP BLACK BAR ON TASK LIST */}
				<TaskListOptionsPanel
					allTasks={tasks}
					taskList={taskList}
					scrollToTop={scrollToTop}
					residents={residents}
					facilityName={currentFacility?.communityName}
					initNewTask={initNewTask}
					initSort={initSort}
				/>

				<div className={styles.TimeViewTaskList_list} ref={taskListRef}>
					{isEmptyArray(taskList) && (
						<NoTasksFound msg={getNoTasksMsg(tasks, taskList)} />
					)}
					{!isEmptyArray(taskList) &&
						taskList.map((task, idx) => (
							<TimeViewTaskListEntry
								key={`TV-TASK-${getTaskID(task)}--${idx}-${isNewTask(
									task,
									newTaskIDs
								)}`}
								isPastDue={isPastDueByTime(task)}
								isNewTask={isNewTask(task, newTaskIDs)}
								task={task}
								currentUser={currentUser}
								currentFacility={currentFacility}
								syncTaskChanges={syncTaskChanges}
								dispatchAlert={dispatchAlert}
								openResidentInfo={openResidentInfo}
								openNewResidentTaskModal={openCreateResidentTaskModal}
								taskListRef={taskListRef}
								scrollState={scrollState}
								handleActive={handleActive}
								sortByState={sortByState}
								wasNewTaskCreated={wasNewTaskCreated}
							/>
						))}
				</div>
				<ScrollToTop />
			</div>

			{/* SORTING CONTROLLER MODAL */}
			{showSortingOptions && (
				<TimeViewSortController
					title="Sort Tasks"
					sortState={sortByState}
					syncAndApplySorting={syncAndApplySorting}
					closeModal={() => setShowSortingOptions(false)}
				/>
			)}

			{/* RESIDENT INFO VIA PHOTO CLICK TARGET */}
			{showResidentInfoModal && (
				<MobileModal
					key={`TV-RESIDENT-DETAILS`}
					title="Viewing Resident Details"
					closeModal={closeResidentInfo}
				>
					<TimeViewResidentDetails
						key={`TV-RESIDENT-INFO`}
						selectedResident={selectedResident}
						currentFacility={currentFacility}
						currentUser={currentUser}
						openNewResidentTaskModal={openCreateResidentTaskModal}
					/>
				</MobileModal>
			)}

			{/* NEW TASK MODAL (RESIDENT-SPECIFIC) */}
			{showCreateResidentTaskModal && (
				<MobileModal
					key={`TV-CREATE-RESIDENT-TASK`}
					title={`Create Task for ${formatResidentNameTimeView(
						selectedResident
					)}`}
					closeModal={(e) => closeCreateResidentTaskModal(e)}
				>
					<ResidentTaskHandler
						key={`RESIDENT-TASK-NEW`}
						vals={values}
						formState={formState}
						handleChange={handleChange}
						handleCheckbox={handleCheckbox}
						handleSettings={handleSettings}
						handleDueDate={handleDueDate}
						handleDueTime={handleDueDate}
						handleDateRange={handleDateRange}
						handleRecurrences={handleRecurrences}
						selectedResident={selectedResident}
						currentUser={currentUser}
						currentFacility={currentFacility}
						recurringTypes={recurringTypes}
						adlCategories={adlCategories}
						isSubmitting={isSavingNewTask}
						createResidentTask={createResidentTask}
						cancelResidentTask={cancelResidentTask}
					/>
				</MobileModal>
			)}

			{/* GENERIC NEW TASK MODAL (NO-SPECIFIC-RESIDENT) */}
			{showNewTaskModal && (
				<MobileModal
					key={`TV-NEW-GENERIC-TASK`}
					title={`Create New Task for ${selectedResident?.name ?? ""}`}
					closeModal={closeNewTaskModal}
				>
					<CreateTaskHandler
						key={`GENERIC-TASK-NEW`}
						vals={values}
						formState={formState}
						photosMap={photosMap}
						handleChange={handleChange}
						handleCheckbox={handleCheckbox}
						handleSettings={handleSettings}
						handleDueDate={handleDueDate}
						handleDueTime={handleDueDate}
						handleDateRange={handleDateRange}
						handleRecurrences={handleRecurrences}
						createResidentTask={createGenericTask}
						cancelResidentTask={cancelGenericTask}
						handleResidentSelection={handleResidentSelection}
						currentUser={currentUser}
						currentFacility={currentFacility}
						selectedResident={selectedResident}
						residentList={formatResidentsNameTimeView(residents)}
						adlCategories={adlCategories}
						filterSelections={filterSelections}
						recurringTypes={recurringTypes}
					/>
				</MobileModal>
			)}
		</>
	);
};

export default TimeViewTaskList;

TimeViewTaskList.defaultProps = {};

TimeViewTaskList.propTypes = {};
