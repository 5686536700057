const STATUS = ["COMPLETE", "NOT-COMPLETE", "PAST-DUE", "EXCEPTION"];
const STATUS_TYPES = [`COMPLETE`, `NOT-COMPLETE`, `PAST-DUE`, `EXCEPTIONS`];

const SHIFTS = ["AM", "PM", "NOC"];

const FALLBACK_SHIFTS = [
	{ AssessmentShiftId: 1, Name: "AM" },
	{ AssessmentShiftId: 2, Name: "PM" },
	{ AssessmentShiftId: 3, Name: "NOC" },
];

const RECURRING_TYPES = [
	"Never",
	"Daily",
	"Weekly",
	"Monthly",
	"Quarterly",
	"Yearly",
	"This day every month",
];

const DAYS = [
	"Sunday",
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
];
const DAYS_SLICED = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

////////////////////////////////////////////////////////
//////////////////// REPORT OPTIONS ////////////////////
////////////////////////////////////////////////////////

const UNIT_TYPES = ["Memory Care", "Independent", "Assisted Living"];
const PARAMS = ["FacilityID", "CompletionStartDate", "CompletionEndDate"];
const REPORTS = ["Completion Report", "Exception Report"];
const FILTERS = ["By Shift", "By Resolution", "By Room #"];
const SORTS = [
	"By Resident",
	"By Staff",
	"By Shift",
	"By Reason",
	"By TimeStamp",
];

/**
 * Used for ALL reports both client-side & server-side (SSRS)
 */
const DATE_RANGE_TYPES = [
	"Custom Range",
	"By Month",
	"By Quarter",
	"By Year",
	"By Date",
	"Last 30 days",
	"Last year",
	"Last quarter",
	"Today",
];
const RANGE_TYPES = ["Custom Range", "By Day", "By Month", "By Quarter"];
// USED FOR EXCEPTIONS (ie "MISSED-EVENT", and sometimes "NOT-COMPLETE")
const REASONS = [
	"COMPLETED-ON-LATER-SHIFT",
	"CANCELLED-BY-SUPERVISOR",
	"NOT-NEEDED",
	"MISSED-FORGOTTEN",
	"INSUFFICIENT-TIME-TO-COMPLETE",
	"COMPLETED-AS-SCHEDULED",
	"NOT-COMPLETED",
	"MISSED",
	"FORGOTTEN",
];

const RESOLUTIONS = [
	"COMPLETED",
	"COMPLETED-REASSESSMENT-NEEDED",
	"TBC-NEXT-SHIFT",
	"RESIDENT-DENIED",
	"CANCELLED-BY-SUPERVISOR",
	"PENDING",
	"TBC-NEXT-SHIFT-NEEDS",
];

const BASE_EXCEPTION_TYPES = [
	"Resident Unavailable",
	"Refused Care",
	"Not In Room",
	"Resident Sleeping",
];

const FACILITY_EXCEPTION_TYPES = [
	{
		AssessmentFacilityExceptionId: 773,
		FacilityId: "8b25b5c3-7201-4437-98b3-4f63000696fa",
		AssessmentExceptionId: 1,
		Description: null,
		IsActive: true,
		CreatedDate: "2020-09-02T03:27:51.577Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageSQL",
		CreatedStation: "DEVPEGGY",
		ModifiedDate: "2020-09-02T03:27:51.577Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageSQL",
		ModifiedStation: "DEVPEGGY",
		BaseName: "ResidentUnavailable",
		BaseDescription: "Resident Unavailable",
	},
	{
		AssessmentFacilityExceptionId: 774,
		FacilityId: "8b25b5c3-7201-4437-98b3-4f63000696fa",
		AssessmentExceptionId: 2,
		Description: null,
		IsActive: true,
		CreatedDate: "2020-09-02T03:27:51.577Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageSQL",
		CreatedStation: "DEVPEGGY",
		ModifiedDate: "2020-09-02T03:27:51.577Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageSQL",
		ModifiedStation: "DEVPEGGY",
		BaseName: "RefusedCare",
		BaseDescription: "Refused Care",
	},
	{
		AssessmentFacilityExceptionId: 775,
		FacilityId: "8b25b5c3-7201-4437-98b3-4f63000696fa",
		AssessmentExceptionId: 3,
		Description: null,
		IsActive: true,
		CreatedDate: "2020-09-02T03:27:51.577Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageSQL",
		CreatedStation: "DEVPEGGY",
		ModifiedDate: "2020-09-02T03:27:51.577Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageSQL",
		ModifiedStation: "DEVPEGGY",
		BaseName: "NotInRoom",
		BaseDescription: "Not In Room",
	},
	{
		AssessmentFacilityExceptionId: 776,
		FacilityId: "8b25b5c3-7201-4437-98b3-4f63000696fa",
		AssessmentExceptionId: 4,
		Description: null,
		IsActive: true,
		CreatedDate: "2020-09-02T03:27:51.577Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageSQL",
		CreatedStation: "DEVPEGGY",
		ModifiedDate: "2020-09-02T03:27:51.577Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageSQL",
		ModifiedStation: "DEVPEGGY",
		BaseName: "ResidentSleeping",
		BaseDescription: "Resident Sleeping",
	},
];

// REPORT SORTING OPTION: BY ADL
const ADLS = [
	"Ambulation",
	"Bathing",
	"Dressing",
	"Grooming",
	"Laundry",
	"Meals",
	"MedAssist",
	"Psychosocial",
	"SpecialCare",
	"StatusChecks",
	"Toileting",
	"Transfers",
	"Others",
	"PRN",
];

export {
	ADLS,
	SHIFTS,
	FALLBACK_SHIFTS,
	STATUS,
	STATUS_TYPES,
	REASONS,
	RESOLUTIONS,
	BASE_EXCEPTION_TYPES,
	FACILITY_EXCEPTION_TYPES,
};

// REPEATING/RECURRING TASK HELPERS //
export { RECURRING_TYPES, REPORTS };

// DATE-PICKER HELPERS //
export { DAYS_SLICED, DAYS };

// REPORT OPTIONS //
export { DATE_RANGE_TYPES, RANGE_TYPES, UNIT_TYPES, PARAMS, FILTERS, SORTS };
