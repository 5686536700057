import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/history/BatchActionsHandler.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import {
	getUsersByFacility,
	processAdvUserForTV,
	processAllAdvUsers,
} from "../../helpers/utils_user";
// components
import ButtonSM from "../shared/ButtonSM";
import HistoricalUserAssignment from "./HistoricalUserAssignment";
import { green, purple, red, yellow } from "../../helpers/utils_styles";
import {
	sortAlphaAscByKey,
	sortByAlphaAsc,
} from "../../helpers/utils_processing";
import Spinner from "../shared/Spinner";

const customCSS = {
	doneBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		fontSize: "1.6rem",
		backgroundColor: green[500],
	},
	exceptionBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		fontSize: "1.6rem",
		backgroundColor: red[600],
	},
	pastdueBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		fontSize: "1.6rem",
		backgroundColor: purple[600],
	},
	statusBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		fontSize: "1.6rem",
		backgroundColor: yellow[600],
	},
};

const getAvailableUsers = (records = []) => {
	if (isEmptyArray(records)) return [];

	// const newList = records.map((x) => {
	// 	// const name = `${x.lastName}, ${x.firstName} - ${x.userID}`;
	// 	const name = `${x.lastName}, ${x.firstName}`;
	// 	return name;
	// });
	// const sorted = sortByAlphaAsc(newList);
	// return sorted;
	const sorted = sortAlphaAscByKey("lastName", records);

	return sorted;
};

const BatchCompleteHandler = ({ taskState = {}, handleBatchCompletion }) => {
	const { selected, tasks } = taskState;
	const { all: allTasks } = tasks;
	const { all: selectedTasks } = selected;

	return (
		<div className={styles.BatchCompleteHandler}>
			<div className={styles.BatchCompleteHandler_action}>
				<ButtonSM
					customStyles={customCSS.doneBtn}
					handleClick={handleBatchCompletion}
				>
					Mark as done
				</ButtonSM>
			</div>
			<div className={styles.BatchCompleteHandler_msg}>
				You're about to mark {selectedTasks?.length} tasks as completed.
			</div>
		</div>
	);
};

const BatchRemoveExceptions = ({
	actionType,
	taskState = {},
	handleBatchExceptions,
}) => {
	const { selected, tasks } = taskState;
	const { all: allTasks } = tasks;
	const { all: selectedTasks } = selected;
	return (
		<div className={styles.BatchRemoveExceptions}>
			<div className={styles.BatchChangeStatus_action}>
				<ButtonSM
					customStyles={customCSS.exceptionBtn}
					handleClick={handleBatchExceptions}
				>
					Remove exception(s)
				</ButtonSM>
			</div>
			<div className={styles.BatchChangeStatus_msg}>
				You're about to change the status of {selectedTasks?.length} tasks.
			</div>
		</div>
	);
};
const BatchRemovePastDue = ({
	actionType,
	taskState = {},
	handleBatchPastDue,
}) => {
	const { selected, tasks } = taskState;
	const { all: allTasks } = tasks;
	const { all: selectedTasks } = selected;
	return (
		<div className={styles.BatchRemovePastDue}>
			<div className={styles.BatchRemovePastDue_action}>
				<ButtonSM
					customStyles={customCSS.pastdueBtn}
					handleClick={handleBatchPastDue}
				>
					Remove past-due
				</ButtonSM>
			</div>
			<div className={styles.BatchRemovePastDue_msg}>
				You're about to change the status of {selectedTasks?.length} tasks.
			</div>
		</div>
	);
};

const BatchChangeStatus = ({
	actionType,
	taskState = {},
	handleBatchStatus,
}) => {
	const { selected, tasks } = taskState;
	const { all: allTasks } = tasks;
	const { all: selectedTasks } = selected;

	return (
		<div className={styles.BatchChangeStatus}>
			<div className={styles.BatchChangeStatus_action}>
				<ButtonSM
					customStyles={customCSS.statusBtn}
					handleClick={handleBatchStatus}
				>
					Change status
				</ButtonSM>
			</div>
			<div className={styles.BatchChangeStatus_msg}>
				You're about to change the status of {selectedTasks?.length} tasks.
			</div>
		</div>
	);
};

const BatchActionsHandler = ({
	actionType, // action type label
	taskState = {},
	assignTo = {},
	currentUser = {},
	currentFacility = {},
	handleUserAssignment,
	// action handlers
	handleBatchCompletion,
	handleBatchStatus,
	handleBatchExceptions,
	handleBatchPastDue,
	isSavingChanges = false,
}) => {
	const [usersList, setUsersList] = useState({
		records: [],
		options: [],
	});

	const fetchAssignableUsers = async () => {
		const { token } = currentUser;
		const { FacilityId: facilityID } = currentFacility;
		const users = await getUsersByFacility(token, facilityID);

		if (!isEmptyArray(users)) {
			const newList = processAllAdvUsers(users);
			const names = getAvailableUsers([...newList]);
			return setUsersList({
				records: names,
				names: names,
			});
		} else {
			return setUsersList({
				records: [],
				names: [],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchAssignableUsers();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (actionType === null || isEmptyVal(actionType)) {
		return null;
	}
	return (
		<>
			{isSavingChanges && (
				<div className={styles.BatchActionsHandler}>
					<Spinner />
					<div className={styles.BatchActionsHandler_loader}>
						Saving changes...please wait
					</div>
				</div>
			)}
			{!isSavingChanges && (
				<div className={styles.BatchActionsHandler}>
					<div className={styles.BatchActionsHandler_msg}>
						You may assign this action to another user and the changes will be
						back-dated to the date and time of the task's original schedule (ie.
						due time).
					</div>
					<div className={styles.BatchActionsHandler_assign}>
						{!isEmptyArray(usersList?.names) && (
							<HistoricalUserAssignment
								key={`USERS-ASSIGN-${usersList?.records.length}-${assignTo?.userID}`}
								assignTo={assignTo}
								availableUsers={usersList?.names ?? []}
								handleUserAssign={handleUserAssignment}
							/>
						)}
					</div>
					<div className={styles.BatchActionsHandler_type}>
						{actionType === "BATCH-MARK-ALL-AS-DONE" && (
							<BatchCompleteHandler
								taskState={taskState}
								handleBatchCompletion={handleBatchCompletion}
							/>
						)}
						{actionType === "BATCH-REMOVE-EXCEPTIONS" && (
							<BatchRemoveExceptions
								taskState={taskState}
								handleBatchExceptions={handleBatchExceptions}
							/>
						)}
						{actionType === "BATCH-REMOVE-PAST-DUE" && (
							<BatchRemovePastDue
								taskState={taskState}
								handleBatchPastDue={handleBatchPastDue}
							/>
						)}
						{/* {actionType === "BATCH-CHANGE-STATUS" && (
					<BatchChangeStatus
						taskState={taskState}
						handleBatchStatus={handleBatchStatus}
					/>
				)} */}
					</div>
				</div>
			)}
		</>
	);
};

export default BatchActionsHandler;

BatchActionsHandler.defaultProps = {};

BatchActionsHandler.propTypes = {};
