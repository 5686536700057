import React, { useState, useReducer, useEffect } from "react";
import styles from "../../css/app/ALAUpdatedSelector.module.scss";
import { PropTypes } from "prop-types";
// helpers
import { matchFacilityByName } from "../../helpers/utils_facility";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { groupBy } from "../../helpers/utils_processing";
import {
	groupResByFacilityID,
	groupResByUnitType,
} from "../../helpers/utils_residents";
// components
import ButtonSM from "../shared/ButtonSM";
import ALAUpdatedController from "./ALAUpdatedController";
import ALAUpdatedModal from "./ALAUpdatedModal";

const btn = {
	padding: "1rem 1.8rem",
	borderRadius: "5rem",
	fontSize: "1.6rem",
};
const hasFacility = (selectedFacility) => {
	if (isEmptyVal(selectedFacility)) return false;
	return true;
};
const hasResident = (selectedResident) => {
	if (isEmptyVal(selectedResident)) return false;
	return true;
};

const getTitle = (currentStep) => {
	switch (true) {
		case currentStep === 1: {
			return `Select Community`;
		}
		case currentStep === 2: {
			return `Select Resident`;
		}

		default:
			return ``;
	}
};

// handles btn Text
const getBtnText = (
	isAdmin = false,
	selectedFacility,
	selectedResident,
	disableResident = false,
	facilities = []
) => {
	if (!isAdmin && !facilities?.length <= 1) {
		return hasResident(selectedResident)
			? `Change Resident`
			: `Select Resident`;
	} else {
		const adminTxt = getAdminTxt(selectedFacility, disableResident);
		return adminTxt;
	}
};

// handles 'isAdmin' conditions
// w / 'disableResident' conditions
const getAdminTxt = (selectedFacility, disableResident = false) => {
	if (disableResident) {
		return hasFacility(selectedFacility)
			? `Change Community`
			: `Select Community`;
	} else {
		return !hasFacility(selectedFacility)
			? `Select Community/Resident`
			: `Change Community/Resident`;
	}
};

const reducer = (state, action) => {
	switch (action.type) {
		case "NEXT": {
			const { currentStep, selectedFacility } = state;
			if (isEmptyVal(selectedFacility)) return { ...state };
			return {
				...state,
				currentStep: currentStep + 1,
			};
		}
		case "PREVIOUS": {
			return {
				...state,
				currentStep: 1,
				selectedResident: "",
			};
		}
		case "SELECT_FACILITY": {
			const { selection, allResidents, allFacilities } = action.data;

			if (isEmptyVal(selection)) {
				return {
					...state,
					selectedFacility: selection,
					facilityResidents: [],
				};
			}
			// const { allResidents, allFacilities } = state;
			/**
			 * 1. group residents by facility
			 * 2. get matching facility record from 'CommunityName' (ie 'selection')
			 * 3. get 'FacilityID' from matching facility record
			 * 4. get residents from that facility & set state
			 */
			// steps 1 & 2
			const resByFacility = groupBy(allResidents, (x) => x.FacilityId);
			const facilityRecord = matchFacilityByName(selection, allFacilities);
			// steps 3 & 4
			const { FacilityId } = facilityRecord;
			const facilityResidents = resByFacility?.[FacilityId] ?? [];
			// NEWLY ADDED UNIT TYPE FILTERING (6/3/2022 at 8:47AM): //
			const byUnitType = groupResByUnitType(facilityResidents);

			return {
				...state,
				selectedFacility: selection,
				facilityResidents: facilityResidents,
				facilityResidentsByType: { ...byUnitType },
				allFacilityResidents: [...facilityResidents],
			};
		}
		case "SELECT_RESIDENT": {
			const { selection } = action.data;
			return {
				...state,
				selectedResident: selection,
			};
		}
		case "SELECT_TYPE": {
			const { facilityResidents = [], allFacilityResidents = [] } = state;
			const { selection } = action.data;
			const byUnitType = groupResByUnitType(allFacilityResidents);
			const selectedTypeResidents = byUnitType?.[selection] ?? [];

			return {
				...state,
				selectedUnitType: selection,
				facilityResidents: selectedTypeResidents,
				facilityResidentsByType: { ...byUnitType },
				// facilityResidents: facilityResidents,
			};
		}
		default:
			return { ...state };
	}
};

/**
 * @description - The state for the ALAUpdatedSelector component, initialized with it's default state.
 * 'currentStep': current 'step' in the form (ie 1 = Facility Selection, 2 = Resident Selection)
 * 'selectedFacility': facility localstate for <CustomDropdown/> used as facility selector.
 * 'selectedResident': resident localstate for <CustomDropdown/> used as resident selector.
 * 'allResidents': raw list of ALL residents a user has access to, as an array.
 * 'allFacilities': raw list of ALL facilities a user has access to, as an array.
 * 'facilityResidents': residents for the selected facility. is empty is no facility selection has been made.
 * 'disableResident': allows 'locking' the resident-selection step; will only allow facility-selections.
 */
const initialState = {
	currentStep: 1,
	selectedFacility: "",
	selectedResident: "",
	selectedUnitType: "All",
	allResidents: [],
	allFacilities: [],
	facilityResidents: [],
	allFacilityResidents: [],
	disableResident: false,
};

// Updated 1st condition: '!isAdmin' => '!isAdmin && facilities?.length <= 1'
// - Updated as of 5/3/2021 at 8:03 AM
const getDefaultFacility = (isAdmin, defaultFacility, facilities) => {
	if (!isAdmin && facilities?.length <= 1) {
		const [currentFacility] = facilities;
		return currentFacility.CommunityName;
	} else if (!isEmptyVal(defaultFacility)) {
		return defaultFacility;
	} else {
		return "";
	}
};

// gets default step based of items from props & local state combined
const getDefaultStep = (defaultRes, defaultFac, disabledResident = false) => {
	if (disabledResident) return 1;
	if (isEmptyVal(defaultRes) && isEmptyVal(defaultFac)) return 1;
	if (isEmptyVal(defaultFac)) return 1;
	if (isEmptyVal(defaultRes) && !isEmptyVal(defaultFac)) return 2;
	if (!isEmptyVal(defaultRes) && !isEmptyVal(defaultFac)) return 2;
	return 1;
};

// determines list of residents, if facility is already selected
const getDefaultFacilityResidents = (
	defaultFacility,
	allResidents,
	allFacilities
) => {
	if (isEmptyVal(defaultFacility)) return [];
	const facilityRecord = matchFacilityByName(defaultFacility, allFacilities);
	const residentsByFacilityID = groupBy(allResidents, (x) => x.FacilityId);
	const { FacilityId } = facilityRecord;

	return residentsByFacilityID?.[FacilityId];
};

const ALAUpdatedSelector = ({
	isAdmin = false,
	facilities = [],
	residents = [],
	syncFacility,
	syncResident,
	defaultFacility = "",
	defaultResident = "",
	defaultUnitType = "All",
	loadResident,
	loadFacility,
	disableResident = false,

	// disableFacility = false?????
}) => {
	const [showSelector, setShowSelector] = useState(false);
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		currentStep: getDefaultStep(
			defaultResident,
			defaultFacility,
			disableResident
		),
		selectedResident: defaultResident,
		selectedFacility: getDefaultFacility(isAdmin, defaultFacility, facilities),
		selectedUnitType: defaultUnitType,
		allFacilities: [...facilities],
		allResidents: [...residents],
		facilityResidents: getDefaultFacilityResidents(
			getDefaultFacility(isAdmin, defaultFacility, facilities),
			residents,
			facilities
		),
		allFacilityResidents: getDefaultFacilityResidents(
			getDefaultFacility(isAdmin, defaultFacility, facilities),
			residents,
			facilities
		),
		disableResident: disableResident,
	});
	const [hasNoResidents, setHasNoResidents] = useState(
		!isEmptyVal(state?.selectedFacility) &&
			isEmptyArray(state?.facilityResidents)
			? true
			: false
	);

	const loadResidentHandler = (selectedResident) => {
		setShowSelector(false);
		return loadResident(selectedResident);
	};

	const loadFacilityHandler = (selectedFacility) => {
		setShowSelector(false);
		return loadFacility(selectedFacility);
	};

	// select facility string - custom dropdown
	const handleFacility = (name, option) => {
		dispatch({
			type: "SELECT_FACILITY",
			data: {
				selection: option,
				allResidents: residents,
				allFacilities: facilities,
			},
		});
		return syncFacility(option);
	};
	// select resident string - custom dropdown
	const handleResident = (name, option) => {
		if (hasNoResidents || option === "No residents found.") return;

		dispatch({
			type: "SELECT_RESIDENT",
			data: {
				selection: option,
			},
		});

		return syncResident(option);
	};

	// handle unit type, then filter residents
	const handleUnitType = (name, option) => {
		dispatch({
			type: "SELECT_TYPE",
			data: {
				selection: option,
				allResidents: residents,
				allFacilities: facilities,
			},
		});
	};

	// navigates to next step
	const goToNext = () => {
		dispatch({
			type: "NEXT",
		});
	};
	// navigates to previous step
	const goToPrevious = () => {
		dispatch({
			type: "PREVIOUS",
		});
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (!isEmptyVal(state.selectedFacility)) {
			setHasNoResidents(isEmptyArray(state.facilityResidents));
		}

		return () => {
			isMounted = false;
		};
	}, [state.facilityResidents, state.selectedFacility]);

	return (
		<>
			<div className={styles.ALAUpdatedSelector}>
				<ButtonSM customStyles={btn} handleClick={() => setShowSelector(true)}>
					<span>
						{getBtnText(
							isAdmin,
							state.selectedFacility,
							state.selectedResident,
							disableResident,
							facilities
						)}
					</span>
				</ButtonSM>
			</div>
			{showSelector && (
				<ALAUpdatedModal
					// isAdmin={isAdmin}
					title={getTitle(state?.currentStep)}
					isAdmin={isAdmin || facilities?.length > 1}
					currentStep={state?.currentStep}
					closeSelector={() => setShowSelector(false)}
					goToNext={goToNext}
					goToPrevious={goToPrevious}
					disableResident={disableResident}
				>
					<ALAUpdatedController
						key={`SELECTOR-${state?.selectedUnitType}-${state?.facilityResidentsByType?.length}`}
						// isAdmin={isAdmin}
						isAdmin={isAdmin || facilities?.length > 1}
						currentStep={state?.currentStep}
						facilities={state?.allFacilities}
						residents={state?.facilityResidents}
						facilityVal={state?.selectedFacility}
						residentVal={state?.selectedResident}
						// newly added - 5/27/2022 at 9:52 AM ↓
						unitTypeVal={state?.selectedUnitType}
						handleUnitType={handleUnitType}
						// newly added ↑
						handleFacility={handleFacility}
						handleResident={handleResident}
						loadResident={loadResidentHandler}
						loadFacility={loadFacilityHandler}
						goToNext={goToNext}
						goToPrev={goToPrevious}
						// added 6/15/2020
						disableResident={disableResident}
						// added 7/2/2021
						hasNoResidents={hasNoResidents}
					/>
				</ALAUpdatedModal>
			)}
		</>
	);
};

export default ALAUpdatedSelector;

ALAUpdatedSelector.defaultProps = {
	isAdmin: false,
	disableResident: false,
	defaultFacility: "",
	residents: [],
	facilities: [],
};

ALAUpdatedSelector.propTypes = {
	isAdmin: PropTypes.bool.isRequired,
	defaultFacility: PropTypes.string,
	defaultResident: PropTypes.string,
	loadResident: PropTypes.func,
	loadFacility: PropTypes.func,
	disableResident: PropTypes.bool,
	facilities: PropTypes.arrayOf(
		PropTypes.shape({
			CommunityName: PropTypes.string,
			FacilityID: PropTypes.string,
			ParentID: PropTypes.string,
			Shifts: PropTypes.arrayOf(
				PropTypes.shape({
					AssessmentFacilityShiftId: PropTypes.number,
					AssessmentShiftId: PropTypes.number,
					StartTime: PropTypes.string,
					EndTime: PropTypes.string,
					IsActive: PropTypes.bool,
				})
			),
			Address: PropTypes.shape({
				Street: PropTypes.string,
				City: PropTypes.string,
				State: PropTypes.string,
				Zip: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			}),
		})
	),
	residents: PropTypes.arrayOf(
		PropTypes.shape({
			ResidentID: PropTypes.number.isRequired,
			FacilityId: PropTypes.string.isRequired,
			FirstName: PropTypes.string,
			LastName: PropTypes.string,
			Age: PropTypes.number,
			FloorUnit: PropTypes.string,
			RoomNum: PropTypes.string,
		})
	),
};

// helpers
export { getDefaultFacility, getDefaultFacilityResidents, getDefaultStep };
