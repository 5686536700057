import React, { useState } from "react";
import styles from "../../css/history/HistoricalTaskSortController.module.scss";
import { PropTypes } from "prop-types";
import RadioButton from "../shared/RadioButton";
import CustomCheckbox from "../shared/CustomCheckbox";

// TASK DISPLAY SORTER FOR HISTORICAL VIEW //
// - Allows displaying ONLY certain types:
// 		- Show ONLY Exceptions
// 		- Show all MISSED tasks
// 		- Show ALL tasks

// REQUIREMENTS:
// - Show ONLY missed tasks (past-due & exceptions)
//    - Should default to true on mount
// - Show All

const options = {
	MISSED: "Only shows PAST-DUE and EXCEPTION tasks.",
	EXCEPTIONS: "Only shows tasks with EXCEPTION(s).",
	ALL: "Shows all tasks matching criteria.",
};

const HistoricalTaskSortController = ({
	initialVal = "ALL",
	syncDisplaySort,
	closeModal,
}) => {
	// determines which tasks to show by 'displaySort'
	const [displaySort, setDisplaySort] = useState(initialVal);

	const handleSort = (sort) => {
		setDisplaySort(sort);
	};

	const applyDisplaySort = () => {
		syncDisplaySort(displaySort);
		closeModal();
	};

	const resetAllSorting = () => {
		setDisplaySort("");
	};

	return (
		<div className={styles.HistoricalTaskSortController}>
			<section className={styles.HistoricalTaskSortController_content}>
				<div className={styles.HistoricalTaskSortController_content_inner}>
					<div
						className={styles.HistoricalTaskSortController_content_inner_option}
					>
						<RadioButton
							id="MISSED"
							name="showMissedOnly"
							val={displaySort === "MISSED"}
							label="Show Missed Tasks Only (Past-Due & Exceptions)"
							handleSelection={handleSort}
						/>
					</div>
					<div
						className={styles.HistoricalTaskSortController_content_inner_option}
					>
						<RadioButton
							id="EXCEPTIONS"
							name="showExceptionsOnly"
							val={displaySort === "EXCEPTIONS"}
							label="Show Exceptions Only"
							handleSelection={handleSort}
						/>
					</div>
					<div
						className={styles.HistoricalTaskSortController_content_inner_option}
					>
						<RadioButton
							id="ALL"
							name="showAll"
							val={displaySort === "ALL"}
							label="Show All Tasks"
							handleSelection={handleSort}
						/>
					</div>
				</div>

				<div className={styles.HistoricalTaskSortController_content_actions}>
					<button
						type="button"
						onClick={applyDisplaySort}
						className={
							styles.HistoricalTaskSortController_content_actions_applyBtn
						}
					>
						Apply
					</button>
					{/* <button
						type="button"
						onClick={resetAllSorting}
						className={
							styles.HistoricalTaskSortController_content_actions_resetBtn
						}
					>
						Reset All
					</button> */}
				</div>
			</section>
		</div>
	);
};

export default HistoricalTaskSortController;

HistoricalTaskSortController.defaultProps = {};

HistoricalTaskSortController.propTypes = {};
