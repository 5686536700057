import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/timeview/TimeViewAdvancedOptions.module.scss";
import sprite from "../../assets/icons/tasks.svg";
import sprite2 from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { getAdvancedResources } from "../../helpers/utils_timeview";
import {
	hasProp,
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import {
	getTaskNotes,
	isCompleted,
	isScheduledTask,
	getTimeViewTaskNameExtended,
	isUnscheduledTask,
	isPRNTask,
} from "../../helpers/utils_tasks";
import { featureFlags } from "../../helpers/utils_features";
import { red, themeColors } from "../../helpers/utils_styles";
import { getExceptionsFromRecords } from "../../helpers/utils_exceptions";
import {
	RECURRING_TYPES as recurringTypes,
	BASE_EXCEPTION_TYPES as baseExceptions,
} from "../../helpers/utils_options";
import {
	getDefaultDayTV,
	getDefaultShiftTV,
	getRecurringStartAndEnd,
	getRecurringTypeFromID,
	hasRecurringType,
	isRecurring,
} from "../../helpers/utils_repeatingTasks";
// components
import FormSection from "../forms/FormSection";
import FormActions from "../forms/FormActions";
import Divider from "../forms/Divider";
import RadioButton from "../shared/RadioButton";
import CustomDropdown from "../shared/CustomDropdown";
import Checkbox from "../shared/Checkbox";
import Textarea from "../shared/Textarea";
import ButtonSM from "../shared/ButtonSM";
import Spinner from "../shared/Spinner";
import TaskNotesController from "../notes/TaskNotesController";
import RecentReassess from "../daily/RecentReassess";
import DueDatePicker from "../daily/DueDatePicker";
import TimeViewResidentHeader from "./TimeViewResidentHeader";
import AdvancedAdlNotes from "./AdvancedAdlNotes";
import CustomDropdownSM from "../shared/CustomDropdownSM";
import RepeatTaskModal from "../daily/RepeatTaskModal";
import DateRangePickerSM from "../shared/DateRangePickerSM";
import { format } from "date-fns";
import { isLocalhost } from "../../helpers/utils_env";

const customCSS = {
	sectionSave: {
		backgroundColor: themeColors.main.blackBlue,
		color: "#ffffff",
		borderRadius: "5rem",
		fontWeight: "600",
		marginRight: ".5rem",
	},
	radios: {
		marginBottom: ".5rem",
	},
	checks: {
		marginBottom: ".5rem",
	},
	saveBtn: {
		padding: "1rem 1.6rem",
		backgroundColor: themeColors.main.main,
		borderRadius: "5rem",
		fontSize: "1.3rem",
	},
	cancelBtn: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: ".8rem 1.6rem",
		backgroundColor: "transparent",
		color: "#333",
		borderRadius: "5rem",
		fontSize: "1.3rem",
		border: "1px solid #ccc",
		marginLeft: "1rem",
	},
	notesTxt: {
		height: "10rem",
	},
	deleteBtn: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "#ffffff",
		backgroundColor: themeColors.main.red,
	},
};

const done = {
	color: themeColors.main.green,
};
const notDone = {
	color: themeColors.main.altYellow,
};

/**
 * Gets the original created date for the task record.
 * @param {Object} taskInfo - Task info record
 * @returns {Date} - Returns date string or empty string
 */
const getTaskOriginDate = (taskInfo) => {
	// date
	const created = taskInfo?.CreatedDate;

	if (!isEmptyVal(created)) {
		return format(created, "MM/DD/YYYY hh:mm A");
	} else {
		return "";
	}
};

// checks if 'isLocked' has changed
const changedLock = (vals, task) => {
	const { IsLocked } = task;
	const { isLocked } = vals;
	return IsLocked !== isLocked;
};

/**
 * Determines whether to show 'Exceptions' UI
 * - Should be hidden for PRN tasks, since they're exempt from exceptions
 */
const showExceptionUI = (task) => {
	const adl = task?.ADL ?? task?.ADLCategory;

	return adl !== "PRN";
};

/**
 * Show task repeat settings if:
 * - User is admin type or above
 * - Non-admins can still create tasks and schedule them for a specific shift, but not add repeats
 */
const showRepeatFeature = (currentUser, task) => {
	const { isAdmin, isExecutiveAdmin, isFacilityAdmin, isSuperUser } =
		currentUser;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;

	return isAdminType && isUnscheduledTask(task);
};

const showLockTaskFeature = (task, currentUser) => {
	const { isAdmin, isExecutiveAdmin, isFacilityAdmin, isSuperUser } =
		currentUser;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;
	const isUnscheduled = !isScheduledTask(task);

	return isUnscheduled && isAdminType;
};

const showNewTaskNotes = (currentUser) => {
	const isEnabled = true;
	// const isEnabled = enableFeatureViaInternalList(currentUser?.userID);
	// const isLocal = isLocalhost();
	// const isDev = isDevOrTest();
	// const isNotProd = isLocal || isDev;

	return isEnabled;
};

const disableDueDateBtn = (formState) => {
	const { touched, values } = formState;
	const { scheduledDueDate: date, scheduledDueTime: time } = values;
	const noChanges =
		!hasProp(touched, "scheduledDueDate") &&
		!hasProp(touched, "scheduledDueTime");
	const isEmpty = isEmptyVal(date) || isEmptyVal(time);

	return noChanges || isEmpty;
};

// applies recurring & other settings to 'values' after fetching
const applyTaskInfoToVals = (taskInfo = {}, formState = {}, setFormState) => {
	if (isScheduledTask(taskInfo)) return;

	const { values } = formState;
	const { AssessmentRecurringId: recurringID } = taskInfo;
	const { startDate, endDate } = getRecurringStartAndEnd(taskInfo);

	const newVals = {
		...values,
		recurringType: getRecurringTypeFromID(recurringID),
		isRecurring: isRecurring(taskInfo),
		recurringCycle: "1",
		recurringCycleOption: "",
		isRecurringAM: getDefaultShiftTV("AM", taskInfo),
		isRecurringPM: getDefaultShiftTV("PM", taskInfo),
		isRecurringNOC: getDefaultShiftTV("NOC", taskInfo),
		isRecurringSun: getDefaultDayTV("Sun", taskInfo),
		isRecurringMon: getDefaultDayTV("Mon", taskInfo),
		isRecurringTue: getDefaultDayTV("Tue", taskInfo),
		isRecurringWed: getDefaultDayTV("Wed", taskInfo),
		isRecurringThu: getDefaultDayTV("Thu", taskInfo),
		isRecurringFri: getDefaultDayTV("Fri", taskInfo),
		isRecurringSat: getDefaultDayTV("Sat", taskInfo),
		startDate: startDate,
		endDate: endDate,
		// NEW TASK NOTES FIELDS
		// assessmentTaskID: task?.AssessmentTaskId ?? null,
	};

	return setFormState({
		...formState,
		values: {
			...newVals,
		},
	});
};

const TimeViewAdvancedOptions = ({
	vals = {},
	task = {},
	formState = {},
	currentUser = {},
	currentFacility = {},
	handleChange,
	handleCheckbox,
	handleDueDate,
	handleDueTime,
	handleShift,
	handleSettings,
	handleAdvancedCompletion,
	handleDateRange,
	handleCycleOptions,
	setFormState,
	syncTaskNotes,
	syncUpdatedTaskNotes,
	saveSectionChanges,
	cancelChanges,
	saveAllChanges,
	cancelAllChanges,
	deleteTask,
	openNewResidentTaskModal,
	saveRef = null,
	wasNoteSaved = false,
	facilityExceptions = [...baseExceptions],
	photoSrc, // base64 string
}) => {
	const [currentResident, setCurrentResident] = useState({
		firstName: task?.FirstName,
		lastName: task?.LastName,
		residentID: task?.ResidentID,
		roomNum: task?.RoomNum,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [taskInfo, setTaskInfo] = useState({});
	// task notes records
	const [allTaskNotes, setAllTaskNotes] = useState([]);
	const [reassessRecords, setReassessRecords] = useState([]);
	// added - 12/1/2022
	const [adlCategory, setAdlCategory] = useState({});
	// new task
	// repeat/recurring settings
	const [showRepeat, setShowRepeat] = useState(false);
	const [repeatDescription, setRepeatDescription] = useState("");

	const {
		exceptionType,
		reassess,
		reassessNotes,
		shift,
		taskNotes,
		signature,
		markComplete,
	} = vals;

	const statusTitle = (
		<span>
			Mark Task Complete:{" "}
			<b style={isCompleted(task) || markComplete ? done : notDone}>
				{isCompleted(task) || markComplete ? "Complete" : "Not-Complete"}
			</b>
		</span>
	);

	const disableForm = () => {
		const { touched, values } = formState;
		// const { markComplete, reassess } = values;

		const wasTouched = !isEmptyObj(touched);
		const hasNotes = !isEmptyVal(values?.syncNotesVal);

		return !wasTouched && !hasNotes;

		// if (markComplete) {
		// 	return false;
		// } else {
		// 	return isEmptyVal(exceptionType) && !reassess;
		// }
	};

	const triggerSaveNote = (e) => {
		saveRef.current.click(e);
	};

	const sectionHandler = () => {
		saveSectionChanges(taskInfo);
	};

	const cancelRepeat = (e) => {
		setShowRepeat(false);
	};

	const fetchInitialResources = useCallback(async () => {
		const { token } = currentUser;
		// ✓ - fetch resident profile/record
		// ✓ - fetch task details record
		// ✓ - fetch task notes record(s)
		setIsLoading(true);
		const advancedResources = await getAdvancedResources(token, task);

		if (!isEmptyObj(advancedResources)) {
			const {
				taskNotes = [],
				taskDetails = {},
				profile = {},
				reassessRecords = [],
				userDetails = {},
			} = advancedResources;
			console.log("taskDetails", taskDetails);
			// sync to local state(s)
			setCurrentResident({ ...profile });
			setTaskInfo({ ...taskDetails, ...userDetails, ADLCategory: task?.ADL });
			setAllTaskNotes(taskNotes);
			setReassessRecords(reassessRecords);
			applyTaskInfoToVals(taskDetails, formState, setFormState);
			return setIsLoading(false);
		} else {
			setCurrentResident({});
			setTaskInfo({});
			setAllTaskNotes([]);
			setReassessRecords([]);
			return setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchInitialResources();

		return () => {
			isMounted = false;
		};
	}, [fetchInitialResources]);

	// sync resident info from task
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		setCurrentResident({
			firstName: task?.FirstName,
			lastName: task?.LastName,
			residentID: task?.ResidentID,
			roomNum: task?.RoomNum,
		});

		return () => {
			isMounted = false;
		};
	}, [task]);

	return (
		<div className={styles.TimeViewAdvancedOptions}>
			{/* RESIDENT DETAILS */}
			<section className={styles.TimeViewAdvancedOptions_resident}>
				<TimeViewResidentHeader
					task={{ ...task, ...taskInfo }}
					residentImage={{ src: photoSrc, alt: "Resident Photo" }}
					currentResident={currentResident}
					currentUser={currentUser}
				>
					<button
						type="button"
						onClick={() =>
							openNewResidentTaskModal({
								...task,
								...taskInfo,
								...vals,
								...currentResident,
								photoSrc,
							})
						}
						className={styles.TimeViewAdvancedOptions_resident_newTask}
					>
						New Task
					</button>
				</TimeViewResidentHeader>
			</section>
			{/* TASK DETAILS */}
			<section className={styles.TimeViewAdvancedOptions_top}>
				{isLoading && (
					<div className={styles.TimeViewAdvancedOptions_top_loader}>
						<Spinner />
						<div className={styles.TimeViewAdvancedOptions_top_loader_text}>
							Loading task details...
						</div>
					</div>
				)}

				{/* TASK ADL, TASK NAME AND TASK SHIFT */}
				{!isLoading && (
					<div className={styles.TimeViewAdvancedOptions_top_title}>
						<div className={styles.TimeViewAdvancedOptions_top_title_adl}>
							{taskInfo?.ADLCategory}:
						</div>
						<div className={styles.TimeViewAdvancedOptions_top_title_name}>
							<div
								className={styles.TimeViewAdvancedOptions_top_title_name_icon}
							>
								<svg
									className={
										vals?.markComplete
											? styles.TimeViewAdvancedOptions_top_title_name_icon_statusBadge_isCompleted
											: styles.TimeViewAdvancedOptions_top_title_name_icon_statusBadge
									}
								>
									<use xlinkHref={`${sprite2}#icon-check_circle`}></use>
								</svg>
							</div>
							<div
								className={styles.TimeViewAdvancedOptions_top_title_name_task}
							>
								{getTimeViewTaskNameExtended(task)}
							</div>
						</div>
						{!isPRNTask(task) && (
							<div className={styles.TimeViewAdvancedOptions_top_title_shift}>
								{task?.Shift}
							</div>
						)}
						<div
							className={styles.TimeViewAdvancedOptions_top_title_originDate}
						>
							Created: {getTaskOriginDate(taskInfo)}
						</div>
					</div>
				)}
			</section>
			<Divider customStyles={{ opacity: ".2" }} />

			<AdvancedAdlNotes
				task={task}
				currentUser={currentUser}
				currentResident={currentResident}
				currentFacility={currentFacility}
			/>
			<Divider customStyles={{ opacity: ".2" }} />

			{!isLoading && (
				<fieldset className={styles.TimeViewAdvancedOptions_form}>
					{/* DUE DATE/TIME SCHEDULER */}
					{!isScheduledTask(task) && !isPRNTask(task) && (
						<>
							<FormSection
								title="Due Date/Time:"
								icon="clock"
								hoverText="Add a due date and time."
							>
								<DueDatePicker
									vals={vals}
									currentUser={currentUser}
									currentFacility={currentFacility}
									handleDueDate={handleDueDate}
									handleDueTime={handleDueTime}
								/>
								<FormActions
									key={`TUP--DUE-DATE-PICKER`}
									isDisabled={disableDueDateBtn(formState)}
									saveTxt="Save Date/Time"
									cancelTxt="Cancel"
									saveHandler={sectionHandler}
									// saveHandler={saveSectionChanges}
									cancelHandler={cancelChanges}
									saveCSS={customCSS.sectionSave}
								/>
							</FormSection>
							<Divider customStyles={{ opacity: ".2" }} />
						</>
					)}

					{/* EXCEPTIONS */}
					{showExceptionUI(task) && (
						<>
							<FormSection
								title="Task Exceptions:"
								icon="calendarBusy"
								hoverText="Reason why care was not provided."
							>
								<>
									<div className={styles.TimeViewAdvancedOptions_main}>
										<CustomDropdown
											name="exceptionType"
											id="exceptionType"
											selection={exceptionType}
											setSelection={handleSettings}
											placeholder="Select Exception"
											autoComplete="off"
											inputMode="none"
											options={facilityExceptions}
										/>
									</div>
									<FormActions
										isDisabled={isEmptyVal(exceptionType)}
										saveTxt="Save Exception"
										cancelTxt="Cancel"
										saveHandler={sectionHandler}
										cancelHandler={cancelChanges}
										saveCSS={customCSS.sectionSave}
									/>
								</>
							</FormSection>
							<Divider customStyles={{ opacity: ".2" }} />
						</>
					)}

					{/* REASSESSMENT/SERVICE-PLAN-CHANGE SECTION */}
					{isScheduledTask(task) && (
						<FormSection
							// title="Reassess Task:"
							title="Service Plan Change:"
							icon="infoOutline"
							hoverText="Task requires service plan change. Timewise, or other reason."
						>
							<div className={styles.TimeViewAdvancedOptions_main}>
								<Checkbox
									id="reassess"
									name="reassess"
									// label="Task needs a reassessment"
									label="Suggested Service Plan change"
									val={reassess}
									handleCheckbox={handleCheckbox}
									customStyles={customCSS.checks}
								/>
								{reassess && (
									<>
										<Textarea
											name="reassessNotes"
											id="reassessNotes"
											label="Service Plan Update Notes (REQUIRED)"
											placeholder={reassessNotes}
											val={reassessNotes}
											addRequiredFlag={true}
											enableCharCount={true}
											maxChar={250}
											handleChange={handleChange}
											customStyles={customCSS.checks}
										/>
										<FormActions
											isDisabled={isEmptyVal(reassessNotes)}
											saveTxt="Save Suggestion"
											cancelTxt="Cancel"
											saveHandler={sectionHandler}
											cancelHandler={cancelChanges}
											saveCSS={customCSS.sectionSave}
										/>
									</>
								)}
								{!isEmptyArray(reassessRecords) && (
									<RecentReassess
										key={`REASSESS-RECORDS-${reassessRecords?.length}`}
										currentResident={currentResident}
										reassessRecords={reassessRecords}
										task={taskInfo}
									/>
								)}
							</div>
						</FormSection>
					)}
					<Divider customStyles={{ opacity: ".2" }} />

					{/* MARK COMPLETED SECTION: SHIFTS - RADIO BUTTONS */}
					<FormSection title={statusTitle} icon="checkbox" hideInfoIcon={true}>
						<div className={styles.TimeViewAdvancedOptions_main}>
							<div className={styles.TimeViewAdvancedOptions_main_markComplete}>
								<button
									className={
										styles.TimeViewAdvancedOptions_main_markComplete_btn
									}
									onClick={handleAdvancedCompletion}
								>
									<svg
										className={
											styles.TimeViewAdvancedOptions_main_markComplete_btn_icon
										}
									>
										<use xlinkHref={`${sprite2}#icon-check_circle`}></use>
									</svg>
									<span>
										{isCompleted(task) || markComplete
											? "Completed!"
											: "Click to Complete"}
									</span>
								</button>
							</div>

							{featureFlags?.enableShiftSelector && (
								<>
									<div className={styles.TimeViewAdvancedOptions_main_subLabel}>
										Task Was Completed During:
									</div>
									<RadioButton
										id="AM"
										name="shift"
										label="AM Shift"
										val={shift === "AM"}
										handleSelection={handleShift}
										customStyles={customCSS.radios}
									/>
									<RadioButton
										id="PM"
										name="shift"
										label="PM Shift"
										val={shift === "PM"}
										handleSelection={handleShift}
										customStyles={customCSS.radios}
									/>
									<RadioButton
										id="NOC"
										name="shift"
										label="NOC Shift"
										val={shift === "NOC"}
										handleSelection={handleShift}
										customStyles={customCSS.radios}
									/>
								</>
							)}
						</div>
						<FormActions
							isDisabled={!markComplete} //  (!markComplete || isEmptyVal(shift)) when enabled
							saveTxt="Save Status"
							cancelTxt="Cancel"
							saveHandler={sectionHandler}
							cancelHandler={cancelChanges}
							saveCSS={customCSS.sectionSave}
						/>
					</FormSection>
					<Divider customStyles={{ opacity: ".2" }} />

					{/* REPEAT/RECURRING SETTINGS */}
					{/* REPEAT/RECURRING TYPE */}
					{showRepeatFeature(currentUser, taskInfo) && (
						<FormSection
							title="Repeat Task"
							icon="recurring"
							hoverText="Select when the task repeats."
						>
							<div className={styles.TimeViewAdvancedOptions_repeat}>
								<span className={styles.TimeViewAdvancedOptions_repeat_label}>
									Repeat:{" "}
								</span>

								<CustomDropdownSM
									key={`ADVANCED-RECURRING`}
									name="recurringType"
									id="recurringType"
									selection={vals?.recurringType}
									setSelection={handleSettings}
									inputSize={12}
									options={recurringTypes}
								/>
								{/* UPDATE SETTINGS BUTTON */}
								{hasRecurringType(vals.recurringType) && (
									<button
										className={styles.TimeViewAdvancedOptions_repeat_updateBtn}
										onClick={(e) => {
											e.preventDefault();
											setShowRepeat(true);
										}}
									>
										Update Settings
									</button>
								)}
								{/* REPEAT TASK MODAL */}
								{showRepeat && (
									<RepeatTaskModal
										key={`ADVANCED-REPEAT-${vals?.recurringType}`}
										vals={vals}
										saveRepeat={() => setShowRepeat(false)}
										cancelRepeat={cancelRepeat}
										closeRepeatWindow={() => setShowRepeat(false)}
										getRepeatDescText={setRepeatDescription} // text description in form
										repeatType={vals.recurringType}
										handleSettings={handleSettings}
										handleCycleOptions={handleCycleOptions}
										handleDaySelections={handleCheckbox}
									/>
								)}
							</div>
							<div
								className={styles.TimeViewAdvancedOptions_repeat_rangePicker}
							>
								<DateRangePickerSM
									label="Select a start and end date"
									startName="startDate"
									endName="endDate"
									rangeVals={vals}
									handleSelection={handleDateRange}
									focusMode={true}
								/>
							</div>
							<div className={styles.TimeViewAdvancedOptions_repeat_desc}>
								{!isEmptyVal(repeatDescription) && (
									<span>{repeatDescription}</span>
								)}
							</div>
						</FormSection>
					)}
					<Divider customStyles={customCSS.divider} />

					{/* NEW TASK NOTES SECTION */}
					{showNewTaskNotes(currentUser) && (
						<>
							<FormSection
								title="Task Notes:"
								icon="note"
								hoverText="Add as many notes as desired."
							>
								{isPRNTask(task) && (
									<div className={styles.AdvancedNotes}>
										Document with as many notes as needed. Supports lists,
										comments, notes, line-breaks etc.
									</div>
								)}
								<TaskNotesController
									key={`TASK-NOTES-${allTaskNotes?.length}`}
									task={taskInfo}
									currentUser={currentUser}
									allTaskNotes={allTaskNotes}
									syncTaskNotes={syncTaskNotes}
									syncTaskNotesHandler={syncUpdatedTaskNotes}
									saveRef={saveRef}
									// new field
									wasNoteSaved={wasNoteSaved}
								/>
							</FormSection>
							<Divider customStyles={{ opacity: ".2" }} />
						</>
					)}

					{/* LOCK/UNLOCK TASK */}
					{/* {currentUser?.isAdmin && !isScheduledTask(task) && ( */}
					{showLockTaskFeature(task, currentUser) && (
						<>
							<FormSection
								title="Lock/Unlock Task"
								icon="lock"
								iconColor={vals?.isLocked ? `#e84855` : null}
								hoverText="Locking a task prevents changes to it's recurring settings from NON-ADMIN users."
							>
								<div className={styles.TimeViewAdvancedOptions_main_desc}>
									Locking a task will prevent unauthorized changes to a task
									from any "NON-ADMIN" user. Only users with "ADMIN"
									access/permissions are able to make changes to a "locked"
									task.
								</div>
								<Checkbox
									name="isLocked"
									id="isLocked"
									val={vals?.isLocked}
									label="Lock Task"
									handleCheckbox={handleCheckbox}
								/>
								<FormActions
									isDisabled={!changedLock(vals, task)}
									saveTxt="Save Lock"
									cancelTxt="Cancel"
									saveHandler={sectionHandler}
									cancelHandler={cancelChanges}
									saveCSS={customCSS.sectionSave}
								/>
							</FormSection>
							<Divider customStyles={{ opacity: ".2" }} />
						</>
					)}

					{/* DELETE TASK - FEATURE FLAG ONLY */}
					{isLocalhost() && !isScheduledTask(task) && (
						<>
							<FormSection
								title="Delete Task (DEV ONLY)"
								icon="trash"
								iconColor={red[600]}
								titleColor={red[600]}
								hoverText="Delete this task permanently"
							>
								<div className={styles.TimeViewAdvancedOptions_main_delete}>
									<div
										className={styles.TimeViewAdvancedOptions_main_delete_msg}
									>
										Warning: Deleting a task will permanently remove it from the
										system. Use with caution.
									</div>

									<ButtonSM
										handleClick={() => deleteTask(task)}
										customStyles={customCSS.deleteBtn}
									>
										<svg
											className={
												styles.TimeViewAdvancedOptions_main_delete_icon
											}
										>
											<use xlinkHref={`${sprite}#icon-delete`}></use>
										</svg>
										<span>Delete</span>
									</ButtonSM>
								</div>
							</FormSection>

							<Divider customStyles={{ opacity: ".2" }} />
						</>
					)}
				</fieldset>
			)}

			{/* ACTION BUTTONS: SAVE/CANCEL */}
			{/* {featureFlags?.enableSaveAll && ( */}
			<div className={styles.TimeViewAdvancedOptions_main_bottom}>
				<ButtonSM
					type="button"
					isDisabled={disableForm()}
					customStyles={customCSS.saveBtn}
					handleClick={(e) => {
						// saveAllChanges(task);
						saveAllChanges({ ...task, ...taskInfo });
						triggerSaveNote(e);
					}}
					title="Please complete the form, first :)"
				>
					<svg className={styles.TimeViewAdvancedOptions_main_bottom_icon}>
						<use xlinkHref={`${sprite}#icon-save11`}></use>
					</svg>
					<span>Save All Changes</span>
				</ButtonSM>
				<ButtonSM
					customStyles={customCSS.cancelBtn}
					handleClick={cancelAllChanges}
				>
					<svg className={styles.TimeViewAdvancedOptions_main_bottom_cancel}>
						<use xlinkHref={`${sprite2}#icon-clearclose`}></use>
					</svg>
					<span>Cancel</span>
				</ButtonSM>
			</div>
			{/* )} */}
		</div>
	);
};

export default TimeViewAdvancedOptions;

TimeViewAdvancedOptions.defaultProps = {};

TimeViewAdvancedOptions.propTypes = {};
