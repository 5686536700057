import React, { useState, useRef, useEffect } from "react";
import styles from "../../css/timeview/TimeViewSortController.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";
// components
import RadioButton from "../shared/RadioButton";
import CustomCheckbox from "../shared/CustomCheckbox";
import { featureFlags } from "../../helpers/utils_features";

// REQUIREMENTS:
// - Sorting Options:
// 		- By ADL
// 		- By Resident
// 		- By Task (name/description)
// 		- By Task Type (scheduled/unscheduled)
// 		- By Due Date (scheduled date) -- TO-BE-IMPLEMENTED!!!

const TimeViewSortController = ({
	title = "Sort Tasks",
	syncAndApplySorting,
	closeModal,
	sortState = {},
}) => {
	const mobileRef = useRef();
	const { isOutside } = useOutsideClick(mobileRef);
	const userEscaped = useKeyboardShortcut(["Escape"]);
	useLockBodyScroll();
	// sorting selection state
	const [sortBy, setSortBy] = useState(() => {
		return sortState?.sortBy;
	});
	const [hideIndependent, setHideIndependent] = useState(false);

	const handleCheckbox = (e) => {
		const { checked } = e.target;

		setHideIndependent(checked);
	};

	const handleSortBy = (selection) => {
		setSortBy(selection);
	};

	const applySorting = (e) => {
		syncAndApplySorting({
			sortBy: sortBy,
			hideIndependent: hideIndependent,
			isSorted: true,
		});
	};

	const resetAllSorting = () => {
		syncAndApplySorting({
			sortBy: "",
			hideIndependent: false,
			isSorted: false,
		});
	};

	// close modal
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || userEscaped) {
			return closeModal();
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside, closeModal, userEscaped]);

	return (
		<>
			<aside className={styles.TimeViewSortController} ref={mobileRef}>
				<section className={styles.TimeViewSortController_top}>
					<h4 className={styles.TimeViewSortController_top_title}>{title}</h4>
					<svg
						className={styles.TimeViewSortController_top_icon}
						onClick={closeModal}
					>
						<use xlinkHref={`${sprite}#icon-clearclose`}></use>
					</svg>
				</section>

				{/* ABOUT SECTION */}
				<section className={styles.TimeViewSortController_about}>
					<ul className={styles.TimeViewSortController_about_info}>
						<li className={styles.TimeViewSortController_about_info_text}>
							Tasks can be sorted in various different ways.
						</li>
						<li className={styles.TimeViewSortController_about_info_text}>
							The default sorting is alphabetical (A-Z) by ADL category (eg.
							Ambulate-Transfers).
						</li>
					</ul>
				</section>

				{/* MAIN CONTENT */}
				<section className={styles.TimeViewSortController_content}>
					<div className={styles.TimeViewSortController_content_inner}>
						<div className={styles.TimeViewSortController_content_inner_option}>
							<RadioButton
								id="ADL"
								name="sortByADL"
								val={sortBy === "ADL"}
								label="Sort By ADL"
								handleSelection={handleSortBy}
							/>
						</div>
						<div className={styles.TimeViewSortController_content_inner_option}>
							<RadioButton
								id="Resident"
								name="sortByResident"
								val={sortBy === "Resident"}
								label="Sort By Resident Name"
								handleSelection={handleSortBy}
							/>
						</div>
						<div className={styles.TimeViewSortController_content_inner_option}>
							<RadioButton
								id="Task"
								name="sortByTask"
								val={sortBy === "Task"}
								label="Sort By Task Name"
								handleSelection={handleSortBy}
							/>
						</div>
						<div className={styles.TimeViewSortController_content_inner_option}>
							<RadioButton
								id="TaskType"
								name="sortByTaskType"
								val={sortBy === "TaskType"}
								label="Sort By Task Type (scheduled vs unscheduled)"
								handleSelection={handleSortBy}
							/>
						</div>
						<div className={styles.TimeViewSortController_content_inner_option}>
							<RadioButton
								id="Time"
								name="sortByTime"
								val={sortBy === "Time"}
								label="Sort By Due Time"
								handleSelection={handleSortBy}
							/>
						</div>
					</div>
					{featureFlags?.enableHideIndependentTasks && (
						<div className={styles.TimeViewSortController_content_other}>
							<CustomCheckbox
								name="hideIndependent"
								id="hideIndependent"
								label="Hide Independent/Auto-Completed Tasks"
								val={hideIndependent}
								handleCheckbox={handleCheckbox}
							/>
						</div>
					)}
					<div className={styles.TimeViewSortController_content_actions}>
						<button
							type="button"
							onClick={resetAllSorting}
							className={styles.TimeViewSortController_content_actions_resetBtn}
						>
							Reset All
						</button>
						<button
							type="button"
							onClick={applySorting}
							className={styles.TimeViewSortController_content_actions_applyBtn}
						>
							Apply
						</button>
					</div>
				</section>
			</aside>
		</>
	);
};

export default TimeViewSortController;

TimeViewSortController.defaultProps = {};

TimeViewSortController.propTypes = {};
