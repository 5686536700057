import React, { createContext, useReducer } from "react";
import { markCompleteByADL, findAndReplaceStale } from "../helpers/utils_tasks";

// UPDATED 6/6 10:00 AM
const initialGlobalState = {
	app: {
		isLoading: false,
		hasLoaded: false,
		isError: false,
		hasUpdated: false,
	},
	user: {
		firstName: null,
		lastName: null,
		username: null,
		password: null,
		token: null,
		userID: null,
		facilityID: null,
		title: null,
		isAdmin: false, // isExecutiveAdmin
		isExecutiveAdmin: false,
		isFacilityAdmin: false,
		isSuperUser: false,
		isMedTechRestricted: false,
		isReadOnly: false,
		isStandardUser: false,
		hasMultiFacility: false,
		facilities: [],
	},
	globals: {
		residents: [],
		residentsByFacility: {},
		currentResident: {
			firstName: null,
			lastName: null,
			age: null,
			residentID: null,
			unit: null,
			height: null,
			weight: null,
			mdReportDue: null,
			servicePlanDue: null,
			monthlyMedReview: null,
			bathNotes: null,
			escortNotes: null,
			groomingNotes: null,
			hygieneNotes: null,
			loa: {},
		},
		currentFacility: {
			communityName: null,
			facilityID: null,
			parentID: null,
			residents: [],
			shifts: [],
			address: {},
			exceptionTypes: [],
		},
		adlDescriptions: [],
		unscheduledTasks: [],
		unscheduledTasksRaw: [],
		scheduledTasks: [],
		scheduledTasksHistory: [],
		scheduledTasksFuture: [],
		trackingTasks: [],
		adls: [],
		profile: {},
		charting: {},
		categories: [],
		vitals: [],
		loa: null,
		prevState: {},
	},
};

const GlobalStateContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case "LOADING": {
			return {
				...state,
				app: {
					...state.app,
					isLoading: true,
				},
			};
		}
		case "SUCCESS": {
			const { newState } = action.data;
			return {
				...state,
				...newState,
				app: {
					hasLoaded: true,
					isLoading: false,
					isError: false,
					hasCache: true,
				},
			};
		}
		case "ERROR": {
			return { ...state };
		}
		case "UPDATE": {
			return { ...state };
		}
		case "REFRESH_STATE": {
			return { ...state };
		}
		case "FORCE_LOGOUT": {
			return {
				...initialGlobalState,
				app: {
					...initialGlobalState.app,
					wasLoggedOut: true,
				},
			};
		}
		case "RESET": {
			return { ...initialGlobalState };
		}
		case "SET_FACILITY": {
			const { facilityRecord, facilityResidents } = action.data;

			const { CommunityName, FacilityId, ParentFacilityId, Address, Shifts } =
				facilityRecord;
			return {
				...state,
				globals: {
					...state.globals,
					currentFacility: {
						communityName: CommunityName,
						facilityID: FacilityId,
						parentID: ParentFacilityId,
						address: {
							street: Address.Street,
							city: Address.City,
							state: Address.State,
							zip: Address.Zip,
						},
						residents: facilityResidents, // removed 'spread'
						shifts: Shifts,
					},
				},
			};
		}
		case "SYNC_RESIDENT_AND_FACILITY": {
			const { resident, facility } = action.data;
			const { CommunityName, FacilityId, ParentFacilityId, Address, Shifts } =
				facility;
			const facilityResidents =
				state.globals.residentsByFacility[facility?.FacilityId];

			return {
				...state,
				globals: {
					...state.globals,
					currentFacility: {
						communityName: CommunityName,
						facilityID: FacilityId,
						parentID: ParentFacilityId,
						address: {
							street: Address.Street,
							city: Address.City,
							state: Address.State,
							zip: Address.Zip,
						},
						residents: facilityResidents,
						shifts: Shifts,
					},
					currentResident: {
						...resident,
					},
				},
			};
		}
		case "LOAD_RESIDENT_FROM_CALENDAR": {
			const { residentRecord } = action.data;

			return {
				...state,
				globals: {
					...state.globals,
					currentResident: {
						...residentRecord,
					},
				},
			};
		}
		case "NO_RESIDENT_LOADED": {
			return {
				...state,
				globals: {
					...state.globals,
					currentResident: {
						firstName: null,
						lastName: null,
						age: null,
						residentID: null,
						unit: null,
						height: null,
						weight: null,
						mdReportDue: null,
						servicePlanDue: null,
						monthlyMedReview: null,
						bathNotes: null,
						escortNotes: null,
						groomingNotes: null,
						hygieneNotes: null,
						loa: {},
					},
				},
			};
		}
		case "REVERT_MARK_COMPLETE_BY_ADL": {
			const { adl } = action.data;
			const { prevState } = state.globals;
			// const updatedScheduled = toggleCompleteByADL(scheduledTasks, adl);
			// const updatedUnscheduled = toggleCompleteByADL(unscheduledTasks, adl);
			return {
				...state,
				app: {
					...state.app,
					hasUpdated: true,
				},
				globals: {
					...state.globals,
					scheduledTasks: [...prevState.scheduledTasks],
					unscheduledTasks: [...prevState.unscheduledTasks],
					prevState: {},
				},
			};
		}
		// VIEW-SPECIFIC ACTIONS - DAILY VIEW
		case "MARK_COMPLETE_BY_ADL": {
			const { adl } = action.data; // String
			// prevState to be used
			const {
				scheduledTasks,
				unscheduledTasks,
				trackingTasks,
				unscheduledTasksRaw,
			} = state.globals;
			const updatedScheduled = markCompleteByADL(scheduledTasks, adl);
			const updatedUnscheduled = markCompleteByADL(unscheduledTasks, adl);

			return {
				...state,
				app: {
					...state.app,
					hasUpdated: true,
				},
				globals: {
					...state.globals,
					scheduledTasks: [...updatedScheduled],
					unscheduledTasks: [...updatedUnscheduled],
					prevState: {
						scheduledTasks: [...scheduledTasks],
						unscheduledTasks: [...unscheduledTasks],
						scheduledRecords: [...trackingTasks],
						unscheduledRecords: [...unscheduledTasksRaw],
					},
				},
			};
		}
		case "QUICK_CREATE_TASK": {
			const { newTask } = action.data;
			const { unscheduledTasks } = state.globals;
			return {
				...state,
				globals: {
					...state.globals,
					unscheduledTasks: [...unscheduledTasks, newTask],
				},
			};
		}
		case "CREATE_NEW_TASK": {
			const { newTasks } = action.data;
			const { unscheduledTasks } = state.globals;
			return {
				...state,
				app: {
					...state.app,
					hasUpdated: true,
				},
				globals: {
					...state.globals,
					unscheduledTasks: [...unscheduledTasks, ...newTasks],
				},
			};
		}
		case "ADVANCED_UPDATE": {
			const { updatedTask, taskType, reassessRecord = {} } = action.data;
			const { scheduledTasks, unscheduledTasks, currentResident } =
				state.globals;
			const { reassessRecords = [] } = currentResident;
			// merge old/existing reassess w/ newly created record, if applicable
			const newReassess = [...reassessRecords, reassessRecord];

			// handle 'Scheduled' and 'Unscheduled' tasks differently
			if (taskType === "SCHEDULED") {
				return {
					...state,
					app: {
						...state.app,
						hasUpdated: true,
					},
					globals: {
						...state.globals,
						scheduledTasks: [
							...findAndReplaceStale(updatedTask, scheduledTasks),
						],
						currentResident: {
							...currentResident,
							reassessRecords: newReassess,
						},
					},
				};
			} else {
				return {
					...state,
					app: {
						...state.app,
						hasUpdated: true,
					},
					globals: {
						...state.globals,
						unscheduledTasks: [
							...findAndReplaceStale(updatedTask, unscheduledTasks),
						],
						currentResident: {
							...currentResident,
							reassessRecords: newReassess,
						},
					},
				};
			}
		}
		// will find existing (stale) task, and replace it("in-place") w/ the updated task
		case "TOGGLE_COMPLETE": {
			const { updatedTask, taskType } = action.data;
			const { scheduledTasks, unscheduledTasks } = state.globals;

			if (taskType === "SCHEDULED") {
				return {
					...state,
					app: {
						...state.app,
						hasUpdated: true,
					},
					globals: {
						...state.globals,
						scheduledTasks: [
							...findAndReplaceStale(updatedTask, scheduledTasks),
						],
					},
				};
			} else {
				return {
					...state,
					app: {
						...state.app,
						hasUpdated: true,
					},
					globals: {
						...state.globals,
						unscheduledTasks: [
							...findAndReplaceStale(updatedTask, unscheduledTasks),
						],
					},
				};
			}
		}
		// TIMEVIEW CASE(S)
		case "LOAD_TIMEVIEW": {
			const { photos, photosMap, selectedFacility } = action.data;
			const { globals, user } = state;
			const { facilities } = user;
			const { currentFacility } = globals;
			const { id, name, shifts, residents } = selectedFacility;

			// find matching facility record

			return {
				...state,
				globals: {
					...globals,
					currentFacility: {
						...currentFacility,
						facilityID: id,
						communityName: name,
						shifts: shifts,
						residents: residents,
						photos: photos,
						photosMap: photosMap,
					},
				},
			};
		}
		case "TV_ADVANCED_UPDATE": {
			const { updatedTask } = action.data;

			return {
				...state,
			};
		}
		case "LOAD_RESIDENTS_ONLY": {
			const { residents, residentsByFacility } = action.data;

			return {
				...state,
				residents,
				residentsByFacility,
			};
		}
		default:
			throw new Error("❌ Oops. Action(type) not recognized:", action.type);
	}
};

const GlobalStateProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialGlobalState);

	return (
		<GlobalStateContext.Provider value={{ state, dispatch }}>
			{children}
		</GlobalStateContext.Provider>
	);
};

export { initialGlobalState, GlobalStateContext, GlobalStateProvider };
