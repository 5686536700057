import React, { useRef, useEffect } from "react";
import styles from "../../css/history/HistoricalBatchActionsModal.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { useKeyboardShortcut } from "../../utils/useKeyboardShortcut";
import { useViewportSize } from "../../utils/useViewportSize";
import { useLockBodyScroll } from "../../utils/useLockBodyScroll";

// REQUIREMENTS:
// - Supports feature(s):
//    - Assigning changes to a caregiver/user
// 		- Mark all as done (eg. COMPLETED)
// 		- Change or Remove exception(s)
// 		- Change status

const HistoricalBatchActionsModal = ({
	title = "Batch Actions",
	actionType = null,
	taskState = {},
	closeModal,
	children,
}) => {
	const modalRef = useRef();
	const { isOutside } = useOutsideClick(modalRef);
	const userEscaped = useKeyboardShortcut(["Escape"]);
	const viewport = useViewportSize();
	useLockBodyScroll();

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (isOutside || userEscaped) {
			return closeModal();
		}
		return () => {
			isMounted = false;
		};
	}, [isOutside, closeModal, userEscaped]);
	return (
		<aside className={styles.HistoricalBatchActionsModal} ref={modalRef}>
			<section className={styles.HistoricalBatchActionsModal_top}>
				<h4 className={styles.HistoricalBatchActionsModal_top_title}>
					{title}
				</h4>
				<svg
					className={styles.HistoricalBatchActionsModal_top_icon}
					onClick={closeModal}
				>
					<use xlinkHref={`${sprite}#icon-clearclose`}></use>
				</svg>
			</section>
			<section className={styles.HistoricalBatchActionsModal_main}>
				{children}
			</section>
		</aside>
	);
};

export default HistoricalBatchActionsModal;

HistoricalBatchActionsModal.defaultProps = {};

HistoricalBatchActionsModal.propTypes = {};
