import React, { useState, useEffect } from "react";
import styles from "../../css/timeview/TimeViewResidentDetails.module.scss";
import { PropTypes } from "prop-types";
import { getResidentInfo } from "../../helpers/utils_residentData";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { formatResidentNameTimeView } from "../../helpers/utils_residents";
// components
import Spinner from "../shared/Spinner";
import ResidentPanel from "../residentinfo/ResidentPanel";
import ResidentInfo from "../residentinfo/ResidentInfo";
import ResidentDetails from "../residentinfo/ResidentDetails";
import ResidentMeds from "../residentinfo/ResidentMeds";
import ResidentServicePlan from "../residentinfo/ResidentServicePlan";
import TimeViewResidentPhoto from "./TimeViewResidentPhoto";

const TimeViewResidentDetails = ({
	selectedResident = {},
	currentUser = {},
	currentFacility = {},
	openNewResidentTaskModal,
}) => {
	const [residentInfo, setResidentInfo] = useState({
		profile: {},
		contacts: [],
		servicePlans: {},
	});
	const [isLoadingProfile, setIsLoadingProfile] = useState(true);

	// fetches onMount & whenever 'selectedResident' changes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		// fetches profile, and sets "residentProfile"
		const fetchProfile = async () => {
			const { residentID } = selectedResident;
			const { token } = currentUser;
			// contains 'profile', 'svc-plan(s)' & 'contacts'
			const profile = await getResidentInfo(token, residentID);
			console.log("profile", profile);

			if (!isEmptyObj(profile)) {
				setIsLoadingProfile(false);
				return setResidentInfo(profile);
			} else {
				setIsLoadingProfile(false);
				return setResidentInfo({ profile: {}, contacts: [], servicePlans: {} });
			}
		};
		fetchProfile();

		return () => {
			isMounted = false;
		};
	}, [currentUser, selectedResident]);

	return (
		<div className={styles.TimeViewResidentDetails}>
			<header className={styles.TimeViewResidentDetails_header}>
				<TimeViewResidentPhoto
					task={selectedResident}
					imgSize="LG"
					residentImage={{
						src: selectedResident?.photoSrc,
						alt: "Resident Photo (LG)",
					}}
					residentInfo={{
						...selectedResident,
						RoomNum: selectedResident?.roomNum,
					}}
				/>
				<div className={styles.TimeViewResidentDetails_header_title}>
					<div className={styles.TimeViewResidentDetails_header_title_name}>
						{formatResidentNameTimeView(selectedResident)}
					</div>
					<button
						type="button"
						onClick={() => openNewResidentTaskModal(residentInfo)}
						className={styles.TimeViewResidentDetails_header_title_newTask}
					>
						New Task
					</button>
				</div>
			</header>

			<div className={styles.TimeViewResidentDetails_main}>
				{/* LOADING UI SPINNER */}
				{isLoadingProfile && (
					<div className={styles.TimeViewResidentDetails_main_loader}>
						<div className={styles.TimeViewResidentDetails_main_loader_msg}>
							Loading {formatResidentNameTimeView(selectedResident)}'s
							profile...
						</div>
						<Spinner />
					</div>
				)}

				{/* {!isLoadingProfile && !isEmptyVal(residentInfo?.profile?.ID) && ( */}
				{!isLoadingProfile && !isEmptyVal(residentInfo?.profile?.ID) && (
					<>
						<ResidentPanel
							isProfileLoaded={true}
							isLoadingProfile={isLoadingProfile}
							profile={residentInfo?.profile}
							contacts={residentInfo?.contacts}
							currentResident={{
								...residentInfo?.profile,
								ResidentID: selectedResident?.residentID,
							}}
						>
							<ResidentDetails title="General Info">
								<ResidentInfo />
							</ResidentDetails>
							<ResidentDetails title="Medical Info">
								<ResidentMeds />
							</ResidentDetails>
						</ResidentPanel>
						<div className={styles.TimeViewResidentDetails_main_heading}>
							Service Plan Info
						</div>
						<ResidentServicePlan
							currentUser={currentUser}
							currentResident={residentInfo}
							residentProfile={residentInfo} // ????
						/>
					</>
				)}
			</div>
			{/*  */}
		</div>
	);
};

export default TimeViewResidentDetails;

TimeViewResidentDetails.defaultProps = {};

TimeViewResidentDetails.propTypes = {};
