import React, { useState } from "react";
import styles from "../../css/timeview/ResidentPhotoController.module.scss";
import { PropTypes } from "prop-types";
import TimeViewResidentPhoto from "./TimeViewResidentPhoto";

const ResidentPhotoController = ({
	imgSize = "MD",
	handleClick,
	residentDetails = {},
}) => {
	const [showHover, setShowHover] = useState(false);

	const { src, alt, roomNum, id } = residentDetails;
	const info = {
		ResidentID: id,
		RoomNum: roomNum,
	};
	const photo = {
		src: src,
		alt: alt,
	};

	return (
		<div
			className={styles.ResidentPhotoController}
			onClick={handleClick}
			onMouseOver={() => setShowHover(true)}
			onMouseLeave={() => setShowHover(false)}
		>
			{showHover && (
				<div className={styles.ResidentPhotoController_hover}>View Info</div>
			)}
			<TimeViewResidentPhoto
				imgSize={imgSize}
				residentImage={photo}
				residentInfo={info}
			/>
		</div>
	);
};

export default ResidentPhotoController;

ResidentPhotoController.defaultProps = {};

ResidentPhotoController.propTypes = {};
