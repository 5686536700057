import React, { useReducer, useState } from "react";
import styles from "../../css/history/HistoricalTasksController.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import sprite2 from "../../assets/icons/filters.svg";
import { PropTypes } from "prop-types";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import { getTaskID, isScheduledTask } from "../../helpers/utils_tasks";
import { blueGrey, blueGreys, purple, red } from "../../helpers/utils_styles";
import { removeDuplicates } from "../../helpers/utils_processing";
import {
	BASE_EXCEPTION_TYPES as exceptionTypes,
	FACILITY_EXCEPTION_TYPES as facilityExceptionTypes,
} from "../../helpers/utils_options";
import {
	getHistoryViewExceptions,
	generateHistoryTasksForChangeStatus,
	generateHistoryTasksForCompletion,
	generateHistoryTasksForException,
	generateHistoryTasksForPastDue,
	markHistoricalAsDoneBatch,
	removeExceptionsFromList,
	removeHistoricalExceptionsBatch,
	sortHistoricalTasks,
	removeHistoricalPastDueBatch,
	sortHistoryTasksByType,
	saveHistoricalTasks,
	wasHistoryChangesSaved,
	getTotalTaskCount,
	removePastDueFromList,
} from "../../helpers/utils_historical";
// components
import ModalLG from "../shared/ModalLG";
import HistoricalTaskActions from "./HistoricalTaskActions";
import HistoricalBatchActionsModal from "./HistoricalBatchActionsModal";
import HistoricalTaskList from "./HistoricalTaskList";
import HistoricalTaskDetails from "./HistoricalTaskDetails";
import BatchActionsHandler from "./BatchActionsHandler";
import HistoricalTaskSortController from "./HistoricalTaskSortController";
import HistoricalModal from "./HistoricalModal";
import SingleTaskActionsHandler from "./SingleTaskActionsHandler";
import HistoricalSingleActionModal from "./HistoricalSingleActionModal";

// REQUIREMENTS:
// - BATCH UPDATE(s):
// 		- Mark as done
// 		- Change status
// 		- Remove exceptions
// 		- Remove past-due???
// - SINGLE UPDATE(s):
// 		- Mark as done
// 		- Change status
// 		- Remove exception
// 		- Add, edit, remove notes

// merges tasks into single array & with 'initialState'
const getInitialTaskState = (tasksFromParent = {}) => {
	const { scheduled = [], unscheduled = [] } = tasksFromParent;
	const missed = getMissedTasks(tasksFromParent);
	const merged = [...scheduled, ...unscheduled];
	const sorted = sortHistoricalTasks(merged);

	const newState = {
		...initialState,
		tasks: {
			scheduled: scheduled,
			unscheduled: unscheduled,
			missed: [...missed],
			// all: [...scheduled, ...unscheduled],
			all: sorted,
		},
	};

	return newState;
};

const defaultIcon = "check_box_outline_blank";
const selectedIcon = "check_box";

// Searches: ADL, resident, task & shift
const advancedSearch = (val, options = []) => {
	const str = val?.toLowerCase();

	return options.filter((item) => {
		const { ADL, FirstName, LastName, TaskDescription, Shift } = item;

		if (
			ADL.toLowerCase().includes(str) ||
			ADL.toLowerCase().startsWith(str) ||
			FirstName.toLowerCase().includes(str) ||
			FirstName.toLowerCase().startsWith(str) ||
			LastName.toLowerCase().includes(str) ||
			LastName.toLowerCase().startsWith(str) ||
			TaskDescription.toLowerCase().includes(str) ||
			TaskDescription.toLowerCase().startsWith(str) ||
			Shift.toLowerCase().includes(str) ||
			Shift.toLowerCase().startsWith(str)
		) {
			return item;
		} else {
			return;
		}
	});
};

const getSelectedCSS = (isSelected = false) => {
	if (isSelected) {
		return {
			fill: blueGreys[400],
			opacity: 1,
		};
	} else {
		return {
			// fill: blueGrey[400],
			opacity: 0.6,
		};
	}
};

const getMissedTasks = (allTasks = {}) => {
	const { scheduled, unscheduled } = allTasks;
	const merged = [...scheduled, ...unscheduled];

	// Exceptions, Past-Due, Not-Complete (ie. omit Completed tasks)
	// const missedTasks = merged.filter(task => !task.IsCompleted)
	const missedTasks = merged.filter((task) => {
		if (!task.IsCompleted || !isEmptyVal(task.Exception)) {
			return task;
		} else {
			return;
		}
	});

	return missedTasks;
};

const findTaskMatch = (task1, task2) => {
	const id1 = getTaskID(task1);
	const id2 = getTaskID(task2);

	const isSame = id1 === id2;

	return isSame;
};

const showTasksByType = (
	sortType,
	isSearching,
	searchResults = [],
	taskState = {}
) => {
	const { selected, tasks } = taskState;

	switch (true) {
		case isSearching: {
			return searchResults;
		}
		case sortType === "ALL": {
			return tasks?.all;
		}
		case sortType === "MISSED": {
			return tasks?.missed;
		}
		case sortType === "EXCEPTIONS": {
			const exceptionsOnly = getHistoryViewExceptions(tasks?.all);
			return exceptionsOnly;
		}

		default:
			return tasks?.all ?? [];
	}
};

const historyReducer = (state, action) => {
	switch (action.type) {
		case "SINGLE-SELECT-TASK": {
			const { selectedTask } = action.data;
			// current state selections
			const { selected } = state;
			// return all tasks except ones matching 'selectedTask' id
			// - this prevents duplicates & will remove the task from selections if already selected
			const selectedIDs = [...selected?.all.map((x) => getTaskID(x))];

			// if task is already selected de-select it???
			if (selectedIDs.includes(getTaskID(selectedTask))) {
				const newList = [
					...selected.all.filter(
						(x) => getTaskID(x) !== getTaskID(selectedTask)
					),
				];
				return {
					...state,
					selected: {
						...state.selected,
						all: [...newList],
						prevSelected: selectedTask,
					},
				};
			} else {
				return {
					...state,
					selected: {
						...state.selected,
						all: [...state.selected.all, selectedTask],
						prevSelected: selectedTask,
					},
				};
			}
		}
		case "SELECT-ALL-TASKS": {
			const { tasks, selected } = state;
			const { sortType, isSearching, searchResults } = action.data;

			// determine what the UI's current display is (ie. what tasks r available for selection)
			// - Added: 2/17/2023 at 9:21 AM
			const availableTasks = showTasksByType(
				sortType,
				isSearching,
				searchResults,
				state
			);
			const newSelected = [...availableTasks];
			const noDups = removeDuplicates(newSelected);
			// PREVIOUS CODE BEFORE ABOVE CHANGES WERE ADDED!!!
			// const newSelected = [...tasks?.all];
			// const noDups = removeDuplicates(newSelected);

			// all tasks are currently selected/de-select them
			if (selected?.isAllSelected) {
				return {
					...state,
					selected: {
						...state.selected,
						scheduled: [],
						unscheduled: [],
						all: [],
						isAllSelected: false,
					},
				};
			} else {
				return {
					...state,
					selected: {
						...state.selected,
						scheduled: [],
						unscheduled: [],
						all: [...noDups],
						isAllSelected: true,
					},
				};
			}
		}
		case "SHIFT-SELECT-TASKS": {
			const { selectedTask } = action.data;
			const { sortType, isSearching, searchResults } = action.data;
			// state
			const { selected, tasks } = state;
			const availableTasks = showTasksByType(
				sortType,
				isSearching,
				searchResults,
				state
			);
			// ##TODOS:
			// - Add 'showTasksByType' to selection logic below

			// 1. Check if 'prevSelected' is populated
			// 		1a. IF populated, proceed w/ multi/shift select
			// 		1b. IF NOT populated, operate as normal/single select
			// 2. Find index of 'selectedTask' (IF 'prevSelected' is populated)
			// 3. Find index of 'prevSelected'
			if (!isEmptyObj(selected?.prevSelected)) {
				const prevIdx = tasks?.all.indexOf(selected?.prevSelected);
				const curIdx = tasks?.all.indexOf(selectedTask);
				const inRange = [...tasks?.all.slice(prevIdx, curIdx)];
				const newSelected = [...selected?.all, ...inRange, selectedTask];
				const noDups = removeDuplicates(newSelected);

				return {
					...state,
					selected: {
						...state.selected,
						all: noDups,
						prevSelected: selectedTask,
					},
				};
			} else {
				// if no 'prevSelected' then just select/de-select as normal
				return {
					...state,
					selected: {
						...state.selected,
						all: [...selected.all, selectedTask],
					},
				};
			}

			return null;
		}

		// SINGLE TASK ACTIONS //
		case "SINGLE-EDIT-TASK": {
			return null;
		}
		case "SINGLE-REMOVE-EXCEPTION": {
			const { selected, tasks } = state;
			const { assignTo, sortType, isSearching, searchResults } = action.data;

			// Iterate thru selected tasks & remove exceptions
			// - remove from 'allTasks'
			const updatedTasks = removeHistoricalExceptionsBatch(
				assignTo,
				selected?.all,
				tasks?.all
			);
			// - remove from 'selectedTasks'
			const updatedSelections = removeExceptionsFromList(selected?.all);

			// const availableTasks = showTasksByType(
			// 	sortType,
			// 	isSearching,
			// 	searchResults,
			// 	state
			// );

			return {
				...state,
				selected: {
					...state.selected,
					all: updatedSelections,
				},
				tasks: {
					...state.tasks,
					all: updatedTasks,
				},
			};
		}
		case "SINGLE-REMOVE-PAST-DUE": {
			const { selected, tasks } = state;
			const { assignTo } = action.data;
			const { all: allSelected } = selected;
			const { all: allTasks } = tasks;

			// remove from 'allTasks'
			const updatedTasks = removeHistoricalPastDueBatch(
				assignTo,
				allSelected,
				allTasks
			);
			// remove from 'selected' tasks
			const updatedSelections = removePastDueFromList(allSelected);

			return {
				...state,
				selected: {
					...state?.selected,
					all: updatedSelections,
				},
				tasks: {
					...state.tasks,
					all: updatedTasks,
				},
			};
		}
		case "SINGLE-MARK-AS-DONE": {
			const { selected, tasks } = state;
			const { assignTo } = action.data;
			const { all: allSelected } = selected;
			const { all: allTasks } = tasks;

			// ##TODOS:
			// - Add 'CompletedByUserId' to each task via 'assignTo' value

			// apply updates to selected & tasks
			const updatedSelections = markHistoricalAsDoneBatch(
				assignTo,
				allSelected,
				allTasks
			);

			return {
				...state,
				tasks: {
					...state.tasks,
					all: updatedSelections,
				},
			};
		}
		case "SINGLE-CHANGE-STATUS": {
			return null;
		}

		// BATCH/BULK TASK ACTIONS //
		case "BATCH-REMOVE-EXCEPTIONS": {
			const { selected, tasks } = state;
			const { assignTo, sortType, isSearching, searchResults } = action.data;

			// Iterate thru selected tasks & remove exceptions
			// - remove from 'allTasks'
			const updatedTasks = removeHistoricalExceptionsBatch(
				assignTo,
				selected?.all,
				tasks?.all
			);
			// - remove from 'selectedTasks'
			const updatedSelections = removeExceptionsFromList(selected?.all);

			// const availableTasks = showTasksByType(
			// 	sortType,
			// 	isSearching,
			// 	searchResults,
			// 	state
			// );

			return {
				...state,
				selected: {
					...state.selected,
					all: updatedSelections,
				},
				tasks: {
					...state.tasks,
					all: updatedTasks,
				},
			};
		}
		case "BATCH-MARK-AS-DONE": {
			const { selected, tasks } = state;
			const { assignTo } = action.data;
			const { all: allSelected } = selected;
			const { all: allTasks } = tasks;

			// ##TODOS:
			// - Add 'CompletedByUserId' to each task via 'assignTo' value

			// apply updates to selected & tasks
			const updatedSelections = markHistoricalAsDoneBatch(
				assignTo,
				allSelected,
				allTasks
			);

			return {
				...state,
				tasks: {
					...state.tasks,
					all: updatedSelections,
				},
			};
		}
		case "BATCH-CHANGE-STATUS": {
			const { selected, tasks } = state;
			const { assignTo } = action.data;
			const { all: allSelected } = selected;
			const { all: allTasks } = tasks;

			return {
				...state,
				selected: {
					...state.selected,
				},
			};
		}
		case "BATCH-REMOVE-PAST-DUE": {
			const { selected, tasks } = state;
			const { assignTo } = action.data;
			const { all: allSelected } = selected;
			const { all: allTasks } = tasks;

			const updatedSelections = removeHistoricalPastDueBatch(
				assignTo,
				allSelected,
				allTasks
			);

			return {
				...state,
				tasks: {
					...state.tasks,
					all: updatedSelections,
				},
			};
		}
		// SYNCING TASK UPDATES/CHANGES
		case "SYNC-TASK-DETAILS": {
			const { updatedTask } = action.data;
			const id = getTaskID(updatedTask);
			const { all } = state?.tasks;
			const newAll = [...all];
			const taskIdx = newAll.reduce((matchIdx, item, idx) => {
				const itemID = getTaskID(item);
				if (itemID === id) {
					matchIdx = idx;
					return matchIdx;
				} else {
					return matchIdx;
				}
			}, 0);
			// replace task w/ 'updatedTask'
			newAll[taskIdx] = updatedTask;

			return {
				...state,
				tasks: {
					...state.tasks,
					all: newAll,
				},
			};
		}

		default:
			return state;
	}
};

const initialState = {
	tasks: {
		scheduled: [],
		unscheduled: [],
		missed: [],
		all: [],
	},
	selected: {
		scheduled: [],
		unscheduled: [],
		all: [],
		isAllSelected: false,
		prevSelected: {},
	},
};

const SelectAllIcon = ({ isAllSelected = false, handleSelectAll }) => {
	return (
		<div className={styles.SelectAllIcon} onClick={handleSelectAll}>
			<svg
				className={styles.SelectAllIcon_icon}
				style={getSelectedCSS(isAllSelected)}
			>
				<use
					xlinkHref={`${sprite}#icon-${
						isAllSelected ? selectedIcon : defaultIcon
					}`}
				></use>
			</svg>
			<div className={styles.SelectAllIcon_text}>
				{isAllSelected ? "De-select All" : "Select All"}
			</div>
		</div>
	);
};

// const TaskCounter = ({ allTasks = {}, selectedTasks = {} }) => {
const TaskCounter = ({ sortType, selectedList = [], allList = [] }) => {
	const outOf = isEmptyArray(allList) ? selectedList?.length : allList?.length;

	return (
		<div className={styles.TaskCounter}>
			<div className={styles.TaskCounter_selected}>
				<span>
					{/* <b>{selectedList?.length ?? 0}</b> of <b>{allList?.length ?? 0}</b>{" "} */}
					<b>{selectedList?.length ?? 0}</b> of <b>{outOf}</b> selected
				</span>
			</div>
			<div className={styles.TaskCounter_msg}>
				<SortCounterMsg sortType={sortType} count={allList?.length ?? 0} />
				{/* <span>
					Showing <b>{allList?.length ?? 0}</b> {getTaskIndicator(sortType)} tasks
				</span> */}
			</div>
		</div>
	);
};

const SortCounterMsg = ({ sortType, count }) => {
	return (
		<span>
			Showing <b>{count}</b>{" "}
			<SortTypeText sortTypeText={getSortTypeText(sortType)} /> tasks
		</span>
	);
};
const SortTypeText = ({ sortTypeText }) => {
	if (sortTypeText === "All") {
		return null;
	}
	return <b style={getSortTypeCss(sortTypeText)}>{sortTypeText}</b>;
};

const getSortTypeCss = (sortTypeText) => {
	switch (sortTypeText) {
		case "Missed": {
			return {
				color: purple[500],
			};
		}
		case "Exception": {
			return {
				color: red[500],
			};
		}
		case "All": {
			return {
				color: blueGrey[500],
			};
		}

		default:
			return {};
	}
};

const getSortTypeText = (sortType) => {
	switch (sortType) {
		case "MISSED": {
			return "Missed";
		}
		case "EXCEPTIONS": {
			return "Exception";
		}
		case "ALL": {
			return "";
		}
		default:
			return "";
	}
};

const SearchInput = ({ searchVal, handleSearch, clearSearch }) => {
	return (
		<div className={styles.SearchInput}>
			<div className={styles.SearchInput_wrapper}>
				<input
					type="text"
					name="searchHistory"
					id="searchHistory"
					value={searchVal}
					onChange={handleSearch}
					className={styles.SearchInput_wrapper_input}
					placeholder="Search by ADL, resident, task..."
				/>
				{!isEmptyVal(searchVal) && (
					<div
						className={styles.SearchInput_wrapper_clear}
						onClick={clearSearch}
					>
						<svg className={styles.SearchInput_wrapper_clear_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</div>
				)}
			</div>
		</div>
	);
};

const SortIcon = ({ openSortModal }) => {
	return (
		<div className={styles.SortIcon} onClick={openSortModal}>
			<svg className={styles.SortIcon_icon}>
				<use xlinkHref={`${sprite2}#icon-filter_list_alt`}></use>
			</svg>
			<span className={styles.SortIcon_text}>Filter</span>
		</div>
	);
};

const HistoricalTasksController = ({
	sortType,
	setSortType,
	tasksByType = {},
	currentUser = {},
	currentFacility = {},
	photosMap = {},
	dispatchAlert,
}) => {
	const [historicalTaskState, historyDispatch] = useReducer(historyReducer, {
		...getInitialTaskState(tasksByType),
	});
	const { tasks, selected } = historicalTaskState;
	// task details modal
	const [showSingleTaskModal, setShowSingleTaskModal] = useState(false);
	const [singleActionType, setSingleActionType] = useState(null);
	const [isSavingSingleAction, setIsSavingSingleAction] = useState(false);
	// batch actions
	const [showBatchActionsModal, setShowBatchActionsModal] = useState(false);
	const [showSortModal, setShowSortModal] = useState(false);
	const [batchActionType, setBatchActionType] = useState(null);
	// task selected for details viewing
	// const [targetTask, setTargetTask] = useState({});

	// search state(s)
	const [searchVal, setSearchVal] = useState("");
	const [searchResults, setSearchResults] = useState(tasks?.all ?? []);
	const [isSearching, setIsSearching] = useState(false);
	// user assignment - stores client-formatted user obj
	const [assignTo, setAssignTo] = useState({});
	const [hasPendingChanges, setHasPendingChanges] = useState(false);
	// state loader for single & batch task changes
	const [isSavingChanges, setIsSavingChanges] = useState(false);
	const [hasError, setHasError] = useState(false);
	// array of tasks, that have been edited, but not saved yet.
	const [pendingQueueTasks, setPendingQueueTasks] = useState({
		scheduled: [],
		unscheduled: [],
	});

	const handleSearch = (e) => {
		const { value } = e.target;
		if (isEmptyVal(value)) {
			setSearchVal("");
			setIsSearching(false);
			return setSearchResults(tasks?.all ?? []);
		} else {
			setIsSearching(true);
			setSearchVal(value);
			return setSearchResults([...advancedSearch(value, tasks?.all)]);
		}
	};

	const clearSearch = (e) => {
		setSearchVal("");
		setIsSearching(false);
		return setSearchResults(tasks?.all ?? []);
	};

	const openBatchActionsModal = (type) => {
		setShowBatchActionsModal(true);
		setBatchActionType(type);
	};
	const closeBatchActionsModal = () => {
		setShowBatchActionsModal(false);
		setBatchActionType(null);
	};

	const openSingleTaskModal = (type) => {
		setShowSingleTaskModal(true);
		setSingleActionType(type);
	};
	const closeSingleTaskModal = () => {
		setShowSingleTaskModal(false);
		setSingleActionType(null);
	};

	// const openTaskDetails = (task) => {
	// 	setTargetTask(task);
	// 	setShowTaskDetailsModal(true);
	// 	// sets task in pending queue
	// 	if (isScheduledTask(task)) {
	// 		return setPendingQueueTasks({
	// 			...pendingQueueTasks,
	// 			scheduled: [...pendingQueueTasks?.scheduled, task],
	// 		});
	// 	} else {
	// 		return setPendingQueueTasks({
	// 			...pendingQueueTasks,
	// 			unscheduled: [...pendingQueueTasks?.unscheduled, task],
	// 		});
	// 	}
	// };

	// const closeTaskDetails = () => {
	// 	setTargetTask({});
	// 	setShowTaskDetailsModal(false);

	// 	// sets task in pending queue
	// 	// if (isScheduledTask(task)) {
	// 	// 	return setPendingQueueTasks({
	// 	// 		...pendingQueueTasks,
	// 	// 		scheduled: [...pendingQueueTasks?.scheduled, task],
	// 	// 	});
	// 	// } else {
	// 	// 	return setPendingQueueTasks({
	// 	// 		...pendingQueueTasks,
	// 	// 		unscheduled: [...pendingQueueTasks?.unscheduled, task],
	// 	// 	});
	// 	// }
	// };

	// selects/de-selects a single task
	const handleSelectTask = (e, task = {}) => {
		if (e.nativeEvent.shiftKey) {
			console.log(`Holding SHIFT...`);
			return historyDispatch({
				type: "SHIFT-SELECT-TASKS",
				data: {
					selectedTask: task,
					sortType: sortType,
					isSearching: isSearching,
					searchResults: searchResults,
				},
			});
		} else {
			return historyDispatch({
				type: "SINGLE-SELECT-TASK",
				data: {
					selectedTask: task,
				},
			});
		}
	};

	const handleSelectAll = () => {
		historyDispatch({
			type: "SELECT-ALL-TASKS",
			data: {
				sortType: sortType,
				isSearching: isSearching,
				searchResults: searchResults,
			},
		});
	};

	const handleUserAssignment = (user = {}) => {
		if (assignTo?.userID === user?.userID) {
			return setAssignTo({});
		} else {
			return setAssignTo(user);
		}
	};

	// batch actions handlers //

	// marks multiple tasks completed at once
	const handleBatchCompletion = async () => {
		setIsSavingChanges(true);
		const { token } = currentUser;
		const { all: allSelected } = selected;
		const { all: allTasks } = tasks;

		// generate tasks w/ updated fields
		const pendingTasks = generateHistoryTasksForCompletion(
			assignTo,
			allSelected,
			allTasks
		);
		const response = await saveHistoricalTasks(token, pendingTasks);
		// const response = {
		// 	scheduled: { response: [123, 546], status: "FULFILLED" },
		// 	unscheduled: { response: null, status: null },
		// };
		console.log("pendingTasks(COMPLETIONS)", pendingTasks);
		// fire off request to server
		// apply completions to client-tasks
		// close modal upon success
		if (wasHistoryChangesSaved(response)) {
			// fire off request to server
			// apply exceptions to client-tasks
			// close modal upon success
			historyDispatch({
				type: "BATCH-MARK-AS-DONE",
				data: {
					assignTo: assignTo,
					sortType: sortType,
					isSearching: isSearching,
					searchResults: searchResults,
				},
			});
			setIsSavingChanges(false);
			setShowBatchActionsModal(false);
			return dispatchAlert("SUCCESS", {
				heading: `${getTotalTaskCount(
					pendingTasks?.scheduled,
					pendingTasks?.unscheduled
				)} tasks were updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Something went wrong.`,
				subheading: `Please try again!`,
			});
		}
	};
	// changes status of multiple tasks at once
	const handleBatchStatus = async () => {
		setIsSavingChanges(true);
		const { token } = currentUser;
		const { all: allSelected } = selected;
		const { all: allTasks } = tasks;

		// generate tasks w/ updated fields
		const pendingTasks = generateHistoryTasksForChangeStatus(
			assignTo,
			allSelected,
			allTasks
		);
		// const response = await saveHistoricalTasks(token, pendingTasks);
		const response = {
			scheduled: { response: [123, 546], status: "FULFILLED" },
			unscheduled: { response: null, status: null },
		};

		console.log("pendingTasks(STATUS)", pendingTasks);

		if (wasHistoryChangesSaved(response)) {
			// fire off request to server
			// apply exceptions to client-tasks
			// close modal upon success
			historyDispatch({
				type: "BATCH-REMOVE-EXCEPTIONS",
				data: {
					assignTo: assignTo,
					sortType: sortType,
					isSearching: isSearching,
					searchResults: searchResults,
				},
			});
			setIsSavingChanges(false);
			setShowBatchActionsModal(false);
			return dispatchAlert("SUCCESS", {
				heading: `${getTotalTaskCount(
					pendingTasks?.scheduled,
					pendingTasks?.unscheduled
				)} tasks were updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Something went wrong.`,
				subheading: `Please try again!`,
			});
		}
	};
	// removes exceptions from multiple tasks at once
	const handleBatchExceptions = async () => {
		setIsSavingChanges(true);
		const { token } = currentUser;
		const { all: allSelected } = selected;
		const { all: allTasks } = tasks;

		// generate tasks w/ updated fields - by task type
		const pendingTasks = generateHistoryTasksForException(
			assignTo,
			allSelected,
			allTasks
		);
		const response = await saveHistoricalTasks(token, pendingTasks);
		// const response = {
		// 	scheduled: { response: [123, 546], status: "FULFILLED" },
		// 	unscheduled: { response: null, status: null },
		// };

		console.group("Exceptions Handler(BATCH)");
		console.log("pendingTasks:", pendingTasks);
		console.log("All Responses", response);
		console.groupEnd();

		if (wasHistoryChangesSaved(response)) {
			// fire off request to server
			// apply exceptions to client-tasks
			// close modal upon success
			historyDispatch({
				type: "BATCH-REMOVE-EXCEPTIONS",
				data: {
					assignTo: assignTo,
					sortType: sortType,
					isSearching: isSearching,
					searchResults: searchResults,
				},
			});
			setIsSavingChanges(false);
			setShowBatchActionsModal(false);
			return dispatchAlert("SUCCESS", {
				heading: `${getTotalTaskCount(
					pendingTasks?.scheduled,
					pendingTasks?.unscheduled
				)} tasks were updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Something went wrong.`,
				subheading: `Please try again!`,
			});
		}
	};
	// removes past-due status from multiple tasks at once
	const handleBatchPastDue = async () => {
		setIsSavingChanges(true);
		const { token } = currentUser;
		const { all: allSelected } = selected;
		const { all: allTasks } = tasks;

		// generate tasks w/ updated fields
		const pendingTasks = generateHistoryTasksForPastDue(
			assignTo,
			allSelected,
			allTasks
		);
		const response = await saveHistoricalTasks(token, pendingTasks);
		// const response = {
		// 	scheduled: { response: [123, 546], status: "FULFILLED" },
		// 	unscheduled: { response: null, status: null },
		// };
		console.log("pendingTasks(PASTDUE)", pendingTasks);
		// fire off request to server
		// apply past-due removals to client-tasks
		// close modal upon success
		if (wasHistoryChangesSaved(response)) {
			historyDispatch({
				type: "BATCH-REMOVE-EXCEPTIONS",
				data: {
					assignTo: assignTo,
					sortType: sortType,
					isSearching: isSearching,
					searchResults: searchResults,
				},
			});
			setIsSavingChanges(false);
			setShowBatchActionsModal(false);
			return dispatchAlert("SUCCESS", {
				heading: `${getTotalTaskCount(
					pendingTasks?.scheduled,
					pendingTasks?.unscheduled
				)} tasks were updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Something went wrong.`,
				subheading: `Please try again!`,
			});
		}
	};

	// single task actions handlers //
	const handleTaskSettings = () => {
		//
		//
	};
	const handleSingleComplete = async () => {
		setIsSavingSingleAction(true);
		const { token } = currentUser;
		const { all: allSelected } = selected;
		const { all: allTasks } = tasks;

		// generate tasks w/ updated fields
		const pendingTasks = generateHistoryTasksForCompletion(
			assignTo,
			allSelected,
			allTasks
		);
		const response = await saveHistoricalTasks(token, pendingTasks);
		// const response = {
		// 	scheduled: { response: [123, 546], status: "FULFILLED" },
		// 	unscheduled: { response: null, status: null },
		// };
		console.group("TEST");
		console.log("pendingTasks(COMPLETIONS)", pendingTasks);
		console.log("response", response);
		console.log("wasHistoryChangesSaved", wasHistoryChangesSaved(response));
		console.groupEnd();
		// fire off request to server
		// apply completions to client-tasks
		// close modal upon success
		if (wasHistoryChangesSaved(response)) {
			// fire off request to server
			// apply exceptions to client-tasks
			// close modal upon success
			historyDispatch({
				type: "SINGLE-MARK-AS-DONE",
				data: {
					assignTo: assignTo,
					sortType: sortType,
					isSearching: isSearching,
					searchResults: searchResults,
				},
			});
			setIsSavingSingleAction(false);
			setShowSingleTaskModal(false);
			return dispatchAlert("SUCCESS", {
				heading: `${getTotalTaskCount(
					pendingTasks?.scheduled,
					pendingTasks?.unscheduled
				)} tasks were updated!`,
			});
		} else {
			setHasError(true);
			return dispatchAlert("ERROR", {
				heading: `Something went wrong.`,
				subheading: `Please try again!`,
			});
		}
	};
	const handleSingleException = async () => {
		setIsSavingSingleAction(true);
		const { token } = currentUser;
		const { all: allSelected } = selected;
		const { all: allTasks } = tasks;

		// generate tasks w/ updated fields - by task type
		const pendingTasks = generateHistoryTasksForException(
			assignTo,
			allSelected,
			allTasks
		);
		const response = await saveHistoricalTasks(token, pendingTasks);
		// const response = {
		// 	scheduled: { response: [123, 546], status: "FULFILLED" },
		// 	unscheduled: { response: null, status: null },
		// };

		console.group("Exceptions Handler(BATCH)");
		console.log("pendingTasks:", pendingTasks);
		console.log("All Responses", response);
		console.groupEnd();

		if (wasHistoryChangesSaved(response)) {
			// fire off request to server
			// apply exceptions to client-tasks
			// close modal upon success
			historyDispatch({
				type: "SINGLE-REMOVE-EXCEPTION",
				data: {
					assignTo: assignTo,
					sortType: sortType,
					isSearching: isSearching,
					searchResults: searchResults,
				},
			});
			setIsSavingSingleAction(false);
			setShowSingleTaskModal(false);
			return dispatchAlert("SUCCESS", {
				heading: `${getTotalTaskCount(
					pendingTasks?.scheduled,
					pendingTasks?.unscheduled
				)} tasks were updated!`,
			});
		} else {
			// setHasError(true)
			return dispatchAlert("ERROR", {
				heading: `Something went wrong.`,
				subheading: `Please try again!`,
			});
		}
	};
	const handleSinglePastDue = async () => {
		setIsSavingSingleAction(true);
		const { token } = currentUser;
		const { all: allSelected } = selected;
		const { all: allTasks } = tasks;

		// generate tasks w/ updated fields
		const pendingTasks = generateHistoryTasksForPastDue(
			assignTo,
			allSelected,
			allTasks
		);
		const response = await saveHistoricalTasks(token, pendingTasks);
		// const response = {
		// 	scheduled: { response: [123, 546], status: "FULFILLED" },
		// 	unscheduled: { response: null, status: null },
		// };
		console.log("pendingTasks(PASTDUE)", pendingTasks);
		// fire off request to server
		// apply past-due removals to client-tasks
		// close modal upon success
		if (wasHistoryChangesSaved(response)) {
			historyDispatch({
				type: "SINGLE-REMOVE-PAST-DUE",
				data: {
					assignTo: assignTo,
					sortType: sortType,
					isSearching: isSearching,
					searchResults: searchResults,
				},
			});
			setIsSavingSingleAction(false);
			setShowSingleTaskModal(false);
			return dispatchAlert("SUCCESS", {
				heading: `${getTotalTaskCount(
					pendingTasks?.scheduled,
					pendingTasks?.unscheduled
				)} tasks were updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Something went wrong.`,
				subheading: `Please try again!`,
			});
		}
	};

	const openSortModal = () => {
		setShowSortModal(true);
	};
	const closeSortModal = () => {
		setShowSortModal(false);
	};

	const syncSortType = (sortType) => {
		setSortType(sortType);
	};

	const syncTaskDetailsChanges = (updatedTask) => {
		// find client task in array of tasks & replace w/ 'updatedTask'
		console.log("updatedTask(Controller)", updatedTask);
		historyDispatch({
			type: "SYNC-TASK-DETAILS",
			data: {
				updatedTask: updatedTask,
			},
		});
	};

	const retryHandler = (actionHandler) => {
		actionHandler();
	};

	return (
		<>
			<div className={styles.HistoricalTasksController}>
				<div className={styles.HistoricalTasksController_actions}>
					<HistoricalTaskActions
						selectedTasks={selected?.all ?? []}
						batchTaskHandler={openBatchActionsModal}
						singleTaskHandler={openSingleTaskModal}
						// singleTaskHandler={openTaskDetails}
						allTasks={showTasksByType(
							sortType,
							isSearching,
							searchResults,
							historicalTaskState
						)}
					/>
					<TaskCounter
						sortType={sortType}
						selectedList={selected?.all}
						allList={showTasksByType(
							sortType,
							isSearching,
							searchResults,
							historicalTaskState
						)}
					/>
					<div className={styles.HistoricalTasksController_actions_bottom}>
						<SelectAllIcon
							handleSelectAll={handleSelectAll}
							isAllSelected={selected?.isAllSelected ?? false}
						/>
						<SortIcon openSortModal={openSortModal} />
					</div>
				</div>
				<div className={styles.HistoricalTasksController_list}>
					<SearchInput
						searchVal={searchVal}
						handleSearch={handleSearch}
						clearSearch={clearSearch}
					/>
					{isSearching && (
						<div className={styles.HistoricalTasksController_list_count}>
							<b>{searchResults?.length ?? 0}</b> matches found.
						</div>
					)}
					<HistoricalTaskList
						key={`${tasks?.all?.length}-${sortType}`}
						photosMap={photosMap}
						currentUser={currentUser}
						currentFacility={currentFacility}
						exceptionTypes={exceptionTypes}
						sortType={sortType}
						selectedTasks={selected?.all}
						handleSelectTask={handleSelectTask}
						syncTaskDetailsChanges={syncTaskDetailsChanges}
						// openTaskDetails={openTaskDetails}
						tasks={showTasksByType(
							sortType,
							isSearching,
							searchResults,
							historicalTaskState
						)}
						dispatchAlert={dispatchAlert}
					/>
				</div>
			</div>

			{showBatchActionsModal && (
				<HistoricalBatchActionsModal
					key={`HISTORY-BATCH-ACTIONS`}
					taskState={historicalTaskState}
					dispatchTaskState={historyDispatch}
					closeModal={closeBatchActionsModal}
				>
					{!isEmptyVal(batchActionType) && (
						<BatchActionsHandler
							isSavingChanges={isSavingChanges}
							actionType={batchActionType}
							taskState={historicalTaskState}
							currentUser={currentUser}
							currentFacility={currentFacility}
							assignTo={assignTo}
							handleUserAssignment={handleUserAssignment}
							handleBatchCompletion={handleBatchCompletion}
							handleBatchStatus={handleBatchStatus}
							handleBatchExceptions={handleBatchExceptions}
							handleBatchPastDue={handleBatchPastDue}
						/>
					)}
				</HistoricalBatchActionsModal>
			)}

			{showSingleTaskModal && (
				<HistoricalSingleActionModal
					key={`HISTORY-SINGLE-TASK`}
					title={`Single Task`}
					closeModal={closeSingleTaskModal}
				>
					<SingleTaskActionsHandler
						key={`SINGLE-TASK-ACTIONS`}
						isSavingChanges={isSavingSingleAction}
						hasError={hasError}
						retryHandler={retryHandler}
						currentUser={currentUser}
						currentFacility={currentFacility}
						actionType={singleActionType}
						taskState={historicalTaskState}
						task={selected?.all?.[0] ?? {}}
						photosMap={photosMap}
						assignTo={assignTo}
						handleSettings={handleTaskSettings}
						handleSingleCompletion={handleSingleComplete}
						handleSingleException={handleSingleException}
						handleSinglePastDue={handleSinglePastDue}
						handleUserAssignment={handleUserAssignment}
						facilityExceptions={exceptionTypes}
					/>
				</HistoricalSingleActionModal>
			)}

			{showSortModal && (
				<HistoricalModal
					key={`HISTORY-SORT`}
					title="Sort Tasks"
					closeModal={closeSortModal}
				>
					<HistoricalTaskSortController
						initialVal={sortType}
						syncDisplaySort={syncSortType}
						closeModal={closeSortModal}
					/>
				</HistoricalModal>
			)}
		</>
	);
};

export default HistoricalTasksController;

HistoricalTasksController.defaultProps = {};

HistoricalTasksController.propTypes = {};
