import React from "react";
import styles from "../../css/timeview/TimeViewFilter.module.scss";
import sprite from "../../assets/icons/carets-arrows.svg";
import sprite2 from "../../assets/icons/resident.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";

const baseOption = {
	name: "First Last",
	id: 123456,
};

const FilterImage = ({ src, alt }) => {
	if (!src || isEmptyVal(src)) {
		return (
			<div className={styles.FilterImage}>
				<svg className={styles.FilterImage_fallback} title={alt}>
					<use xlinkHref={`${sprite2}#icon-user-solid-circle`}></use>
				</svg>
			</div>
		);
	}
	return (
		<div className={styles.FilterImage}>
			<img src={src} alt={alt} className={styles.FilterImage_image} />
		</div>
	);
};

const TimeViewFilter = ({
	isDisabled = false,
	isSelected = false,
	option = {},
	handleSelect,
}) => {
	const custom = {
		opacity: isDisabled ? ".4" : "1",
		cursor: isDisabled ? "not-allowed" : "pointer",
	};
	return (
		<li
			className={
				isSelected ? styles.TimeViewFilter_isSelected : styles.TimeViewFilter
			}
			onClick={isDisabled ? null : () => handleSelect(option)}
			style={custom}
		>
			{/* WITH RESIDENT PHOTO */}
			<div className={styles.TimeViewFilter_inner2}>
				<div className={styles.TimeViewFilter_inner2_photo}>
					<FilterImage src={null} alt="Resident Photo" />
				</div>
				<div className={styles.TimeViewFilter_inner2_info}>
					<div className={styles.TimeViewFilter_inner2_info_name}>
						{option?.name}
					</div>
					<div className={styles.TimeViewFilter_inner2_info_id}>
						{option?.id}
					</div>
				</div>
			</div>

			{/* WITHOUT RESIDENT PHOTO */}
			{/* <div className={styles.TimeViewFilter_inner}>
				<div className={styles.TimeViewFilter_inner_name}>{option?.name}</div>
				<div className={styles.TimeViewFilter_inner_id}>{option?.id}</div>
			</div> */}

			{isSelected && (
				<svg className={styles.TimeViewFilter_icon}>
					<use xlinkHref={`${sprite}#icon-checkmark-outline`}></use>
				</svg>
			)}
		</li>
	);
};

export default TimeViewFilter;

// single dropdown menu option
TimeViewFilter.defaultProps = {
	isSelected: false,
	option: {},
};
TimeViewFilter.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	option: PropTypes.object.isRequired,
	handleSelect: PropTypes.func.isRequired,
};
