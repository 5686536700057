import React, { useState, useEffect } from "react";
import styles from "../../css/timeview/CreateTaskHandler.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { featureFlags } from "../../helpers/utils_features";
import { formatDate } from "../../helpers/utils_dates";
import { hasProp, isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { red, themeColors } from "../../helpers/utils_styles";
import {
	formatResidentNameTimeView,
	getResidentPhotoByID,
} from "../../helpers/utils_residents";
import { hasRecurringType } from "../../helpers/utils_repeatingTasks";
// components
import { ShiftIndicator } from "../daily/RepeatDesc";
import Divider from "../forms/Divider";
import FormSection from "../forms/FormSection";
import TextInput from "../shared/TextInput";
import CustomDropdown from "../shared/CustomDropdown";
import Textarea from "../shared/Textarea";
import DueDatePicker from "../daily/DueDatePicker";
import CustomCheckbox from "../shared/CustomCheckbox";
import ButtonSM from "../shared/ButtonSM";
import TimeViewResidentPhoto from "./TimeViewResidentPhoto";
import CustomDropdownSM from "../shared/CustomDropdownSM";
import RepeatTaskModal from "../daily/RepeatTaskModal";
import DateRangePickerSM from "../shared/DateRangePickerSM";

const customCSS = {
	divider: {
		margin: "2rem 0",
		opacity: ".4",
	},
	resident: {
		width: "35rem",
		maxWidth: "100%",
	},
	adl: {
		width: "35rem",
		maxWidth: "100%",
	},
	createTaskBtn: {
		backgroundColor: themeColors.main.main,
		fontSize: "1.5rem",
		padding: "1rem 1.8rem",
		marginRight: "1rem",
	},
	cancelTaskBtn: {
		backgroundColor: "transparent",
		color: themeColors.main.blackBlue,
		border: "1px solid #ccc",
		fontSize: "1.5rem",
		padding: "1rem 1.8rem",
	},
	shiftBtn: {
		margin: "0 1rem",
	},
	allowed: {
		cursor: "initial",
		opacity: 1,
	},
	notAllowed: {
		cursor: "not-allowed",
		opacity: 0.3,
	},
};

// checks for shift selections when creating (scheduling) a task
const hasShiftSelections = (vals) => {
	const {
		isRecurringAM: hasAM,
		isRecurringPM: hasPM,
		isRecurringNOC: hasNOC,
	} = vals;
	const hasShifts = hasAM || hasPM || hasNOC;

	return hasShifts;
};
const hasDueTime = (formState = {}) => {
	const { touched, values } = formState;
	const dueTime = values?.scheduledDueTime;
	const hasChanges =
		hasProp(touched, "scheduledDueTime") && !isEmptyVal(dueTime);

	return hasChanges;
};

/**
 * Show task repeat settings if:
 * - User is admin type or above
 * - Non-admins can still create tasks and schedule them for a specific shift, but not add repeats
 */
const showRepeatFeature = (currentUser) => {
	const { isAdmin, isExecutiveAdmin, isFacilityAdmin, isSuperUser } =
		currentUser;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;

	return isAdminType;
};
/**
 * Show task lock settings if:
 * - User is admin type or above ONLY
 */
const showLockTaskFeature = (currentUser) => {
	const isAdmin = currentUser?.isAdmin ?? false;
	const isExecutiveAdmin = currentUser?.isExecutiveAdmin ?? false;
	const isFacilityAdmin = currentUser?.isFacilityAdmin ?? false;
	const isSuperUser = currentUser?.isSuperUser ?? false;
	const isAdminType =
		isAdmin || isExecutiveAdmin || isFacilityAdmin || isSuperUser;

	return isAdminType;
};

const noSelections = (vals = {}) => {
	const { newTaskName, newTaskCategory } = vals;
	return isEmptyVal(newTaskCategory) || isEmptyVal(newTaskName);
};
const noAdlSelected = (vals = {}) => {
	const { newTaskCategory } = vals;
	return isEmptyVal(newTaskCategory);
};

// filters out non-loaded residents
const getLoadedResidentsList = (filterSelections, residentList) => {
	const { selectedResidents } = filterSelections;
	const filteredList = selectedResidents.map(({ name }) => name);

	return filteredList;
};

const CreateTaskHandler = ({
	vals = {},
	formState = {},
	selectedResident = {},
	currentUser = {},
	currentFacility = {},
	handleChange,
	handleCheckbox,
	handleSettings,
	handleDueDate,
	handleDueTime,
	handleRecurrences,
	handleCycleOptions,
	handleDateRange,
	handleResidentSelection,
	createResidentTask,
	cancelResidentTask,
	cancelRepeat,
	isSubmitting = false,
	photosMap = {},
	residentList = [],
	adlCategories = [],
	recurringTypes = [],
	filterSelections = {},
}) => {
	const [photoSrc, setPhotoSrc] = useState(null);
	// repeat/recurring settings
	const [showRepeat, setShowRepeat] = useState(false);
	const [repeatDescription, setRepeatDescription] = useState("");

	// load resident photo from state or fetch it
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		const getResidentPhotoSrc = async () => {
			const { token } = currentUser;
			const { id } = selectedResident;
			const photoFromState = photosMap?.[id] ?? {};

			if (isEmptyObj(photoFromState)) {
				const photo = await getResidentPhotoByID(token, id);
				return setPhotoSrc(photo?.photoSrc);
			} else {
				return setPhotoSrc(photoFromState?.photoSrc);
			}
		};

		// if selectedResident exists & no photoSrc
		if (!isEmptyVal(selectedResident?.id)) {
			getResidentPhotoSrc();
		}

		return () => {
			isMounted = false;
		};
	}, [currentUser, photosMap, selectedResident]);

	return (
		<div className={styles.CreateTaskHandler}>
			<section className={styles.CreateTaskHandler_top}>
				<h2 className={styles.CreateTaskHandler_top_title}>
					Create a New Task
				</h2>
				<div className={styles.CreateTaskHandler_top_date}>
					<svg className={styles.CreateTaskHandler_top_date_icon}>
						<use xlinkHref={`${sprite}#icon-event_note`}></use>
					</svg>
					<span>{formatDate(new Date())}</span>
				</div>
			</section>
			<Divider customStyles={customCSS.divider} />

			<section className={styles.CreateTaskHandler_resident}>
				<TimeViewResidentPhoto
					task={selectedResident}
					imgSize="LG"
					residentImage={{
						src: photoSrc,
						alt: "Resident Photo (LG)",
					}}
					residentInfo={{
						...selectedResident,
						RoomNum: selectedResident?.roomNum,
					}}
				/>
				<div className={styles.CreateTaskHandler_resident_title}>
					<div className={styles.CreateTaskHandler_resident_title_name}>
						{selectedResident?.name ?? ""}
					</div>
					<div className={styles.CreateTaskHandler_resident_title_id}>
						ALA ID: {selectedResident?.id ?? selectedResident?.residentID}
					</div>
				</div>
			</section>
			<Divider customStyles={customCSS.divider} />
			{/* -- NEW TASK FORM BEGINS HERE -- */}
			<fieldset className={styles.CreateTaskHandler_form}>
				{/* RESIDENT SELECTION */}
				<FormSection
					title="Select Resident"
					icon="user"
					hoverText="Select resident to create a task for."
				>
					<div className={styles.CreateTaskHandler_form_residentMsg}>
						You may only create tasks for residents that have been loaded.
					</div>
					<CustomDropdown
						name="selectedResident"
						id="selectedResident"
						label="Choose resident"
						selection={vals?.selectedResident}
						setSelection={handleResidentSelection}
						// options={residentList}
						options={getLoadedResidentsList(filterSelections, residentList)}
						customStyles={customCSS.resident}
					/>
				</FormSection>
				<Divider />

				{/* TASK NAME - TEXT INPUT */}
				<FormSection
					title="Task Name"
					icon="signature"
					hoverText="Enter a name for the task."
				>
					<TextInput
						name="newTaskName"
						id="newTaskName"
						val={vals.newTaskName}
						handleChange={handleChange}
						placeholder="Helped resident with..."
					/>
				</FormSection>
				<Divider customStyles={customCSS.divider} />

				{/* TASK CATEGORY - CUSTOM DROPDOWN */}
				<FormSection
					title="Categorize"
					icon="building"
					hoverText="Select an ADL for the task."
				>
					<div className={styles.CreateTaskHandler_adl}>
						<CustomDropdown
							name="newTaskCategory"
							id="newTaskCategory"
							placeholder="Select ADL..."
							selection={vals.newTaskCategory}
							setSelection={handleSettings}
							options={adlCategories}
							customStyles={customCSS.adl}
						/>
					</div>
					{noAdlSelected(vals) && (
						<span className={styles.CreateTaskHandler_required}>
							Please select an ADL
						</span>
					)}
				</FormSection>

				<Divider customStyles={customCSS.divider} />

				{/* TASK NOTES - TEXTAREA */}
				<FormSection
					title="Add a Note Or Comment"
					icon="note"
					hoverText="Add a description or note."
				>
					<Textarea
						name="newTaskNotes"
						id="newTaskNotes"
						placeholder="Enter a note or comment..."
						val={vals.newTaskNotes}
						handleChange={handleChange}
						enableCharCount={true}
						maxChar={250}
					/>
				</FormSection>
				<Divider customStyles={customCSS.divider} />

				{/* SCHEDULED DATE - DUE DATE PICKER */}
				{featureFlags?.enableDueDate && (
					<>
						<FormSection
							title="Schedule Task Shift(s) OR Pick a Time"
							icon="clock"
							hoverText="Pick shift or time for task."
						>
							<div className={styles.CreateTaskHandler_scheduler}>
								<div className={styles.CreateTaskHandler_scheduler_msg}>
									Pick a specific time OR select one or more shifts to schedule
									a task for.
									<div>
										NOTE: selecting shifts will disable due time and vice versa.
									</div>
								</div>

								{/* DATE/TIME PICKER */}
								{/* {!hasShiftSelections(vals) && ( */}
								<fieldset
									className={styles.CreateTaskHandler_scheduler_option}
									style={
										hasShiftSelections(vals)
											? customCSS.notAllowed
											: customCSS.allowed
									}
								>
									<DueDatePicker
										key={`GENERIC-DUE-TIME`}
										vals={vals}
										currentUser={currentUser}
										currentFacility={currentFacility}
										handleDueDate={handleDueDate}
										handleDueTime={handleDueTime}
									/>
								</fieldset>
								{/* )} */}
								<div className={styles.CreateTaskHandler_scheduler_divider}>
									OR
								</div>
								{/* SHIFT SELECTION */}
								{/* {!hasDueTime(formState) && ( */}
								<fieldset
									className={styles.CreateTaskHandler_scheduler_shifts}
									style={
										hasDueTime(formState)
											? customCSS.notAllowed
											: customCSS.allowed
									}
								>
									<ShiftIndicator
										shift="AM"
										key={`AM__NewTask`}
										val={vals.isRecurringAM}
										toggleShift={handleRecurrences}
										isDisabled={vals.isLocked || hasDueTime(formState)}
										customStyles={customCSS.shiftBtn}
									/>
									<ShiftIndicator
										shift="PM"
										key={`PM__NewTask`}
										val={vals.isRecurringPM}
										toggleShift={handleRecurrences}
										isDisabled={vals.isLocked || hasDueTime(formState)}
										customStyles={customCSS.shiftBtn}
									/>
									<ShiftIndicator
										shift="NOC"
										key={`NOC__NewTask`}
										val={vals.isRecurringNOC}
										toggleShift={handleRecurrences}
										isDisabled={vals.isLocked || hasDueTime(formState)}
										customStyles={customCSS.shiftBtn}
									/>
								</fieldset>
								{/* )} */}
							</div>
						</FormSection>
						<Divider customStyles={{ opacity: ".2" }} />
					</>
				)}

				{/* REPEAT/RECURRING SETTINGS */}
				{/* REPEAT/RECURRING TYPE */}
				{showRepeatFeature(currentUser) && (
					<FormSection
						title="Repeat Task"
						icon="recurring"
						hoverText="Select when the task repeats."
					>
						<div className={styles.CreateTaskHandler_repeat}>
							<span className={styles.CreateTaskHandler_repeat_label}>
								Repeat:{" "}
							</span>

							<CustomDropdownSM
								key={`GENERIC-TASK-RECURRING`}
								name="recurringType"
								id="recurringType"
								selection={vals.recurringType}
								setSelection={handleSettings}
								inputSize={12}
								options={recurringTypes}
							/>
							{/* UPDATE SETTINGS BUTTON */}
							{hasRecurringType(vals.recurringType) && (
								<button
									className={styles.CreateTaskHandler_repeat_updateBtn}
									onClick={(e) => {
										e.preventDefault();
										setShowRepeat(true);
									}}
								>
									Update Settings
								</button>
							)}
							{/* REPEAT TASK MODAL */}
							{showRepeat && (
								<RepeatTaskModal
									key={`GENERIC-TASK-REPEAT`}
									vals={vals}
									saveRepeat={() => setShowRepeat(false)}
									cancelRepeat={cancelRepeat}
									closeRepeatWindow={() => setShowRepeat(false)}
									getRepeatDescText={setRepeatDescription} // text description in form
									repeatType={vals.recurringType}
									handleSettings={handleSettings}
									handleCycleOptions={handleCycleOptions}
									handleDaySelections={handleCheckbox}
								/>
							)}
						</div>
						<div className={styles.CreateTaskHandler_repeat_rangePicker}>
							<DateRangePickerSM
								label="Select a start and end date"
								startName="startDate"
								endName="endDate"
								rangeVals={vals}
								handleSelection={handleDateRange}
								focusMode={true}
							/>
						</div>
						<div className={styles.CreateTaskHandler_repeat_desc}>
							{!isEmptyVal(repeatDescription) && (
								<span>{repeatDescription}</span>
							)}
						</div>
					</FormSection>
				)}
				<Divider customStyles={customCSS.divider} />

				{/* SHIFT SELECTION */}
				{/* <FormSection
					title="Schedule task for one or more Shift(s)"
					icon="timer"
					hoverText="Selecting more than one shift will create multiple task occurrences."
				>
					<div className={styles.CreateTaskHandler_shifts}>
						<ShiftIndicator
							shift="AM"
							key={`AM__NewTask`}
							val={vals.isRecurringAM}
							toggleShift={handleRecurrences}
							isDisabled={vals.isLocked}
							customStyles={customCSS.shiftBtn}
						/>
						<ShiftIndicator
							shift="PM"
							key={`PM__NewTask`}
							val={vals.isRecurringPM}
							toggleShift={handleRecurrences}
							isDisabled={vals.isLocked}
							customStyles={customCSS.shiftBtn}
						/>
						<ShiftIndicator
							shift="NOC"
							key={`NOC__NewTask`}
							val={vals.isRecurringNOC}
							toggleShift={handleRecurrences}
							isDisabled={vals.isLocked}
							customStyles={customCSS.shiftBtn}
						/>
					</div>
				</FormSection>
				<Divider customStyles={customCSS.divider} /> */}

				{/* LOCK TASK SECTION */}
				{showLockTaskFeature(currentUser) && (
					<FormSection
						title="Lock Task"
						icon="lock"
						iconColor={red[600]}
						titleColor={red[600]}
						hoverText="Locking a task disables non-admins from changing it."
					>
						<div className={styles.CreateTaskHandler_lockTask}>
							<p className={styles.CreateTaskHandler_lockTask_desc}>
								Locking a task will prevent unauthorized changes to a task from
								any "NON-ADMIN" user. Only users with "ADMIN" access/permissions
								are able to make changes to a "locked" task.
							</p>
						</div>
						<div className={styles.CreateTaskHandler_lockTask_checkbox}>
							<CustomCheckbox
								name="isLocked"
								id="isLocked"
								label={vals.isLocked ? `Locked Task` : `Lock this task`}
								val={vals.isLocked}
								handleCheckbox={handleCheckbox}
							/>
						</div>
					</FormSection>
				)}

				<Divider customStyles={customCSS.divider} />

				{/* CREATE-TASK/CANCEL BUTTONS */}
				<section className={styles.CreateTaskHandler_actions}>
					<ButtonSM
						isDisabled={noSelections(vals)}
						handleClick={createResidentTask}
						customStyles={customCSS.createTaskBtn}
					>
						<span>{isSubmitting ? "Saving..." : "Create Task"}</span>
					</ButtonSM>
					<ButtonSM
						handleClick={cancelResidentTask}
						customStyles={customCSS.cancelTaskBtn}
					>
						<span>Cancel</span>
					</ButtonSM>
				</section>
			</fieldset>
		</div>
	);
};

export default CreateTaskHandler;

CreateTaskHandler.defaultProps = {};

CreateTaskHandler.propTypes = {};
