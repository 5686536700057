import React, { useState } from "react";
import styles from "../../css/timeview/TimeViewControllerTabs.module.scss";
import { PropTypes } from "prop-types";

const TimeViewTabButton = ({
	handleTabButton,
	activeIndex,
	tabIndex,
	children,
}) => {
	return (
		<button
			className={
				activeIndex === tabIndex
					? styles.TimeViewTabButton_isActive
					: styles.TimeViewTabButton
			}
			onClick={() => handleTabButton(tabIndex)}
		>
			{children}
		</button>
	);
};

const TimeViewTabButtons = ({ handleTabButton, activeIndex, children }) => {
	const withProps = React.Children.map(children, (child, i) =>
		React.cloneElement(child, {
			handleTabButton,
			tabIndex: i,
			activeIndex: activeIndex,
		})
	);
	return <section className={styles.TimeViewTabButtons}>{withProps}</section>;
};

const TimeViewTabPanel = ({ activeIndex, tabIndex, children }) => {
	return <>{activeIndex === tabIndex ? children : null}</>;
};

const TimeViewTabPanels = ({ activeIndex, children }) => {
	const withActiveIndex = React.Children.map(children, (child, i) =>
		React.cloneElement(child, { activeIndex: activeIndex, tabIndex: i })
	);
	return (
		<section className={styles.TimeViewTabPanels}>{withActiveIndex}</section>
	);
};

const TimeViewControllerTabs = ({ defaultIndex = 0, children }) => {
	const [activeIndex, setActiveIndex] = useState(defaultIndex);

	const handleTabButton = (tabIndex) => {
		return setActiveIndex(tabIndex);
	};

	const withHandler = React.Children.map(children, (child, i) =>
		React.cloneElement(child, {
			handleTabButton: handleTabButton,
			activeIndex: activeIndex,
		})
	);
	return (
		<article className={styles.TimeViewControllerTabs}>{withHandler}</article>
	);
};

export default TimeViewControllerTabs;

export {
	TimeViewTabButton,
	TimeViewTabButtons,
	TimeViewTabPanel,
	TimeViewTabPanels,
};

TimeViewControllerTabs.defaultProps = {};

TimeViewControllerTabs.propTypes = {};
