import React from "react";
import styles from "../../css/timeview/TimeViewResidentHeader.module.scss";
import { PropTypes } from "prop-types";
import TimeViewResidentPhoto from "./TimeViewResidentPhoto";

const TimeViewResidentHeader = ({
	task = {},
	currentResident = {},
	residentImage = {},
	children,
}) => {
	const { residentID } = currentResident;

	return (
		<div className={styles.TimeViewResidentHeader}>
			<div className={styles.TimeViewResidentHeader_photo}>
				<TimeViewResidentPhoto
					key={`TV-ADVANCED-PHOTO`}
					imgSize="LG"
					task={task}
					residentImage={residentImage}
					residentInfo={{
						...currentResident,
						ResidentID: residentID,
						RoomNum: task?.RoomNum,
					}}
				/>
			</div>
			<div className={styles.TimeViewResidentHeader_name}>
				<div className={styles.TimeViewResidentHeader_name_text}>
					{task?.FirstName} {task?.LastName}
				</div>
				{children}
			</div>
		</div>
	);
};

export default TimeViewResidentHeader;

TimeViewResidentHeader.defaultProps = {};

TimeViewResidentHeader.propTypes = {};
