import React, { useState, useRef } from "react";
import styles from "../../css/history/HistoricalTaskEntry.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import {
	blueGreys,
	green,
	pink,
	purple,
	yellow,
} from "../../helpers/utils_styles";
import { getResidentName } from "../../helpers/utils_residents";
import {
	applyAllChangesToHistoryClient,
	applyAllChangesToHistoryTask,
	generateHistoryBaseTask,
	getHistoricalResidentName,
	getHistoricalTaskADL,
	getHistoricalTaskName,
	saveHistoricalTaskChanges,
} from "../../helpers/utils_historical";
import { addEllipsis } from "../../helpers/utils_processing";
import {
	getTaskDueTime,
	isCompleted,
	isLocked,
	isNotComplete,
	isPRNTask,
} from "../../helpers/utils_tasks";
import { useForm } from "../../utils/useForm";
import {
	getDefaultDayTV,
	getDefaultShift,
	getDefaultShiftTV,
	getRecurringStartAndEnd,
	getRecurringTypeFromID,
	isRecurring,
} from "../../helpers/utils_repeatingTasks";
import { format } from "date-fns";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { hasException } from "../../helpers/utils_exceptions";
import { FACILITY_EXCEPTION_TYPES } from "../../helpers/utils_options";
import {
	initAndUpdateNewTaskNote,
	saveTaskNotes,
} from "../../helpers/utils_notes";
// components
import ModalLG from "../shared/ModalLG";
import HistoricalTaskDetails from "./HistoricalTaskDetails";

const defaultIcon = "check_box_outline_blank";
const selectedIcon = "check_box";

const getStatusCss = (task = {}, vals = {}, shiftTimes = []) => {
	const taskDueTime = getTaskDueTime(task, new Date(), shiftTimes);
	const withException = hasException(task) || !isEmptyVal(vals?.exceptionType);
	const hasPastDue = vals?.hasPastDue && isEmptyVal(vals?.exceptionType);
	const wasDone = isCompleted(task) || vals?.markComplete;

	// due date/time anchored to today's date
	const dueDate = taskDueTime;

	switch (true) {
		// PRN TASK (FLOATING)
		case isPRNTask(task): {
			return {
				borderLeft: `5px solid ${pink[600]}`,
			};
		}
		// COMPLETED STATUS
		case wasDone: {
			return {
				borderLeft: `5px solid ${green[600]}`,
				opacity: 0.5,
			};
		}
		// PAST-DUE STATUS
		case hasPastDue && !withException: {
			return {
				borderLeft: `5px solid ${purple[700]}`,
			};
		}
		case isNotComplete(task, dueDate, shiftTimes): {
			return {
				borderLeft: `5px solid ${yellow[500]}`,
			};
		}

		default:
			return {
				borderLeft: `5px solid ${purple[700]}`,
			};
	}
};

const getTaskStatusDesc = (task, vals) => {
	const isDone = task?.IsCompleted || vals?.markComplete;

	const status = !isDone ? `NOT-COMPLETE` : `COMPLETED`;

	return status;
};

const getSelectedCSS = (isSelected = false) => {
	if (isSelected) {
		return {
			fill: blueGreys[700],
			opacity: 1,
		};
	} else {
		return {
			// fill: blueGrey[400],
			opacity: 0.6,
		};
	}
};

const ExceptionIcon = () => {
	return (
		<svg className={styles.ExceptionIcon}>
			<use xlinkHref={`${sprite}#icon-error_outline`}></use>
		</svg>
	);
};

const ExceptionDesc = ({ desc }) => {
	return (
		<div className={styles.ExceptionDesc} title={`EXCEPTION: ${desc}`}>
			{addEllipsis(desc, 20)}
		</div>
	);
};

const ExceptionIndicator = ({
	vals = {},
	task = {},
	facilityExceptions = [],
}) => {
	if (isEmptyVal(vals?.exceptionType)) {
		return null;
	}
	return (
		<div className={styles.ExceptionIndicator}>
			<ExceptionDesc desc={vals?.exceptionType} />
			<ExceptionIcon />
		</div>
	);
};

const SelectionIndicator = ({
	isSelected = false,
	handleSelectTask,
	handleShiftSelect,
}) => {
	return (
		<div
			className={styles.SelectionIndicator}
			onClick={handleSelectTask}
			onKeyDown={handleShiftSelect}
		>
			<svg
				className={styles.SelectionIndicator_icon}
				style={getSelectedCSS(isSelected)}
			>
				{/* <use xlinkHref={`${sprite}#icon-check_circle`}></use> */}
				<use
					xlinkHref={`${sprite}#icon-${
						isSelected ? selectedIcon : defaultIcon
					}`}
				></use>
			</svg>
		</div>
	);
};

const TaskInfo = ({ task = {}, openTaskDetails }) => {
	return (
		<div className={styles.TaskInfo} onClick={openTaskDetails}>
			<div className={styles.TaskInfo_resident}>
				{getHistoricalResidentName("N/A", task)}
			</div>
			<div className={styles.TaskInfo_task}>
				{addEllipsis(getHistoricalTaskName(task), 60)}
			</div>
			<div className={styles.TaskInfo_adl}>{getHistoricalTaskADL(task)}</div>
		</div>
	);
};

const TaskShift = ({ task }) => {
	return (
		<div className={styles.TaskShift}>
			<div className={styles.TaskShift_text}>{task?.Shift}</div>
		</div>
	);
};

const getInitialTaskState = (task = {}) => {
	const initialState = {
		reassess: false,
		reassessNotes: "",
		shift: task?.Shift, // when shift was completed (ie statusing)
		taskNotes: task?.TaskNotes?.[0] ?? "",
		syncNotesVal: "", // only used to track notes in children
		signature: "",
		markComplete: isCompleted(task),
		hasPastDue: !isCompleted(task) && isEmptyVal(task?.Exception),
		isLocked: isLocked(task),
		exceptionType: task?.Exception ?? "",
		recurringType: getRecurringTypeFromID(task?.AssessmentRecurringId),
		isRecurring: isRecurring(task),
		recurringCycle: "1",
		recurringCycleOption: "",
		isRecurringAM: getDefaultShiftTV("AM", task),
		isRecurringPM: getDefaultShift("PM", task),
		isRecurringNOC: getDefaultShift("NOC", task),
		isRecurringSun: getDefaultDayTV("Sun", task),
		isRecurringMon: getDefaultDayTV("Mon", task),
		isRecurringTue: getDefaultDayTV("Tue", task),
		isRecurringWed: getDefaultDayTV("Wed", task),
		isRecurringThu: getDefaultDayTV("Thu", task),
		isRecurringFri: getDefaultDayTV("Fri", task),
		isRecurringSat: getDefaultDayTV("Sat", task),
		startDate: getRecurringStartAndEnd(task).startDate,
		endDate: getRecurringStartAndEnd(task).endDate,
		// NEW TASK NOTES FIELDS
		trackingID: task?.AssessmentTrackingId ?? 0,
		taskID:
			task?.AssessmentTrackingTaskId ?? task?.AssessmentUnscheduleTaskId ?? 0,
		scheduledDueDate: task?.ScheduledDate ?? new Date(),
		scheduledDueTime: format(task?.ScheduledDate, "hh:mm A") ?? "3:00 PM",
	};

	return initialState;
};

const HistoricalTaskEntry = ({
	task = {},
	isSelected = false,
	handleSelectTask,
	handleShiftSelect,
	// openTaskDetails,
	shiftTimes = [],
	currentFacility = {},
	currentUser = {},
	photosMap = {},
	exceptionTypes = [],
	syncTaskDetailsChanges,
	dispatchAlert,
}) => {
	const saveRef = useRef();
	const taskRef = useRef();
	const { formState, setFormState, handleChange, handleReset } = useForm({
		...getInitialTaskState(task),
	});
	const { values, touched } = formState;
	const [showTaskDetailsModal, setShowTaskDetailsModal] = useState(false);
	// task details 'assignTo'
	const [assignTo, setAssignTo] = useState({});
	const [hasChanges, setHasChanges] = useState(false);
	// new task note
	const [wasNoteSaved, setWasNoteSaved] = useState(false);

	const openTaskDetails = () => {
		setShowTaskDetailsModal(true);
	};
	const closeTaskDetails = () => {
		setShowTaskDetailsModal(false);
	};

	const handleUserAssignment = (user = {}) => {
		if (assignTo?.userID === user?.userID) {
			setHasChanges(true);
			return setAssignTo({});
		} else {
			setHasChanges(true);
			return setAssignTo(user);
		}
	};

	// exception and others
	const handleSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
		setHasChanges(true);
	};
	// complete from task details
	const handleCompletion = () => {
		const { markComplete } = values;
		setHasChanges(true);
		return setFormState({
			...formState,
			values: {
				...values,
				markComplete: !markComplete,
			},
			touched: {
				...touched,
				markComplete: !markComplete,
			},
		});
	};

	const removeException = () => {
		setFormState({
			...formState,
			values: {
				...values,
				exceptionType: "",
			},
			touched: {
				...touched,
				exceptionType: true,
			},
		});
		setHasChanges(true);
	};
	const removePastDue = () => {
		setFormState({
			...formState,
			values: {
				...values,
				hasPastDue: "",
			},
			touched: {
				...touched,
				hasPastDue: true,
			},
		});
		setHasChanges(true);
	};
	// task details
	const saveTaskChanges = async (record) => {
		const { token } = currentUser;
		const baseTask = generateHistoryBaseTask(record);
		const exceptions = [...FACILITY_EXCEPTION_TYPES];
		const userAssign = isEmptyObj(assignTo) ? currentUser : assignTo;
		// apply all changes to task record
		const updatedClientTask = applyAllChangesToHistoryClient(
			formState,
			task,
			exceptions,
			userAssign
		);
		const updatedTask = applyAllChangesToHistoryTask(
			formState,
			baseTask,
			exceptions,
			userAssign
		);

		// const success = true;
		const success = await saveHistoricalTaskChanges(token, updatedTask);

		console.group("Saved Details");
		console.log("record", record);
		console.log("baseTask:", baseTask);
		console.log("updatedClientTask", updatedClientTask);
		console.log("updatedTask", updatedTask);
		console.log("success", success);
		console.groupEnd();

		if (success) {
			syncTaskDetailsChanges(updatedClientTask);
			closeTaskDetails();
			return dispatchAlert("SUCCESS", {
				heading: `Changes were saved!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `There was an error!`,
			});
		}

		// fire off request to save task record
		// close modal & dispatch alert accordingly
	};
	const cancelTaskChanges = async (e) => {
		handleReset(e);
		closeTaskDetails();
	};

	// just monitors task notes changes
	const syncTaskNotes = (e) => {
		const { value } = e.target;

		setFormState({
			...formState,
			values: {
				...values,
				syncNotesVal: value,
			},
		});
	};

	const saveNewTaskNote = async () => {
		const { token } = currentUser;
		// creates client-side & server-formatted note models
		const { client, server } = initAndUpdateNewTaskNote(task, {
			...values,
			entryDate: new Date().toISOString(),
			createdDate: new Date().toISOString(),
			modifiedDate: new Date().toISOString(),
		});

		// const wasSaved = true;
		const wasSaved = await saveTaskNotes(token, server);

		if (wasSaved) {
			// const newNotesList = [...allNotes, client];
			// setAllNotes([...newNotesList]);
			// prolly need to sync new task note to <TaskUpdateForm/> above, or re-fetch task notes
			// handleReset(e);
			return setWasNoteSaved(true);
		} else {
			return setWasNoteSaved(false);
		}
	};

	return (
		<>
			<li
				className={styles.HistoricalTaskEntry}
				style={getStatusCss(task, values, shiftTimes)}
				ref={taskRef}
			>
				<div className={styles.HistoricalTaskEntry_selection}>
					<SelectionIndicator
						handleSelectTask={handleSelectTask}
						handleShiftSelect={handleShiftSelect}
						isSelected={isSelected}
					/>
					<TaskShift task={task} />
				</div>
				<div className={styles.HistoricalTaskEntry_main}>
					<TaskInfo
						openTaskDetails={openTaskDetails}
						isSelected={isSelected}
						task={task}
					/>
					{!isEmptyVal(values?.exceptionType) && (
						<ExceptionIndicator
							vals={values}
							task={task}
							facilityExceptions={[]}
						/>
					)}
				</div>
			</li>

			{showTaskDetailsModal && (
				<ModalLG
					key={`HISTORY-DETAILS`}
					closeModal={closeTaskDetails}
					title={`Task Details (Historical) - ${getTaskStatusDesc(
						task,
						values
					)} - ${hasChanges ? "Pending Changes" : ""}`}
				>
					<HistoricalTaskDetails
						key={`HISTORY-TASK-DETAILS-${values?.exceptionType}-${values?.markComplete}`}
						vals={values}
						saveRef={saveRef}
						assignTo={assignTo}
						currentUser={currentUser}
						currentFacility={currentFacility}
						task={task}
						photosMap={photosMap}
						handleUserAssignment={handleUserAssignment}
						handleSettings={handleSettings}
						markCompleteHandler={handleCompletion}
						facilityExceptions={exceptionTypes}
						saveTaskChanges={saveTaskChanges}
						cancelTaskChanges={cancelTaskChanges}
						removeExceptionHandler={removeException}
						removePastDueHandler={removePastDue}
						syncTaskNotes={syncTaskNotes}
						saveNewTaskNote={saveNewTaskNote}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default HistoricalTaskEntry;

HistoricalTaskEntry.defaultProps = {};

HistoricalTaskEntry.propTypes = {};
