import React, { useState, useEffect } from "react";
import styles from "../../css/daily/DailySummaryCard.module.scss";
import { PropTypes } from "prop-types";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { debounce } from "../../helpers/utils_processing";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { markAllComplete } from "../../helpers/utils_tasks";
import {
	markCompleteTasksAndRecords,
	savePendingRecords,
	saveUnscheduledRecord,
} from "../../helpers/utils_updates";
import { featureFlags } from "../../helpers/utils_features";
import {
	initAndUpdateQuickTask,
	saveUnscheduledUpdates,
} from "../../helpers/utils_unscheduled";
import { getCurrentShiftRecord } from "../../helpers/utils_shifts";
import { addHours } from "date-fns";
import { isLocalhost } from "../../helpers/utils_env";
// components
import DailySummaryList from "./DailySummaryList";
import DailySummaryCardHeading from "./DailySummaryCardHeading";
import DailySummaryCardCounts from "./DailySummaryCardCounts";
import DailySummaryCardBottom from "./DailySummaryCardBottom";
import DailySummaryCardToolTip from "./DailySummaryCardToolTip";
import CreateADLTaskButton from "./CreateADLTaskButton";
import QuickCreateTask from "./QuickCreateTask";
import ProtectedFeature from "../permissions/ProtectedFeature";
import { pink } from "../../helpers/utils_styles";

const hasNoTasks = (allTasks = []) => {
	if (isEmptyArray(allTasks) || !allTasks) return true;
	return false;
};

const isPRNCard = (adlCategory = {}) => {
	const isPRN =
		adlCategory?.AdlCategoryType === "PRN" ||
		adlCategory?.AssessmentCategoryId === 15;

	return isPRN;
};

const DailySummaryCard = ({
	hasUpdated = false,
	isCompleted = false,
	showCard = true,
	category,
	day,
	dispatch,
	allTasks = [], // includes scheduled & unscheduled already merged by ADL
	trackingTasks = [],
	unscheduledTasksRaw = [],
	currentResident = {},
	currentUser = {},
	currentFacility = {},
	facilityExceptions = [],
	activeStatusFilters = [],
	reloadResident,
}) => {
	const { AlertsHandler, dispatchAlert } = useAlerts({
		...initialSettings,
		expiry: 2000,
	});
	const [showResidentName, setShowResidentName] = useState(false);
	const [completeAll, setCompleteAll] = useState(isCompleted);
	const [scrollState, setScrollState] = useState({
		scrollPos: null,
		active: null,
		updated: false,
	});
	const [showCreateAdlTaskModal, setShowCreateAdlTaskModal] = useState(false);
	const [adlTaskName, setAdlTaskName] = useState("");

	const handleAdlTask = (e) => {
		const { value } = e.target;

		setAdlTaskName(value);
	};

	const handleMarkComplete = (e) => {
		const { checked } = e.target;
		setCompleteAll(checked);
		markCompleteHandler();
	};

	// handles 'Mark All Complete' feature
	const saveMarkedCompleteRecords = async () => {
		const { token } = currentUser;

		const { Scheduled, Unscheduled } = markCompleteTasksAndRecords(
			allTasks,
			category.AdlCategoryType,
			trackingTasks,
			unscheduledTasksRaw
		);
		const resp = await savePendingRecords(token, Scheduled, Unscheduled);

		return resp;
	};

	// light wrapper for alert handler
	const triggerAlert = (type, heading, subheading) => {
		dispatchAlert(type, {
			heading,
			subheading,
		});
	};

	// marks all tasks for a specific card as completed
	const markCompleteHandler = () => {
		// if already marked 'COMPLETE', then toggle to previous state
		if (completeAll) {
			setCompleteAll(false);
			dispatch({
				type: "REVERT_MARK_COMPLETE_BY_ADL",
				data: {
					adl: category.AdlCategoryType,
				},
			});
			triggerAlert(
				"success",
				"Success!",
				`All ${category.AdlCategoryType} tasks were updated.`
			);
			// update tracking; send request(s)
			return saveMarkedCompleteRecords();
		}
		markAllComplete(allTasks);
		dispatch({
			type: "MARK_COMPLETE_BY_ADL",
			data: {
				adl: category.AdlCategoryType,
			},
		});
		triggerAlert(
			"success",
			"Completed!",
			`All ${category.AdlCategoryType} tasks were marked 'COMPLETE'.`
		);
		// update tracking; send request(s)
		return saveMarkedCompleteRecords();
	};

	// records current taskID & persists the scroll position of the task list
	const handleActive = ({ active, scrollPos, wasUpdated }) => {
		setScrollState({
			active,
			scrollPos,
			wasUpdated,
		});
	};

	// quick-create task: opens "quick-create" modal
	const initAdlTask = () => {
		setShowCreateAdlTaskModal(true);
	};

	const createAdlTask = async (e) => {
		e.preventDefault();
		const { token, userID } = currentUser;
		const { ResidentID } = currentResident;
		const { AdlCategoryId: id } = category;
		const currentShift = getCurrentShiftRecord(currentFacility?.shifts);
		const dueDate = addHours(new Date(), 4);

		const quickTask = {
			taskName: adlTaskName,
			categoryID: id,
			residentID: ResidentID,
			userID: userID,
			shiftID: currentShift?.AssessmentShiftId,
			dueDate: dueDate,
		};

		// prevent empty task names
		if (isEmptyVal(adlTaskName))
			return dispatchAlert("WARN", { heading: "Please enter a task name" });
		const taskModel = initAndUpdateQuickTask(quickTask);
		const rawIDs = await saveUnscheduledUpdates(token, taskModel);
		const newTaskID = rawIDs?.[0];
		// add to global store
		if (newTaskID) {
			dispatch({
				type: "QUICK_CREATE_TASK",
				data: {
					newTask: { ...taskModel, AssessmentUnscheduleTaskId: newTaskID },
				},
			});
			setShowCreateAdlTaskModal(false);
			return dispatchAlert("SUCCESS", {
				heading: `Task was created!`,
			});
		} else {
			return dispatchAlert(`ERROR`, {
				heading: `Error!`,
				subheading: `Could not create task. Try again, please.`,
			});
		}
	};

	const cancelAdlTask = () => {
		setShowCreateAdlTaskModal(false);
	};

	// resets 'scrollState.wasUpdated' AFTER animation ends
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let timerID;
		if (scrollState.wasUpdated) {
			timerID = setTimeout(() => {
				setScrollState({
					...scrollState,
					wasUpdated: false,
				});
			}, 5000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [scrollState]);

	if (!showCard) {
		return null;
	}
	return (
		<>
			<div
				className={
					isCompleted || hasNoTasks(allTasks)
						? styles.DailySummaryCard_isCompleted
						: styles.DailySummaryCard
				}
				onMouseOver={() => debounce(setShowResidentName(true), 800)}
				onMouseLeave={() => debounce(setShowResidentName(false), 800)}
				style={
					isPRNCard(category)
						? { borderTop: `3px solid ${pink[500]}`, opacity: "1" }
						: {}
				}
			>
				<DailySummaryCardHeading
					isCompleted={isCompleted}
					category={category}
					day={day}
				/>
				<DailySummaryCardCounts
					allTasks={allTasks}
					shiftTimes={currentFacility?.shifts}
					hasUpdated={hasUpdated}
				/>
				<section className={styles.DailySummaryCard_tasks}>
					<DailySummaryList
						key={`${category.AdlCategoryType}_${day}`}
						tasks={allTasks}
						category={category}
						dispatch={dispatch}
						trackingTasks={trackingTasks}
						unscheduledTasksRaw={unscheduledTasksRaw}
						currentResident={currentResident}
						currentUser={currentUser}
						currentFacility={currentFacility}
						dispatchAlert={dispatchAlert}
						facilityExceptions={facilityExceptions}
						activeStatusFilters={activeStatusFilters}
						reloadResident={reloadResident}
						handleActive={handleActive}
						scrollState={scrollState}
					/>
				</section>
				<DailySummaryCardBottom
					category={category}
					completeAll={completeAll}
					isCompleted={isCompleted}
					handleMarkComplete={handleMarkComplete}
					enableMarkAllComplete={false}
				>
					{/* MARK ALL COMPLETE (in children) */}
					{/* CREATE ADL TASK BUTTON */}
					<ProtectedFeature isEnabled={featureFlags.enableQuickCreate}>
						<CreateADLTaskButton
							adl={category}
							initCreateADLTask={initAdlTask}
							isHidden={!isLocalhost()}
						/>
					</ProtectedFeature>
				</DailySummaryCardBottom>

				{showResidentName && (
					<DailySummaryCardToolTip currentResident={currentResident} />
				)}
			</div>

			{showCreateAdlTaskModal && (
				<QuickCreateTask
					adl={category}
					adlTaskName={adlTaskName}
					handleChange={handleAdlTask}
					closeModal={() => setShowCreateAdlTaskModal(false)}
					createAdlTask={createAdlTask}
					cancelAdlTask={cancelAdlTask}
				/>
			)}

			{/* ALERTS NOTIFIER */}
			{AlertsHandler}
		</>
	);
};
export default React.memo(DailySummaryCard);

DailySummaryCard.defaultProps = {
	hasUpdated: false,
	showCard: true,
	scheduledTasks: [],
	unscheduledTasks: [],
	// tracking records
	trackingTasks: [],
	unscheduledTasksRaw: [],
	facilityExceptions: [],
};
DailySummaryCard.propTypes = {
	showCard: PropTypes.bool.isRequired,
	dispatch: PropTypes.func,
	hasUpdated: PropTypes.bool,
	currentResident: PropTypes.object.isRequired,
	category: PropTypes.object.isRequired,
	day: PropTypes.instanceOf(Date).isRequired,
	scheduledTasks: PropTypes.array,
	unscheduledTasks: PropTypes.array,
	// tracking records
	trackingTasks: PropTypes.array.isRequired,
	unscheduledTasksRaw: PropTypes.array.isRequired,
	facilityExceptions: PropTypes.array.isRequired,
};
