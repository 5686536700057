import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import styles from "../../css/daily/DailySummaryListItem.module.scss";
import sprite from "../../assets/icons/repeat.svg";
import sprite2 from "../../assets/icons/modals-complete.svg";
// UTILS & HELPERS
import { useForm } from "../../utils/useForm";
import { themeColors } from "../../helpers/utils_styles";
import {
	isCompleted,
	isScheduledTask,
	hasRepeat,
	toggleComplete,
	getTaskShift,
	isPastDue,
	getCreatedDate,
	getTaskID,
	isLocked,
	getTaskName,
	getTaskDueTime,
	getTaskScheduledDueDate,
	isPastDueByTimeOrShift,
	isPRNTask,
	getTaskType,
	getRawTaskDate,
	getTaskDueTimeByTaskType,
} from "../../helpers/utils_tasks";
import { utcToLocal } from "../../helpers/utils_dates";
import {
	recordQuickComplete,
	saveAppliedTaskUpdates,
	applyUpdatesToTask,
	findAndApplyTaskRecordUpdates,
	saveAllChangesHandler,
} from "../../helpers/utils_updates";
import {
	isRecurring,
	getRecurringStartAndEnd,
	getRecurringTypeFromID,
	getDefaultDay,
	getDefaultShift,
} from "../../helpers/utils_repeatingTasks";
import {
	hasException,
	matchExceptionDescFromTask,
} from "../../helpers/utils_exceptions";
import { changedSettings } from "../../helpers/utils_validation";
import { applyReassessToTracking } from "../../helpers/utils_reassess";
import { deleteUnscheduledByID } from "../../helpers/utils_unscheduled";
import { isEmptyVal } from "../../helpers/utils_types";
import {
	initAndUpdateNewTaskNote,
	saveTaskNotes,
} from "../../helpers/utils_notes";
import { format } from "date-fns";
// COMPONENTS
import TaskUpdateForm from "./TaskUpdateForm";
import CheckboxSM from "../shared/CheckboxSM";
import ModalLG from "../shared/ModalLG";
import Beacon from "../app/Beacon";

const getCurrentDate = (task, dueDate, shiftTimes) => {
	const { date, time } = getTaskScheduledDueDate(task, dueDate, shiftTimes);

	return format(date, "MM/DD/YYYY");
};

const getCurrentTime_OLD = (task, dueDate, shiftTimes) => {
	const { date, time } = getTaskScheduledDueDate(task, dueDate, shiftTimes);
	// const currentTime = format(time, "hh:mm A");
	const currentTime = format(date, "hh:mm A");

	console.group("getCurrentTime()");
	console.log("date", date);
	console.log("time:", time);
	console.log("currentTime:", currentTime);
	console.groupEnd();

	return time;
	// return currentTime;
};
const getCurrentTime = (task, dueDate, shiftTimes) => {
	// extracts & formats date & time for task
	const { date, time } = getTaskScheduledDueDate(task, dueDate, shiftTimes);
	// const currentTime = format(time, "hh:mm A");
	// const currentTime = format(date, "hh:mm A");

	return time;
	// return currentTime;
};

// adds line-through styles to description if completed
const addStrike = (task) => {
	if (isCompleted(task)) {
		return { textDecoration: "line-through" };
	}
	return null;
};

// adds color border corresponding w/ task status
// ##TODOS:
// - Check if dueDate in 'isPastDue' should use task.TrackDate or task.TaskDate
// - Add logic to check the 'IsPastDue' field in the task record itself as well
// - Add logic to to check 'scheduled date' & fall back to shift end time, if empty
// - UPDATED FOR TICKET #174 TO PREVENT 'Exceptions' BEING MARKED "PAST-DUE"
const getStatusStyles = (task = {}, vals = {}, wasUpdated, shiftTimes = []) => {
	const taskDueTime = getTaskDueTime(task, new Date(), shiftTimes);
	const isPRN = isPRNTask(task);
	const withException = hasException(task) || !isEmptyVal(vals?.exceptionType);

	// due date/time anchored to today's date
	const dueDate = taskDueTime;

	if (isPRNTask(task)) {
		return wasUpdated
			? `${styles.DailySummaryListItem_isPRN}  ${styles.wasUpdated}`
			: `${styles.DailySummaryListItem_isPRN}`;
	}

	if (isCompleted(task)) {
		return wasUpdated
			? `${styles.DailySummaryListItem_isCompleted} ${styles.wasUpdated}`
			: styles.DailySummaryListItem_isCompleted;
		// } else if (isPastDue(task, task?.TaskDate ?? new Date(), shiftTimes)) {
	} else if (isPastDue(task, dueDate, shiftTimes) && !isPRN && !withException) {
		// WORKING SHIFT ONLY VERSION
		// } else if (isPastDueByTimeOrShift(task, dueDate, shiftTimes)) {
		// WORKING SHIFT OR DATE VERSION
		return wasUpdated
			? `${styles.DailySummaryListItem_isPastDue} ${styles.wasUpdated}`
			: styles.DailySummaryListItem_isPastDue;
	} else {
		return wasUpdated
			? `${styles.DailySummaryListItem_isNotCompleted} ${styles.wasUpdated}`
			: styles.DailySummaryListItem_isNotCompleted;
	}
};

const getMatchingRecord = (task = {}, allRecords = {}) => {
	const taskType = getTaskType(task)?.toUpperCase();
	const { trackingTasks, unscheduledTasksRaw } = allRecords;

	switch (taskType) {
		case "SCHEDULED": {
			const match = trackingTasks.reduce((matchingRecord, record) => {
				const targetID = getTaskID(task);
				const recordID = getTaskID(record);

				if (recordID === targetID) {
					matchingRecord = { ...record };
					return matchingRecord;
				} else {
					return matchingRecord;
				}
			}, {});

			return match;
		}

		case "UNSCHEDULED": {
			const match = unscheduledTasksRaw.reduce((matchingRecord, record) => {
				const targetID = getTaskID(task);
				const recordID = getTaskID(record);

				if (recordID === targetID) {
					matchingRecord = { ...record };
					return matchingRecord;
				} else {
					return matchingRecord;
				}
			}, {});

			return match;
		}
		default:
			return {};
	}
};

const DailySummaryListItem = ({
	task,
	taskIndex,
	dispatch,
	trackingTasks = [],
	unscheduledTasksRaw = [],
	currentResident = {},
	currentUser = {},
	currentFacility = {},
	facilityExceptions = [],
	showTask = true,
	dispatchAlert,
	reloadResident,
	handleActive,
	listRef,
	scrollState = {},
	wasUpdated = false,
}) => {
	const saveRef = useRef();
	const matchingRecord = useRef(() => {
		return getMatchingRecord(task, {
			trackingTasks,
			unscheduledTasksRaw,
		});
	});
	const [showAdvancedModal, setShowAdvancedModal] = useState(false);
	const [changedRecurring, setChangedRecurring] = useState(false);
	// new task note
	const [wasNoteSaved, setWasNoteSaved] = useState(false);
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			exceptionType: matchExceptionDescFromTask(
				task,
				currentFacility.exceptions
			),
			reassess: false,
			reassessNotes: "",
			shift: "", // when shift was completed (ie statusing)
			taskNotes: "",
			syncNotesVal: "", // only used to track notes in children
			signature: "",
			markComplete: isCompleted(task),
			isLocked: isLocked(task),
			recurringType: getRecurringTypeFromID(task?.AssessmentRecurringId),
			isRecurring: isRecurring(task),
			recurringCycle: "1",
			recurringCycleOption: "",
			isRecurringAM: getDefaultShift("AM", task),
			isRecurringPM: getDefaultShift("PM", task),
			isRecurringNOC: getDefaultShift("NOC", task),
			isRecurringSun: getDefaultDay("Sun", task),
			isRecurringMon: getDefaultDay("Mon", task),
			isRecurringTue: getDefaultDay("Tue", task),
			isRecurringWed: getDefaultDay("Wed", task),
			isRecurringThu: getDefaultDay("Thu", task),
			isRecurringFri: getDefaultDay("Fri", task),
			isRecurringSat: getDefaultDay("Sat", task),
			startDate: getRecurringStartAndEnd(task).startDate,
			endDate: getRecurringStartAndEnd(task).endDate,
			// scheduled due date/time
			scheduledDueDate: getCurrentDate(
				task,
				new Date(),
				currentFacility?.shifts
			),
			// scheduledDueTime: getCurrentTime(
			// 	getMatchingRecord(task, { trackingTasks, unscheduledTasksRaw }),
			// 	new Date(),
			// 	currentFacility?.shifts ?? []
			// ),
			scheduledDueTime: getTaskDueTimeByTaskType(
				task,
				new Date(),
				currentFacility?.shifts ?? [],
				{ trackingTasks, unscheduledTasksRaw }
			),
			// NEW TASK NOTES FIELDS
			trackingID: task?.AssessmentTrackingId ?? 0,
			taskID:
				task?.AssessmentTrackingTaskId ?? task?.AssessmentUnscheduleTaskId ?? 0,
			newNote: "",
			createdBy: currentUser?.userID,
			createdDate: null,
			entryDate: null,
			entryByUserId: currentUser?.userID,
			initialBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
			isActive: true,
			isStrikeOut: false,
			strikeOutByUserId: null,
			strikeOutDate: null,
			modifiedDate: null,
			modifiedBy: currentUser?.userID,
		});
	const { values, touched } = formState;

	// opens 'Advanced Modal' & perists scroll position
	const initAdvancedModal = (e) => {
		e.stopPropagation();
		e.nativeEvent.stopPropagation();
		setShowAdvancedModal(true);

		// passes active taskID to parent
		return handleActive({
			...scrollState,
			scrollPos: listRef?.current?.scrollTop,
			active: getTaskID(task),
		});
	};

	// just monitors task notes changes
	const syncTaskNotes = (e) => {
		const { value } = e.target;

		setFormState({
			...formState,
			values: {
				...values,
				syncNotesVal: value,
			},
		});
	};

	const handleDueDate = (name, date) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: date,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};
	const handleDueTime = (name, time) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: time,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	const handleAdvancedCompletion = () => {
		const { markComplete } = values;
		return setFormState({
			...formState,
			values: {
				...values,
				markComplete: !markComplete,
			},
			touched: {
				...touched,
				markComplete: !markComplete,
			},
		});
	};
	// handle custom dropddowns & date pickers
	const handleTaskSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...formState.values,
				[name]: val,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};
	// handle radios in create task form
	const handleShift = (shift) => {
		setFormState({
			...formState,
			values: {
				...values,
				shift: shift,
			},
		});
	};
	const handleCycleOptions = (val) => {
		return setFormState({
			...formState,
			values: {
				...values,
				recurringCycleOption: val,
			},
		});
	};
	const handleDateRange = ({ startDate, endDate }) => {
		return setFormState({
			...formState,
			values: {
				...values,
				startDate,
				endDate,
			},
		});
	};
	// handles days and shifts for recurring
	const handleRecurrences = (name) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: !values[name],
			},
		});
	};

	// triggers 'quick-complete' & persists scroll position
	const initQuickComplete = (e) => {
		handleCheckbox(e);
		handleActive({
			wasUpdated: true, // this enables/disables update 'animation'
			scrollPos: listRef?.current?.scrollTop,
			active: getTaskID(task),
		});
		toggleCompleteHandler(task);
	};

	// toggles a single task complete/pending
	// consider passing the task's index to the dispatch and using Array.splice()...
	// ##TODOS:
	// - Consider removing: "ModifiedDate" & "ModifiedBy" fields
	const toggleCompleteHandler = (task) => {
		const { token } = currentUser;
		const updated = toggleComplete(task, currentFacility?.shifts, currentUser);

		console.log("updated", updated);

		// handle ALL updates by task type
		if (isScheduledTask(task)) {
			// update global store

			dispatch({
				type: "TOGGLE_COMPLETE",
				data: {
					updatedTask: updated,
					taskType: "SCHEDULED",
				},
			});

			recordQuickComplete(
				token,
				updated,
				trackingTasks,
				unscheduledTasksRaw,
				currentFacility.shifts,
				currentUser
			);

			return dispatchAlert("success", {
				heading: "Success!",
				subheading: updated.IsCompleted
					? `Task marked 'COMPLETE'.`
					: "Task was updated.",
			});
			// send request
		} else {
			recordQuickComplete(
				token,
				updated,
				// task,
				trackingTasks,
				unscheduledTasksRaw,
				currentFacility.shifts,
				currentUser
			);

			// update global store
			dispatch({
				type: "TOGGLE_COMPLETE",
				data: {
					updatedTask: updated,
					taskType: "UNSCHEDULED",
				},
			});
			return dispatchAlert("success", {
				heading: "Success!",
				subheading: updated.IsCompleted
					? `Task marked 'COMPLETE'.`
					: "Task was updated.",
			});
			// send request
			// return;
		}
	};

	const saveNewTaskNote = async () => {
		const { token } = currentUser;
		// creates client-side & server-formatted note models
		const { client, server } = initAndUpdateNewTaskNote(task, {
			...values,
			entryDate: new Date().toISOString(),
			createdDate: new Date().toISOString(),
			modifiedDate: new Date().toISOString(),
		});

		// const wasSaved = true;
		const wasSaved = await saveTaskNotes(token, server);

		if (wasSaved) {
			// const newNotesList = [...allNotes, client];
			// setAllNotes([...newNotesList]);
			// prolly need to sync new task note to <TaskUpdateForm/> above, or re-fetch task notes
			// handleReset(e);
			return setWasNoteSaved(true);
		} else {
			return setWasNoteSaved(false);
		}
	};
	const cancelNewTaskNote = (e) => {
		handleReset(e);
	};

	// only saves a single section; does close modal
	// save 'Save Section Changes' handler
	// ##TODOS:
	// - Consider removing: "ModifiedDate" & "ModifiedBy" fields
	const saveSectionChanges = async (e) => {
		const { token, userID } = currentUser;
		// merge 'changedSettings' into values
		const vals = {
			...values,
			changedSettings: changedSettings(values, task),
		};

		// - Added 'currentUser' as arg to task updater util
		const updatedTask = applyUpdatesToTask(
			formState,
			// vals, // REMOVED TO PROVIDE 'formState' instead
			task,
			currentFacility?.exceptions,
			currentFacility?.shifts,
			currentUser
		);

		console.log("changedSettings", vals.changedSettings);
		console.log("updatedTask", updatedTask);

		// const success = true; // USED FOR DUMMY TESTING
		// fire off tracking task update request
		const success = await saveAppliedTaskUpdates(
			token,
			formState,
			// vals,
			task,
			trackingTasks,
			unscheduledTasksRaw,
			currentFacility?.exceptions,
			currentFacility?.shifts,
			currentUser
		);
		// fire off reassess tracking update
		const { success: wasReassessed, reassessRecord } =
			await applyReassessToTracking(
				token,
				vals,
				// values,
				userID,
				updatedTask,
				currentUser
			);

		setShowAdvancedModal(true);
		if (success) {
			dispatch({
				type: "ADVANCED_UPDATE",
				data: {
					updatedTask: { ...updatedTask },
					taskType: isScheduledTask(updatedTask) ? "SCHEDULED" : "UNSCHEDULED",
					reassessRecord: reassessRecord,
				},
			});
			handleActive({
				...scrollState,
				wasUpdated: true,
			});
			return dispatchAlert("info", {
				heading: "Success!",
				subheading: "Your task was updated.",
			});
		} else {
			handleReset(e);
			return dispatchAlert("error", {
				heading: "Error!",
				subheading: "Oops. Something went wrong. Please try again.",
			});
		}
	};

	// consider passing "task" as the argument, to make it reusable, and specific
	// 'Save All Changes'
	const saveChanges = async (task) => {
		const { token, userID } = currentUser;
		// merge 'changedSettings' into values
		const vals = {
			...values,
			changedSettings: changedSettings(values, task),
		};

		const updatedTask = applyUpdatesToTask(
			vals,
			task,
			facilityExceptions,
			currentFacility?.shifts,
			currentUser
		);
		const success = await saveAppliedTaskUpdates(
			token,
			values,
			task,
			trackingTasks,
			unscheduledTasksRaw,
			facilityExceptions,
			currentFacility?.shifts,
			currentUser
		);

		// fire off reassess tracking update
		const { success: wasReassessed, reassessRecord } =
			await applyReassessToTracking(
				token,
				vals,
				// values,
				userID,
				updatedTask,
				currentUser
			);

		if (success) {
			if (!isEmptyVal(values?.newNote)) {
				saveNewTaskNote();
			}

			dispatch({
				type: "ADVANCED_UPDATE",
				data: {
					updatedTask: { ...updatedTask },
					taskType: isScheduledTask(updatedTask) ? "SCHEDULED" : "UNSCHEDULED",
				},
			});
			setShowAdvancedModal(false);
			// fire off some indicator here
			handleActive({
				...scrollState,
				wasUpdated: true,
			});
			// reset 'new task notes' tracking values
			setFormState({
				...formState,
				values: { ...values, taskNotes: "", syncNotesVal: "" },
			});
			return dispatchAlert("info", {
				heading: "Success!",
				subheading: "Your task was updated.",
			});
		} else {
			// fire off some indicator here
			return dispatchAlert("error", {
				heading: "Error!",
				subheading: "Oops. Something went wrong. Please try again.",
			});
		}
	};
	const cancelChanges = (e) => {
		handleReset(e);
		setShowAdvancedModal(false);
	};

	// NEW SAVE ALL CHANGES
	const saveAllChanges = async (task) => {
		// merge 'changedSettings' into values
		const vals = {
			...values,
			changedSettings: changedSettings(values, task),
		};

		// update task - MIGHT NOT BE NEEDED, SINCE I REFETCH RESIDENT DATA
		const updatedUITask = applyUpdatesToTask(
			formState,
			// vals,
			task,
			facilityExceptions,
			currentFacility?.shifts,
			currentUser
		);
		// find and apply updates tracking/unschedule record
		const updatedRecord = findAndApplyTaskRecordUpdates(
			formState,
			// vals,
			task,
			trackingTasks,
			unscheduledTasksRaw,
			facilityExceptions,
			currentFacility?.shifts,
			currentUser
		);

		// fire off request(s)
		const { wasTaskSaved, wasReassessSaved, wasNoteSaved, reassessRecord } =
			await saveAllChangesHandler(vals, updatedRecord, currentUser);

		if (wasTaskSaved) {
			dispatch({
				type: "ADVANCED_UPDATE",
				data: {
					updatedTask: { ...updatedUITask },
					taskType: isScheduledTask(updatedUITask)
						? "SCHEDULED"
						: "UNSCHEDULED",
					reassessRecord: reassessRecord,
				},
			});

			setShowAdvancedModal(false);
			// fire off some indicator here
			handleActive({
				...scrollState,
				wasUpdated: true,
			});

			// reset 'new task notes' tracking values
			setFormState({
				...formState,
				values: { ...values, taskNotes: "", syncNotesVal: "", newNote: "" },
			});
			return dispatchAlert("info", {
				heading: "Success!",
				subheading: "Your task was updated.",
			});
		} else {
			// fire off some indicator here
			return dispatchAlert("error", {
				heading: "Error!",
				subheading: "Oops. Something went wrong. Please try again.",
			});
		}
	};

	// deletes unscheduled tasks ONLY
	const deleteTask = async (task) => {
		const { token } = currentUser;
		const id = task?.AssessmentUnscheduleTaskId ?? null;
		const wasSuccessful = await deleteUnscheduledByID(token, id);
		setShowAdvancedModal(false);

		if (wasSuccessful) {
			reloadResident();
			return dispatchAlert("SUCCESS", {
				heading: `Success!`,
				subheading: `Task was deleted!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Oops!`,
				subheading: `'deleteTask()' failed!`,
			});
		}
	};

	// resets 'wasNoteSaved' after 3 seconds
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let resetID;
		if (wasNoteSaved) {
			resetID = setTimeout(() => {
				setWasNoteSaved(false);
			}, 3000);
		}

		return () => {
			isMounted = false;
			clearTimeout(resetID);
		};
	}, [wasNoteSaved]);

	// listens for 'recurring settings' changes
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		const hasChanged = () => {
			const changed = changedSettings(values, task);
			if (!changed) return;
			setChangedRecurring(changed);
		};

		hasChanged();

		return () => {
			isMounted = false;
		};
	}, [task, values]);

	if (!showTask) {
		return null;
	}
	return (
		<>
			<li
				title={task.TaskDescription}
				className={getStatusStyles(
					task,
					values,
					wasUpdated,
					currentFacility?.shifts
				)}
			>
				{/* TASK INDEX NUMBER (LEFT-CORNER) */}
				<span className={styles.DailySummaryListItem_taskNumber}>
					{taskIndex}
				</span>
				<span className={styles.DailySummaryListItem_shift}>
					{getTaskShift(task)}
				</span>
				<section className={styles.DailySummaryListItem_details}>
					<div
						className={styles.DailySummaryListItem_details_desc}
						style={addStrike(task)}
					>
						{getTaskName(task)}
					</div>
					{/* EXCEPTION INDICATOR */}
					{hasException(task) && (
						<div className={styles.DailySummaryListItem_details_exception}>
							<div
								className={styles.DailySummaryListItem_details_exception_desc}
							>
								<div
									className={
										styles.DailySummaryListItem_details_exception_desc_text
									}
								>
									{matchExceptionDescFromTask(task, facilityExceptions)}
								</div>
							</div>
							<svg
								className={styles.DailySummaryListItem_details_exception_icon}
							>
								<use xlinkHref={`${sprite2}#icon-error_outline`}></use>
							</svg>
						</div>
					)}
				</section>
				{/* MARK-COMPLETE CHECKBOX (small colored circle) */}
				<div className={styles.DailySummaryListItem_item}>
					<div className={styles.DailySummaryListItem_item_markComplete}>
						<CheckboxSM
							key={`--${getTaskID(task)}--`}
							id={`--${getTaskID(task)}--`}
							label="Complete"
							name="markComplete"
							val={values.markComplete}
							customTxt={{ color: themeColors.main.green }}
							handleCheckbox={initQuickComplete}
						/>
					</div>

					{/* ICON - INDICATES RECURRING/REPEATING TASK */}
					{hasRepeat(task) && (
						<div
							className={styles.DailySummaryListItem_item_repeat}
							title="Repeating Task"
						>
							<svg className={styles.DailySummaryListItem_item_repeat_icon}>
								<use xlinkHref={`${sprite}#icon-cycle`}></use>
							</svg>
						</div>
					)}
					{/* 'CREATED TASK' INDICATOR ICON */}
					{!isScheduledTask(task) && (
						<div
							className={styles.DailySummaryListItem_item_createdTask}
							title={`Created Task on ${getCreatedDate(task)}`}
						>
							<svg
								className={styles.DailySummaryListItem_item_createdTask_icon}
							>
								<use xlinkHref={`${sprite2}#icon-add2`}></use>
							</svg>
						</div>
					)}
					{/* ADVANCED OPTIONS BUTTON */}
					<section className={styles.DailySummaryListItem_item_options}>
						<button
							className={styles.DailySummaryListItem_item_advanced}
							title="Show Advanced Options"
							onClick={initAdvancedModal}
						>
							Advanced
						</button>
					</section>
				</div>
				{wasUpdated && <Beacon size="XSM" />}
			</li>

			{showAdvancedModal && (
				<ModalLG
					title="Advanced Options"
					closeModal={() => setShowAdvancedModal(false)}
				>
					<TaskUpdateForm
						key={`TASK-UPDATE-FORM-${wasNoteSaved}`}
						wasNoteSaved={wasNoteSaved}
						saveRef={saveRef}
						task={task}
						vals={values}
						formState={formState}
						setFormState={setFormState}
						saveSectionChanges={saveSectionChanges}
						// saveChanges={() => saveChanges(task)}
						saveChanges={() => saveAllChanges(task)}
						deleteTask={() => deleteTask(task)}
						cancelChanges={cancelChanges}
						handleChange={handleChange}
						handleShift={handleShift}
						handleCheckbox={handleCheckbox}
						handleSettings={handleTaskSettings}
						handleDueDate={handleDueDate}
						handleDueTime={handleDueTime}
						syncTaskNotes={syncTaskNotes}
						handleAdvancedCompletion={handleAdvancedCompletion}
						handleCycleOptions={handleCycleOptions}
						currentResident={currentResident}
						currentUser={currentUser}
						currentFacility={currentFacility}
						facilityExceptions={facilityExceptions}
						handleDateRange={handleDateRange}
						handleRecurrences={handleRecurrences}
						changedRecurringSettings={changedRecurring}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default React.memo(DailySummaryListItem);

DailySummaryListItem.defaultProps = {
	currentUser: {},
	currentResident: {},
	trackingTasks: [],
	unscheduledTasksRaw: [],
	facilityExceptions: [],
};

DailySummaryListItem.propTypes = {
	task: PropTypes.object.isRequired,
	taskIndex: PropTypes.number.isRequired,
	dispatch: PropTypes.func.isRequired,
	trackingTasks: PropTypes.array.isRequired,
	unscheduledTasksRaw: PropTypes.array.isRequired,
	currentResident: PropTypes.object.isRequired,
	currentUser: PropTypes.object.isRequired,
	facilityExceptions: PropTypes.array.isRequired,
};
